import {ManageUserModalMode} from '@/enums/modal-modes/manage-user-modal-mode';
import {RoleCode} from '@/enums/role-code';
import {SiteSpecificRoleCode} from '@/enums/site-specific-role-code';
import {LoginResponse} from '@/models/api/responses/authentication/LoginResponse';
import {ManageUserForm} from '@/models/users/ManageUserForm';
import {UserFarmSiteRoleLevelAssignmentsDto} from '@/models/users/UserFarmSiteRoleLevelAssignmentsDto';
import {defineStore} from 'pinia';
import {computed, ref, watch} from 'vue';
import {useActionWorkTaskModalStore} from '@/stores/modals/action-work-task-modal-store';
import {useAdvancedSearchModalStore} from '@/stores/modals/advanced-search-modal-store';
import {useApplyWorkTaskPlanModalStore} from '@/stores/modals/apply-work-task-plan-modal-store';
import {useClearTasksModalStore} from '@/stores/modals/clear-tasks-modal-store';
import {useDeleteFarmFieldModalStore} from '@/stores/modals/delete-farm-field-modal-store';
import {useDeleteFarmSiteModalStore} from '@/stores/modals/delete-farm-site-modal-store';
import {useDeleteOrCancelWorkTaskModalStore} from '@/stores/modals/delete-or-cancel-work-task-modal-store';
import {useDeleteUserModalStore} from '@/stores/modals/delete-user-modal-store';
import {useDeleteWorkTaskPlanModalStore} from '@/stores/modals/delete-work-task-plan-modal-store';
import {useDeleteWorkTaskTemplateModalStore} from '@/stores/modals/delete-work-task-template-modal-store';
import {useManageFarmFieldModalStore} from '@/stores/modals/manage-farm-field-modal-store';
import {useManageFarmFieldSeasonModalStore} from '@/stores/modals/manage-farm-field-season-modal-store';
import {useManageFarmSiteModalStore} from '@/stores/modals/manage-farm-site-modal-store';
import {useManageObservationModalStore} from '@/stores/modals/manage-observation-modal-store';
import {useManageUserModalStore} from '@/stores/modals/manage-user-modal-store';
import {useManageWorkTaskModalStore} from '@/stores/modals/manage-work-task-modal-store';
import {useManageWorkTaskPlanModalStore} from '@/stores/modals/manage-work-task-plan-modal-store';
import {useRescheduleWorkTaskModalStore} from '@/stores/modals/reschedule-work-task-modal-store';
import {useResetUserPasswordModalStore} from '@/stores/modals/reset-user-password-modal-store';
import {useSelectWorkTaskTemplateModalStore} from '@/stores/modals/select-work-task-template-modal-store';
import {useViewCropVarietyModalStore} from '@/stores/modals/view-crop-variety-modal-store';
import {useViewFarmFieldModalStore} from '@/stores/modals/view-farm-field-modal-store';
import {useViewFarmSiteModalStore} from '@/stores/modals/view-farm-site-modal-store';
import {useViewMixtureModalStore} from '@/stores/modals/view-mixture-modal-store';
import {useViewObservationModalStore} from '@/stores/modals/view-observation-modal-store';
import {useViewProductModalStore} from '@/stores/modals/view-product-modal-store';
import {useViewWorkTaskModalStore} from '@/stores/modals/view-work-task-modal-store';
import {useViewWorkTaskPlanModalStore} from '@/stores/modals/view-work-task-plan-modal-store';
import {useViewWorkTaskTemplateModalStore} from '@/stores/modals/view-work-task-template-modal-store';
import {useFarmStore} from '@/stores/farm-store';

export const useUserStore = defineStore('user', () => {
    //Stores

    const actionWorkTaskModalStore = useActionWorkTaskModalStore();
    const advancedSearchModalStore = useAdvancedSearchModalStore();
    const applyWorkTaskPlanModalStore = useApplyWorkTaskPlanModalStore();
    const clearTasksModalStore = useClearTasksModalStore();
    const deleteFarmFieldModalStore = useDeleteFarmFieldModalStore();
    const deleteFarmSiteModalStore = useDeleteFarmSiteModalStore();
    const deleteOrCancelWorkTaskModalStore = useDeleteOrCancelWorkTaskModalStore();
    const deleteUserModalStore = useDeleteUserModalStore();
    const deleteWorkTaskPlanModalStore = useDeleteWorkTaskPlanModalStore();
    const deleteWorkTaskTemplateModalStore = useDeleteWorkTaskTemplateModalStore();
    const manageFarmFieldModalStore = useManageFarmFieldModalStore();
    const manageFarmFieldSeasonModalStore = useManageFarmFieldSeasonModalStore();
    const manageFarmSiteModalStore = useManageFarmSiteModalStore();
    const manageObservationModalStore = useManageObservationModalStore();
    const manageUserModalStore = useManageUserModalStore();
    const manageWorkTaskModalStore = useManageWorkTaskModalStore();
    const manageWorkTaskPlanModalStore = useManageWorkTaskPlanModalStore();
    const rescheduleWorkTaskModalStore = useRescheduleWorkTaskModalStore();
    const resetUserPasswordModalStore = useResetUserPasswordModalStore();
    const selectWorkTaskTemplateModalStore = useSelectWorkTaskTemplateModalStore();
    const viewCropVarietyModalStore = useViewCropVarietyModalStore();
    const viewFarmFieldModalStore = useViewFarmFieldModalStore();
    const viewFarmSiteModalStore = useViewFarmSiteModalStore();
    const viewMixtureModalStore = useViewMixtureModalStore();
    const viewObservationModalStore = useViewObservationModalStore();
    const viewProductModalStore = useViewProductModalStore();
    const viewWorkTaskModalStore = useViewWorkTaskModalStore();
    const viewWorkTaskPlanModalStore = useViewWorkTaskPlanModalStore();
    const viewWorkTaskTemplateModalStore = useViewWorkTaskTemplateModalStore();
    const farmStore = useFarmStore();

    function closeAllPopups() {
        actionWorkTaskModalStore.close();
        advancedSearchModalStore.close();
        applyWorkTaskPlanModalStore.close();
        clearTasksModalStore.close();
        deleteFarmFieldModalStore.close();
        deleteFarmSiteModalStore.close();
        deleteOrCancelWorkTaskModalStore.close();
        deleteUserModalStore.close();
        deleteWorkTaskPlanModalStore.close();
        deleteWorkTaskTemplateModalStore.close();
        manageFarmFieldModalStore.close();
        manageFarmFieldSeasonModalStore.close();
        manageFarmSiteModalStore.close();
        manageObservationModalStore.close();
        manageUserModalStore.close();
        manageWorkTaskModalStore.close();
        manageWorkTaskPlanModalStore.close();
        rescheduleWorkTaskModalStore.close();
        resetUserPasswordModalStore.close();
        selectWorkTaskTemplateModalStore.close();
        viewCropVarietyModalStore.close();
        viewFarmFieldModalStore.close();
        viewFarmSiteModalStore.close();
        viewMixtureModalStore.close();
        viewObservationModalStore.close();
        viewProductModalStore.close();
        viewWorkTaskModalStore.close();
        viewWorkTaskPlanModalStore.close();
        viewWorkTaskTemplateModalStore.close();
    }

    const localStorageUserKey = 'AGRIAPP_USER';

    // Reactive state holding the user object
    const user = ref<LoginResponse | null>(null);

    // Initialize the user object from local storage if available
    const savedUser = localStorage.getItem(localStorageUserKey);
    if (savedUser) {
        user.value = JSON.parse(savedUser);
    }

    /**
     * Set user data.
     */
    function login(userDetails: LoginResponse) {
        user.value = userDetails;
    }

    /**
     * Clear user data.
     */
    function logout() {
        farmStore.selectedSiteId = undefined;
        farmStore.farmSites = [];
        user.value = null;
    }

    /**
     * Checks to see if the user's level for a role is greater than or equal to the specified role level.
     * @param roleCode Role to check access for.
     * @param minimumRequiredRoleLevel Minimum role level for check for.
     * @returns True if the user's access level for the specified roleCode is greater than or equal to the
     * level specified in minimumRequiredRoleLevel.
     */
    function hasRoleLevel(
        roleCode: RoleCode,
        minimumRequiredRoleLevel: number,
        farmSiteId: number | null = null,
    ): boolean {
        const roleLevel = getRoleLevel(roleCode, farmSiteId);

        // Check if user has the minimum role level
        return roleLevel >= minimumRequiredRoleLevel;
    }

    /**
     * Gets the user's access level for the specified role.
     * @param roleCode Role to get access for.
     * @returns User's role level.
     */
    function getRoleLevel(roleCode: RoleCode, farmSiteId: number | null = null): number {
        // Check if role is site specific
        if (isSiteSpecificRole(roleCode)) {
            // Get site-specific roles
            const siteRoles = user.value?.farmSiteRoleLevels;
            if (!siteRoles) return 0;

            const currentFarmSiteId = farmSiteId ?? user.value?.farmSiteId;

            // Find the site-specific role based on current farm site
            const siteRole = siteRoles.find((sr) => sr.roleCode === roleCode && sr.farmSiteId === currentFarmSiteId);

            return siteRole?.roleLevel || 0;
        } else {
            // Get roles
            const userRoles = user.value?.roleLevels;
            if (!userRoles) return 0;

            // Get the role assignment for the specified role
            const role = userRoles.find((ra) => ra.roleCode === roleCode);

            return role?.roleLevel || 0;
        }
    }

    /**
     * Determines if a role is site-specific
     */
    function isSiteSpecificRole(roleCode: RoleCode): boolean {
        return Object.values(SiteSpecificRoleCode).includes(roleCode as string as SiteSpecificRoleCode);
    }

    /**
     * Update the user store based on the details of the saved Manage User form.
     * @param userForm User details that were saved.
     */
    function updateAfterSavingUser(userForm: ManageUserForm, modalMode: ManageUserModalMode) {
        if (user.value != null && userForm.userId == user.value.userId) {
            user.value.firstName = userForm.userGivenName !== null ? userForm.userGivenName : undefined;
            user.value.lastName = userForm.userSurname !== null ? userForm.userSurname : undefined;
            user.value.username = userForm.username !== null ? userForm.username : undefined;
            user.value.emailAddress = userForm.userEmailAddress !== null ? userForm.userEmailAddress : undefined;

            if (modalMode == ManageUserModalMode.Modify) {
                user.value.roleLevels = userForm.userRoleLevelAssignments;

                if (user.value.farmSiteRoleLevels) {
                    userForm.userFarmSiteRoleLevelAssignment.forEach((roleAssignment) => {
                        const siteRoles = user.value?.farmSiteRoleLevels.filter(
                            (f) => f.farmSiteId == roleAssignment.farmSiteId,
                        );

                        if (siteRoles) {
                            updateRoleLevel(siteRoles, 'Fields', roleAssignment.fieldLevel);
                            updateRoleLevel(siteRoles, 'Tasks', roleAssignment.taskLevel);
                            updateRoleLevel(siteRoles, 'Observations', roleAssignment.observationLevel);
                        }
                    });
                }
            }
        }
    }

    /**
     * Update specific site level.
     * @param roleAssignments User's roles filtered by farm site.
     * @param roleCode The code being updated.
     * @param roleLevel The new role level to assign.
     */
    function updateRoleLevel(
        roleAssignments: UserFarmSiteRoleLevelAssignmentsDto[],
        roleCode: string,
        roleLevel: number,
    ) {
        const role = roleAssignments.find((f) => f.roleCode === roleCode);
        if (role) {
            role.roleLevel = roleLevel;
        }
    }

    /**
     * Check if the user is logged in.
     */
    const isLoggedIn = computed(() => !!user.value);

    /**
     * Warning when accessing log out only pages.
     */
    const showLogoutWarning = ref<boolean>(false);

    function setShowLogoutWarning(value: boolean) {
        showLogoutWarning.value = value;
    }

    // Watch the user state and update local storage
    watch(
        user,
        (newUser) => {
            if (newUser) {
                localStorage.setItem(localStorageUserKey, JSON.stringify(newUser));
            } else {
                localStorage.removeItem(localStorageUserKey);
            }
        },
        {deep: true},
    );

    return {
        user,
        login,
        logout,
        hasRoleLevel,
        getRoleLevel,
        updateAfterSavingUser,
        isLoggedIn,
        showLogoutWarning,
        setShowLogoutWarning,
        closeAllPopups,
    };
});
