import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1c59139d"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "chart-container"
}
const _hoisted_2 = { class: "swatchWHT pt-2 pl-3" }
const _hoisted_3 = { class: "swatchWHT pr-5" }
const _hoisted_4 = { class: "larger-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_highcharts = _resolveComponent("highcharts")!

  return (!_ctx.isHide)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_v_row, { class: "pa-1" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.workTaskCodeName), 1),
            _createVNode(_component_v_spacer),
            _createTextVNode(),
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.workTaskCodeColorClasses)
            }, null, 2)
          ]),
          _: 1
        }),
        _createVNode(_component_highcharts, { options: _ctx.chartOptions }, null, 8, ["options"]),
        (_ctx.totalArea != null && _ctx.totalArea != undefined)
          ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
              default: _withCtx(() => [
                _createVNode(_component_v_spacer),
                _createElementVNode("span", _hoisted_3, [
                  _createElementVNode("span", _hoisted_4, [
                    _createElementVNode("b", null, _toDisplayString(_ctx.$n(_ctx.totalArea, 'decimal')), 1)
                  ]),
                  _createTextVNode(" Ha")
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}