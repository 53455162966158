<template>
    <v-form ref="form" v-model="valid">
        <table style="width: 100%; border-spacing: 1px; margin-bottom: -1px" class="tblForm swatchG9BG">
            <tr v-if="index == 0">
                <td class="swatchG7BG">{{ $t('BlockCode') }}</td>
                <td class="swatchG7BG">{{ $t('Variety') }}</td>
                <td class="swatchG7BG">{{ $t('SeedOrigin') }}</td>
                <td class="swatchG7BG">{{ $t('SeedRate') }}</td>
                <td class="swatchG7BG">{{ $t('RowsPlanted') }}</td>
                <td class="swatchG7BG"></td>
            </tr>
            <tr>
                <td style="width: 7%; text-align: center" class="swatchWHTBG swatchA1 bold">
                    {{ `${farmField.farmFieldCode}.${localWorkTaskFarmBlock.farmBlockCode}` }}
                </td>
                <td style="width: 22%" class="swatchWHTBG">
                    <div style="display: flex; align-items: center">
                        <v-autocomplete
                            v-model="localWorkTaskFarmBlock.cropVarietyId"
                            :rules="[requiredRule]"
                            required
                            :placeholder="$t('SelectAVariety')"
                            :items="filteredCropVarieties"
                            item-title="cropVarietyName"
                            item-value="cropVarietyId"
                            max-width="220px"
                            menu-icon=""
                            class="swatchA1 font-weight-bold"
                            :class="{noSelection: !isLastItem}"
                            @update:model-value="addWorkTaskFarmBlock">
                            <template v-slot:append-inner>
                                <CircleIcon
                                    class="marginLeft marginRightSmall swatchGRNBG"
                                    color="swatchWHT"
                                    v-if="localWorkTaskFarmBlock.cropVarietyId != null">
                                    <FontAwesomeIcon :icon="['fas', 'check']" />
                                </CircleIcon>
                            </template>
                        </v-autocomplete>

                        <!-- View Crop Variety button -->
                        <v-btn
                            v-if="localWorkTaskFarmBlock.cropVarietyId != null"
                            @click="viewCropVarietyModalStore.open(localWorkTaskFarmBlock.cropVarietyId)"
                            class="v-btn--custom ml-4">
                            <template v-slot:prepend>
                                <FontAwesomeIcon :icon="['fal', 'arrow-circle-right']" size="xl" />
                            </template>
                            {{ $t('View') }}
                        </v-btn>
                    </div>
                </td>
                <td style="width: 18%; text-align: center" class="swatchWHTBG">
                    <v-autocomplete
                        v-model="localWorkTaskFarmBlock.seedOriginBlockId"
                        :placeholder="$t('SelectSeedOrigin')"
                        :items="
                            farmBlocks.filter(
                                (fb) =>
                                    localWorkTaskFarmBlock.cropVarietyId != null &&
                                    fb.cropVarietyId == localWorkTaskFarmBlock.cropVarietyId,
                            )
                        "
                        item-title="farmBlockFullName"
                        item-value="farmBlockId"
                        max-width="250px"
                        menu-icon=""
                        class="swatchA1 font-weight-bold">
                        <template v-slot:append-inner>
                            <CircleIcon
                                class="marginLeft marginRightSmall swatchGRNBG"
                                color="swatchWHT"
                                v-if="localWorkTaskFarmBlock.seedOriginBlockId != null">
                                <FontAwesomeIcon :icon="['fas', 'check']" />
                            </CircleIcon>
                        </template>
                    </v-autocomplete>
                </td>
                <td style="width: 20%" class="swatchWHTBG">
                    <div style="display: flex; align-items: center">
                        <LocalizedNumberInput
                            v-model="localWorkTaskFarmBlock.seedRate"
                            :rules="[requiredRule, numericGreaterThanZeroRule]"
                            style="max-width: 100px"
                            class="swatchA1 font-weight-bold"
                            @blur="addWorkTaskFarmBlock" />

                        <v-select
                            v-model="localWorkTaskFarmBlock.seedRateUnitCode"
                            :rules="[requiredRule]"
                            required
                            :items="seedRateUnits"
                            :item-title="(item: SeedRateUnit) => item.getSeedRateUnitName()"
                            item-value="seedRateUnitCode"
                            max-width="180px"
                            class="ml-2"
                            @update:model-value="addWorkTaskFarmBlock" />
                    </div>
                </td>
                <td style="width: 28%; text-align: center" class="swatchWHTBG">
                    <div style="display: flex; align-items: center">
                        {{ $t('From') }}

                        <v-text-field
                            v-model="localWorkTaskFarmBlock.startRow"
                            :readonly="true"
                            max-width="90px"
                            class="swatchA1 font-weight-bold ml-2"
                            @blur="addWorkTaskFarmBlock">
                            <template v-slot:append-inner>
                                <CircleIcon class="swatchG2BG" color="swatchWHT">
                                    <FontAwesomeIcon :icon="['fas', 'lock']" />
                                </CircleIcon>
                            </template>
                        </v-text-field>

                        <span class="ml-4">{{ $t('To') }}</span>

                        <v-text-field
                            v-model="localWorkTaskFarmBlock.endRow"
                            :rules="[requiredRule, numericRowRule, wholeNumberRule]"
                            required
                            :readonly="!isLastItem"
                            max-width="90px"
                            class="swatchA1 font-weight-bold ml-2"
                            @blur="addWorkTaskFarmBlock">
                            <template v-slot:append-inner>
                                <CircleIcon class="swatchG2BG" color="swatchWHT" v-if="!isLastItem">
                                    <FontAwesomeIcon :icon="['fas', 'lock']" />
                                </CircleIcon>
                            </template>
                        </v-text-field>

                        <div v-if="numRows" style="display: flex; align-items: center">
                            <span class="ml-2 swatchA1">{{ numRows }}</span>
                            <span class="ml-1">{{ numRows == 1 ? $t('Row') : $t('Rows') }}</span>
                            <span v-if="localWorkTaskFarmBlock.farmBlockSize != null" class="ml-1 swatchA1">
                                ({{ $n(localWorkTaskFarmBlock.farmBlockSize, 'decimal') }} ha)
                            </span>
                        </div>
                    </div>
                </td>
                <td style="width: 5%; text-align: center" class="swatchWHTBG">
                    <v-btn v-if="index != 0" @click="deleteWorkTaskFarmBlock(index)" class="v-btn--custom">
                        <FontAwesomeIcon :icon="['fal', 'trash']" size="xl" />
                    </v-btn>
                </td>
            </tr>
        </table>
    </v-form>
</template>

<script setup lang="ts">
import Validation from '@/helpers/ValidationHelper';
import i18n from '@/i18n';
import {computed, inject, nextTick, onMounted, ref, watch} from 'vue';
import {ActionWorkTaskFarmBlockForm} from '@/models/work-tasks/ActionWorkTaskFarmBlockForm';
import {GetCropVarietiesResponse} from '@/models/api/responses/data-source/GetCropVarietiesResponse';
import {GetFarmBlocksResponse} from '@/models/api/responses/data-source/GetFarmBlocksResponse';
import {SeedRateUnit, seedRateUnits} from '@/services/seed-rate-units-service';
import {WorkTaskSearchResultDto} from '@/models/data-transfer-objects/search/work-task-search/WorkTaskSearchResultDto';
import {useViewCropVarietyModalStore} from '@/stores/modals/view-crop-variety-modal-store';
import {FarmFieldSearchResultDto} from '@/models/data-transfer-objects/search/farm-field-search/FarmFieldSearchResultDto';
import ApiService from '@/services/api-service';
import {GetFarmBlockSizeRequest} from '@/models/api/requests/farm/GetFarmBlockSizeRequest';
const viewCropVarietyModalStore = useViewCropVarietyModalStore();

const props = defineProps<{
    workTaskFarmBlock?: ActionWorkTaskFarmBlockForm | null;
    workTaskFarmBlockList: ActionWorkTaskFarmBlockForm[];
    workTask: WorkTaskSearchResultDto;
    farmField: FarmFieldSearchResultDto;
    cropId: number | null;
    cropVarieties: GetCropVarietiesResponse[];
    farmBlocks: GetFarmBlocksResponse[];
    index: number;
}>();
const emit = defineEmits(['add', 'update', 'delete']);

// Services
const apiService = inject('apiService') as ApiService;

// Form
const form = ref();
const valid = ref<boolean>(true);
const isLoading = ref<boolean>(true);
const localWorkTaskFarmBlock = ref<ActionWorkTaskFarmBlockForm>(new ActionWorkTaskFarmBlockForm());

// Rules
const requiredRule = Validation.createRule_Required();
const numericGreaterThanZeroRule = Validation.createRule_Numeric(
    i18n.global.t('Validation_MustBeGreaterThanZero'),
    0.000000000001,
);
const wholeNumberRule = Validation.createRule_WholeNumber();
const numericRowRule = (v: number | string) => {
    if ((v ?? '') !== '') {
        const minValue = localWorkTaskFarmBlock.value.startRow;
        const maxValue = props.farmField.farmFieldNumRows;
        const errorMessage = i18n.global.t('Validation_MustBeBetween', {
            minValue: minRowValue.value,
            maxValue: props.farmField.farmFieldNumRows,
        });

        //Perform validation
        if (isNaN((v as string).toString().replaceAll(',', '') as never)) {
            //Not a number
            return i18n.global.t('Validation_Number');
        } else if (minValue != null && (v as number) < minValue) {
            //Less than min value
            return errorMessage;
        } else if (maxValue != null && (v as number) > maxValue) {
            //Less than max value
            return errorMessage;
        }
    }

    return true;
};

/**
 * Adds a new block.
 */
const addWorkTaskFarmBlock = async () => {
    // If this is not the last block, return early because we do not want to add a new block
    if (!isLastItem.value) return;

    // Perform final client side validation of form
    await validate();

    // If form is valid
    if (valid.value && localWorkTaskFarmBlock.value.endRow! < (props.farmField.farmFieldNumRows ?? 0)) {
        // Get the next crop variety preference if there is one. This is used to set default values for
        // the next block.
        const cropVarietyPreference = props.workTask.workTaskCropVarietyPreferences.find((cropVarietyPreference) => {
            return !props.workTaskFarmBlockList.some(
                (farmBlock) => farmBlock.cropVarietyId === cropVarietyPreference.cropVarietyId,
            );
        });

        // Create new block to fill up the remaining rows
        const newItemId = `NewItem_${new Date().toISOString()}`;
        const newBlock = new ActionWorkTaskFarmBlockForm({
            newItemId: newItemId,
            startRow: parseInt(localWorkTaskFarmBlock.value.endRow!.toString()) + 1,
            endRow: props.farmField.farmFieldNumRows,
            cropVarietyId: cropVarietyPreference?.cropVarietyId,
            seedOriginBlockId: cropVarietyPreference?.seedOriginBlockId,
            seedRate: cropVarietyPreference?.seedRate,
            seedRateUnitCode: cropVarietyPreference?.seedRateUnitCode,
        });

        // Call emit
        emit('add', newBlock);
    }
};

/**
 * Deletes the block.
 */
const deleteWorkTaskFarmBlock = (workTaskFarmBlockIndex: number) => {
    emit('delete', workTaskFarmBlockIndex);
};

/**
 * Validate the form.
 */
const validate = async (): Promise<boolean> => {
    if (form.value) {
        const {valid} = await form.value.validate();
        return valid;
    }
    return false;
};

/**
 * Call API to calculate the block size.
 */
const getFarmBlockSize = async () => {
    const request: GetFarmBlockSizeRequest = {
        startRow: localWorkTaskFarmBlock.value.startRow!,
        endRow: localWorkTaskFarmBlock.value.endRow!,
        farmFieldId: props.farmField.farmFieldId,
    };
    const farmBlockSize = (await apiService.get('farmFields/farm-block-size', request)) as number;
    localWorkTaskFarmBlock.value.farmBlockSize = farmBlockSize;
};

/**
 * Flag to indicate if this is the last item in the list.
 */
const isLastItem = computed(() => props.index == props.workTaskFarmBlockList.length - 1);

/**
 * Calculated number of rows for this block.
 */
const numRows = computed(() =>
    props.workTaskFarmBlock?.startRow &&
    props.workTaskFarmBlock?.endRow &&
    props.workTaskFarmBlock.endRow >= props.workTaskFarmBlock.startRow
        ? props.workTaskFarmBlock.endRow - props.workTaskFarmBlock.startRow + 1
        : null,
);

/**
 * Details of the selected product.
 */
const minRowValue = computed(() => {
    // If this is not the first item, use the "End Row" value (plus 1) of the previous row
    if (props.index > 0) {
        const previousEndRow = props.workTaskFarmBlockList[props.index - 1].endRow;

        if (previousEndRow) {
            return parseInt(previousEndRow.toString()) + 1;
        }
    }

    return 1;
});

/**
 * The block code, generated from the index and the farm site naming convention.
 */
const formattedBlockCode = computed(() => {
    const blockNumber = props.index + 1;

    // Split the naming convention to get the part after the decimal (this is the part that determines the farm block code naming convention)
    const conventionParts = props.farmField.farmSiteNamingConventionName.split('.');

    // If field and block code naming conventions were found
    if (conventionParts.length == 2) {
        const farmBlockNamingConvention = conventionParts[1];

        // Prepend a 0 if needed
        if (farmBlockNamingConvention === '00' && blockNumber < 10) {
            return `0${blockNumber}`;
        }
    }

    return blockNumber.toString();
});

/**
 * Crop varieties for the specified crop.
 */
const filteredCropVarieties = computed(() => props.cropVarieties.filter((cv) => cv.cropId == props.cropId));

// Update parent when the data is changed.
watch(
    () => localWorkTaskFarmBlock,
    () => {
        if (!isLoading.value) {
            emit('update', localWorkTaskFarmBlock.value);
        }
    },
    {deep: true},
);

// When the Variety is changed, clear the Seed Origin selection.
watch(
    () => localWorkTaskFarmBlock.value.cropVarietyId,
    () => {
        if (!isLoading.value) {
            localWorkTaskFarmBlock.value.seedOriginBlockId = null;
        }
    },
    {deep: true},
);

// When the start or end row is changed, update the block size
watch([() => localWorkTaskFarmBlock.value.startRow, () => localWorkTaskFarmBlock.value.endRow], async () => {
    await getFarmBlockSize();
});

/**
 * Update details when the block's rows are changed in the parent. This usually only happens in
 * response to the deletion of another block.
 */
watch([() => props.workTaskFarmBlock?.startRow, () => props.workTaskFarmBlock?.endRow], () => {
    if (!isLoading.value && props.workTaskFarmBlock) {
        localWorkTaskFarmBlock.value.startRow = props.workTaskFarmBlock.startRow;
        localWorkTaskFarmBlock.value.endRow = props.workTaskFarmBlock.endRow;
    }
});

onMounted(async () => {
    localWorkTaskFarmBlock.value = Object.assign(new ActionWorkTaskFarmBlockForm(), props.workTaskFarmBlock);

    // End loading (nextTick is used to ensure that the watch events on localWorkTaskFarmBlock are not triggered during the load)
    nextTick(async () => {
        isLoading.value = false;

        // Set farm block code
        localWorkTaskFarmBlock.value.farmBlockCode = formattedBlockCode.value;

        // Get farm block size
        await getFarmBlockSize();
    });
});

defineExpose({validate});
</script>

<style scoped>
.v-btn--size-default {
    min-width: auto !important;
}
</style>
