<template>
    <div class="divGenericInfo">
        <v-card outlined style="width: 90%; max-width: 50rem; margin: auto">
            <v-toolbar color="swatchORG">
                <v-toolbar-title>
                    <FontAwesomeIcon :icon="['fal', 'ban']" size="xl" class="mr-2" />
                    {{ $t('YouHaveBeenLockedOut') }}
                </v-toolbar-title>
            </v-toolbar>

            <v-form class="pa-4">
                <div class="mainPanelInner">
                    <div class="table" style="width: 100%">
                        <h1
                            class="tableCell"
                            style="
                                text-align: center;
                                font-weight: bold;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            ">
                            <div class="icon-container swatchREDBG">
                                <FontAwesomeIcon :icon="['fa', 'lock']" size="xl" class="swatchWHT" />
                            </div>
                            <span class="ml-10 swatchRED" style="font-size: 40px"> {{ $t('LockedOut') }}</span>
                        </h1>
                    </div>
                    <div v-if="isLoading">{{ $t('Loading_Ellipsis') }}</div>
                    <div v-else class="table" style="width: 100%; margin-top: 50px">
                        <div class="tableCell">
                            {{
                                $t('LockedOut_Paragraph1', {
                                    systemName: configStore.systemName,
                                    lockoutEndDate: formattedLockoutEndDate,
                                })
                            }}
                            <br /><br />
                            {{ $t('LockedOut_Paragraph2') }}
                            <br /><br />
                            <ul>
                                <li v-for="(identification, index) in lockoutIdentification" :key="index">
                                    {{ identification }}
                                </li>
                            </ul>
                            <br />
                            {{ $t('LockedOut_Paragraph3', {systemName: configStore.systemName}) }}
                            <br /><br />
                            <ul>
                                <li>{{ lockoutDetails?.lockoutReason }}</li>
                            </ul>
                            <br />
                            {{ $t('LockedOut_Paragraph4', {utilityNameFull: configStore.utilityNameFull}) }}
                            <br /><br />
                            {{ $t('LockedOut_Paragraph5', {utilityNameFull: configStore.utilityNameFull}) }}
                            <br />
                            {{
                                $t('EmailCustomerService', {
                                    utilityCustomerServiceName: configStore.utilityCustomerServiceName,
                                    utilityEmailAddress: configStore.utilityEmailAddress,
                                })
                            }}
                            <br />
                            {{
                                $t('PhoneCustomerService', {
                                    utilityCustomerServiceName: configStore.utilityCustomerServiceName,
                                    utilityPhoneNumber: configStore.utilityPhoneNumber,
                                })
                            }}
                        </div>
                    </div>
                </div>
            </v-form>
        </v-card>
    </div>
</template>

// TS
<script lang="ts">
import {computed, defineComponent, inject, onMounted, ref} from 'vue';
import {useConfigStore} from '@/stores/config-store';
import {useLocaleCodeStore} from '@/stores/locale-code-store';
import ApiService from '@/services/api-service';
import {GetCurrentLockoutDetailsResponse} from '@/models/api/responses/security/GetCurrentLockoutDetailsResponse';
import router from '@/router';
import {formatTime} from '@/helpers/TimeFormatHelper';
import i18n from '@/i18n';
import {LocaleCode} from '@/enums/locale-code';
import {useUserStore} from '@/stores/user-store';

export default defineComponent({
    setup() {
        let isLoading = ref<boolean>(true);
        let lockoutDetails = ref<GetCurrentLockoutDetailsResponse | null>(null);
        const apiService = inject('apiService') as ApiService;
        const localeCodeStore = useLocaleCodeStore();
        const isLocaleCodeLoading = computed(() => !(localeCodeStore.locales && localeCodeStore.locales.length > 0));
        const userStore = useUserStore();

        const loadData = async () => {
            // User should be logged out after being locked
            if (userStore.user) {
                apiService.logout();
            }

            // Call API to get lockout details
            //Get locale Code from local storage.
            if (isLocaleCodeLoading.value) await localeCodeStore.fetchLocaleCodeSettings(apiService);
            const localCulturalCode = localStorage.getItem('languagePreference')
                ? localStorage.getItem('languagePreference')
                : i18n.global.locale;
            let localeCode;
            const currentLocale = localeCodeStore.locales.filter((e) => e.localeCultureCode === localCulturalCode);
            if (currentLocale.length > 0) localeCode = currentLocale[0].localeCode;
            else {
                localeCode = LocaleCode.EnglishAustralian;
            }

            lockoutDetails.value = await apiService.post('security/current-lockout-details', localeCode);

            //If there is no lockout
            if (lockoutDetails.value == null) {
                //Redirect to the home dashboard
                router.push({name: 'HomeDashboard'});
                return;
            }

            isLoading.value = false;
        };

        /**
         * Formatted lockout end date.
         */
        const formattedLockoutEndDate = computed(() => {
            const endDate = lockoutDetails.value?.lockoutEndDate;
            return endDate ? formatTime(new Date(endDate), 'dd/MM/yyyy hh:mm a') : '';
        });

        /**
         * Lockout reason
         */
        const lockoutIdentification = computed(() => {
            const ids = [];

            if (lockoutDetails.value) {
                if (lockoutDetails.value.username) {
                    ids.push(`${i18n.global.t('Username_Colon')} ${lockoutDetails.value.username}`);
                }

                if (lockoutDetails.value.ipAddress) {
                    ids.push(`${i18n.global.t('IpAddress_Colon')} ${lockoutDetails.value.ipAddress}`);
                }

                if (lockoutDetails.value.userCookie) {
                    ids.push(`${i18n.global.t('Cookie_Colon')} ${lockoutDetails.value.userCookie}`);
                }
            }

            return ids;
        });

        onMounted(loadData);

        return {
            configStore: useConfigStore(),
            isLoading,
            lockoutDetails,
            lockoutIdentification,
            formattedLockoutEndDate,
        };
    },
});
</script>

// CSS
<style scoped>
.mainPanelOuter {
    width: 800px;
}

.mainPanelInner {
    padding: 30px;
}

.errorTitle {
    font-size: 18pt;
    margin-bottom: 20px;
}

.icon-container {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 55px;
    height: 55px;
}
</style>
