// highchartsLocale.ts
import Highcharts from 'highcharts';
import i18n from '@/i18n';

export function configureHighchartsLocale() {
    const currentLocale = i18n.global.locale;

    // Define locale-specific settings
    const settings = {
        'id-ID': {
            lang: {
                decimalPoint: ',',
                thousandsSep: '.',
                numericSymbols: ['rb', 'jt', 'M', 'T'],
                months: [
                    'Januari',
                    'Februari',
                    'Maret',
                    'April',
                    'Mei',
                    'Juni',
                    'Juli',
                    'Agustus',
                    'September',
                    'Oktober',
                    'November',
                    'Desember',
                ],
                shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agt', 'Sep', 'Okt', 'Nov', 'Des'],
                weekdays: ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'],
            },
        },
        'en-AU': {
            lang: {
                decimalPoint: '.',
                thousandsSep: ',',
                numericSymbols: ['k', 'M', 'B', 'T'],
                months: [
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December',
                ],
                shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            },
        },
    };

    const localeSettings = settings[currentLocale] || settings['en-AU'];

    // Set Highcharts global options
    Highcharts.setOptions(localeSettings);
}
