import {defineStore} from 'pinia';
import {GetFrontEndConfigSettingsResponse} from '@/models/api/responses/system/GetFrontEndConfigSettingsResponse';
import ApiService from '@/services/api-service';

export const useConfigStore = defineStore('config', {
    state: (): GetFrontEndConfigSettingsResponse => ({
        defaultMapLatitude: 0,
        defaultMapLongitude: 0,
        defaultListItemNumberPerPage: 25,
        enableLoginViaUsername: false,
        isDeveloperMode: false,
        passwordResetHoursValid: 72,
        regexEmail: '',
        regexMobile: '',
        regexPassword: '',
        regexUsername: '',
        siteUrl: '',
        systemName: '',
        taskPlanItemDefaultDueAfterValue: 0,
        utilityCustomerServiceName: '',
        utilityEmailAddress: '',
        utilityPhoneNumber: '',
        utilityName: '',
        utilityNameFull: '',
        instanceHeaderText: '',
        loginBackgroundImageFilename: '',
    }),
    actions: {
        async fetchConfigSettings(apiService: ApiService) {
            try {
                const response = await apiService.get('config/front-end-config-settings');

                const data = response as GetFrontEndConfigSettingsResponse; // Type cast to expected interface
                Object.assign(this, data); // Update state with fetched data
            } catch (error) {
                console.error('Failed to fetch config settings:', error);
            }
        },
    },
    getters: {
        getSetting: (state) => (key: keyof GetFrontEndConfigSettingsResponse) => {
            return state[key]; // Use keyof to ensure type safety
        },
    },
});
