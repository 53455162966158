<template>
    <v-form class="work-sub-task-item" :class="{'first-item': index == 0}">
        <div style="width: 3.7rem" class="swatchA1 pl-2">
            {{ index + 1 }}
        </div>
        <div style="width: 100%">
            <div style="display: flex; align-items: center">
                <div class="swatchA1 bold" style="width: 30rem">{{ workSubTask.workSubTaskName }}</div>

                <div v-if="!isTemplateMode(workSubTask) && workSubTask.isCompleted" class="swatchA1">
                    <FontAwesomeIcon class="swatchGRN mr-2" :icon="['fa', 'calendar-check']" size="xl" />
                    {{ formatDate(workSubTask.completedDate) }}
                </div>
            </div>
            <div class="swatchA1 mt-6">
                <TranslatableTexts :text="workSubTask.workSubTaskInstructions" />
            </div>
        </div>
        <div
            style="
                text-align: right;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 35rem;
            ">
            <div style="align-self: flex-end">
                <div
                    v-for="(artifact, index) in workSubTask.artifacts"
                    :key="artifact.artifactId"
                    :class="{'mt-2': index > 0}">
                    <FileTypeButton
                        :url="artifact.artifactUrl"
                        :display-name="formatFileName(artifact.artifactDisplayName)"
                        :file-type-code="artifact.artifactFileTypeCode" />
                </div>
            </div>
        </div>
    </v-form>
</template>

<script setup lang="ts">
import {WorkSubTaskDto} from '@/models/data-transfer-objects/search/work-task-search/WorkSubTaskDto';
import FileTypeButton from '@/components/FileTypeButton.vue';
import {formatTime} from '@/helpers/TimeFormatHelper';
import {WorkTaskTemplateSubTaskDto} from '@/models/data-transfer-objects/search/work-task-template-search/WorkTaskTemplateSubTaskDto';

defineProps<{
    workSubTask: WorkSubTaskDto | WorkTaskTemplateSubTaskDto;
    index: number;
}>();

/**
 * Flag to indicate if the passed object is in Task or Template mode. This is used to prevent type errors.
 */
const isTemplateMode = (
    workSubTask: WorkSubTaskDto | WorkTaskTemplateSubTaskDto,
): workSubTask is WorkTaskTemplateSubTaskDto => {
    return !('isCompleted' in workSubTask);
};

/**
 * Shortern the file name if needed to ensure the buttons are not too wide.
 */
const formatFileName = (fileName: string, maxLength: number = 30): string => {
    if (fileName.length <= maxLength) {
        return fileName;
    }
    return fileName.slice(0, maxLength) + '...';
};

/**
 * Formats a date.
 */
const formatDate = (date: Date | null): string | null => {
    if (!date) return null;
    return formatTime(date, 'dd MMM yyyy, HH:mm');
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/swatches.scss';

.work-sub-task-item {
    border-left: 1px solid $swatchG6;
    border-right: 1px solid $swatchG6;
    border-bottom: 1px solid $swatchG6;
    display: flex;
    padding: 10px;

    &.first-item {
        border-top: 1px solid $swatchG6;
        margin-top: 0.5rem;
    }
}
</style>
