import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-49463847"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "ml-1"
}
const _hoisted_2 = {
  key: 1,
  class: "ml-1"
}
const _hoisted_3 = { style: {"gap":"0.2rem","display":"flex","align-items":"center","margin-right":"0.2rem"} }
const _hoisted_4 = {
  key: 0,
  class: "chip-text"
}
const _hoisted_5 = {
  key: 0,
  class: "main-text"
}
const _hoisted_6 = {
  key: 1,
  class: "secondary-text"
}
const _hoisted_7 = {
  key: 2,
  class: "secondary-text"
}
const _hoisted_8 = {
  key: 3,
  class: "secondary-text"
}
const _hoisted_9 = {
  key: 1,
  style: {"display":"flex","align-items":"center"}
}
const _hoisted_10 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FarmFieldAgeIcon = _resolveComponent("FarmFieldAgeIcon")!
  const _component_WorkTaskTypeIcon = _resolveComponent("WorkTaskTypeIcon")!
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_CircleIcon = _resolveComponent("CircleIcon")!
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_chip = _resolveComponent("v-chip")!

  return (_ctx.showCropYear && !_ctx.showNextWorkTask && !_ctx.showFarmFieldName && !_ctx.showCropVarieties)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_FarmFieldAgeIcon, {
          iconHeight: "40",
          iconWidth: "40",
          fieldAge: _ctx.farmField.cropYear!.toString()
        }, null, 8, ["fieldAge"])
      ]))
    : (_ctx.showNextWorkTask && !_ctx.showFarmFieldName && !_ctx.showCropVarieties && !_ctx.showCropYear)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_WorkTaskTypeIcon, {
            workTaskTypeCode: _ctx.farmField.nextWorkTask?.workTaskTypeCode,
            iconHeight: "40",
            iconWidth: "40",
            border: ""
          }, null, 8, ["workTaskTypeCode"])
        ]))
      : (_openBlock(), _createBlock(_component_v_chip, {
          key: 2,
          variant: "flat",
          size: "xlarge",
          color: "white",
          class: "farm-field-chip",
          style: _normalizeStyle({'padding-left': _ctx.paddingLeft, 'padding-right': _ctx.paddingRight})
        }, {
          default: _withCtx(() => [
            (_ctx.showCropVarieties)
              ? (_openBlock(), _createBlock(_component_v_avatar, {
                  key: 0,
                  start: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CircleIcon, {
                      style: _normalizeStyle({height: _ctx.iconHeight + 'px', width: _ctx.iconWidth + 'px', fontSize: _ctx.iconSize + 'pt'}),
                      class: _normalizeClass(_ctx.iconClass)
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_FontAwesomeIcon, {
                          icon: [_ctx.iconFamily, _ctx.iconCode],
                          class: _normalizeClass(_ctx.backgroundClass)
                        }, null, 8, ["icon", "class"])
                      ]),
                      _: 1
                    }, 8, ["style", "class"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_3, [
              (_ctx.showText)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    (_ctx.showFarmFieldName)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.farmFieldNameFull), 1))
                      : _createCommentVNode("", true),
                    (_ctx.showCropVarieties)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.cropVarietiesDisplayText), 1))
                      : _createCommentVNode("", true),
                    (_ctx.showNextWorkTaskText)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.nextWorkTaskTypeName), 1))
                      : _createCommentVNode("", true),
                    (_ctx.showCropYearText)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.cropYearDisplayText), 1))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.showCropYear)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createVNode(_component_FarmFieldAgeIcon, {
                      iconHeight: "40",
                      iconWidth: "40",
                      fieldAge: _ctx.farmField.cropYear!.toString()
                    }, null, 8, ["fieldAge"])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.showNextWorkTask)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createVNode(_component_WorkTaskTypeIcon, {
                      workTaskTypeCode: _ctx.farmField.nextWorkTask?.workTaskTypeCode,
                      iconHeight: "40",
                      iconWidth: "40",
                      border: ""
                    }, null, 8, ["workTaskTypeCode"])
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }, 8, ["style"]))
}