<template>
    <v-form ref="form" v-model="valid" class="work-sub-task-item" :class="{'new-item': isAddNewItem}">
        <div style="width: 3.7rem" class="pt-2 pl-2">
            <span v-if="isAddNewItem">{{ $t('NewInCapital') }}</span>
            <span v-if="!isAddNewItem">{{ index + 1 }}</span>
        </div>
        <div style="width: 100%">
            <div>
                <v-text-field
                    v-model="localWorkSubTask.workSubTaskName"
                    :rules="[requiredRule]"
                    required
                    :placeholder="$t('EnterSubTaskName')"
                    max-width="600px"
                    class="swatchA1 font-weight-bold"
                    maxlength="50">
                </v-text-field>
            </div>
            <div class="mt-2">
                <v-textarea
                    v-model="localWorkSubTask.workSubTaskInstructions"
                    :placeholder="$t('SpecifyTheSubTaskInstructions')"
                    variant="outlined"
                    density="compact"
                    rows="1"
                    auto-grow
                    hide-details="auto"
                    class="swatchA1 font-weight-bold">
                </v-textarea>
            </div>
            <div class="mt-2">
                <v-file-input
                    v-model="localWorkSubTask.artifacts"
                    :rules="[uploadedFileRule]"
                    :label="$t('FileUploads')"
                    :prepend-icon="null"
                    prepend-inner-icon="mdi-paperclip"
                    chips
                    multiple
                    variant="outlined"
                    density="compact"
                    hide-details="auto"
                    max-width="600px"
                    class="swatchA1">
                    <template v-slot:selection="{fileNames}">
                        <template v-for="(artifact, index) in localWorkSubTask.artifacts" :key="artifact.artifactId">
                            <v-chip class="me-2 swatchA3BG swatchA3txt" size="small" label rounded="0">
                                {{ (artifact as any).artifactDisplayName || fileNames[index] }}
                            </v-chip>
                        </template>
                    </template>
                </v-file-input>
            </div>
        </div>
        <div
            style="
                width: 10rem;
                text-align: right;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            ">
            <div v-if="!isAddNewItem" style="align-self: flex-end">
                <v-btn
                    @click="moveItemBack(index)"
                    icon="mdi-arrow-up"
                    class="v-btn--custom"
                    density="comfortable"
                    :disabled="index == 0"
                    :ripple="false"
                    v-ripple="{duration: 250}" />

                <v-btn
                    @click="moveItemAhead(index)"
                    icon="mdi-arrow-down"
                    class="v-btn--custom ml-6 mr-2"
                    density="comfortable"
                    :disabled="index == workSubTaskList.length - 1"
                    :ripple="false"
                    v-ripple="{duration: 250}" />
            </div>

            <v-btn
                v-if="isAddNewItem"
                @click="addWorkSubTask"
                class="v-btn--custom"
                style="align-self: flex-end; max-width: 100%">
                <template v-slot:prepend>
                    <FontAwesomeIcon :icon="['fal', 'plus']" size="xl" />
                </template>
                {{ $t('Add') }}
            </v-btn>

            <v-btn
                v-if="!isAddNewItem"
                @click="deleteWorkSubTask(index)"
                class="v-btn--custom"
                style="align-self: flex-end; max-width: 100%">
                <template v-slot:prepend>
                    <FontAwesomeIcon :icon="['fal', 'trash']" size="xl" />
                </template>
                {{ $t('Delete') }}
            </v-btn>
        </div>
    </v-form>
</template>

<script setup lang="ts">
import Validation from '@/helpers/ValidationHelper';
import {WorkSubTaskForm} from '@/models/work-tasks/WorkSubTaskForm';
import {computed, nextTick, onMounted, ref, watch} from 'vue';

const props = withDefaults(
    defineProps<{
        workSubTask?: WorkSubTaskForm | null;
        workSubTaskList: WorkSubTaskForm[];
        index: number;
    }>(),
    {
        workSubTask: null,
    },
);
const emit = defineEmits(['add', 'update', 'delete', 'moveItemAhead', 'moveItemBack']);

// Form
const form = ref();
const valid = ref<boolean>(true);
const isLoading = ref<boolean>(true);
const localWorkSubTask = ref<WorkSubTaskForm>(new WorkSubTaskForm());
let newItemCount = 0;

// Rules
const requiredRule = Validation.createRule_Required();
const uploadedFileRule = Validation.createRule_UploadedFile();

/**
 * Adds the sub-task.
 */
const addWorkSubTask = async () => {
    // Perform final client side validation of form
    await validate();

    // If form is valid
    if (valid.value) {
        // Increment new item count so that new items have a unique ID
        newItemCount += 1;

        // Set new item ID
        localWorkSubTask.value.newItemId = 'NewItem' + newItemCount;

        // Call emit
        emit('add', localWorkSubTask.value);

        // Reset data so that another item can be added if needed
        localWorkSubTask.value = new WorkSubTaskForm();
    }
};

/**
 * Deletes the sub-task.
 */
const deleteWorkSubTask = (workSubTaskIndex: number) => {
    emit('delete', workSubTaskIndex);
};

/**
 * Moves the sub-task up.
 */
const moveItemAhead = (workSubTaskIndex: number) => {
    emit('moveItemAhead', workSubTaskIndex);
};

/**
 * Moves the sub-task down.
 */
const moveItemBack = (workSubTaskIndex: number) => {
    emit('moveItemBack', workSubTaskIndex);
};

/**
 * Validate the form.
 */
const validate = async (): Promise<boolean> => {
    if (form.value) {
        const {valid} = await form.value.validate();
        return valid;
    }
    return false;
};

/**
 * Flag to indicate if this is in "Add" mode or if it's displaying an existing sub-task.
 */
const isAddNewItem = computed(() => props.index == -1);

// Update parent when the data is changed.
watch(
    [() => localWorkSubTask],
    () => {
        if (!isLoading.value) {
            emit('update', localWorkSubTask.value);
        }
    },
    {deep: true},
);

// Update flag to indicate that artifacts have been updated.
watch(
    () => localWorkSubTask.value.artifacts,
    () => {
        if (!isLoading.value) {
            localWorkSubTask.value.haveArtifactsBeenUpdated = true;
        }
    },
);

onMounted(async () => {
    if (props.workSubTask) {
        localWorkSubTask.value = Object.assign(new WorkSubTaskForm(), props.workSubTask);
    }

    // End loading (nextTick is used to ensure that the watch events on localWorkSubTask are not triggered during the load)
    nextTick(() => {
        isLoading.value = false;
    });
});

defineExpose({validate});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/swatches.scss';

.work-sub-task-item {
    border-top: 1px solid $swatchG6;
    border-left: 1px solid $swatchG6;
    border-right: 1px solid $swatchG6;
    display: flex;
    padding: 10px;

    &.new-item {
        border-bottom: 1px solid $swatchG6;
    }
}
</style>
