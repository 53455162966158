<template>
    <!-- ml-1 is used on these divs to fix an issue where the default square marker image was slightly visible underneath the marker HTML  -->
    <div v-if="showCropYear && !showNextWorkTask && !showFarmFieldName && !showCropVarieties" class="ml-1">
        <FarmFieldAgeIcon iconHeight="40" iconWidth="40" :fieldAge="farmField.cropYear!.toString()" />
    </div>
    <div v-else-if="showNextWorkTask && !showFarmFieldName && !showCropVarieties && !showCropYear" class="ml-1">
        <WorkTaskTypeIcon
            :workTaskTypeCode="farmField.nextWorkTask?.workTaskTypeCode"
            iconHeight="40"
            iconWidth="40"
            border="" />
    </div>
    <v-chip
        v-else
        variant="flat"
        size="xlarge"
        color="white"
        class="farm-field-chip"
        :style="{'padding-left': paddingLeft, 'padding-right': paddingRight}">
        <v-avatar start v-if="showCropVarieties">
            <CircleIcon
                :style="{height: iconHeight + 'px', width: iconWidth + 'px', fontSize: iconSize + 'pt'}"
                :class="iconClass">
                <FontAwesomeIcon :icon="[iconFamily, iconCode]" :class="backgroundClass" />
            </CircleIcon>
        </v-avatar>

        <div style="gap: 0.2rem; display: flex; align-items: center; margin-right: 0.2rem">
            <div v-if="showText" class="chip-text">
                <div v-if="showFarmFieldName" class="main-text">{{ farmFieldNameFull }}</div>
                <div v-if="showCropVarieties" class="secondary-text">{{ cropVarietiesDisplayText }}</div>
                <div v-if="showNextWorkTaskText" class="secondary-text">
                    {{ nextWorkTaskTypeName }}
                </div>
                <div v-if="showCropYearText" class="secondary-text">
                    {{ cropYearDisplayText }}
                </div>
            </div>

            <div v-if="showCropYear" style="display: flex; align-items: center">
                <FarmFieldAgeIcon iconHeight="40" iconWidth="40" :fieldAge="farmField.cropYear!.toString()" />
            </div>

            <div v-if="showNextWorkTask">
                <WorkTaskTypeIcon
                    :workTaskTypeCode="farmField.nextWorkTask?.workTaskTypeCode"
                    iconHeight="40"
                    iconWidth="40"
                    border="" />
            </div>
        </div>
    </v-chip>
</template>

<script lang="ts">
import {PropType, defineComponent, computed} from 'vue';
import {getCropByCode, Crop} from '@/services/crops-service';
import {CropCode} from '@/enums/crop-code';
import FarmFieldAgeIcon from '@/components/FarmFieldAgeIcon.vue';
import {FarmFieldSearchResultDto} from '@/models/data-transfer-objects/search/farm-field-search/FarmFieldSearchResultDto';
import {getWorkTaskTypeByCode} from '@/services/work-task-types-service';
import {WorkTaskTypeCode} from '@/enums/work-task-type-code';
import {useCropYears} from '@/composables/data-source/crop-years';
const {cropYears} = useCropYears();

export default defineComponent({
    components: {
        FarmFieldAgeIcon,
    },
    props: {
        farmField: {
            type: Object as PropType<FarmFieldSearchResultDto>,
            required: true,
        },
        showFarmFieldName: {
            type: Boolean,
            default: false,
        },
        showCropVarieties: {
            type: Boolean,
            default: false,
        },
        showCropYear: {
            type: Boolean,
            default: false,
        },
        showNextWorkTask: {
            type: Boolean,
            default: false,
        },
        primary: {
            type: Boolean,
            default: false,
        },
        iconHeight: {
            type: String,
            default: '40',
        },
        iconWidth: {
            type: String,
            default: '40',
        },
        iconSize: {
            type: String,
            default: '20',
        },
    },
    setup(props) {
        const crop = computed<Crop | undefined>(() => {
            return getCropByCode(props.farmField.cropCode as CropCode);
        });

        const iconFamily = computed<string>(() => {
            return crop.value?.iconFamily || 'fas';
        });

        const iconCode = computed<string>(() => {
            return crop.value?.iconCode || 'question';
        });

        const iconClass = computed<string>(() =>
            props.farmField.cropCode == CropCode.SugarCane ? 'primary-icon' : 'secondary-icon',
        );

        const backgroundClass = computed<string>(() =>
            props.farmField.cropCode == CropCode.SugarCane ? 'primary-background' : 'secondary-background',
        );

        const farmFieldNameFull = computed<string>(() => {
            return `${props.farmField.farmFieldCode} - ${props.farmField.farmFieldName}`;
        });

        const cropVarietiesDisplayText = computed<string>(() => {
            const cropVarieties = props.farmField.cropVarieties.map((cv) => cv.cropVarietyName);

            if (cropVarieties && cropVarieties.length > 0) {
                if (cropVarieties.length == 1) {
                    return cropVarieties[0];
                } else return cropVarieties.join(' / ');
            } else return '';
        });

        const nextWorkTaskTypeName = computed<string | null>(() => {
            if (props.farmField.nextWorkTask) {
                const workTaskType = getWorkTaskTypeByCode(
                    props.farmField.nextWorkTask.workTaskTypeCode as WorkTaskTypeCode,
                );
                return workTaskType?.getWorkTaskTypeName() ?? null;
            }

            return null;
        });

        const cropYearDisplayText = computed<string | null>(() => {
            const cropYear = cropYears.value.find((cy) => cy.value == props.farmField.cropYear);

            if (cropYear != null) {
                return cropYear.getTitle!();
            } else {
                return null;
            }
        });

        const showNextWorkTaskText = computed<boolean>(() => {
            return (
                // Next Work Task must be visible
                props.showNextWorkTask &&
                // Do not show Next Work Task text if only the Crop Year and Next Work Task are visible
                !(props.showNextWorkTask && !props.showFarmFieldName && !props.showCropVarieties) &&
                // Do not show Next Work Task if more than 1 other text is visible (because we only want to display 2 lines max)
                [props.showFarmFieldName, props.showCropVarieties].filter(Boolean).length <= 1
            );
        });

        const showCropYearText = computed<boolean>(() => {
            return (
                // Crop Year must be visible
                props.showCropYear &&
                // Do not show Crop Year text if only the Crop Year and Next Work Task are visible
                !(props.showNextWorkTask && !props.showFarmFieldName && !props.showCropVarieties) &&
                // Do not show Crop Year if more than 1 other text is visible (because we only want to display 2 lines max)
                [props.showFarmFieldName, props.showCropVarieties, props.showNextWorkTask].filter(Boolean).length <= 1
            );
        });

        const showText = computed<boolean>(() => {
            return (
                props.showFarmFieldName ||
                props.showCropVarieties ||
                showNextWorkTaskText.value ||
                showCropYearText.value
            );
        });

        /**
         * Ensures that there is a larger gap between the text and the start of the chip when there are no
         * icons shown on the left.
         */
        const paddingLeft = computed<string>(() => {
            if (!props.showCropVarieties && showText.value) {
                return '0.8rem';
            } else {
                return '0rem';
            }
        });

        /**
         * Ensures that there is a larger gap between the text and the end of the chip when there are no
         * icons shown on the right.
         */
        const paddingRight = computed<string>(() => {
            if (!props.showCropYear && !props.showNextWorkTask) {
                return '1rem';
            } else {
                return '0rem';
            }
        });

        return {
            iconFamily,
            iconCode,
            iconClass,
            backgroundClass,
            farmFieldNameFull,
            cropVarietiesDisplayText,
            nextWorkTaskTypeName,
            cropYearDisplayText,
            showNextWorkTaskText,
            showCropYearText,
            showText,
            paddingLeft,
            paddingRight,
        };
    },
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/swatches.scss';

.farm-field-chip {
    cursor: pointer;
    font-size: 11pt;
    color: $swatchA1 !important;
    min-height: 2.5rem;

    border: 1px solid $swatchG4;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;

    .chip-container {
        display: flex;
        align-items: center;
    }

    .chip-text {
        white-space: pre-line;
    }

    .main-text {
        font-weight: bold;
    }

    .secondary-text {
        font-size: 10pt;
    }

    .v-avatar {
        margin-right: 0.2rem;
        margin-left: 0.2rem;
    }
    .primary-background {
        background-color: rgba($swatchA2, 0.8);
        color: $swatchWHT;
    }
    .secondary-background {
        background-color: rgba($swatchA5, 0.8);
        color: $swatchBLK;
    }
    .primary-icon {
        background-color: $swatchA2 !important;
    }
    .secondary-icon {
        background-color: $swatchA5 !important;
    }
}
</style>
