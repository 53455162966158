<template>
    <v-data-table :headers="headers" :items="processedUsers" :items-per-page="10" @click:row="handleResultClick">
        <template v-slot:[`item.userFullName`]="{item}">
            <div class="tableCell">
                <span class="swatchA1 slightlyLarger"> {{ item.userFullName }} </span>
            </div>
            <div class="tableCell pl-2">
                <UserChip
                    :icon-only="true"
                    :user="UserHelper.userChipType(item.userId, item.userFullName, item.userImage)" />
            </div>
            <div class="tableCell">
                <IdIcon>{{ item.userId }}</IdIcon>
            </div>
        </template>
        <template v-slot:[`item.dateLastLoggedIn`]="{item}">
            <div v-if="item.dateLastLoggedIn">
                {{ formatDate(item.dateLastLoggedIn) }}
            </div>
            <div v-else style="font-style: italic">{{ $t('Never') }}</div>
        </template>
        <template v-slot:[`item.platform`]="{item}">
            <div v-if="item.lastLoggedInWebBrowser || item.lastLoggedInMobileDevicePlatform" class="pt-1 pb-1">
                <div :class="item.lastLoggedInWebBrowser !== null ? '' : 'italicFont'">
                    <CircleIcon class="swatchWHTBG swatchA2Border" color="swatchA2" :title="$t('WebBrowser')">
                        <FontAwesomeIcon :icon="['fal', 'browser']" />
                    </CircleIcon>
                    <span class="marginLeftSmall">
                        {{ item.lastLoggedInWebBrowser ? item.lastLoggedInWebBrowser : $t('None') }}
                    </span>
                    <span v-if="item.lastLoggedInWebBrowserVersion" class="swatchA4 ml-1">
                        {{ item.lastLoggedInWebBrowserVersion }}
                    </span>
                </div>
                <div class="marginTopSmall" :class="item.lastLoggedInMobileDevicePlatform !== null ? '' : 'italicFont'">
                    <CircleIcon class="swatchWHTBG swatchA2Border" color="swatchA2" :title="$t('MobileBrowser')">
                        <FontAwesomeIcon :icon="['fal', 'mobile-android']" />
                    </CircleIcon>
                    <span class="marginLeftSmall">
                        {{ item.lastLoggedInMobileDevicePlatform ? item.lastLoggedInMobileDevicePlatform : $t('None') }}
                    </span>
                    <span v-if="item.lastLoggedInMobileDevicePlatformVersion" class="swatchA4 ml-1">
                        {{ item.lastLoggedInMobileDevicePlatformVersion }}
                    </span>
                </div>
            </div>

            <div v-else style="font-style: italic">{{ $t('None') }}</div>
        </template>
        <template v-slot:[`item.action`]="{item}">
            <div style="text-align: center">
                <v-menu open-on-hover>
                    <template v-slot:activator="{props}">
                        <v-btn color="primary" v-bind="props"> {{ $t('Actions') }} </v-btn>
                    </template>

                    <v-list>
                        <v-list-item>
                            <v-list-item-title v-if="hasModifyStaffAccess && item.userStatus == UserStatus.Active">
                                <v-btn @click="resetUserPasswordModalStore.open(item)">{{
                                    $t('ResetUsersPassword')
                                }}</v-btn>
                            </v-list-item-title>
                            <v-list-item-title v-if="hasModifyStaffAccess">
                                <v-btn @click="manageUserModalStore.open(ManageUserModalMode.Modify, item.userId)">
                                    {{ $t('ManageUserSlashRoles') }}
                                </v-btn>
                            </v-list-item-title>
                            <v-list-item-title v-if="hasModifyStaffAccess">
                                <v-btn @click="deleteUserModalStore.open(item)"> {{ $t('DeleteUserAccount') }} </v-btn>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </template>
    </v-data-table>
</template>

<script setup lang="ts">
import {computed, ref} from 'vue';
import {SearchUsersResponse} from '@/models/api/responses/search/SearchUsersResponse';
import {formatTime} from '@/helpers/TimeFormatHelper';
import {useUserStore} from '@/stores/user-store';
import {RoleCode} from '@/enums/role-code';
import {useDeleteUserModalStore} from '@/stores/modals/delete-user-modal-store';
import {useManageUserModalStore} from '@/stores/modals/manage-user-modal-store';
import {ManageUserModalMode} from '@/enums/modal-modes/manage-user-modal-mode';
import {useResetUserPasswordModalStore} from '@/stores/modals/reset-user-password-modal-store';
import i18n from '@/i18n';
import UserChip from '@/components/UserChip.vue';
import {UserStatus} from '@/enums/user-status';
import UserHelper from '@/helpers/UserHelper';

const props = withDefaults(
    defineProps<{
        resultsList: SearchUsersResponse[];
    }>(),
    {
        resultsList: () => [] as SearchUsersResponse[],
    },
);

const userStore = useUserStore();
const deleteUserModalStore = useDeleteUserModalStore();
const manageUserModalStore = useManageUserModalStore();
const resetUserPasswordModalStore = useResetUserPasswordModalStore();
const hasModifyStaffAccess = userStore.hasRoleLevel(RoleCode.User, 2);

const headers = ref([
    {title: i18n.global.t('FullName'), key: 'userFullName', sortable: true},
    {title: i18n.global.t('EmailAddress'), key: 'userEmailAddress', sortable: true},
    {title: i18n.global.t('Phone'), key: 'userMobile', sortable: true},
    {title: i18n.global.t('LastLogin'), key: 'dateLastLoggedIn', sortable: true},
    {title: i18n.global.t('Platform'), key: 'platform', sortable: false},
]);

if (hasModifyStaffAccess) {
    headers.value.push({title: '', key: 'action', sortable: false});
}

/**
 * Format date for report results.
 */
const formatDate = (date: Date): string => {
    return formatTime(date, 'd/MM/yyyy h:mm a');
};

/**
 * Handle click event on a result.
 */
const handleResultClick = (event: MouseEvent, row: {item: SearchUsersResponse}) => {
    manageUserModalStore.open(ManageUserModalMode.View, row.item.userId);
};

/**
 * Update properties of the user list where necessary.
 */
const processedUsers = computed(() => {
    return props.resultsList.map((user) => ({
        ...user,
        userFullName: `${user.userSurname}, ${user.userGivenName}`,
    }));
});
</script>
