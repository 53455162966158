<template>
    <v-menu v-model="isOperationsActive" open-on-click>
        <template v-slot:activator="{props}">
            <v-btn
                class="operation-button icon-only"
                :class="isOperationsActive ? 'active-button' : ''"
                elevation="2"
                rounded="x-large"
                v-bind="props">
                <FontAwesomeIcon :icon="['fal', 'bars']" size="xl" />
            </v-btn>
        </template>

        <v-list>
            <v-list-item v-if="observation">
                <v-list-item>
                    <v-list-item-title>
                        <v-btn @click="viewObservationModalStore.open(observation.observationId)">
                            {{ $t('ViewSlashActionObservation') }}
                        </v-btn>
                    </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="userStore.hasRoleLevel(RoleCode.Observations, 2)">
                    <v-list-item-title>
                        <v-btn @click="manageObservationModalStore.open(observation.observationId)">
                            {{ $t('ModifyObservation') }}
                        </v-btn>
                    </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="userStore.hasRoleLevel(RoleCode.Observations, 3)">
                    <v-list-item-title>
                        <v-btn @click="viewObservationModalStore.open(observation.observationId, null, true)">
                            {{ $t('DeleteObservation') }}
                        </v-btn>
                    </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="observation.farmFieldId">
                    <v-list-item-title>
                        <v-btn @click="viewOnMap(observation.farmFieldId)">
                            {{ $t('SelectField') }}
                        </v-btn>
                    </v-list-item-title>
                </v-list-item>
            </v-list-item>

            <v-list-item v-else>
                <v-list-item-title>
                    <v-btn @click="manageObservationModalStore.open()">
                        {{ $t('CreateANewObservation') }}
                    </v-btn>
                </v-list-item-title>
                <v-list-item-title>
                    <v-btn @click="observationListDirect">
                        {{ $t('ObservationList') }}
                    </v-btn>
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script setup lang="ts">
import {defineProps, ref} from 'vue';
import {useHomeDashboardStore} from '@/stores/home-dashboard-store';
import {useManageObservationModalStore} from '@/stores/modals/manage-observation-modal-store';
import {useViewObservationModalStore} from '@/stores/modals/view-observation-modal-store';
import {ObservationSearchResultDto} from '@/models/data-transfer-objects/search/observation-search/ObservationSearchResultDto';
import {useUserStore} from '@/stores/user-store';
import {RoleCode} from '@/enums/role-code';
import router from '@/router';
import {SearchObservationsRequest} from '@/models/api/requests/search/SearchObservationsRequest';

defineProps<{
    observation: ObservationSearchResultDto | null;
}>();

// Stores
const homeDashboardStore = useHomeDashboardStore();
const manageObservationModalStore = useManageObservationModalStore();
const viewObservationModalStore = useViewObservationModalStore();
const userStore = useUserStore();

const isOperationsActive = ref<boolean>(false);

// view field on map
async function viewOnMap(farmFieldId: number) {
    homeDashboardStore.farmFieldIdToFocusOn = farmFieldId;
}

const observationListDirect = () => {
    if (homeDashboardStore.isObservationOpenMode) {
        homeDashboardStore.observationListFilter = Object.assign(
            new SearchObservationsRequest(),
            homeDashboardStore.searchParametersOpenObservation,
        );
        router.push({path: `/observation-list`});
    } else {
        homeDashboardStore.observationListFilter = Object.assign(
            new SearchObservationsRequest(),
            homeDashboardStore.searchParametersClosedObservation,
        );
        router.push({path: `/observation-list/true`});
    }
};
</script>
