<template>
    <v-dialog v-model="purgeDeviceModalStore.isVisible" style="width: 50vw !important">
        <v-card>
            <v-toolbar color="secondary">
                <v-toolbar-title>
                    <FontAwesomeIcon :icon="['fa', 'trash-can-xmark']" size="xl" class="mr-2" />
                    {{ $t('ConfirmDevicePurge') }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <CloseModalButton @click="close" />
            </v-toolbar>

            <LoadingSymbol v-if="isLoading" />

            <v-form v-else-if="devices.length > 0" class="pa-4" style="overflow-y: scroll">
                <span v-for="device in devices" :key="device.mobileDeviceId">
                    <div class="bold swatchB6 slightlyLarger">{{ device.mobileDeviceExternalId }}</div>
                    <div class="swatchA4">{{ device.mobileDeviceManufacturer }} {{ device.mobileDeviceModel }}</div>
                </span>

                <!-- Confirm Purge -->
                <v-card class="marginTop">
                    <v-toolbar class="pa-3 permissionDenied" v-if="!hasDevicePurgeAccess">
                        <v-toolbar-title style="font-size: medium">
                            {{ $t('PurgeDevice_PermissionDenied') }}
                        </v-toolbar-title>
                    </v-toolbar>
                    <span v-else>
                        <v-card-text class="device-instructions-purge pa-0 mb-4">
                            <v-row class="confirm-header pa-3 ma-0">
                                <FontAwesomeIcon :icon="['fal', 'square-question']" size="xl" class="mr-5" />
                                <span> {{ $t('Instructions') }} </span>
                            </v-row>

                            <div class="pa-2">
                                <v-row class="ma-2" style="font-size: medium">
                                    {{
                                        $t('PurgeDevice_Instructions_PanelMessage1', {
                                            systemName: configStore.systemName,
                                        })
                                    }}
                                </v-row>
                                <v-row class="ma-2" style="font-size: medium">
                                    {{
                                        $t('PurgeDevice_Instructions_PanelMessage2', {
                                            systemName: configStore.systemName,
                                        })
                                    }}
                                </v-row>
                            </div>
                        </v-card-text>

                        <v-card-text class="device-confirm-purge pa-0">
                            <v-row class="confirm-header pa-3 ma-0">
                                <FontAwesomeIcon :icon="['fal', 'check']" size="xl" class="mr-5" />
                                <span> {{ $t('ConfirmDevicePurge') }} </span>
                            </v-row>

                            <div class="pa-2">
                                <v-row class="ma-2" style="font-size: medium">
                                    {{ $t('PurgeDevice_ActionTracked') }}
                                </v-row>

                                <v-row class="ma-1">
                                    <v-col cols="1" align-self="center">
                                        <v-checkbox v-model="isConfirmed" class="confirm-checkbox" hide-details />
                                    </v-col>
                                    <v-col cols="11" align-self="center" style="font-size: medium">
                                        {{ $t('PurgeDevice_Confirmation') }}
                                    </v-col>
                                </v-row>
                            </div>
                        </v-card-text>
                    </span>
                </v-card>
            </v-form>

            <v-card-actions class="justify-space-between swatchG9BG">
                <v-btn @click="close">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'arrow-rotate-left']" size="xl" />
                    </template>
                    {{ $t('Cancel') }}
                </v-btn>
                <v-btn :disabled="!isConfirmed" @click.stop="purgeDevice">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fa', 'check']" size="xl" />
                    </template>
                    {{ $t('Confirm') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" timeout="4000">
        {{ snackbar.text }}
    </v-snackbar>
</template>

<script setup lang="ts">
import {usePurgeDeviceModalStore} from '@/stores/modals/purge-device-modal-store';
import {watch, inject, ref, computed} from 'vue';
import ApiService from '@/services/api-service.js';
import CloseModalButton from '@/components/CloseModalButton.vue';
import {MobileDeviceSearchResultDto} from '@/models/data-transfer-objects/search/mobile-device-search/MobileDeviceSearchResultDto';
import {SearchMobileDeviceRequest} from '@/models/api/requests/search/SearchMobileDeviceRequest';
import {PurgeMobileDeviceRequest} from '@/models/api/requests/users/PurgeMobileDeviceRequest';
import {useUserStore} from '@/stores/user-store';
import i18n from '@/i18n';
import {RoleCode} from '@/enums/role-code';
import {useConfigStore} from '@/stores/config-store';
import {SerachMobileDevicesResponse} from '@/models/api/responses/search/SearchMobileDevicesResponse';

// Form
let isLoading = ref<boolean>(false);
const snackbar = ref({
    show: false,
    text: '',
});
const isConfirmed = ref<boolean>(false);
const purgeDeviceModalStore = usePurgeDeviceModalStore();
const devices = ref<MobileDeviceSearchResultDto[]>([]);

// Services
const apiService = inject('apiService') as ApiService;
const userStore = useUserStore();
const configStore = useConfigStore();

const hasDevicePurgeAccess = computed(() => userStore.hasRoleLevel(RoleCode.User, 3));

/**
 * Load data for the modal.
 */
const loadData = async (mobileDeviceIds: number[]) => {
    isLoading.value = true;

    let searchParams = new SearchMobileDeviceRequest();
    searchParams.mobileDeviceIdList = mobileDeviceIds;

    // Set devices
    const response: SerachMobileDevicesResponse = await apiService.post('search/mobile-devices', searchParams);
    devices.value = response.mobileDevices;

    isLoading.value = false;
};

const purgeDevice = async () => {
    if (devices.value.length > 0 && userStore.user) {
        isLoading.value = true;

        const searchParams: PurgeMobileDeviceRequest = {
            mobileDeviceIdList: devices.value.map((device) => device.mobileDeviceId),
        };

        // Set form user
        const response = await apiService.post('mobile-devices/purge-device', searchParams);

        if (response) {
            // Show success feedback to user
            snackbar.value.show = true;
            snackbar.value.text = i18n.global.t('PurgeDevice_Success');

            purgeDeviceModalStore.purgedCounter++;

            // Close modal
            close();
        } else {
            // Show fail feedback to user
            snackbar.value.show = true;
            snackbar.value.text = i18n.global.t('ErrorGeneric');
            isLoading.value = false;
        }

        isLoading.value = false;
    }
};

/**
 * Close the modal.
 */
const close = () => {
    isConfirmed.value = false;
    purgeDeviceModalStore.close();
};

// Watch for changes to purgeDeviceModalStore.isVisible
watch(
    () => purgeDeviceModalStore.isVisible,
    async (isVisible) => {
        if (
            isVisible &&
            purgeDeviceModalStore.mobileDeviceIds !== null &&
            purgeDeviceModalStore.mobileDeviceIds.length > 0
        ) {
            await loadData(purgeDeviceModalStore.mobileDeviceIds);
        }
    },
);
</script>

<style lang="scss">
@import '@/assets/scss/swatches.scss';

.flexRow {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.device-instructions-purge {
    background-color: rgba($swatchA3, 0.2) !important;

    .confirm-header {
        background-color: rgba($swatchA3, 0.7);
        font-size: large;
    }
}

.device-confirm-purge {
    background-color: rgba($swatchGRN, 0.2) !important;

    .confirm-header {
        background-color: rgba($swatchA5, 0.5);
        font-size: large;
    }

    .confirm-checkbox {
        font-size: 18pt;
    }
}

.permissionDenied {
    background-color: rgba($swatchRED, 0.2) !important;
}
</style>
