<template>
    <v-dialog max-width="1000px" v-model="homeDashboardStore.observationFiltersVisible">
        <v-card style="overflow: visible">
            <v-toolbar color="swatchG2">
                <v-tab class="ma-0 swatchA3BG" height="100%" readonly>
                    <FontAwesomeIcon :icon="['fal', 'filter']" size="xl" class="mr-2" />
                    {{ $t('ApplyFilter') }}
                </v-tab>
                <v-spacer></v-spacer>
                <CloseModalButton @click="closeModal()" />
            </v-toolbar>
            <LoadingSymbol v-if="isLoading" />
            <table v-else style="width: 100%" class="tblForm pa-4">
                <tr>
                    <td class="tableCell padding" style="width: 10%; min-width: 13rem">
                        <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                            <span>{{ $t('ObservationType') }}</span>
                            <HelpIcon :help-text="$t('ObservationSearch_ObservationType_HelpText')" />
                        </div>
                    </td>
                    <td>
                        <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                            <v-select
                                v-model="searchParameters.observationTypeCodeList"
                                hide-details
                                multiple
                                clearable
                                chips
                                closable-chips
                                :items="observationTypes"
                                item-title="observationTypeName"
                                item-value="observationTypeCode"
                                class="custom-chips"></v-select>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="tableCell padding">
                        <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                            <span>{{ $t('Status') }}</span>
                            <HelpIcon :help-text="$t('ObservationSearch_ObservationStatus_HelpText')" />
                        </div>
                    </td>
                    <td>
                        <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                            <v-select
                                v-model="searchParameters.observationStatusCodeList"
                                hide-details
                                :items="filteredStatuses"
                                multiple
                                clearable
                                chips
                                item-title="observationStatusName"
                                item-value="observationStatusCode"></v-select>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="tableCell padding">
                        <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                            <span>{{ $t('DateRaised') }}</span>
                            <HelpIcon :help-text="$t('ObservationSearch_DateRaised_HelpText')" />
                        </div>
                    </td>
                    <td>
                        <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                            <VueDatePicker
                                v-model="dateRaised"
                                range
                                format="dd/MM/yyyy"
                                :enable-time-picker="false"
                                text-input
                                :preset-dates="presetDates"
                                style="max-width: 300px"
                                :locale="i18n.global.locale" />
                        </div>
                    </td>
                </tr>
                <tr v-if="isObservationOpenMode">
                    <td class="tableCell padding">
                        <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                            <span>{{ $t('DateLastActivity') }}</span>
                            <HelpIcon :help-text="$t('ObservationSearch_DateLastActivity_HelpText')" />
                        </div>
                    </td>
                    <td>
                        <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                            <VueDatePicker
                                v-model="dateLastActivity"
                                range
                                format="dd/MM/yyyy"
                                :enable-time-picker="false"
                                text-input
                                :preset-dates="presetDates"
                                style="max-width: 300px"
                                :locale="i18n.global.locale" />
                        </div>
                    </td>
                </tr>
                <tr v-else>
                    <td class="tableCell padding">
                        <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                            <span>{{ $t('CompletedDate') }}</span>
                            <HelpIcon :help-text="$t('ObservationSearch_DateConcluded_HelpText')" />
                        </div>
                    </td>
                    <td>
                        <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                            <VueDatePicker
                                v-model="dateConcluded"
                                range
                                format="dd/MM/yyyy"
                                :enable-time-picker="false"
                                text-input
                                :preset-dates="presetDates"
                                style="max-width: 300px"
                                :locale="i18n.global.locale" />
                        </div>
                    </td>
                </tr>
                <tr v-if="!isObservationOpenMode">
                    <td class="tableCell padding">
                        <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                            <span>{{ $t('UsersAssociated') }}</span>
                            <HelpIcon :help-text="$t('ObservationSearch_UsersAssociated_HelpText')" />
                        </div>
                    </td>
                    <td>
                        <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                            <v-autocomplete
                                v-model="searchParameters.userIdList"
                                :items="users"
                                hide-details
                                multiple
                                clearable
                                small-chips
                                item-title="userFullName"
                                item-value="userId">
                                <template #selection="{item}">
                                    <UserChip
                                        :user="UserHelper.userChipType(item.value, item.title)"
                                        :closable="true"
                                        @close-chip="handleChipClose" />
                                </template>
                            </v-autocomplete>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="tableCell padding">
                        <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                            <span>{{ $t('SortOrder') }}</span>
                            <HelpIcon :help-text="$t('ObservationSearch_SortOrder_HelpText')" />
                        </div>
                    </td>
                    <td colspan="4">
                        <v-select
                            v-model="searchParameters.sortOrder"
                            hide-details
                            :items="isObservationOpenMode ? sortOrderFuture : sortOrderCompleted"
                            item-title="title"
                            item-value="value"
                            max-width="400"></v-select>
                    </td>
                </tr>
            </table>

            <v-card-actions class="swatchG9BG">
                <v-btn @click="closeModal()">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'arrow-rotate-left']" size="xl" />
                    </template>
                    {{ $t('Back') }}
                </v-btn>
                <v-spacer />
                <v-btn @click="resetParameters()">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'filter-circle-xmark']" size="xl" />
                    </template>
                    {{ $t('ResetFilters') }}
                </v-btn>
                <v-btn @click="search(false)">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fac', 'filter-check']" size="xl" />
                    </template>
                    {{ $t('ApplyFilters') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
import {computed, defineAsyncComponent, inject, onMounted, onUnmounted, ref, watch} from 'vue';
import ApiService from '@/services/api-service';
import {useHomeDashboardStore} from '@/stores/home-dashboard-store';
import {useUserStore} from '@/stores/user-store';
import {addDays, addMonths} from 'date-fns';
import {formatTime} from '@/helpers/TimeFormatHelper';
import {useObservationTypes} from '@/composables/data-source/observation-types';
const {getObservationTypes, observationTypes} = useObservationTypes();
import {useUsers} from '@/composables/data-source/users';
const {getUsers, users} = useUsers();
import {GetUsersRequest} from '@/models/api/requests/data-source/GetUsersRequest';
import {useObservationStatuses} from '@/composables/data-source/observation-statuses';
import i18n from '@/i18n';
import {SearchObservationsResponse} from '@/models/api/responses/search/SearchObservationsResponse';
import UserChip from '@/components/UserChip.vue';
import {SearchObservationsRequest} from '@/models/api/requests/search/SearchObservationsRequest';
const {getObservationStatuses, observationStatuses} = useObservationStatuses();
const VueDatePicker = defineAsyncComponent(() => import('@vuepic/vue-datepicker'));
import UserHelper from '@/helpers/UserHelper';

// Services and stores
const apiService = inject('apiService') as ApiService;
const homeDashboardStore = useHomeDashboardStore();
const userStore = useUserStore();

const isLoading = ref<boolean>(false);
const dateRaised = ref<Array<Date> | null>(null);
const dateLastActivity = ref<Array<Date> | null>(null);
const dateConcluded = ref<Array<Date> | null>(null);
const farmSiteId = computed(() => userStore.user?.farmSiteId ?? null);

// Options for Sort Order
const sortOrderFuture = [
    {value: 'FarmCodeName', title: i18n.global.t('FarmCodeAndName')},
    {value: 'DateRaised', title: i18n.global.t('DateRaisedAscendingBrackets')},
    {value: 'LastActivityDate', title: i18n.global.t('LastActivityDateAscendingBrackets')},
];

const sortOrderCompleted = [
    {value: 'FarmCodeName', title: i18n.global.t('FarmCodeAndName')},
    {value: 'DateCompleted', title: i18n.global.t('DateCompletedDescendingBrackets')},
    {value: 'DateRaised', title: i18n.global.t('DateRaisedAscendingBrackets')},
];

const isObservationOpenMode = computed(() => homeDashboardStore.isObservationOpenMode);

const searchParameters = ref<SearchObservationsRequest>(new SearchObservationsRequest());
const defaultSearchParameters = ref<SearchObservationsRequest>({
    ...new SearchObservationsRequest(),
    sortOrder: 'FarmCodeName',
});

const filteredStatuses = computed(() =>
    observationStatuses.value.filter((o) => o.isClosed == !isObservationOpenMode.value),
);

//The present date for the date picker
const today = new Date();
const presetDates = ref([
    {
        label: i18n.global.t('Last7Days'),
        value: [addDays(today, -6), today],
    },
    {
        label: i18n.global.t('Last14Days'),
        value: [addDays(today, -13), today],
    },
    {
        label: i18n.global.t('Last30Days'),
        value: [addDays(today, -29), today],
    },
    {
        label: i18n.global.t('Last3Months'),
        value: [addMonths(today, -3), today],
    },
    {
        label: i18n.global.t('Last6Months'),
        value: [addMonths(today, -6), today],
    },
    {
        label: i18n.global.t('Last9Months'),
        value: [addMonths(today, -9), today],
    },
    {
        label: i18n.global.t('Last12Months'),
        value: [addMonths(today, -12), today],
    },
]);

/**
 * Gets the From date (first date) from a date range array.
 */
const getFromDate = (dates: Array<Date> | null) => {
    // If there is a From date
    if (dates != null && dates.length >= 1 && dates[0] != null) {
        // Return From date
        return formatTime(dates[0], 'yyyy-MM-dd');
    }

    return null;
};

/**
 * Gets the To date (second date) from a date range array.
 */
const getToDate = (dates: Array<Date> | null) => {
    // If there is a To date
    if (dates != null && dates.length >= 2 && dates[1] != null) {
        // Return To date
        return formatTime(dates[1], 'yyyy-MM-dd');
    }

    return null;
};

// Watch for changes in the dateRaised object and update local parameters
watch(
    () => dateRaised.value,
    (newVal) => {
        searchParameters.value.dateRaisedFrom = getFromDate(newVal);
        searchParameters.value.dateRaisedTo = getToDate(newVal);
    },
);

// Watch for changes in the dateLastActivity object and update local parameters
watch(
    () => dateLastActivity.value,
    (newVal) => {
        searchParameters.value.dateLastActivityFrom = getFromDate(newVal);
        searchParameters.value.dateLastActivityTo = getToDate(newVal);
    },
);

// Watch for changes in the dateConcluded object and update local parameters
watch(
    () => dateConcluded.value,
    (newVal) => {
        searchParameters.value.dateConcludedFrom = getFromDate(newVal);
        searchParameters.value.dateConcludedTo = getToDate(newVal);
    },
);

// Deselect user chip if manually closed
const handleChipClose = (userId: number) => {
    if (searchParameters.value.userIdList) {
        const index = searchParameters.value.userIdList.indexOf(userId);
        if (index !== -1) {
            searchParameters.value.userIdList.splice(index, 1);
        }
    }
};

// Close filter modal
const closeModal = () => {
    homeDashboardStore.observationFiltersVisible = false;
};

// Search for observations
const search = async (isReset: boolean) => {
    isLoading.value = true;

    const response: SearchObservationsResponse = await apiService.post('search/observations', searchParameters.value);

    if (isObservationOpenMode.value) {
        homeDashboardStore.openObservationsFiltered = response.observations;
    } else {
        homeDashboardStore.closedObservationsFiltered = response.observations;
    }

    if (!isReset) {
        const filterApplied = isFilterApplied();

        if (isObservationOpenMode.value) {
            homeDashboardStore.observationOpenFilterApplied = filterApplied;
        } else {
            homeDashboardStore.observationClosedFilterApplied = filterApplied;
        }

        homeDashboardStore.deselectObservationLevel1();

        Object.assign(
            homeDashboardStore.isObservationOpenMode
                ? homeDashboardStore.searchParametersOpenObservation
                : homeDashboardStore.searchParametersClosedObservation,
            searchParameters.value,
        );

        closeModal();
    }

    isLoading.value = false;
};

// Determines if filters have changed from default
const isFilterApplied = () => {
    const sortedObj1 = sortArraysInObject(searchParameters.value);
    const sortedObj2 = sortArraysInObject(defaultSearchParameters.value);

    return JSON.stringify(sortedObj1) !== JSON.stringify(sortedObj2);
};

// Makes a copy to compare arrays regardless of order
function sortArraysInObject(obj: SearchObservationsRequest): SearchObservationsRequest {
    const copy = new SearchObservationsRequest({...obj});

    if (copy.observationTypeCodeList)
        copy.observationTypeCodeList = [...copy.observationTypeCodeList].sort((a, b) => a.localeCompare(b));
    if (copy.userIdList && copy.userIdList.length > 0) {
        copy.userIdList = [...copy.userIdList].sort((a, b) => a - b);
    } else {
        copy.userIdList = [];
    }

    return copy;
}

// Reset search parameters to default
const resetParameters = async () => {
    homeDashboardStore.resetObservationSearchParameters(false);
    searchParameters.value = {...defaultSearchParameters.value};

    initializeDateProperties();

    search(true);
};

// Get dropdown data
const initializeDropdowns = async () => {
    const searchRequest: GetUsersRequest = {
        farmSiteId: farmSiteId.value,
    };

    await getObservationTypes();
    await getObservationStatuses();
    await getUsers(searchRequest);
};

// Initialize default and regular search parameters
const initializeSearchParameters = () => {
    Object.assign(
        searchParameters.value,
        homeDashboardStore.isObservationOpenMode
            ? homeDashboardStore.searchParametersOpenObservation
            : homeDashboardStore.searchParametersClosedObservation,
    );

    // Initialize search parameters only if they haven't been set
    if (!searchParameters.value.observationTypeCodeList) {
        searchParameters.value.observationTypeCodeList = observationTypes.value.map(
            (observationType) => observationType.observationTypeCode,
        );
    }

    initializeDateProperties();

    searchParameters.value.farmSiteId = farmSiteId.value;
    defaultSearchParameters.value.farmSiteId = farmSiteId.value;

    defaultSearchParameters.value.observationTypeCodeList = observationTypes.value.map(
        (observationType) => observationType.observationTypeCode,
    );
};

// Initialize date properties
const initializeDateProperties = () => {
    dateRaised.value = [
        searchParameters.value.dateRaisedFrom ? new Date(searchParameters.value.dateRaisedFrom) : null,
        searchParameters.value.dateRaisedTo ? new Date(searchParameters.value.dateRaisedTo) : null,
    ].filter(Boolean) as Array<Date>;

    dateLastActivity.value = [
        searchParameters.value.dateLastActivityFrom && isObservationOpenMode.value
            ? new Date(searchParameters.value.dateLastActivityFrom)
            : null,
        searchParameters.value.dateLastActivityTo && isObservationOpenMode.value
            ? new Date(searchParameters.value.dateLastActivityTo)
            : null,
    ].filter(Boolean) as Array<Date>;

    dateConcluded.value = [
        searchParameters.value.dateConcludedFrom && !isObservationOpenMode.value
            ? new Date(searchParameters.value.dateConcludedFrom)
            : null,
        searchParameters.value.dateConcludedTo && !isObservationOpenMode.value
            ? new Date(searchParameters.value.dateConcludedTo)
            : null,
    ].filter(Boolean) as Array<Date>;
};

onUnmounted(() => {
    dateRaised.value = [];
    dateLastActivity.value = [];
    dateConcluded.value = [];
});

onMounted(async () => {
    isLoading.value = true;

    await initializeDropdowns();
    initializeSearchParameters();

    isLoading.value = false;
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/swatches.scss';

.custom-chips :deep(.v-chip) {
    background-color: $swatchB2;
    color: $swatchWHT;
}
</style>
