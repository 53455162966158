<template>
    <div class="pb-5" v-if="farmField">
        <div class="title">
            <span class="space-between-div">
                <div class="farm-field-title">
                    <span class="bold swatchA1 slightlyLarger" v-if="farmField.farmFieldCode"
                        >{{ farmField.farmFieldCode }}
                    </span>
                    <span class="pl-1 pr-1 bold swatchA1 slightlyLarger">-</span>
                    <span class="bold swatchA1 slightlyLarger" v-if="farmField.farmFieldName">
                        {{ farmField.farmFieldName }}
                    </span>
                    <IdIcon v-if="farmField.farmFieldId">{{ farmField.farmFieldId }}</IdIcon>

                    <div class="farm-field-icons pl-5">
                        <CropIcon
                            v-if="farmField.cropCode"
                            :cropCode="farmField.cropCode"
                            iconHeight="45"
                            iconWidth="45"
                            iconSize="25" />

                        <FarmFieldAgeIcon
                            v-if="farmField.cropYear || farmField.cropYear === 0"
                            iconHeight="45"
                            iconWidth="45"
                            :fieldAge="farmField.cropYear.toString()" />

                        <WorkTaskTypeIcon
                            v-if="farmField.nextWorkTask"
                            :workTaskTypeCode="nextTaskCode"
                            iconHeight="45"
                            iconWidth="45" />
                    </div>
                    <div v-if="isShowButton">
                        <v-btn
                            :disabled="!farmField"
                            rounded="1"
                            class="mr-2 v-btn--custom"
                            v-bind="props"
                            @click="viewOnMap(farmField)">
                            <template v-slot:prepend>
                                <FontAwesomeIcon :icon="['fal', 'map']" size="xl" />
                            </template>
                            {{ $t('ViewOnMap') }}
                        </v-btn>
                        <v-menu open-on-hover>
                            <template v-slot:activator="{props}">
                                <v-btn rounded="1" class="v-btn--custom" v-bind="props">
                                    <template v-slot:append>
                                        <FontAwesomeIcon :icon="['fas', 'caret-down']" size="xl" />
                                    </template>
                                    {{ $t('Operations') }}
                                </v-btn>
                            </template>

                            <v-list>
                                <v-list-item>
                                    <v-list-item-title
                                        v-if="farmField.farmFieldId && userStore.hasRoleLevel(RoleCode.Fields, 2)">
                                        <v-btn @click="openManageFarmFieldModal(farmField.farmFieldId)">
                                            {{ $t('ModifyField') }}
                                        </v-btn>
                                    </v-list-item-title>

                                    <v-list-item-title
                                        v-if="farmField.farmFieldId && userStore.hasRoleLevel(RoleCode.Tasks, 2)">
                                        <v-btn
                                            @click="
                                                manageWorkTaskModalStore.open(
                                                    ManageWorkTaskModalMode.Create,
                                                    null,
                                                    farmField.farmFieldId,
                                                )
                                            ">
                                            {{ $t('CreateNewTaskInField') }}
                                        </v-btn>
                                    </v-list-item-title>
                                    <v-list-item-title
                                        v-if="farmField.farmFieldId && userStore.hasRoleLevel(RoleCode.Tasks, 2)">
                                        <v-btn @click="selectWorkTaskTemplateModalStore.open(farmField.farmFieldId)">
                                            {{ $t('CreateNewTaskFromTemplate') }}
                                        </v-btn>
                                    </v-list-item-title>
                                    <v-list-item-title v-if="userStore.hasRoleLevel(RoleCode.Fields, 1)">
                                        <v-btn @click="manageFarmFieldSeasonModalStore.open(farmField.farmFieldId)">
                                            {{ $t('ManageSeasons') }}
                                        </v-btn>
                                    </v-list-item-title>

                                    <v-list-item-title
                                        v-if="farmField.farmFieldId && userStore.hasRoleLevel(RoleCode.Fields, 3)">
                                        <v-btn
                                            @click="
                                                viewFarmFieldModalStore.close();
                                                deleteFarmFieldModalStore.open(farmField.farmFieldId);
                                            ">
                                            {{ $t('DeleteField') }}
                                        </v-btn>
                                    </v-list-item-title>

                                    <v-list-item-title v-if="userStore.hasRoleLevel(RoleCode.Tasks, 3)">
                                        <v-btn @click="clearTasksModalStore.open(farmField.farmFieldId)">
                                            {{ $t('ClearTasks') }}
                                        </v-btn>
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </div>

                <div class="profile-detail pt-2">
                    <div class="left-content">
                        <div v-if="farmField.farmFieldDescription">
                            <span><TranslatableTexts :text="farmField.farmFieldDescription" /></span>
                        </div>
                        <div class="farm-field-icons pt-3">
                            <FieldSizeChip
                                v-if="farmField.farmFieldSize || farmField.farmFieldSize == 0"
                                :farmFieldSize="farmField.farmFieldSize" />
                            <RowWidthChip v-if="farmField.rowWidth" :rowWidth="parseFloat(farmField.rowWidth)" />
                            <RowChip
                                v-if="farmField.farmFieldNumRows"
                                :numberOfRows="farmField.farmFieldNumRows"
                                :rowDirection="
                                    farmField.farmFieldRowDirection ? farmField.farmFieldRowDirection : undefined
                                " />
                        </div>
                    </div>
                    <div class="right-chip-container">
                        <FarmSiteChip
                            v-if="associatedSiteAbbreviation && associatedSiteAbbreviation != ''"
                            :farm-site-abbreviation="associatedSiteAbbreviation"
                            :farm-site-name="associatedSiteName" />
                        <SeasonChip v-if="farmField" :seasonName="seasonText" />
                        <FirstPlantedChip
                            v-if="farmField.plantedDate"
                            :seasonStartTime="new Date(farmField.plantedDate)" />
                    </div>
                </div>
            </span>
        </div>
    </div>
</template>
<script setup lang="ts">
import {onMounted, ref} from 'vue';
import RowChip from '@/components/other-chips/RowChip.vue';
import RowWidthChip from '@/components/other-chips/RowWidthChip.vue';
import FieldSizeChip from '@/components/other-chips/FieldSizeChip.vue';
import SeasonChip from '@/components/other-chips/SeasonChip.vue';
import FarmSiteChip from '@/components/other-chips/FarmSiteChip.vue';
import FirstPlantedChip from '@/components/other-chips/FirstPlantedChip.vue';
import FarmFieldAgeIcon from '@/components/FarmFieldAgeIcon.vue';
import {WorkTaskTypeCode} from '@/enums/work-task-type-code';
import {computed} from 'vue';
import {ManageFarmFieldForm} from '@/models/farm/ManageFarmFieldForm';
import i18n from '@/i18n';
import {RoleCode} from '@/enums/role-code';
import {useManageFarmFieldModalStore} from '@/stores/modals/manage-farm-field-modal-store';
const manageFarmFieldModalStore = useManageFarmFieldModalStore();
import {useHomeDashboardStore} from '@/stores/home-dashboard-store';
import {useFarmSites} from '@/composables/data-source/farm-sites';
const {getFarmSites, farmSites} = useFarmSites();
import {ManageWorkTaskModalMode} from '@/enums/modal-modes/manage-work-task-modal-mode';

import {useFarmStore} from '@/stores/farm-store';
const farmStore = useFarmStore();
import {useAdvancedSearchModalStore} from '@/stores/modals/advanced-search-modal-store';
const advancedSearchModalStore = useAdvancedSearchModalStore();
import {useViewFarmFieldModalStore} from '@/stores/modals/view-farm-field-modal-store';
const viewFarmFieldModalStore = useViewFarmFieldModalStore();
import router from '@/router';
import {useUserStore} from '@/stores/user-store';
const userStore = useUserStore();
import {useSelectWorkTaskTemplateModalStore} from '@/stores/modals/select-work-task-template-modal-store';
const selectWorkTaskTemplateModalStore = useSelectWorkTaskTemplateModalStore();
import {useManageWorkTaskModalStore} from '@/stores/modals/manage-work-task-modal-store';
const manageWorkTaskModalStore = useManageWorkTaskModalStore();
import {useDeleteFarmFieldModalStore} from '@/stores/modals/delete-farm-field-modal-store';
import {useManageFarmFieldSeasonModalStore} from '@/stores/modals/manage-farm-field-season-modal-store';
import {useClearTasksModalStore} from '@/stores/modals/clear-tasks-modal-store';
const clearTasksModalStore = useClearTasksModalStore();

// Stores
const homeDashboardStore = useHomeDashboardStore();
const deleteFarmFieldModalStore = useDeleteFarmFieldModalStore();
const manageFarmFieldSeasonModalStore = useManageFarmFieldSeasonModalStore();

// view field on map
async function viewOnMap(farmField: ManageFarmFieldForm) {
    if (farmField.farmSiteId && farmField.farmFieldId) {
        if (farmStore.selectedSiteId !== farmField.farmSiteId) {
            farmStore.selectedSiteId = farmField.farmSiteId;
            if (userStore.isLoggedIn && userStore.user) userStore.user.farmSiteId = farmField.farmSiteId;
            setTimeout(() => {
                homeDashboardStore.farmFieldIdToFocusOn = farmField.farmFieldId;
            }, 1000);
        } else homeDashboardStore.farmFieldIdToFocusOn = farmField.farmFieldId;

        advancedSearchModalStore.close();
        viewFarmFieldModalStore.close();

        if (!homeDashboardStore.isHomeDashboardOpen) {
            router.push({name: 'HomeDashboard'});
        }
    }
}

const associatedSiteAbbreviation = ref<string>('');
const associatedSiteName = ref<string>('');
onMounted(async () => {
    if (props.farmField && props.farmField.farmSiteId) {
        if (!farmSites.value || farmSites.value.length === 0) {
            await getFarmSites();
        }
        const siteItem = farmSites.value.find((e) => e.farmSiteId === props.farmField?.farmSiteId);
        if (siteItem) {
            const farmSiteAbbreviationAndNameTokens = siteItem.farmSiteAbbreviationAndName.split('-');
            associatedSiteAbbreviation.value = farmSiteAbbreviationAndNameTokens[0].trim();
            if (farmSiteAbbreviationAndNameTokens.length > 1)
                associatedSiteName.value = farmSiteAbbreviationAndNameTokens[1].trim();
        }
    }
});

// open Manage Farm Field Modal
function openManageFarmFieldModal(farmFieldId: number) {
    viewFarmFieldModalStore.close();
    manageFarmFieldModalStore.open(farmFieldId);
}

// Props
const props = withDefaults(
    defineProps<{
        farmField: ManageFarmFieldForm | null;
        isShowButton?: boolean;
    }>(),
    {
        farmField: null,
        isShowButton: false,
    },
);

const seasonText = computed(() => {
    if (props.farmField && props.farmField.currentSeasonName) {
        return props.farmField.currentSeasonName;
    }

    return i18n.global.t('NoActiveSeason');
});

// Computed property for next task code
const nextTaskCode = computed(() => {
    return props.farmField?.nextWorkTask?.workTaskTypeCode as WorkTaskTypeCode;
});
</script>
<style lang="scss" scoped>
@import '../assets/scss/swatches.scss';

.rounded-button {
    border-radius: 20px;
}

.farm-field-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
}

.farm-field-icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    gap: 0.5rem;
}

.profile-detail {
    display: flex;
    justify-content: space-between; /* Ensure elements are spaced out */
    align-items: flex-start; /* Align items at the start of the container */
    gap: 10px; /* Add gap between the elements */
    width: 100%;
}

.left-content {
    display: flex;
    flex-direction: column;
    flex: 1; /* Allow the left content to take up the remaining space */
    align-items: flex-start; /* Align content to the left */
}

.right-chip-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end; /* Align the map-container content to the right */
    row-gap: 0.5rem;
}
</style>
