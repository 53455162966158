<template>
    <v-dialog v-model="manageWorkTaskModalStore.isVisible" class="pnlUserManager" height="100%">
        <v-card height="100%">
            <v-toolbar color="secondary">
                <v-toolbar-title>
                    <FontAwesomeIcon :icon="[headerIconFamily, headerIconCode]" size="xl" class="mr-2" />
                    {{ modalTitle }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <CloseModalButton @click="close" />
            </v-toolbar>

            <div v-if="isLoading" style="height: 100%">
                <LoadingSymbol />
            </div>

            <v-form
                v-else
                ref="form"
                class="pa-4 divUserManager"
                v-model="valid"
                style="height: 100%; overflow-y: scroll">
                <!-- Work Task Details Header (shown when modifying a work task) -->
                <WorkTaskDetailsHeader
                    v-if="modalMode == ManageWorkTaskModalMode.Modify && workTaskSearchResult != null"
                    :work-task="workTaskSearchResult"
                    :farm-field="farmFieldSearchResult!"
                    :show-operations-menu="false" />

                <!-- Work Task Template Details Header (shown when modifying a work task template) -->
                <WorkTaskTemplateDetailsHeader
                    v-if="modalMode == ManageWorkTaskModalMode.ModifyTemplate && workTaskTemplateSearchResult != null"
                    :work-task-template="workTaskTemplateSearchResult"
                    :show-operations-menu="false" />

                <!-- Work Task Details Subheader (shown when modifying a work task) -->
                <div
                    v-if="
                        modalMode == ManageWorkTaskModalMode.Modify ||
                        modalMode == ManageWorkTaskModalMode.ModifyTemplate
                    "
                    class="subHeader mt-2">
                    {{ $t('TaskDetails') }}
                </div>

                <!-- Instructions panel (shown when duplicating a work task or template) -->
                <v-card
                    v-else-if="
                        modalMode == ManageWorkTaskModalMode.Duplicate ||
                        modalMode == ManageWorkTaskModalMode.DuplicateTemplate
                    "
                    class="pnlInstructions mt-2">
                    <v-toolbar color="swatchA6">
                        <v-toolbar-title>
                            <FontAwesomeIcon :icon="['fas', 'question-circle']" size="xl" class="mr-2" />
                            {{ $t('Instructions') }}
                        </v-toolbar-title>
                    </v-toolbar>

                    <v-card-text class="instructionContentBG">
                        {{
                            modalMode == ManageWorkTaskModalMode.Duplicate
                                ? $t('DuplicateWorkTaskInstructions')
                                : $t('DuplicateWorkTaskTemplateInstructions')
                        }}
                    </v-card-text>
                </v-card>

                <!-- Select Task Type -->
                <div
                    v-if="
                        modalMode == ManageWorkTaskModalMode.Create ||
                        modalMode == ManageWorkTaskModalMode.CreateTemplate ||
                        modalMode == ManageWorkTaskModalMode.DuplicateTemplate
                    "
                    class="flex-layout fill flex-layout-space-05 align-items-center">
                    <div class="formHead">{{ $t('SelectTaskType') }}</div>
                    <div class="formIcon">
                        <HelpIcon :help-text="$t('ManageWorkTask_SelectTaskType_HelpText')" />
                    </div>
                    <div>
                        <WorkTaskTypeSelect
                            v-model="localWorkTask.workTaskTypeCode"
                            :rules="[requiredRule]"
                            :is-enabled="isWorkTaskTypeChangeable || localWorkTask.workTaskTypeCode == null" />
                    </div>
                </div>

                <!-- Field -->
                <div
                    v-if="modalMode == ManageWorkTaskModalMode.Create || modalMode == ManageWorkTaskModalMode.Duplicate"
                    class="flex-layout fill flex-layout-space-05 align-items-center">
                    <div class="formHead">{{ $t('Field') }}</div>
                    <div class="formIcon">
                        <HelpIcon :help-text="$t('ManageWorkTask_Field_HelpText')" />
                    </div>
                    <div class="formModifyGroup">
                        <v-autocomplete
                            v-model="localWorkTask.farmFieldId"
                            :rules="[requiredRule]"
                            required
                            :placeholder="$t('SelectField')"
                            :items="farmFields"
                            item-title="farmFieldCodeAndName"
                            item-value="farmFieldId"
                            max-width="400px"
                            menu-icon=""
                            class="swatchA1 font-weight-bold">
                            <template v-slot:append-inner>
                                <CircleIcon
                                    class="marginLeft marginRightSmall swatchGRNBG"
                                    color="swatchWHT"
                                    v-if="localWorkTask.farmFieldId != null">
                                    <FontAwesomeIcon :icon="['fas', 'check']" />
                                </CircleIcon>
                            </template>
                        </v-autocomplete>
                    </div>
                </div>

                <!-- Template Name -->
                <div
                    v-if="
                        modalMode == ManageWorkTaskModalMode.CreateTemplate ||
                        modalMode == ManageWorkTaskModalMode.ModifyTemplate ||
                        modalMode == ManageWorkTaskModalMode.DuplicateTemplate
                    "
                    class="flex-layout fill flex-layout-space-05 align-items-center formRow flex-wrap-nowrap">
                    <div class="formHead">{{ $t('TemplateName') }}</div>
                    <div class="formIcon">
                        <HelpIcon :help-text="$t('ManageWorkTask_TemplateName_HelpText')" />
                    </div>
                    <div class="formModifyGroup">
                        <v-text-field
                            v-model="localWorkTask.workTaskTemplateName"
                            :rules="[requiredRule, templateNameUniqueRule]"
                            :placeholder="$t('EnterTemplateName')"
                            max-width="400px"
                            class="swatchA1 font-weight-bold">
                            <template v-slot:append-inner>
                                <FontAwesomeIcon
                                    :icon="['fas', 'spinner']"
                                    pulse
                                    size="xl"
                                    class="swatchA2"
                                    v-if="localWorkTask.workTaskTemplateName && isCheckingTemplateName" />

                                <CircleIcon
                                    class="marginLeft marginRightSmall swatchREDBG"
                                    color="swatchWHT"
                                    :title="$t('TemplateNameIsNotAvailable')"
                                    v-if="
                                        localWorkTask.workTaskTemplateName &&
                                        !isCheckingTemplateName &&
                                        !isTemplateNameAvailable
                                    ">
                                    <FontAwesomeIcon :icon="['fas', 'xmark']" />
                                </CircleIcon>

                                <CircleIcon
                                    class="marginLeft marginRightSmall swatchGRNBG"
                                    color="swatchWHT"
                                    :title="$t('TemplateNameIsAvailable')"
                                    v-if="
                                        localWorkTask.workTaskTemplateName &&
                                        !isCheckingTemplateName &&
                                        isTemplateNameAvailable
                                    ">
                                    <FontAwesomeIcon :icon="['fas', 'check']" />
                                </CircleIcon>
                            </template>
                        </v-text-field>
                    </div>
                </div>

                <!-- Task Name -->
                <div
                    v-if="
                        modalMode == ManageWorkTaskModalMode.Create ||
                        modalMode == ManageWorkTaskModalMode.Modify ||
                        modalMode == ManageWorkTaskModalMode.Duplicate
                    "
                    class="flex-layout fill flex-layout-space-05 align-items-center formRow flex-wrap-nowrap">
                    <div class="formHead">{{ $t('TaskName') }}</div>
                    <div class="formIcon">
                        <HelpIcon :help-text="$t('ManageWorkTask_TaskName_HelpText')" />
                    </div>
                    <div class="formModifyGroup">
                        <v-text-field
                            v-model="localWorkTask.workTaskName"
                            :placeholder="$t('EnterTaskName')"
                            max-width="400px"
                            class="swatchA1 font-weight-bold">
                        </v-text-field>
                    </div>
                </div>

                <!-- Default Task Name -->
                <div
                    v-if="
                        modalMode == ManageWorkTaskModalMode.CreateTemplate ||
                        modalMode == ManageWorkTaskModalMode.ModifyTemplate ||
                        modalMode == ManageWorkTaskModalMode.DuplicateTemplate
                    "
                    class="flex-layout fill flex-layout-space-05 align-items-center formRow flex-wrap-nowrap">
                    <div class="formHead">{{ $t('DefaultTaskName') }}</div>
                    <div class="formIcon">
                        <HelpIcon :help-text="$t('ManageWorkTask_DefaultTaskName_HelpText')" />
                    </div>
                    <div class="formModifyGroup">
                        <v-text-field
                            v-model="localWorkTask.workTaskName"
                            :placeholder="$t('EnterDefaultTaskName')"
                            max-width="400px"
                            class="swatchA1 font-weight-bold">
                        </v-text-field>
                    </div>
                </div>

                <!-- Due Date Timing -->
                <div
                    v-if="
                        modalMode == ManageWorkTaskModalMode.Create ||
                        modalMode == ManageWorkTaskModalMode.Modify ||
                        modalMode == ManageWorkTaskModalMode.Duplicate
                    "
                    class="flex-layout fill flex-layout-space-05 align-items-center">
                    <div class="formHead">{{ $t('DueDateTiming') }}</div>
                    <div class="formIcon">
                        <HelpIcon :help-text="$t('ManageWorkTask_DueDateTiming_HelpText')" />
                    </div>
                    <div class="formModifyGroup">
                        <WorkTaskDueDateTimingControl
                            ref="dueDateTimingControl"
                            v-model:work-task="localWorkTask"
                            :work-task-type-code="localWorkTask.workTaskTypeCode"
                            :is-loading="isLoading"
                            :is-past-validation-required="false" />
                    </div>
                </div>

                <!-- Crop to Plant -->
                <div
                    v-if="localWorkTask.workTaskTypeCode == WorkTaskTypeCode.Plant"
                    class="flex-layout fill flex-layout-space-05 align-items-center formRow">
                    <div class="formHead">{{ $t('CropToPlant') }}</div>
                    <div class="formIcon">
                        <HelpIcon :help-text="$t('ManageWorkTask_CropToPlant_HelpText')" />
                    </div>
                    <div class="formModifyGroup">
                        <v-select
                            v-model="localWorkTask.plantCropId"
                            :rules="[requiredRule]"
                            required
                            :readonly="localWorkTask.workTaskCropVarietyPreferences.length > 0"
                            :placeholder="$t('SelectCrop')"
                            :items="cropsWithNull"
                            item-title="title"
                            item-value="value"
                            max-width="400px"
                            :menu-icon="localWorkTask.workTaskCropVarietyPreferences.length > 0 ? '' : '$dropdown'"
                            class="swatchA1 font-weight-bold">
                            <template v-slot:append-inner>
                                <CircleIcon
                                    class="marginLeft marginRightSmall swatchG2BG"
                                    color="swatchWHT"
                                    v-if="localWorkTask.workTaskCropVarietyPreferences.length > 0"
                                    :title="$t('CropCannotBeChangedBecauseAVarietyPreferenceExists')">
                                    <FontAwesomeIcon :icon="['fas', 'lock']" />
                                </CircleIcon>
                            </template>
                        </v-select>
                    </div>
                </div>

                <!-- Instructions -->
                <div class="flex-layout fill flex-layout-space-05 align-items-center">
                    <div class="formHead">{{ $t('Instructions') }}</div>
                    <div class="formIcon">
                        <HelpIcon :help-text="$t('ManageWorkTask_Instructions_HelpText')" />
                    </div>
                    <div class="formModifyGroup">
                        <v-textarea
                            v-model="localWorkTask.workTaskInstructions"
                            :placeholder="$t('EnterInstructions')"
                            variant="outlined"
                            density="compact"
                            rows="1"
                            auto-grow
                            hide-details="auto"
                            class="swatchA1 font-weight-bold">
                        </v-textarea>
                    </div>
                </div>

                <!-- Attachments -->
                <div class="flex-layout fill flex-layout-space-05 align-items-center formRow">
                    <div class="formHead">{{ $t('Attachments') }}</div>
                    <div class="formIcon">
                        <HelpIcon :help-text="$t('ManageWorkTask_Attachments_HelpText')" />
                    </div>
                    <div class="formModifyGroup">
                        <v-file-input
                            v-model="localWorkTask.artifacts"
                            :rules="[uploadedFileRule]"
                            :label="$t('FileUploads')"
                            :prepend-icon="null"
                            prepend-inner-icon="mdi-paperclip"
                            chips
                            multiple
                            variant="outlined"
                            density="compact"
                            hide-details="auto"
                            class="swatchA1">
                            <template v-slot:selection="{fileNames}">
                                <template
                                    v-for="(artifact, index) in localWorkTask.artifacts"
                                    :key="artifact.artifactId">
                                    <v-chip class="me-2 swatchA3BG swatchA3txt" size="small" label rounded="0">
                                        {{ (artifact as any).artifactDisplayName || fileNames[index] }}
                                    </v-chip>
                                </template>
                            </template>
                        </v-file-input>
                    </div>
                </div>

                <!-- Irrigation Rate -->
                <div
                    v-if="localWorkTask.workTaskTypeCode == WorkTaskTypeCode.Irrigate"
                    class="flex-layout fill flex-layout-space-05 align-items-center formRow">
                    <div class="formHead">{{ $t('IrrigationRate') }}</div>
                    <div class="formIcon">
                        <HelpIcon :help-text="$t('ManageWorkTask_IrrigationRate_HelpText')" />
                    </div>
                    <div class="formModifyGroup">
                        <LocalizedNumberInput
                            v-model="localWorkTask.irrigationRatePlanned"
                            :rules="irrigationRatePlannedRules"
                            style="max-width: 180px"
                            class="swatchA1 font-weight-bold">
                            <template #append-inner> <div style="min-width: 80px">ML per Ha</div></template>
                        </LocalizedNumberInput>
                    </div>
                </div>

                <WorkTaskImpactOfChanges
                    v-if="shouldShowImpactOfChanges"
                    :is-loading="isImpactOfChangeLoading"
                    :tasks-that-will-be-changed="localWorkTask.tasksThatWillBeChanged" />

                <!-- Cultivate: Sub-Tasks -->
                <div v-if="localWorkTask.workTaskTypeCode == WorkTaskTypeCode.Cultivate" class="mt-6">
                    <v-expansion-panels class="marginTop" v-model="expansionPanelsCultivate">
                        <v-expansion-panel>
                            <v-expansion-panel-title class="expansion-header">
                                {{ $t('CultivateSubTasks') }}
                            </v-expansion-panel-title>
                            <v-expansion-panel-text>
                                <!-- Sub-tasks saved against this task -->
                                <ManageWorkSubTaskItem
                                    v-for="(workSubTask, index) in localWorkTask.workSubTasks"
                                    :ref="
                                        (el) => {
                                            if (el) (workSubTaskRefs[index] as any) = el;
                                        }
                                    "
                                    :key="
                                        'WorkSubTask' +
                                        (workSubTask.workSubTaskId ??
                                            workSubTask.workTaskTemplateSubTaskId ??
                                            workSubTask.newItemId)
                                    "
                                    :work-sub-task="workSubTask"
                                    :work-sub-task-list="localWorkTask.workSubTasks"
                                    :index="index"
                                    @update="updateWorkSubTask"
                                    @delete="deleteWorkSubTask"
                                    @move-item-ahead="moveWorkSubTaskAhead"
                                    @move-item-back="moveWorkSubTaskBack" />

                                <!-- Add sub-task -->
                                <ManageWorkSubTaskItem
                                    :work-sub-task-list="localWorkTask.workSubTasks"
                                    :index="-1"
                                    @add="addWorkSubTask" />
                            </v-expansion-panel-text>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </div>

                <!-- Fertilize/Spray: Mixtures and Products -->
                <div
                    v-if="
                        localWorkTask.workTaskTypeCode == WorkTaskTypeCode.Fertilize ||
                        localWorkTask.workTaskTypeCode == WorkTaskTypeCode.Spray
                    "
                    class="mt-6">
                    <!-- Fertilizer/Spray Mixtures -->
                    <v-expansion-panels class="marginTop" v-model="expansionPanelsMixtures">
                        <v-expansion-panel>
                            <v-expansion-panel-title class="expansion-header">
                                {{
                                    localWorkTask.workTaskTypeCode == WorkTaskTypeCode.Fertilize
                                        ? $t('FertiliserMixtures')
                                        : $t('SprayMixtures')
                                }}
                            </v-expansion-panel-title>
                            <v-expansion-panel-text>
                                <!-- Mixtures saved against this task -->
                                <ManageWorkTaskMixtureItem
                                    v-for="(workTaskMixture, index) in localWorkTask.workTaskMixtures"
                                    :key="
                                        'WorkTaskMixture' +
                                        (workTaskMixture.workTaskMixtureId ??
                                            workTaskMixture.workTaskTemplateMixtureId ??
                                            workTaskMixture.newItemId)
                                    "
                                    :work-task-mixture="workTaskMixture"
                                    :work-task-mixture-list="localWorkTask.workTaskMixtures"
                                    :index="index"
                                    :mixtures="mixtures"
                                    @delete="deleteWorkTaskMixture" />

                                <!-- Add mixture -->
                                <ManageWorkTaskMixtureItem
                                    :work-task-mixture-list="localWorkTask.workTaskMixtures"
                                    :index="-1"
                                    :mixtures="mixtures"
                                    @add="addWorkTaskMixture" />
                            </v-expansion-panel-text>
                        </v-expansion-panel>
                    </v-expansion-panels>

                    <!-- Fertilizer/Spray Products -->
                    <v-expansion-panels class="marginTop" v-model="expansionPanelsProducts">
                        <v-expansion-panel>
                            <v-expansion-panel-title class="expansion-header">
                                {{
                                    localWorkTask.workTaskTypeCode == WorkTaskTypeCode.Fertilize
                                        ? $t('FertiliserProducts')
                                        : $t('SprayProducts')
                                }}
                            </v-expansion-panel-title>
                            <v-expansion-panel-text>
                                <!-- Products saved against this task -->
                                <ManageWorkTaskProductItem
                                    v-for="(workTaskProduct, index) in localWorkTask.workTaskProducts"
                                    :ref="
                                        (el) => {
                                            if (el) (workTaskProductRefs[index] as any) = el;
                                        }
                                    "
                                    :key="`WorkTaskProduct-${
                                        workTaskProduct.workTaskProductId ??
                                        workTaskProduct.workTaskTemplateProductId ??
                                        workTaskProduct.newItemId
                                    }`"
                                    :work-task-product="workTaskProduct"
                                    :work-task-product-list="localWorkTask.workTaskProducts"
                                    :work-task-mixture-list="localWorkTask.workTaskMixtures"
                                    :index="index"
                                    :products="products"
                                    :work-task-type-code="localWorkTask.workTaskTypeCode"
                                    @update="updateWorkTaskProduct"
                                    @delete="deleteWorkTaskProduct" />

                                <!-- Add product -->
                                <ManageWorkTaskProductItem
                                    :work-task-product-list="localWorkTask.workTaskProducts"
                                    :work-task-mixture-list="localWorkTask.workTaskMixtures"
                                    :index="-1"
                                    :products="products"
                                    :work-task-type-code="localWorkTask.workTaskTypeCode"
                                    @add="addWorkTaskProduct" />
                            </v-expansion-panel-text>
                        </v-expansion-panel>
                    </v-expansion-panels>

                    <!-- "At Least One Product Added" validator -->
                    <v-validation
                        v-if="isTaskTemplateMode"
                        :validation-value="numberOfProducts"
                        :rules="[atLeastOneProductAddedRule]">
                        <template v-slot="{errorMessages, isValid}">
                            <div v-if="isValid.value != null">
                                <span v-for="(error, index) in errorMessages.value" :key="index" class="error-message">
                                    {{ error }}
                                </span>
                            </div>
                        </template>
                    </v-validation>
                </div>

                <!-- Plant: Variety Preferences, Fertilizer Products, Spray Products -->
                <div v-if="localWorkTask.workTaskTypeCode == WorkTaskTypeCode.Plant" class="mt-6">
                    <!-- Variety Preferences -->
                    <v-expansion-panels class="marginTop" v-model="expansionPanelsCropVarietyPreferences">
                        <v-expansion-panel>
                            <v-expansion-panel-title class="expansion-header">
                                {{ $t('VarietyPreferences') }}
                            </v-expansion-panel-title>
                            <v-expansion-panel-text>
                                <!-- Variety Preferences saved against this task -->
                                <ManageWorkTaskCropVarietyPreferenceItem
                                    v-for="(
                                        workTaskCropVarietyPreference, index
                                    ) in localWorkTask.workTaskCropVarietyPreferences"
                                    :ref="
                                        (el) => {
                                            if (el) (workTaskCropVarietyPreferenceRefs[index] as any) = el;
                                        }
                                    "
                                    :key="`workTaskCropVarietyPreference-${
                                        workTaskCropVarietyPreference.workTaskCropVarietyPreferenceId ??
                                        workTaskCropVarietyPreference.workTaskTemplateCropVarietyPreferenceId ??
                                        workTaskCropVarietyPreference.newItemId
                                    }`"
                                    :work-task-crop-variety-preference="workTaskCropVarietyPreference"
                                    :work-task-crop-variety-preference-list="
                                        localWorkTask.workTaskCropVarietyPreferences
                                    "
                                    :index="index"
                                    :crop-id="localWorkTask.plantCropId"
                                    :crop-varieties="cropVarieties"
                                    :farm-blocks="farmBlocks"
                                    @update="updateWorkTaskCropVarietyPreference"
                                    @delete="deleteWorkTaskCropVarietyPreference" />

                                <!-- Add Variety Preference -->
                                <ManageWorkTaskCropVarietyPreferenceItem
                                    :work-task-crop-variety-preference-list="
                                        localWorkTask.workTaskCropVarietyPreferences
                                    "
                                    :index="-1"
                                    :crop-id="localWorkTask.plantCropId"
                                    :crop-varieties="cropVarieties"
                                    :farm-blocks="farmBlocks"
                                    @add="addWorkTaskCropVarietyPreference" />
                            </v-expansion-panel-text>
                        </v-expansion-panel>
                    </v-expansion-panels>

                    <!-- "At Least One Variety Added" validator -->
                    <v-validation
                        v-if="localWorkTask.workTaskTypeCode == WorkTaskTypeCode.Plant && !isTaskTemplateMode"
                        :validation-value="numberOfCropVarietyPreferences"
                        :rules="[atLeastOneVarietyAddedRule]">
                        <template v-slot="{errorMessages, isValid}">
                            <div v-if="isValid.value != null">
                                <span v-for="(error, index) in errorMessages.value" :key="index" class="error-message">
                                    {{ error }}
                                </span>
                            </div>
                        </template>
                    </v-validation>

                    <!-- Fertilizer Products -->
                    <v-expansion-panels class="marginTop" v-model="expansionPanelsFertilizerProducts">
                        <v-expansion-panel>
                            <v-expansion-panel-title class="expansion-header">
                                {{ $t('FertiliserProductsToApplyAtPlanting') }}
                            </v-expansion-panel-title>
                            <v-expansion-panel-text>
                                <!-- Products saved against this task -->
                                <ManageWorkTaskProductItem
                                    v-for="(workTaskProduct, index) in fertilizerWorkTaskProducts"
                                    :ref="
                                        (el) => {
                                            if (el) (workTaskFertilizeProductRefs[index] as any) = el;
                                        }
                                    "
                                    :key="`WorkTaskFertiliserProduct-${
                                        workTaskProduct.workTaskProductId ??
                                        workTaskProduct.workTaskTemplateProductId ??
                                        workTaskProduct.newItemId
                                    }`"
                                    :work-task-product="workTaskProduct"
                                    :work-task-product-list="fertilizerWorkTaskProducts"
                                    :work-task-mixture-list="localWorkTask.workTaskMixtures"
                                    :index="index"
                                    :products="
                                        products.filter(
                                            (p) =>
                                                p.productTypeCode == ProductTypeCode.Fertilizer ||
                                                p.productTypeCode == ProductTypeCode.Other,
                                        )
                                    "
                                    :work-task-type-code="localWorkTask.workTaskTypeCode"
                                    :is-fertilizer="true"
                                    @update="updateWorkTaskProduct"
                                    @delete="deleteWorkTaskFertilizeProduct" />

                                <!-- Add product -->
                                <ManageWorkTaskProductItem
                                    :work-task-product-list="fertilizerWorkTaskProducts"
                                    :work-task-mixture-list="localWorkTask.workTaskMixtures"
                                    :index="-1"
                                    :products="
                                        products.filter(
                                            (p) =>
                                                p.productTypeCode == ProductTypeCode.Fertilizer ||
                                                p.productTypeCode == ProductTypeCode.Other,
                                        )
                                    "
                                    :work-task-type-code="localWorkTask.workTaskTypeCode"
                                    :is-fertilizer="true"
                                    @add="addWorkTaskProduct" />
                            </v-expansion-panel-text>
                        </v-expansion-panel>
                    </v-expansion-panels>

                    <!-- Spray Products -->
                    <v-expansion-panels class="marginTop" v-model="expansionPanelsSprayProducts">
                        <v-expansion-panel>
                            <v-expansion-panel-title class="expansion-header">
                                {{ $t('SprayProductsToApplyAtPlanting') }}
                            </v-expansion-panel-title>
                            <v-expansion-panel-text>
                                <!-- Products saved against this task -->
                                <ManageWorkTaskProductItem
                                    v-for="(workTaskProduct, index) in sprayWorkTaskProducts"
                                    :ref="
                                        (el) => {
                                            if (el) (workTaskSprayProductRefs[index] as any) = el;
                                        }
                                    "
                                    :key="`WorkTaskSprayProduct-${
                                        workTaskProduct.workTaskProductId ??
                                        workTaskProduct.workTaskTemplateProductId ??
                                        workTaskProduct.newItemId
                                    }`"
                                    :work-task-product="workTaskProduct"
                                    :work-task-product-list="sprayWorkTaskProducts"
                                    :work-task-mixture-list="localWorkTask.workTaskMixtures"
                                    :index="index"
                                    :products="
                                        products.filter(
                                            (p) =>
                                                p.productTypeCode == ProductTypeCode.Chemical ||
                                                p.productTypeCode == ProductTypeCode.Other,
                                        )
                                    "
                                    :work-task-type-code="localWorkTask.workTaskTypeCode"
                                    :is-spray="true"
                                    @update="updateWorkTaskProduct"
                                    @delete="deleteWorkTaskSprayProduct" />

                                <!-- Add product -->
                                <ManageWorkTaskProductItem
                                    :work-task-product-list="sprayWorkTaskProducts"
                                    :work-task-mixture-list="localWorkTask.workTaskMixtures"
                                    :index="-1"
                                    :products="
                                        products.filter(
                                            (p) =>
                                                p.productTypeCode == ProductTypeCode.Chemical ||
                                                p.productTypeCode == ProductTypeCode.Other,
                                        )
                                    "
                                    :work-task-type-code="localWorkTask.workTaskTypeCode"
                                    :is-spray="true"
                                    @add="addWorkTaskProduct" />
                            </v-expansion-panel-text>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </div>

                <!-- Confirm Modify Template -->
                <v-card v-if="showConfirmModifyTemplate" class="marginTop">
                    <v-card-text class="completeConfirmation pa-0">
                        <v-row class="confirm-header pa-3 ma-0">
                            <FontAwesomeIcon :icon="['fal', 'check']" size="xl" class="mr-5" />
                            <span>
                                {{ $t('ConfirmModificationOfThisTaskTemplate') }}
                            </span>
                        </v-row>

                        <div class="pa-2">
                            <v-row class="ma-1">
                                <v-col cols="1" align-self="center">
                                    <v-checkbox
                                        v-model="localWorkTask.isModifyTemplateConfirmed"
                                        class="confirm-checkbox"
                                        hide-details />
                                </v-col>
                                <v-col cols="11" align-self="center" style="font-size: medium">
                                    {{
                                        $t('ModifyTaskTemplateConfirmationMessage', {
                                            numberOfWorkTaskPlans: workTaskTemplateSearchResult?.numberOfWorkTaskPlans,
                                        })
                                    }}
                                </v-col>
                            </v-row>
                        </div>
                    </v-card-text>
                </v-card>
            </v-form>

            <v-card-actions class="justify-space-between swatchG9BG">
                <!-- Close Modal -->
                <v-btn @click="close">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'arrow-rotate-left']" size="xl" />
                    </template>
                    {{ $t('Cancel') }}
                </v-btn>
                <!-- Save Task Template -->
                <v-btn
                    v-if="
                        modalMode == ManageWorkTaskModalMode.CreateTemplate ||
                        modalMode == ManageWorkTaskModalMode.ModifyTemplate ||
                        modalMode == ManageWorkTaskModalMode.DuplicateTemplate
                    "
                    @click="saveTemplate"
                    :disabled="isLoading || (showConfirmModifyTemplate && !localWorkTask.isModifyTemplateConfirmed)">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'save']" size="xl" />
                    </template>
                    {{ $t('Save') }}
                </v-btn>
                <!-- Save Task -->
                <v-btn v-else @click="save" :disabled="isLoading">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'save']" size="xl" />
                    </template>
                    {{ $t('Save') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="deleteConfirmationDialog" style="width: 40vw">
        <v-card>
            <v-toolbar color="secondary">
                <v-toolbar-title style="width: 100%">
                    <FontAwesomeIcon :icon="['fal', 'triangle-exclamation']" size="xl" class="mr-2" />
                    <span>{{
                        deleteType === 'mixture' ? $t('ConfirmDeletingMixture') : $t('ConfirmDeletingProduct')
                    }}</span>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <CloseModalButton @click="deleteConfirmationDialog = false" />
            </v-toolbar>

            <v-card-text class="pa-4">
                <div v-if="itemToDelete">
                    <template v-if="deleteType === 'mixture'">
                        <!-- Mixture warning messages -->
                        <div
                            v-if="getMixtureProductsAssociatedWithOtherMixtures(itemToDelete as WorkTaskMixtureForm).length > 0"
                            class="mb-4">
                            {{
                                getMixtureProductsAssociatedWithOtherMixtures(itemToDelete as WorkTaskMixtureForm)
                                    .length === 1
                                    ? $t('DeleteMixtureProductsAssociatedWithAnotherMixtureSingular')
                                    : $t('DeleteMixtureProductsAssociatedWithAnotherMixturePlural', {
                                          numberOfMixtureProductsAssociatedWithOtherMixtures:
                                              getMixtureProductsAssociatedWithOtherMixtures(
                                                  itemToDelete as WorkTaskMixtureForm,
                                              ).length,
                                      })
                            }}
                        </div>
                        <div>
                            {{
                                getProductsToDeleteAfterDeletingMixture(
                                    itemToDelete as WorkTaskMixtureForm,
                                    getMixtureProductsAssociatedWithOtherMixtures(itemToDelete as WorkTaskMixtureForm),
                                ).length === 1
                                    ? $t('DeleteMixtureNumberOfDeletionsSingular')
                                    : $t('DeleteMixtureNumberOfDeletionsPlural', {
                                          numberOfProductsToDeleteAfterDeletingMixture:
                                              getProductsToDeleteAfterDeletingMixture(
                                                  itemToDelete as WorkTaskMixtureForm,
                                                  getMixtureProductsAssociatedWithOtherMixtures(
                                                      itemToDelete as WorkTaskMixtureForm,
                                                  ),
                                              ).length,
                                      })
                            }}
                        </div>
                    </template>
                    <template v-else>
                        <!-- Product warning messages -->
                        <div
                            v-if="getMixturesAssociatedWithProduct(itemToDelete as WorkTaskProductForm).length > 0"
                            class="mb-4">
                            {{
                                getMixturesAssociatedWithProduct(itemToDelete as WorkTaskProductForm).length === 1
                                    ? $t('DeleteProductMixtureDetailsSingular', {
                                          mixtureName: `"${
                                              ManageWorkTaskHelper.getMixtureDetails(
                                                  getMixturesAssociatedWithProduct(
                                                      itemToDelete as WorkTaskProductForm,
                                                  )[0].mixtureId!,
                                                  mixtures,
                                              )?.mixtureName
                                          }"`,
                                      })
                                    : $t('DeleteProductMixtureDetailsPlural', {
                                          mixtureNames: getMixturesAssociatedWithProduct(
                                              itemToDelete as WorkTaskProductForm,
                                          )
                                              .map(
                                                  (m) =>
                                                      `"${
                                                          ManageWorkTaskHelper.getMixtureDetails(m.mixtureId!, mixtures)
                                                              ?.mixtureName
                                                      }"`,
                                              )
                                              .join(', '),
                                      })
                            }}
                        </div>
                        <div>
                            {{ $t('DeleteProductAreYouSure') }}
                        </div>
                    </template>
                </div>
            </v-card-text>

            <v-card-actions class="justify-space-between swatchG9BG pa-4">
                <v-btn @click="deleteConfirmationDialog = false">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'arrow-rotate-left']" size="xl" />
                    </template>
                    {{ $t('Cancel') }}
                </v-btn>
                <v-btn color="error" @click="confirmDelete">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'trash-xmark']" size="xl" />
                    </template>
                    {{ $t('Delete') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" timeout="4000">
        {{ snackbar.text }}
    </v-snackbar>
</template>

<script setup lang="ts">
import '@/assets/scss/user/user-manager.scss';
import {ManageWorkTaskModalMode} from '@/enums/modal-modes/manage-work-task-modal-mode';
import {computed, watch, inject, ref, nextTick} from 'vue';
import ApiService from '@/services/api-service.js';
import CloseModalButton from '@/components/CloseModalButton.vue';
import WorkTaskDetailsHeader from '@/components/WorkTaskDetailsHeader.vue';
import WorkTaskTemplateDetailsHeader from '@/components/WorkTaskTemplateDetailsHeader.vue';
import WorkTaskTypeSelect from '@/components/WorkTaskTypeSelect.vue';
import i18n from '@/i18n';
import {SearchWorkTasksRequest} from '@/models/api/requests/search/SearchWorkTasksRequest';
import {SearchWorkTasksResponse} from '@/models/api/responses/search/SearchWorkTasksResponse';
import {SearchWorkTaskTemplatesRequest} from '@/models/api/requests/search/SearchWorkTaskTemplatesRequest';
import {SearchWorkTaskTemplatesResponse} from '@/models/api/responses/search/SearchWorkTaskTemplatesResponse';
import {ManageWorkTaskForm} from '@/models/work-tasks/ManageWorkTaskForm';
import {SaveWorkTaskRequest} from '@/models/api/requests/work-tasks/SaveWorkTaskRequest';
import {useManageWorkTaskModalStore} from '@/stores/modals/manage-work-task-modal-store';
import {useFarmBlocks} from '@/composables/data-source/farm-blocks';
const {getFarmBlocks, farmBlocks} = useFarmBlocks();
import {useFarmFields} from '@/composables/data-source/farm-fields';
const {getFarmFields, farmFields} = useFarmFields();
import {useCrops} from '@/composables/data-source/crops';
const {getCrops, cropsWithNull} = useCrops();
import {useCropVarieties} from '@/composables/data-source/crop-varieties';
const {getCropVarieties, cropVarieties} = useCropVarieties();
import {useMixtures} from '@/composables/data-source/mixtures';
const {getMixtures, mixtures} = useMixtures();
import {useProducts} from '@/composables/data-source/products';
const {getProducts, products} = useProducts();
import {WorkTaskDueDateTiming} from '@/enums/work-task-due-date-timing';
import {useUserStore} from '@/stores/user-store';
import {formatTime} from '@/helpers/TimeFormatHelper';
import Validation from '@/helpers/ValidationHelper';
import {UploadedArtifactDto} from '@/models/data-transfer-objects/system/UploadedArtifactDto';
import {ArtifactFolder} from '@/enums/artifact-folder';
import {ArtifactTypeCode} from '@/enums/artifact-type-code';
import ManageWorkSubTaskItem from '@/views/work-tasks/ManageWorkSubTaskItem.vue';
import ManageWorkTaskMixtureItem from '@/views/work-tasks/ManageWorkTaskMixtureItem.vue';
import ManageWorkTaskProductItem from '@/views/work-tasks/ManageWorkTaskProductItem.vue';
import ManageWorkTaskCropVarietyPreferenceItem from '@/views/work-tasks/ManageWorkTaskCropVarietyPreferenceItem.vue';
import FileUploadHelper from '@/helpers/FileUploadHelper';
import {WorkSubTaskForm} from '@/models/work-tasks/WorkSubTaskForm';
import {SaveWorkSubTaskDto} from '@/models/data-transfer-objects/work-tasks/SaveWorkSubTaskDto';
import {ValidatableComponent} from '@/models/generic/ValidatableComponent';
import {WorkTaskTypeCode} from '@/enums/work-task-type-code';
import {WorkTaskMixtureForm} from '@/models/work-tasks/WorkTaskMixtureForm';
import {SaveWorkTaskMixtureDto} from '@/models/data-transfer-objects/work-tasks/SaveWorkTaskMixtureDto';
import {SaveWorkTaskProductDto} from '@/models/data-transfer-objects/work-tasks/SaveWorkTaskProductDto';
import {WorkTaskProductForm} from '@/models/work-tasks/WorkTaskProductForm';
import ManageWorkTaskHelper from '@/helpers/ManageWorkTaskHelper';
import {MixtureProductDto} from '@/models/data-transfer-objects/data-source/MixtureProductDto';
import {ProductTypeCode} from '@/enums/product-type-code';
import {SaveWorkTaskCropVarietyPreferenceDto} from '@/models/data-transfer-objects/work-tasks/SaveWorkTaskCropVarietyPreferenceDto';
import {WorkTaskCropVarietyPreferenceForm} from '@/models/work-tasks/WorkTaskCropVarietyPreferenceForm';
import {WorkTaskSearchResultDto} from '@/models/data-transfer-objects/search/work-task-search/WorkTaskSearchResultDto';
import {SearchFarmFieldsRequest} from '@/models/api/requests/search/SearchFarmFieldsRequest';
import {SearchFarmFieldsResponse} from '@/models/api/responses/search/SearchFarmFieldsResponse';
import {FarmFieldSearchResultDto} from '@/models/data-transfer-objects/search/farm-field-search/FarmFieldSearchResultDto';
import {SaveWorkTaskTemplateSubTaskDto} from '@/models/data-transfer-objects/work-tasks/SaveWorkTaskTemplateSubTaskDto';
import {SaveWorkTaskTemplateMixtureDto} from '@/models/data-transfer-objects/work-tasks/SaveWorkTaskTemplateMixtureDto';
import {SaveWorkTaskTemplateProductDto} from '@/models/data-transfer-objects/work-tasks/SaveWorkTaskTemplateProductDto';
import {SaveWorkTaskTemplateCropVarietyPreferenceDto} from '@/models/data-transfer-objects/work-tasks/SaveWorkTaskTemplateCropVarietyPreferenceDto';
import {SaveWorkTaskTemplateRequest} from '@/models/api/requests/work-tasks/SaveWorkTaskTemplateRequest';
import {WorkTaskTemplateSearchResultDto} from '@/models/data-transfer-objects/search/work-task-template-search/WorkTaskTemplateSearchResultDto';
import {WorkTaskTemplateSearchParametersDto} from '@/models/data-transfer-objects/search/work-task-template-search/WorkTaskTemplateSearchParametersDto';
import {debounce} from 'lodash';
import {CheckTemplateNameAvailableRequest} from '@/models/api/requests/work-tasks/CheckTemplateNameAvailableRequest';
import WorkTaskDueDateTimingControl from '@/components/work-tasks/WorkTaskDueDateTimingControl.vue';
import {useHomeDashboardStore} from '@/stores/home-dashboard-store';
import WorkTaskImpactOfChanges from '@/components/work-tasks/WorkTaskImpactOfChanges.vue';

const userStore = useUserStore();
const homeDashboardStore = useHomeDashboardStore();

// Form
const form = ref();
const dueDateTimingControl = ref();
const valid = ref<boolean>(true);
let isLoading = ref<boolean>(false);
const snackbar = ref({
    show: false,
    text: '',
});
let isWorkTaskTypeChangeable = ref<boolean>(true);
const expansionPanelsCultivate = ref([0]);
const expansionPanelsMixtures = ref([0]);
const expansionPanelsProducts = ref([0]);
const expansionPanelsCropVarietyPreferences = ref([0]);
const expansionPanelsFertilizerProducts = ref([0]);
const expansionPanelsSprayProducts = ref([0]);
const workSubTaskRefs = ref<ValidatableComponent[]>([]);
const workTaskProductRefs = ref<ValidatableComponent[]>([]);
const workTaskFertilizeProductRefs = ref<ValidatableComponent[]>([]);
const workTaskSprayProductRefs = ref<ValidatableComponent[]>([]);
const workTaskCropVarietyPreferenceRefs = ref<ValidatableComponent[]>([]);
const isCheckingTemplateName = ref(false);
const isTemplateNameAvailable = ref(true);

// Rules
const requiredRule = Validation.createRule_Required();
const numericGreaterThanZeroRule = Validation.createRule_Numeric(
    i18n.global.t('Validation_MustBeGreaterThanZero'),
    0.000000000001,
);
const uploadedFileRule = Validation.createRule_UploadedFile();
const atLeastOneVarietyAddedRule = (v: number) => {
    return v > 0 || i18n.global.t('Validation_AtLeastOneVarietyAdded');
};
const atLeastOneProductAddedRule = (v: number) => {
    return v > 0 || i18n.global.t('Validation_AtLeastOneProductAdded');
};
const templateNameUniqueRule = async (v: string) => {
    if (!v) return true;
    if (isCheckingTemplateName.value == true) return false;
    return await checkTemplateNameAvailable(v);
};

// Services
const apiService = inject('apiService') as ApiService;

// Modal
const manageWorkTaskModalStore = useManageWorkTaskModalStore();
const modalMode = computed(() => manageWorkTaskModalStore.modalMode);
const workTask = ref<ManageWorkTaskForm>(new ManageWorkTaskForm());
const localWorkTask = ref<ManageWorkTaskForm>({...workTask.value});
const workTaskSearchResult = ref<WorkTaskSearchResultDto | null>(null);
const farmFieldSearchResult = ref<FarmFieldSearchResultDto | null>(null);
const workTaskTemplateSearchResult = ref<WorkTaskTemplateSearchResultDto | null>(null);
const deleteConfirmationDialog = ref(false);
const deleteType = ref<'mixture' | 'product'>('mixture');
const itemToDelete = ref<WorkTaskMixtureForm | WorkTaskProductForm | null>(null);
const itemToDeleteIndex = ref<number | null>(null);
const isImpactOfChangeLoading = ref<boolean>(false);

const shouldShowImpactOfChanges = computed(() => {
    return (
        modalMode.value === ManageWorkTaskModalMode.Modify &&
        localWorkTask.value.workTaskId != null &&
        (localWorkTask.value.dueDateTiming !== workTask.value.dueDateTiming ||
            localWorkTask.value.dueDateOn !== workTask.value.dueDateOn ||
            localWorkTask.value.dueDateLatest !== workTask.value.dueDateLatest ||
            localWorkTask.value.dueDateMonth !== workTask.value.dueDateMonth ||
            localWorkTask.value.dueDateRange !== workTask.value.dueDateRange ||
            localWorkTask.value.dueAfterWorkTaskId !== workTask.value.dueAfterWorkTaskId ||
            localWorkTask.value.dueAfterWorkTaskWithinDays !== workTask.value.dueAfterWorkTaskWithinDays)
    );
});

/**
 * Get the impact of timing changes
 */
const getImpactOfChanges = async () => {
    if (!shouldShowImpactOfChanges.value) {
        localWorkTask.value.tasksThatWillBeChanged = [];
        return;
    }

    isImpactOfChangeLoading.value = true;

    try {
        const request = {
            parameters: {
                workTaskId: localWorkTask.value.workTaskId,
                dueDateTiming: localWorkTask.value.dueDateTiming,
                dueDateOn: localWorkTask.value.dueDateOn ? formatDateForApi(localWorkTask.value.dueDateOn) : null,
                dueDateLatest: localWorkTask.value.dueDateLatest
                    ? formatDateForApi(localWorkTask.value.dueDateLatest)
                    : null,
                dueDateMonth: localWorkTask.value.dueDateMonth
                    ? formatDateForApi(
                          new Date(localWorkTask.value.dueDateMonth.year, localWorkTask.value.dueDateMonth.month, 1),
                      )
                    : null,
                dueDateRange:
                    localWorkTask.value.dueDateRange && localWorkTask.value.dueDateRange.length == 2
                        ? [
                              formatDateForApi(localWorkTask.value.dueDateRange[0]),
                              formatDateForApi(localWorkTask.value.dueDateRange[1]),
                          ]
                        : null,
                dueAfterWorkTaskId: localWorkTask.value.dueAfterWorkTaskId,
                dueAfterWorkTaskWithinDays: localWorkTask.value.dueAfterWorkTaskWithinDays,
                commitChanges: false,
                getBeforeAndAfterTasks: true,
            },
        };
        const response = await apiService.post('work-tasks/reschedule-work-task', request);
        localWorkTask.value.tasksThatWillBeChanged = response.workTasksThatWillBeChanged;
    } catch (error) {
        console.error('Error getting impact of changes:', error);
        localWorkTask.value.tasksThatWillBeChanged = [];
    }

    isImpactOfChangeLoading.value = false;
};

/**
 * Load work task data for the modal.
 */
const loadModal = async () => {
    isLoading.value = true;

    // Reset form
    resetForm();

    // Load dropdown data
    await getFarmFields(userStore.user?.farmSiteId ? [userStore.user.farmSiteId] : []);
    await getFarmBlocks();
    await getCrops();
    await getCropVarieties();
    await getMixtures();
    await getProducts();

    // If a work task ID was specified
    if (manageWorkTaskModalStore.workTaskId !== null || manageWorkTaskModalStore.workTaskTemplateId !== null) {
        // Load data for the task/template that is being modified
        if (manageWorkTaskModalStore.workTaskId !== null) {
            // Load task data
            await loadWorkTaskData();
            await getImpactOfChanges();
        } else if (manageWorkTaskModalStore.workTaskTemplateId !== null) {
            // Load task template data
            await loadWorkTaskTemplateData();
        }

        // Disable task type selection for existing tasks (or tasks that are being duplicated)
        isWorkTaskTypeChangeable.value = false;
    }

    // If a default farm field has been specified (when creating a new task), select it
    if (manageWorkTaskModalStore.farmFieldId !== null) {
        workTask.value.farmFieldId = manageWorkTaskModalStore.farmFieldId;
    }

    // Update value of local work task
    localWorkTask.value = {...workTask.value};

    // If the task is being duplicated, perform additional setup on the work task object
    if (
        modalMode.value == ManageWorkTaskModalMode.Duplicate ||
        modalMode.value == ManageWorkTaskModalMode.DuplicateTemplate
    ) {
        setupNewDuplicatedTask();
    }

    // If a new task/template is being created from another task/template
    if (
        (modalMode.value == ManageWorkTaskModalMode.Create && manageWorkTaskModalStore.workTaskTemplateId !== null) ||
        (modalMode.value == ManageWorkTaskModalMode.CreateTemplate && manageWorkTaskModalStore.workTaskId !== null)
    ) {
        setupNewCreatedFromTask();
    }

    // Get product details for all mixtures
    getProductDetailsForAllMixtures();

    // End loading (nextTick is used to ensure that the watch events on localWorkTask are not triggered during the load)
    nextTick(() => {
        isLoading.value = false;
    });
};

/**
 * Loads data for a specific work task.
 */
const loadWorkTaskData = async () => {
    // Build search request using the work task ID
    const searchRequest: SearchWorkTasksRequest = new SearchWorkTasksRequest({
        isQuickSearch: false,
        workTaskId: manageWorkTaskModalStore.workTaskId,
        includeArtifacts: true,
        includeWorkSubTasks: true,
        includeWorkTaskMixtures: true,
        includeWorkTaskProducts: true,
        includeWorkTaskCropVarietyPreferences: true,
    });

    // Get work task details
    const searchResults = (await apiService.post('search/work-tasks', searchRequest)) as SearchWorkTasksResponse;

    if (searchResults.workTasks.length == 1) {
        // There is a single result, so set the work task object to that value
        workTask.value = Object.assign(new ManageWorkTaskForm(), searchResults.workTasks[0]);

        // Store search result
        workTaskSearchResult.value = searchResults.workTasks[0];

        // Get farm field details
        await getFarmFieldDetails();
    }
};

/**
 * Loads data for a specific work task template.
 */
const loadWorkTaskTemplateData = async () => {
    // Build search request using the work task template ID
    const searchRequest: SearchWorkTaskTemplatesRequest = {
        searchParameters: new WorkTaskTemplateSearchParametersDto({
            workTaskTemplateId: manageWorkTaskModalStore.workTaskTemplateId,
            includeArtifacts: true,
            includeWorkTaskTemplateSubTasks: true,
            includeWorkTaskTemplateMixtures: true,
            includeWorkTaskTemplateProducts: true,
            includeWorkTaskTemplateCropVarietyPreferences: true,
        }),
    };

    // Get work task template details
    const searchResults = (await apiService.post(
        'search/work-task-templates',
        searchRequest,
    )) as SearchWorkTaskTemplatesResponse;

    if (searchResults.workTaskTemplates.length == 1) {
        const workTaskTemplate = searchResults.workTaskTemplates[0];

        // There is a single result, so set the work task object to that value
        workTask.value = Object.assign(new ManageWorkTaskForm(), workTaskTemplate);

        // Store search result
        workTaskTemplateSearchResult.value = workTaskTemplate;

        // Set other values manually
        workTask.value.workSubTasks = Object.assign([], workTaskTemplate.workTaskTemplateSubTasks);
        workTask.value.workTaskMixtures = Object.assign([], workTaskTemplate.workTaskTemplateMixtures);
        workTask.value.workTaskProducts = Object.assign([], workTaskTemplate.workTaskTemplateProducts);
        workTask.value.workTaskCropVarietyPreferences = Object.assign(
            [],
            workTaskTemplate.workTaskTemplateCropVarietyPreferences,
        );
    }
};

/**
 * Clear IDs and set "duplicated from" IDs when this task is being duplicated from another task.
 */
const setupNewDuplicatedTask = () => {
    // Store the ID of the task that this was duplicated from (this will need to be passed to the Save API later)
    localWorkTask.value.duplicatedFromWorkTaskId = localWorkTask.value.workTaskId;
    localWorkTask.value.duplicatedFromWorkTaskTemplateId = localWorkTask.value.workTaskTemplateId;

    // Set the work task ID to null to ensure that a new task ends up being created when saving
    localWorkTask.value.workTaskId = null;
    localWorkTask.value.workTaskTemplateId = null;

    // For each sub-task
    localWorkTask.value.workSubTasks.forEach((workSubTask) => {
        // Store the ID of the sub-task that this was duplicated from (this will need to be passed to the Save API later)
        workSubTask.duplicatedFromWorkSubTaskId = workSubTask.workSubTaskId;
        workSubTask.duplicatedFromWorkTaskTemplateSubTaskId = workSubTask.workTaskTemplateSubTaskId;
    });

    // Clear IDs
    clearWorkSubTaskIds();
    clearWorkTaskMixtureIds();
    clearWorkTaskProductIds();
    clearWorkTaskCropVarietyPreferenceIds();

    // Template name should be set to null (only relevant if in task template mode)
    localWorkTask.value.workTaskTemplateName = null;
};

/**
 * Clear IDs and set "created from" IDs when this task template is being created from a task, or when a task
 * is being created from a task template.
 */
const setupNewCreatedFromTask = () => {
    // Store the ID of the task that this was created from (this will need to be passed to the Save API later)
    localWorkTask.value.createdFromWorkTaskId = localWorkTask.value.workTaskId;
    localWorkTask.value.createdFromWorkTaskTemplateId = localWorkTask.value.workTaskTemplateId;

    // Set the work task ID to null to ensure that a new task template ends up being created when saving
    localWorkTask.value.workTaskId = null;
    localWorkTask.value.workTaskTemplateId = null;

    // For each sub-task
    localWorkTask.value.workSubTasks.forEach((workSubTask) => {
        // Store the ID of the sub-task that this was duplicated from (this will need to be passed to the Save API later)
        workSubTask.createdFromWorkSubTaskId = workSubTask.workSubTaskId;
        workSubTask.createdFromWorkTaskTemplateSubTaskId = workSubTask.workTaskTemplateSubTaskId;
    });

    // Clear IDs
    clearWorkSubTaskIds();
    clearWorkTaskMixtureIds();
    clearWorkTaskProductIds();
    clearWorkTaskCropVarietyPreferenceIds();
};

/**
 * Set the IDs of the sub-tasks to null. Also update the newItemId to ensure they can be uniquely identified.
 */
const clearWorkSubTaskIds = async () => {
    localWorkTask.value.workSubTasks.forEach((workSubTask, index) => {
        workSubTask.newItemId = `Duplicate-${index}`;
        workSubTask.workSubTaskId = null;
        workSubTask.workTaskTemplateSubTaskId = null;
    });
};

/**
 * Set the IDs of the mixtures to null. Also update the newItemId to ensure they can be uniquely identified.
 */
const clearWorkTaskMixtureIds = async () => {
    localWorkTask.value.workTaskMixtures.forEach((workTaskMixture, index) => {
        // Set the IDs of the mixtures to null. Also update the newItemId to ensure they can be uniquely identified.
        workTaskMixture.newItemId = `Duplicate-${index}`;
        workTaskMixture.workTaskMixtureId = null;
        workTaskMixture.workTaskTemplateMixtureId = null;
    });
};

/**
 * Set the IDs of the products to null. Also update the newItemId to ensure they can be uniquely identified.
 */
const clearWorkTaskProductIds = async () => {
    localWorkTask.value.workTaskProducts.forEach((workTaskProduct, index) => {
        // Set the IDs of the products to null. Also update the newItemId to ensure they can be uniquely identified.
        workTaskProduct.newItemId = `Duplicate-${index}`;
        workTaskProduct.workTaskProductId = null;
        workTaskProduct.workTaskTemplateProductId = null;
    });
};

/**
 * Set the IDs of the crop variety preferences to null. Also update the newItemId to ensure they can be uniquely identified.
 */
const clearWorkTaskCropVarietyPreferenceIds = async () => {
    localWorkTask.value.workTaskCropVarietyPreferences.forEach((workTaskCropVarietyPreference, index) => {
        // Set the IDs of the crop variety preferences to null. Also update the newItemId to ensure they can be uniquely identified.
        workTaskCropVarietyPreference.newItemId = `Duplicate-${index}`;
        workTaskCropVarietyPreference.workTaskCropVarietyPreferenceId = null;
        workTaskCropVarietyPreference.workTaskTemplateCropVarietyPreferenceId = null;
    });
};

/**
 * Get farm field details of an existing work task.
 */
const getFarmFieldDetails = async () => {
    // Build search request using the farm field ID
    const searchRequest: SearchFarmFieldsRequest = new SearchFarmFieldsRequest({
        isQuickSearch: false,
        farmFieldId: workTask.value.farmFieldId,
    });

    // Get farm field details
    const searchResults = (await apiService.post('search/farm-fields', searchRequest)) as SearchFarmFieldsResponse;

    if (searchResults.farmFields.length == 1) {
        // Store search result
        farmFieldSearchResult.value = searchResults.farmFields[0];
    }
};

/**
 * Close the modal.
 */
const close = () => {
    manageWorkTaskModalStore.close();
};

/**
 * Format date for the API.
 */
const formatDateForApi = (date: Date) => {
    return formatTime(date, 'yyyy-MM-dd');
};

/**
 * Validate all sub-tasks before saving.
 */
const validateAllSubTasks = async (): Promise<boolean> => {
    const results = await Promise.all(workSubTaskRefs.value.map((child) => child.validate()));
    return results.every((result) => result === true);
};

/**
 * Validate all products before saving.
 */
const validateAllProducts = async (): Promise<boolean> => {
    // Multiple refs are used for work task products (because they are sometimes split into Fertilizer and Spray products),
    // so we will concatenate each array to ensure all are validated.
    const allRefs = workTaskProductRefs.value
        .concat(workTaskFertilizeProductRefs.value)
        .concat(workTaskSprayProductRefs.value);

    const results = await Promise.all(allRefs.map((child) => child.validate()));
    return results.every((result) => result === true);
};

/**
 * Validate all crop variety preferences before saving.
 */
const validateAllCropVarietyPreferences = async (): Promise<boolean> => {
    const results = await Promise.all(workTaskCropVarietyPreferenceRefs.value.map((child) => child.validate()));
    return results.every((result) => result === true);
};

/**
 * Save the modal.
 */
const save = async () => {
    // Perform final client side validation of form
    await form.value.validate();
    const isDueDateValid = await dueDateTimingControl.value.validate();
    const isSubTasksValid = await validateAllSubTasks();
    const isProductsValid = await validateAllProducts();
    const isCropVarietyPreferencesValid = await validateAllCropVarietyPreferences();

    // If form is valid
    if (valid.value && isDueDateValid && isSubTasksValid && isProductsValid && isCropVarietyPreferencesValid) {
        let snackbarMessage = '';
        isLoading.value = true;

        try {
            // If sub-task artifacts have been updated, add them to the request
            const uploadedArtifacts: UploadedArtifactDto[] = [];
            if (localWorkTask.value.haveArtifactsBeenUpdated) {
                // Build artifacts object
                localWorkTask.value.artifacts.forEach((artifact) => {
                    const uploadedArtifact = new UploadedArtifactDto({
                        file: artifact,
                        artifactFolder: ArtifactFolder.WorkTasks,
                        artifactTypeCode: ArtifactTypeCode.WorkTaskAttachment,
                    });

                    uploadedArtifacts.push(uploadedArtifact);
                });
            }

            // For each sub-task
            const workSubTasks: SaveWorkSubTaskDto[] = [];
            localWorkTask.value.workSubTasks.forEach((workSubTask, index) => {
                // Build sub-task object
                const uploadedWorkSubTask = new SaveWorkSubTaskDto({
                    workSubTaskId: workSubTask.workSubTaskId,
                    sequenceNumber: index + 1,
                    workSubTaskName: workSubTask.workSubTaskName!,
                    workSubTaskInstructions: workSubTask.workSubTaskInstructions,
                    haveArtifactsBeenUpdated: workSubTask.haveArtifactsBeenUpdated,
                    duplicatedFromWorkSubTaskId: workSubTask.duplicatedFromWorkSubTaskId,
                    createdFromWorkTaskTemplateSubTaskId: workSubTask.createdFromWorkTaskTemplateSubTaskId,
                });

                // If sub-task artifacts have been updated, add them to the request
                if (workSubTask.haveArtifactsBeenUpdated) {
                    // Build sub-task artifacts object
                    const uploadedSubTaskArtifacts: UploadedArtifactDto[] = [];
                    workSubTask.artifacts.forEach((artifact) => {
                        const uploadedSubTaskArtifact = new UploadedArtifactDto({
                            file: artifact,
                            artifactFolder: ArtifactFolder.WorkSubTasks,
                            artifactTypeCode: ArtifactTypeCode.WorkSubTaskAttachment,
                        });

                        uploadedSubTaskArtifacts.push(uploadedSubTaskArtifact);
                    });
                    uploadedWorkSubTask.artifacts = uploadedSubTaskArtifacts;
                }

                // Add sub-task to array
                workSubTasks.push(uploadedWorkSubTask);
            });

            // For each mixture
            const workTaskMixtures: SaveWorkTaskMixtureDto[] = [];
            localWorkTask.value.workTaskMixtures.forEach((workTaskMixture) => {
                // Build mixture object
                const uploadedWorkTaskMixture = new SaveWorkTaskMixtureDto({
                    workTaskMixtureId: workTaskMixture.workTaskMixtureId,
                    mixtureId: workTaskMixture.mixtureId!,
                });

                // Add mixture to array
                workTaskMixtures.push(uploadedWorkTaskMixture);
            });

            // For each product
            const workTaskProducts: SaveWorkTaskProductDto[] = [];
            localWorkTask.value.workTaskProducts.forEach((workTaskProduct) => {
                // Build product object
                const uploadedWorkTaskProduct = new SaveWorkTaskProductDto({
                    workTaskProductId: workTaskProduct.workTaskProductId,
                    productId: workTaskProduct.productId!,
                    plannedApplicationRate: workTaskProduct.plannedApplicationRate!,
                    plannedApplicationRateUnitCode: workTaskProduct.plannedApplicationRateUnitCode!,
                    isFertilizer: workTaskProduct.isFertilizer!,
                    isSpray: workTaskProduct.isSpray!,
                });

                // Add product to array
                workTaskProducts.push(uploadedWorkTaskProduct);
            });

            // For each variety preference
            const workTaskCropVarietyPreferences: SaveWorkTaskCropVarietyPreferenceDto[] = [];
            localWorkTask.value.workTaskCropVarietyPreferences.forEach((workTaskCropVarietyPreference) => {
                // Build variety preference object
                const uploadedWorkTaskCropVarietyPreference = new SaveWorkTaskCropVarietyPreferenceDto({
                    workTaskCropVarietyPreferenceId: workTaskCropVarietyPreference.workTaskCropVarietyPreferenceId,
                    cropVarietyId: workTaskCropVarietyPreference.cropVarietyId!,
                    seedOriginBlockId: workTaskCropVarietyPreference.seedOriginBlockId,
                    seedRate: workTaskCropVarietyPreference.seedRate!,
                    seedRateUnitCode: workTaskCropVarietyPreference.seedRateUnitCode!,
                });

                // Add product to array
                workTaskCropVarietyPreferences.push(uploadedWorkTaskCropVarietyPreference);
            });

            // Build API request
            const saveWorkTaskRequest: SaveWorkTaskRequest = {
                workTask: {
                    workTaskId: localWorkTask.value.workTaskId,
                    workTaskTypeCode: localWorkTask.value.workTaskTypeCode!,
                    farmFieldId: localWorkTask.value.farmFieldId!,
                    workTaskName: localWorkTask.value.workTaskName,
                    dueDateTiming: localWorkTask.value.dueDateTiming,
                    dueDateOn: localWorkTask.value.dueDateOn ? formatDateForApi(localWorkTask.value.dueDateOn) : null,
                    dueDateLatest: localWorkTask.value.dueDateLatest
                        ? formatDateForApi(localWorkTask.value.dueDateLatest)
                        : null,
                    dueDateMonth: localWorkTask.value.dueDateMonth
                        ? formatDateForApi(
                              new Date(
                                  localWorkTask.value.dueDateMonth.year,
                                  localWorkTask.value.dueDateMonth.month,
                                  1,
                              ),
                          )
                        : null,
                    dueDateRange:
                        localWorkTask.value.dueDateRange && localWorkTask.value.dueDateRange.length == 2
                            ? [
                                  formatDateForApi(localWorkTask.value.dueDateRange[0]),
                                  formatDateForApi(localWorkTask.value.dueDateRange[1]),
                              ]
                            : null,
                    dueAfterWorkTaskId: localWorkTask.value.dueAfterWorkTaskId,
                    dueAfterWorkTaskWithinDays: localWorkTask.value.dueAfterWorkTaskWithinDays,
                    workTaskInstructions: localWorkTask.value.workTaskInstructions,
                    irrigationRatePlanned: localWorkTask.value.irrigationRatePlanned,
                    plantCropId:
                        localWorkTask.value.workTaskTypeCode == WorkTaskTypeCode.Plant
                            ? localWorkTask.value.plantCropId
                            : null,
                    artifacts: uploadedArtifacts,
                    haveArtifactsBeenUpdated: localWorkTask.value.haveArtifactsBeenUpdated,
                    workSubTasks: workSubTasks,
                    workTaskMixtures: workTaskMixtures,
                    workTaskProducts: workTaskProducts,
                    workTaskCropVarietyPreferences: workTaskCropVarietyPreferences,
                    duplicatedFromWorkTaskId: localWorkTask.value.duplicatedFromWorkTaskId,
                    createdFromWorkTaskTemplateId: localWorkTask.value.createdFromWorkTaskTemplateId,
                },
            };

            // Call API to save work task
            const taskId = await apiService.post(
                'work-tasks/work-task',
                FileUploadHelper.createFormDataObject(saveWorkTaskRequest),
            );

            // Set snackbar message
            if (
                modalMode.value == ManageWorkTaskModalMode.Create ||
                modalMode.value == ManageWorkTaskModalMode.Duplicate
            ) {
                snackbarMessage = i18n.global.t('CreateWorkTask_Success');
            } else if (modalMode.value == ManageWorkTaskModalMode.Modify) {
                snackbarMessage = i18n.global.t('ModifyWorkTask_Success');
            }

            // Show success feedback to user
            snackbar.value.show = true;
            snackbar.value.text = snackbarMessage;
            homeDashboardStore.workTaskIdToFocus = taskId;
            manageWorkTaskModalStore.savedCounter++;

            // Close modal
            manageWorkTaskModalStore.close();
        } catch (ex: unknown) {
            // Show fail feedback to user
            snackbar.value.show = true;
            snackbar.value.text = i18n.global.t('ErrorGeneric');
            isLoading.value = false;
        }
    } else {
        // Show validation error feedback to user
        snackbar.value.show = true;
        snackbar.value.text = i18n.global.t('ErrorValidation');
    }
};

/**
 * Save the modal.
 */
const saveTemplate = async () => {
    // If template name validation is currently running, wait until it finishes
    await AsyncHelper.waitForCondition(() => !isCheckingTemplateName.value, 5000, 50);

    // Perform final client side validation of form
    await form.value.validate();
    let isValid =
        valid.value &&
        (await validateAllSubTasks()) &&
        (await validateAllProducts()) &&
        (await validateAllCropVarietyPreferences());

    // If form is valid
    if (isValid) {
        let snackbarMessage = '';
        isLoading.value = true;

        try {
            // If sub-task artifacts have been updated, add them to the request
            const uploadedArtifacts: UploadedArtifactDto[] = [];
            if (localWorkTask.value.haveArtifactsBeenUpdated) {
                // Build artifacts object
                localWorkTask.value.artifacts.forEach((artifact) => {
                    const uploadedArtifact = new UploadedArtifactDto({
                        file: artifact,
                        artifactFolder: ArtifactFolder.WorkTasks,
                        artifactTypeCode: ArtifactTypeCode.WorkTaskAttachment,
                    });

                    uploadedArtifacts.push(uploadedArtifact);
                });
            }

            // For each sub-task
            const workSubTasks: SaveWorkTaskTemplateSubTaskDto[] = [];
            localWorkTask.value.workSubTasks.forEach((workSubTask, index) => {
                // Build sub-task object
                const uploadedWorkSubTask = new SaveWorkTaskTemplateSubTaskDto({
                    workTaskTemplateSubTaskId: workSubTask.workTaskTemplateSubTaskId,
                    sequenceNumber: index + 1,
                    workSubTaskName: workSubTask.workSubTaskName!,
                    workSubTaskInstructions: workSubTask.workSubTaskInstructions,
                    haveArtifactsBeenUpdated: workSubTask.haveArtifactsBeenUpdated,
                    duplicatedFromWorkTaskTemplateSubTaskId: workSubTask.duplicatedFromWorkTaskTemplateSubTaskId,
                    createdFromWorkSubTaskId: workSubTask.createdFromWorkSubTaskId,
                });

                // If sub-task artifacts have been updated, add them to the request
                if (workSubTask.haveArtifactsBeenUpdated) {
                    // Build sub-task artifacts object
                    const uploadedSubTaskArtifacts: UploadedArtifactDto[] = [];
                    workSubTask.artifacts.forEach((artifact) => {
                        const uploadedSubTaskArtifact = new UploadedArtifactDto({
                            file: artifact,
                            artifactFolder: ArtifactFolder.WorkSubTasks,
                            artifactTypeCode: ArtifactTypeCode.WorkSubTaskAttachment,
                        });

                        uploadedSubTaskArtifacts.push(uploadedSubTaskArtifact);
                    });
                    uploadedWorkSubTask.artifacts = uploadedSubTaskArtifacts;
                }

                // Add sub-task to array
                workSubTasks.push(uploadedWorkSubTask);
            });

            // For each mixture
            const workTaskMixtures: SaveWorkTaskTemplateMixtureDto[] = [];
            localWorkTask.value.workTaskMixtures.forEach((workTaskMixture) => {
                // Build mixture object
                const uploadedWorkTaskMixture = new SaveWorkTaskTemplateMixtureDto({
                    workTaskTemplateMixtureId: workTaskMixture.workTaskTemplateMixtureId,
                    mixtureId: workTaskMixture.mixtureId!,
                });

                // Add mixture to array
                workTaskMixtures.push(uploadedWorkTaskMixture);
            });

            // For each product
            const workTaskProducts: SaveWorkTaskTemplateProductDto[] = [];
            localWorkTask.value.workTaskProducts.forEach((workTaskProduct) => {
                // Build product object
                const uploadedWorkTaskProduct = new SaveWorkTaskTemplateProductDto({
                    workTaskTemplateProductId: workTaskProduct.workTaskTemplateProductId,
                    productId: workTaskProduct.productId!,
                    plannedApplicationRate: workTaskProduct.plannedApplicationRate!,
                    plannedApplicationRateUnitCode: workTaskProduct.plannedApplicationRateUnitCode!,
                    isFertilizer: workTaskProduct.isFertilizer!,
                    isSpray: workTaskProduct.isSpray!,
                });

                // Add product to array
                workTaskProducts.push(uploadedWorkTaskProduct);
            });

            // For each variety preference
            const workTaskCropVarietyPreferences: SaveWorkTaskTemplateCropVarietyPreferenceDto[] = [];
            localWorkTask.value.workTaskCropVarietyPreferences.forEach((workTaskCropVarietyPreference) => {
                // Build variety preference object
                const uploadedWorkTaskCropVarietyPreference = new SaveWorkTaskTemplateCropVarietyPreferenceDto({
                    workTaskTemplateCropVarietyPreferenceId:
                        workTaskCropVarietyPreference.workTaskTemplateCropVarietyPreferenceId,
                    cropVarietyId: workTaskCropVarietyPreference.cropVarietyId!,
                    seedOriginBlockId: workTaskCropVarietyPreference.seedOriginBlockId,
                    seedRate: workTaskCropVarietyPreference.seedRate!,
                    seedRateUnitCode: workTaskCropVarietyPreference.seedRateUnitCode!,
                });

                // Add product to array
                workTaskCropVarietyPreferences.push(uploadedWorkTaskCropVarietyPreference);
            });

            // Build API request
            const saveWorkTaskTemplateRequest: SaveWorkTaskTemplateRequest = {
                workTaskTemplate: {
                    workTaskTemplateId: localWorkTask.value.workTaskTemplateId,
                    workTaskTypeCode: localWorkTask.value.workTaskTypeCode!,
                    workTaskTemplateName: localWorkTask.value.workTaskTemplateName!,
                    workTaskName: localWorkTask.value.workTaskName,
                    workTaskInstructions: localWorkTask.value.workTaskInstructions,
                    irrigationRatePlanned: localWorkTask.value.irrigationRatePlanned,
                    plantCropId:
                        localWorkTask.value.workTaskTypeCode == WorkTaskTypeCode.Plant
                            ? localWorkTask.value.plantCropId
                            : null,
                    artifacts: uploadedArtifacts,
                    haveArtifactsBeenUpdated: localWorkTask.value.haveArtifactsBeenUpdated,
                    workTaskTemplateSubTasks: workSubTasks,
                    workTaskTemplateMixtures: workTaskMixtures,
                    workTaskTemplateProducts: workTaskProducts,
                    workTaskTemplateCropVarietyPreferences: workTaskCropVarietyPreferences,
                    duplicatedFromWorkTaskTemplateId: localWorkTask.value.duplicatedFromWorkTaskTemplateId,
                    createdFromWorkTaskId: localWorkTask.value.createdFromWorkTaskId,
                },
            };

            // Call API to save work task
            await apiService.post(
                'work-task-templates/work-task-template',
                FileUploadHelper.createFormDataObject(saveWorkTaskTemplateRequest),
            );

            // Set snackbar message
            if (
                modalMode.value == ManageWorkTaskModalMode.CreateTemplate ||
                modalMode.value == ManageWorkTaskModalMode.DuplicateTemplate
            ) {
                snackbarMessage = i18n.global.t('CreateWorkTaskTemplate_Success');
            } else if (modalMode.value == ManageWorkTaskModalMode.ModifyTemplate) {
                snackbarMessage = i18n.global.t('ModifyWorkTaskTemplate_Success');
            }

            // Show success feedback to user
            snackbar.value.show = true;
            snackbar.value.text = snackbarMessage;
            homeDashboardStore.workTaskIdToFocus = localWorkTask.value.createdFromWorkTaskId;
            manageWorkTaskModalStore.savedCounter++;

            // Close modal
            manageWorkTaskModalStore.close();
        } catch (ex: unknown) {
            // Show fail feedback to user
            snackbar.value.show = true;
            snackbar.value.text = i18n.global.t('ErrorGeneric');
            isLoading.value = false;
        }
    } else {
        // Show validation error feedback to user
        snackbar.value.show = true;
        snackbar.value.text = i18n.global.t('ErrorValidation');
    }
};

/**
 * Adds a new sub-task.
 */
const addWorkSubTask = (workSubTask: WorkSubTaskForm) => {
    localWorkTask.value.workSubTasks.push(workSubTask);
};

/**
 * Updates a sub-task.
 */
const updateWorkSubTask = (workSubTask: WorkSubTaskForm) => {
    const index = localWorkTask.value.workSubTasks.findIndex(
        (wst) =>
            (workSubTask.workSubTaskId != null && wst.workSubTaskId === workSubTask.workSubTaskId) ||
            (workSubTask.workTaskTemplateSubTaskId != null &&
                wst.workTaskTemplateSubTaskId === workSubTask.workTaskTemplateSubTaskId) ||
            (workSubTask.newItemId != null && wst.newItemId === workSubTask.newItemId),
    );

    if (index !== -1) {
        // Update the found object with the new object's properties
        localWorkTask.value.workSubTasks[index] = {...localWorkTask.value.workSubTasks[index], ...workSubTask};
    }
};

/**
 * Deletes the specified sub-task. This deletion is not saved until the form is saved.
 */
const deleteWorkSubTask = (workSubTaskIndex: number) => {
    localWorkTask.value.workSubTasks.splice(workSubTaskIndex, 1);

    // Delete the ref object for this deleted sub-task so that it's no longer validated
    workSubTaskRefs.value.splice(workSubTaskIndex, 1);
};

/**
 * Adds a new mixture.
 */
const addWorkTaskMixture = (workTaskMixture: WorkTaskMixtureForm) => {
    localWorkTask.value.workTaskMixtures.push(workTaskMixture);
    addProductsFromMixture(workTaskMixture);
};

const deleteWorkTaskMixture = (workTaskMixtureIndex: number) => {
    const mixture = localWorkTask.value.workTaskMixtures[workTaskMixtureIndex];

    // Store the mixture and index for the confirmation modal
    deleteType.value = 'mixture';
    itemToDelete.value = mixture;
    itemToDeleteIndex.value = workTaskMixtureIndex;
    deleteConfirmationDialog.value = true;
};

const deleteWorkTaskProduct = (refObjectIndex: number, workTaskProduct: WorkTaskProductForm) => {
    // Store the product and index for the confirmation modal
    deleteType.value = 'product';
    itemToDelete.value = workTaskProduct;
    itemToDeleteIndex.value = refObjectIndex;
    deleteConfirmationDialog.value = true;
};

/**
 * Get products within the specified mixture that are associated with other mixtures in this task.
 */
const getMixtureProductsAssociatedWithOtherMixtures = (workTaskMixture: WorkTaskMixtureForm) => {
    let mixtureProductsAssociatedWithOtherMixtures: MixtureProductDto[] = [];

    // For each product in this mixture
    workTaskMixture.mixtureProducts.forEach((mixtureProduct) => {
        // Check if there are any other mixtures that have been added to the task which contain this product
        if (
            localWorkTask.value.workTaskMixtures
                .filter((m) => m.mixtureId != workTaskMixture.mixtureId)
                .some((m) => m.mixtureProducts.some((mp) => mp.productId == mixtureProduct.productId))
        ) {
            mixtureProductsAssociatedWithOtherMixtures.push(mixtureProduct);
        }
    });

    return mixtureProductsAssociatedWithOtherMixtures;
};

/**
 * Get the products that should be deleted after deleting the specified mixture. This will be products associated with that mixture
 * that are not also associated with another mixture.
 */
const getProductsToDeleteAfterDeletingMixture = (
    workTaskMixture: WorkTaskMixtureForm,
    mixtureProductsAssociatedWithOtherMixtures: MixtureProductDto[],
) => {
    return workTaskMixture.mixtureProducts.filter(
        (mp) => !mixtureProductsAssociatedWithOtherMixtures.some((mpo) => mpo.productId == mp.productId),
    );
};

/**
 * Add products from mixture if they are not already assigned to this task.
 */
const addProductsFromMixture = (workTaskMixture: WorkTaskMixtureForm) => {
    // For each mixture product
    workTaskMixture.mixtureProducts.forEach((mixtureProduct) => {
        // If the product has not already been added
        const productAlreadyAdded = localWorkTask.value.workTaskProducts.some(
            (wtp) => wtp.productId === mixtureProduct.productId,
        );
        if (!productAlreadyAdded) {
            // Add product
            localWorkTask.value.workTaskProducts.push(
                new WorkTaskProductForm({
                    newItemId: `NewItemFromMixture_${mixtureProduct.productId}_${new Date().toISOString()}`,
                    productId: mixtureProduct.productId,
                    plannedApplicationRate: mixtureProduct.defaultApplicationRate,
                    plannedApplicationRateUnitCode: mixtureProduct.defaultApplicationRateUnitCode,
                }),
            );
        }
    });
};

/**
 * For each mixture, get its product details from the full list of data source mixtures.
 */
const getProductDetailsForAllMixtures = () => {
    localWorkTask.value.workTaskMixtures.forEach((workTaskMixture) => {
        ManageWorkTaskHelper.getMixtureProductDetails(workTaskMixture, mixtures.value);
    });
};

/**
 * Adds a new product.
 */
const addWorkTaskProduct = (workTaskProduct: WorkTaskProductForm) => {
    localWorkTask.value.workTaskProducts.push(workTaskProduct);
};

/**
 * Updates a product.
 */
const updateWorkTaskProduct = (workTaskProduct: WorkTaskProductForm) => {
    const index = getIndexOfWorkTaskProduct(workTaskProduct);

    if (index !== -1) {
        // Update the found object with the new object's properties
        localWorkTask.value.workTaskProducts[index] = {
            ...localWorkTask.value.workTaskProducts[index],
            ...workTaskProduct,
        };
    }
};

const confirmDelete = () => {
    if (!itemToDelete.value || itemToDeleteIndex.value === null) return;

    if (deleteType.value === 'mixture') {
        const mixture = itemToDelete.value as WorkTaskMixtureForm;
        const mixtureProductsAssociatedWithOtherMixtures = getMixtureProductsAssociatedWithOtherMixtures(mixture);
        const productsToDeleteAfterDeletingMixture = getProductsToDeleteAfterDeletingMixture(
            mixture,
            mixtureProductsAssociatedWithOtherMixtures,
        );

        // Delete products associated with this mixture
        productsToDeleteAfterDeletingMixture.forEach((mixtureProduct) => {
            const deleteIndex = localWorkTask.value.workTaskProducts.findIndex(
                (wtp) => wtp.productId == mixtureProduct.productId,
            );
            localWorkTask.value.workTaskProducts.splice(deleteIndex, 1);
            workTaskProductRefs.value.splice(deleteIndex, 1);
        });

        // Delete mixture
        localWorkTask.value.workTaskMixtures.splice(itemToDeleteIndex.value, 1);
    } else {
        const product = itemToDelete.value as WorkTaskProductForm;
        const index = getIndexOfWorkTaskProduct(product);

        // Get the mixtures associated with this product
        const mixturesAssociatedWithProduct = getMixturesAssociatedWithProduct(product);

        // Delete mixtures associated with this product
        mixturesAssociatedWithProduct.forEach((mixture) => {
            const deleteIndex = localWorkTask.value.workTaskMixtures.findIndex(
                (wtm) => wtm.mixtureId == mixture.mixtureId,
            );
            localWorkTask.value.workTaskMixtures.splice(deleteIndex, 1);
        });

        // Delete product
        localWorkTask.value.workTaskProducts.splice(index, 1);
        workTaskProductRefs.value.splice(itemToDeleteIndex.value, 1);
    }

    // Close dialog
    deleteConfirmationDialog.value = false;
};

/**
 * Deletes the specified Fertilize product. This deletion is not saved until the form is saved. This is done separately
 * to the main "delete product" method to ensure that the corresponding value in workTaskFertilizeProductRefs is deleted.
 * It is only used for Plant tasks because they have their Fertilize and Spray products listed separately. It is also not
 * necessary to check for associated mixtures because Plant tasks do not have mixtures.
 */
const deleteWorkTaskFertilizeProduct = (refObjectIndex: number, workTaskProduct: WorkTaskProductForm) => {
    const index = getIndexOfWorkTaskProduct(workTaskProduct);

    // Delete product
    localWorkTask.value.workTaskProducts.splice(index, 1);

    // Delete the ref object for this deleted product so that it's no longer validated
    workTaskFertilizeProductRefs.value.splice(refObjectIndex, 1);
};

/**
 * Deletes the specified Spray product. This deletion is not saved until the form is saved. This is done separately
 * to the main "delete product" method to ensure that the corresponding value in workTaskSprayProductRefs is deleted.
 * It is only used for Plant tasks because they have their Spray and Spray products listed separately. It is also not
 * necessary to check for associated mixtures because Plant tasks do not have mixtures.
 */
const deleteWorkTaskSprayProduct = (refObjectIndex: number, workTaskProduct: WorkTaskProductForm) => {
    const index = getIndexOfWorkTaskProduct(workTaskProduct);

    // Delete product
    localWorkTask.value.workTaskProducts.splice(index, 1);

    // Delete the ref object for this deleted product so that it's no longer validated
    workTaskSprayProductRefs.value.splice(refObjectIndex, 1);
};

/**
 * Get the index of the specified work task product, based on its unique ID.
 */
const getIndexOfWorkTaskProduct = (workTaskProduct: WorkTaskProductForm) => {
    return localWorkTask.value.workTaskProducts.findIndex(
        (wst) =>
            (workTaskProduct.workTaskProductId != null &&
                wst.workTaskProductId === workTaskProduct.workTaskProductId) ||
            (workTaskProduct.workTaskTemplateProductId != null &&
                wst.workTaskTemplateProductId === workTaskProduct.workTaskTemplateProductId) ||
            (workTaskProduct.newItemId != null && wst.newItemId === workTaskProduct.newItemId),
    );
};

/**
 * Get the mixtures associated with the specified product.
 */
const getMixturesAssociatedWithProduct = (workTaskProduct: WorkTaskProductForm) => {
    return localWorkTask.value.workTaskMixtures.filter((m) =>
        m.mixtureProducts.some((mp) => mp.productId == workTaskProduct.productId),
    );
};

/**
 * Adds a new variety preference.
 */
const addWorkTaskCropVarietyPreference = (workTaskCropVarietyPreference: WorkTaskCropVarietyPreferenceForm) => {
    localWorkTask.value.workTaskCropVarietyPreferences.push(workTaskCropVarietyPreference);
};

/**
 * Updates a variety preference.
 */
const updateWorkTaskCropVarietyPreference = (workTaskCropVarietyPreference: WorkTaskCropVarietyPreferenceForm) => {
    const index = localWorkTask.value.workTaskCropVarietyPreferences.findIndex(
        (wst) =>
            (workTaskCropVarietyPreference.workTaskCropVarietyPreferenceId != null &&
                wst.workTaskCropVarietyPreferenceId ===
                    workTaskCropVarietyPreference.workTaskCropVarietyPreferenceId) ||
            (workTaskCropVarietyPreference.workTaskTemplateCropVarietyPreferenceId != null &&
                wst.workTaskTemplateCropVarietyPreferenceId ===
                    workTaskCropVarietyPreference.workTaskTemplateCropVarietyPreferenceId) ||
            (workTaskCropVarietyPreference.newItemId != null &&
                wst.newItemId === workTaskCropVarietyPreference.newItemId),
    );

    if (index !== -1) {
        // Update the found object with the new object's properties
        localWorkTask.value.workTaskCropVarietyPreferences[index] = {
            ...localWorkTask.value.workTaskCropVarietyPreferences[index],
            ...workTaskCropVarietyPreference,
        };
    }
};

/**
 * Deletes the specified variety preference. This deletion is not saved until the form is saved.
 */
const deleteWorkTaskCropVarietyPreference = (workTaskCropVarietyPreferenceIndex: number) => {
    // Delete variety preference
    localWorkTask.value.workTaskCropVarietyPreferences.splice(workTaskCropVarietyPreferenceIndex, 1);

    // Delete the ref object for this deleted crop variety preference so that it's no longer validated
    workTaskCropVarietyPreferenceRefs.value.splice(workTaskCropVarietyPreferenceIndex, 1);
};

/**
 * Moves the sub task up.
 */
const moveWorkSubTaskAhead = (workSubTaskIndex: number) => {
    setTimeout(() => {
        // Check if the index is within bounds and not the last element
        if (workSubTaskIndex < localWorkTask.value.workSubTasks.length - 1) {
            // Swap the item with the next item
            [
                localWorkTask.value.workSubTasks[workSubTaskIndex],
                localWorkTask.value.workSubTasks[workSubTaskIndex + 1],
            ] = [
                localWorkTask.value.workSubTasks[workSubTaskIndex + 1],
                localWorkTask.value.workSubTasks[workSubTaskIndex],
            ];
        }
    }, 250);
};

/**
 * Moves the sub task down.
 */
const moveWorkSubTaskBack = (workSubTaskIndex: number) => {
    setTimeout(() => {
        // Check if the index is within bounds and not the first element
        if (workSubTaskIndex > 0) {
            // Swap the item with the previous item
            [
                localWorkTask.value.workSubTasks[workSubTaskIndex],
                localWorkTask.value.workSubTasks[workSubTaskIndex - 1],
            ] = [
                localWorkTask.value.workSubTasks[workSubTaskIndex - 1],
                localWorkTask.value.workSubTasks[workSubTaskIndex],
            ];
        }
    }, 250);
};

/**
 * Reset the form to its default state.
 */
const resetForm = () => {
    workTask.value = new ManageWorkTaskForm();

    // If creating a task template, the irrigation rate should default to null instead of 0
    if (modalMode.value == ManageWorkTaskModalMode.CreateTemplate) {
        workTask.value.irrigationRatePlanned = null;
    }

    localWorkTask.value = {...workTask.value};
    workTaskSearchResult.value = null;
    workTaskTemplateSearchResult.value = null;
    farmFieldSearchResult.value = null;
    isWorkTaskTypeChangeable.value = true;
    workSubTaskRefs.value = [];
    workTaskProductRefs.value = [];
    workTaskFertilizeProductRefs.value = [];
    workTaskSprayProductRefs.value = [];
    workTaskCropVarietyPreferenceRefs.value = [];
    expansionPanelsCultivate.value = [0];
    expansionPanelsMixtures.value = [0];
    expansionPanelsProducts.value = [0];
    expansionPanelsCropVarietyPreferences.value = [0];
    expansionPanelsFertilizerProducts.value = [0];
    expansionPanelsSprayProducts.value = [0];
    isCheckingTemplateName.value = false;
    isTemplateNameAvailable.value = true;
};

/**
 * Checks if the specified template name is available. Debounce is used to prevent the API from being called too often.
 * TODO: Currently this only prevents the API from being called more than once every 500ms. We should change it so
 * that it prevents the API from being called until 500ms after the value is last updated. We might also want to
 * move this into a reusable component at some point.
 */
const debouncedCheckTemplateNameAvailable = debounce(
    async (workTaskTemplateName: string, resolve: (value: string | boolean) => void) => {
        const request: CheckTemplateNameAvailableRequest = {
            workTaskTemplateName,
            workTaskTemplateId: localWorkTask.value.workTaskTemplateId,
        };

        const isAvailable = await apiService.post('work-task-templates/check-template-name-available', request);
        isCheckingTemplateName.value = false;
        isTemplateNameAvailable.value = isAvailable;

        resolve(isAvailable || i18n.global.t('Validation_TemplateNameUnique'));
    },
    500,
);

/**
 * Checks if the specified template name is available.
 */
const checkTemplateNameAvailable = async (workTaskTemplateName: string): Promise<string | boolean> => {
    isCheckingTemplateName.value = true;

    return new Promise((resolve) => {
        debouncedCheckTemplateNameAvailable(workTaskTemplateName, resolve);
    });
};

/**
 * The title of the modal.
 */
const modalTitle = computed(() => {
    let title = '';

    switch (modalMode.value) {
        case ManageWorkTaskModalMode.Modify:
            title = i18n.global.t('ModifyTask');
            break;
        case ManageWorkTaskModalMode.Create:
            title = i18n.global.t('CreateATask');
            break;
        case ManageWorkTaskModalMode.Duplicate:
            title = i18n.global.t('DuplicateTask');
            break;
        case ManageWorkTaskModalMode.DuplicateTemplate:
            title = i18n.global.t('DuplicateTaskTemplate');
            break;
        case ManageWorkTaskModalMode.CreateTemplate:
            title = i18n.global.t('CreateATaskTemplate');
            break;
        case ManageWorkTaskModalMode.ModifyTemplate:
            title = i18n.global.t('ModifyTaskTemplate');
            break;
    }
    return title;
});

/**
 * The family of the FontAwesome icon to show in the header.
 */
const headerIconFamily = computed(() => {
    if (
        modalMode.value == ManageWorkTaskModalMode.CreateTemplate ||
        modalMode.value == ManageWorkTaskModalMode.ModifyTemplate ||
        modalMode.value == ManageWorkTaskModalMode.DuplicateTemplate
    ) {
        return 'fak';
    } else {
        return 'fal';
    }
});

/**
 * The code of the FontAwesome icon to show in the header.
 */
const headerIconCode = computed(() => {
    if (
        modalMode.value == ManageWorkTaskModalMode.CreateTemplate ||
        modalMode.value == ManageWorkTaskModalMode.ModifyTemplate ||
        modalMode.value == ManageWorkTaskModalMode.DuplicateTemplate
    ) {
        return 'task-template';
    } else {
        return 'clipboard-list-check';
    }
});

/**
 * The number of crop variety preferences that have been added.
 */
const numberOfCropVarietyPreferences = computed(() => {
    return localWorkTask.value.workTaskCropVarietyPreferences.length;
});

/**
 * The number of products that have been added.
 */
const numberOfProducts = computed(() => {
    return localWorkTask.value.workTaskProducts.length;
});

/**
 * The rules for the planned Irrigation Rate field.
 */
const irrigationRatePlannedRules = computed(() => {
    // Add numeric greater than 0 rule
    let rules = [numericGreaterThanZeroRule];

    // If this is not a task template, make the field mandatory
    if (!isTaskTemplateMode.value) {
        rules.push(requiredRule);
    }

    return rules;
});

/**
 * Flag to indicate if the modal is in task or task template mode.
 */
const isTaskTemplateMode = computed(() => {
    return (
        modalMode.value == ManageWorkTaskModalMode.CreateTemplate ||
        modalMode.value == ManageWorkTaskModalMode.ModifyTemplate ||
        modalMode.value == ManageWorkTaskModalMode.DuplicateTemplate
    );
});

/**
 * Flag to indicate if the users needs to confirm that they want to modify the template before saving.
 */
const showConfirmModifyTemplate = computed(() => {
    return (
        modalMode.value == ManageWorkTaskModalMode.ModifyTemplate &&
        workTaskTemplateSearchResult.value != null &&
        workTaskTemplateSearchResult.value.numberOfWorkTaskPlans > 0
    );
});

/**
 * List of Fertilizer work task products (for Plant tasks where Fertilizer/Spray products are shown separately).
 */
const fertilizerWorkTaskProducts = computed(() => {
    return localWorkTask.value.workTaskProducts.filter(
        (wtp) =>
            (ManageWorkTaskHelper.getProductDetails(wtp.productId, products.value)?.productTypeCode ==
                ProductTypeCode.Fertilizer ||
                ManageWorkTaskHelper.getProductDetails(wtp.productId, products.value)?.productTypeCode ==
                    ProductTypeCode.Other) &&
            wtp.isFertilizer,
    );
});

/**
 * List of Spray work task products (for Plant tasks where Fertilizer/Spray products are shown separately).
 */
const sprayWorkTaskProducts = computed(() => {
    return localWorkTask.value.workTaskProducts.filter(
        (wtp) =>
            (ManageWorkTaskHelper.getProductDetails(wtp.productId, products.value)?.productTypeCode ==
                ProductTypeCode.Chemical ||
                ManageWorkTaskHelper.getProductDetails(wtp.productId, products.value)?.productTypeCode ==
                    ProductTypeCode.Other) &&
            wtp.isSpray,
    );
});

// Watch for changes to modal state
watch(
    () => manageWorkTaskModalStore.isVisible,
    async (isVisible) => {
        if (isVisible) {
            await loadModal();
        }
    },
);

// Deselect predecessor task when farm field changes.
watch(
    () => localWorkTask.value.farmFieldId,
    async () => {
        if (!isLoading.value) {
            // Set selected predecessor task back to null
            localWorkTask.value.dueAfterWorkTaskId = null;
        }
    },
);

// Update flag to indicate that artifacts have been updated.
watch(
    () => localWorkTask.value.artifacts,
    () => {
        if (!isLoading.value) {
            localWorkTask.value.haveArtifactsBeenUpdated = true;
        }
    },
);

// Watch for when the task type is changed.
watch(
    () => localWorkTask.value.workTaskTypeCode,
    () => {
        // If this is a new Harvest task
        if (!localWorkTask.value.workTaskId && localWorkTask.value.workTaskTypeCode == WorkTaskTypeCode.Harvest) {
            // Default the timing to "Due in Month"
            localWorkTask.value.dueDateTiming = WorkTaskDueDateTiming.DueInMonth;
        }
    },
);

// Watch for when the field is changed.
watch(
    () => localWorkTask.value.farmFieldId,
    () => {
        // If this is a new Plant task and a field has been selected
        if (
            !localWorkTask.value.workTaskId &&
            localWorkTask.value.farmFieldId != null &&
            modalMode.value == ManageWorkTaskModalMode.Create
        ) {
            // Default the crop to the field's primary crop
            localWorkTask.value.plantCropId =
                farmFields.value.find((ff) => ff.farmFieldId == localWorkTask.value.farmFieldId)?.primaryCropId || null;
        }
    },
);

// Update flag to indicate that changes have been made and the work task type cannot be changed.
watch(
    () => ({...JSON.parse(JSON.stringify(localWorkTask.value))}),
    (newVal, oldVal) => {
        if (!isLoading.value) {
            // If the work task type or farm field were changed, still allow the work task type to be changed
            if (
                JSON.stringify(oldVal) == JSON.stringify(newVal) ||
                oldVal.workTaskTypeCode != newVal.workTaskTypeCode ||
                oldVal.farmFieldId != newVal.farmFieldId
            ) {
                return;
            }

            // If other changes were made, prevent the work task type from being changed
            isWorkTaskTypeChangeable.value = false;
        }
    },
    {deep: true},
);

import {useDeleteOrCancelWorkTaskModalStore} from '@/stores/modals/delete-or-cancel-work-task-modal-store';
const deleteOrCancelWorkTaskModalStore = useDeleteOrCancelWorkTaskModalStore();
import {useDeleteUserModalStore} from '@/stores/modals/delete-user-modal-store';
import AsyncHelper from '@/helpers/AsyncHelper';
const deleteUserModalStore = useDeleteUserModalStore();
watch([() => deleteOrCancelWorkTaskModalStore.savedCounter, () => deleteUserModalStore.deletedCounter], () => {
    if (manageWorkTaskModalStore.isVisible) close();
});

watch(
    [
        () => localWorkTask.value.dueDateTiming,
        () => localWorkTask.value.dueDateOn,
        () => localWorkTask.value.dueDateLatest,
        () => localWorkTask.value.dueDateMonth,
        () => localWorkTask.value.dueDateRange,
        () => localWorkTask.value.dueAfterWorkTaskId,
        () => localWorkTask.value.dueAfterWorkTaskWithinDays,
    ],
    async () => {
        if (!isLoading.value) {
            const isDueDateValid = await dueDateTimingControl.value.validate();
            if (isDueDateValid) {
                getImpactOfChanges();
            }
        }
    },
);
</script>

<style lang="scss" scoped>
@import '@/assets/scss/swatches.scss';

.completeConfirmation {
    background-color: rgba($swatchGRN, 0.2) !important;

    .confirm-header {
        background-color: rgba($swatchA5, 0.5);
        font-size: large;
    }

    .confirm-checkbox {
        font-size: 18pt;
    }
}
</style>
