<template>
    <div v-if="workTaskActionsFiltered.length > 0">
        <v-data-table
            :headers="headers"
            :items="workTaskActionsFiltered"
            :hide-default-footer="true"
            :items-per-page="-1"
            class="action-details">
            <template v-slot:[`item.workTaskActionDate`]="{item}">
                <div class="swatchA1 bold" style="font-size: medium">
                    {{ formatDate(item.workTaskActionDate) }}
                </div>
            </template>

            <template v-slot:[`item.workTaskActionTypeCode`]="{item}">
                <div class="swatchA1 bold" style="font-size: medium">
                    {{ getWorkTaskActionTypeByCode(item.workTaskActionTypeCode)?.getWorkTaskActionTextTypeByCode() }}
                    <CircleIcon
                        v-if="item.wasActionedOnMobileApp"
                        class="marginLeft marginRightSmall swatchBLKBG swatchWHTBorder"
                        color="swatchWHT"
                        :title="$t('ActionedOnTheMobileApp')">
                        <FontAwesomeIcon :icon="['fal', 'mobile']" />
                    </CircleIcon>
                </div>
            </template>

            <template v-slot:[`item.workTaskActionComments`]="{item}">
                <TranslatableTexts :text="item.workTaskActionComments" />
            </template>

            <template v-slot:[`item.createdUserId`]="{item}">
                <div v-if="item.createdUserId && item.workTaskActionUserFullName">
                    <UserChip :user="UserHelper.userChipType(item.createdUserId, item.createdUserFullName)" />
                </div>
            </template>

            <template v-slot:[`item.workTaskActionUserId`]="{item}">
                <div v-if="item.workTaskActionUserId && item.workTaskActionUserFullName">
                    <UserChip
                        :user="UserHelper.userChipType(item.workTaskActionUserId, item.workTaskActionUserFullName)" />
                </div>
            </template>
        </v-data-table>
        <!-- Concluding Remarks -->
        <div v-if="concludingActionRemark">
            <div class="subHeader mt-2 swatchG4BG">
                {{ $t('ConcludingRemarks') }}
            </div>
            <div class="pa-3 swatchG2">
                <TranslatableTexts :text="concludingActionRemark" />
            </div>
        </div>
    </div>
    <div v-else class="pa-3">{{ $t('NoActionsToDisplay') }}</div>
</template>

<script setup lang="ts">
import {computed, ref} from 'vue';
import i18n from '@/i18n';
import {WorkTaskActionDto} from '@/models/data-transfer-objects/search/work-task-search/WorkTaskActionDto';
import {formatTime} from '@/helpers/TimeFormatHelper';
import UserHelper from '@/helpers/UserHelper';
import UserChip from '@/components/UserChip.vue';
import {getWorkTaskActionTypeByCode} from '@/services/work-task-action-types-service';
import {WorkTaskActionTypeCode} from '@/enums/work-task-action-type-code';

// Props
const props = defineProps<{
    workTaskActions: WorkTaskActionDto[];
}>();

const workTaskActionsFiltered = computed(() => {
    return props.workTaskActions.filter(
        (ta) =>
            ta.workTaskActionTypeCode == WorkTaskActionTypeCode.Started ||
            ta.workTaskActionTypeCode == WorkTaskActionTypeCode.Paused ||
            ta.workTaskActionTypeCode == WorkTaskActionTypeCode.Completed ||
            ta.workTaskActionTypeCode == WorkTaskActionTypeCode.Cancelled ||
            ta.workTaskActionTypeCode == WorkTaskActionTypeCode.Restarted,
    );
});

const concludingActionRemark = computed(() => {
    const actions = workTaskActionsFiltered.value;
    if (actions && actions.length > 0) {
        const lastAction = actions[actions.length - 1];
        if (
            lastAction.workTaskActionTypeCode === WorkTaskActionTypeCode.Completed ||
            lastAction.workTaskActionTypeCode === WorkTaskActionTypeCode.Cancelled
        ) {
            return lastAction.workTaskActionComments;
        }
    }
    return null;
});

const headers = ref([
    {title: i18n.global.t('DateAmpersandTime'), key: 'workTaskActionDate', sortable: true, width: '20%'},
    {title: i18n.global.t('Event'), key: 'workTaskActionTypeCode', sortable: true, width: '20%'},
    {title: i18n.global.t('Comments'), key: 'workTaskActionComments', sortable: false, width: '20%'},
    {title: i18n.global.t('ByUser'), key: 'createdUserId', sortable: false, width: '20%'},
    {title: i18n.global.t('OnBehalfOf'), key: 'workTaskActionUserId', sortable: false, width: '20%'},
]);

/**
 * Format date for report results.
 */
const formatDate = (date: Date): string => {
    return formatTime(date, 'dd MMMM yyyy, HH:mm');
};
</script>

<style lang="scss">
@import '@/assets/scss/swatches.scss';
.action-details {
    .v-table__wrapper thead {
        background-color: $swatchG9;
    }
}
</style>
