<template>
    <LoadingSymbol v-if="isLoading" />

    <table v-else style="width: 100%" class="tblForm">
        <tr>
            <td colspan="4" class="subHeader">{{ $t('Particulars') }}</td>
        </tr>
        <tr>
            <td class="tableCell padding" style="width: 10%; min-width: 10rem">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('FarmSite(s)') }}</span>
                    <HelpIcon :help-text="$t('WorkTaskSearch_FarmSites_HelpText')" />
                </div>
            </td>
            <td style="width: 40%">
                <v-select
                    v-model="localSearchParameters.farmSiteIdList"
                    hide-details
                    multiple
                    clearable
                    chips
                    :items="farmSites"
                    item-title="farmSiteAbbreviationAndName"
                    item-value="farmSiteId"
                    max-width="400px"></v-select>
            </td>
            <td class="tableCell padding" style="width: 10%; min-width: 10rem">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('FarmFields') }}</span>
                    <HelpIcon :help-text="$t('WorkTaskSearch_Fields_HelpText')" />
                </div>
            </td>
            <td style="width: 40%">
                <v-select
                    v-model="localSearchParameters.farmFieldIdList"
                    hide-details
                    multiple
                    clearable
                    chips
                    :items="farmFields"
                    item-title="farmFieldCodeAndName"
                    item-value="farmFieldId"
                    max-width="400px"></v-select>
            </td>
        </tr>
        <tr>
            <td class="tableCell padding">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('TaskTypes') }}</span>
                    <HelpIcon :help-text="$t('WorkTaskSearch_TaskTypes_HelpText')" />
                </div>
            </td>
            <td>
                <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                    <v-select
                        v-model="localSearchParameters.workTaskTypeCodeList"
                        hide-details
                        multiple
                        clearable
                        chips
                        :items="workTaskTypes"
                        item-title="workTaskTypeName"
                        item-value="workTaskTypeCode"
                        max-width="400px"></v-select>
                </div>
            </td>
            <td class="tableCell padding">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('TaskStatus') }}</span>
                    <HelpIcon :help-text="$t('WorkTaskSearch_TaskStatus_HelpText')" />
                </div>
            </td>
            <td>
                <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                    <v-select
                        v-model="localSearchParameters.workTaskStatus"
                        hide-details
                        :items="workTaskStatusOptions"
                        item-title="title"
                        item-value="value"
                        max-width="400px"></v-select>
                </div>
            </td>
        </tr>
        <tr v-if="!isOverdueReport">
            <td class="tableCell padding">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('TaskDetails') }}</span>
                    <HelpIcon :help-text="$t('WorkTaskSearch_TaskDetails_HelpText')" />
                </div>
            </td>
            <td>
                <v-text-field
                    v-model="localSearchParameters.workTaskDetails"
                    hide-details
                    clearable
                    style="width: 400px">
                </v-text-field>
            </td>
        </tr>
        <tr v-if="!isOverdueReport">
            <td colspan="4" class="subHeader">{{ $t('AttributesAndAssociations') }}</td>
        </tr>
        <tr v-if="!isOverdueReport">
            <td class="tableCell padding">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('DateRaised') }}</span>
                    <HelpIcon :help-text="$t('WorkTaskSearch_DateRaised_HelpText')" />
                </div>
            </td>
            <td>
                <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                    <VueDatePicker
                        v-model="dateRaised"
                        range
                        format="dd/MM/yyyy"
                        :enable-time-picker="false"
                        text-input
                        :teleport="true"
                        :preset-dates="presetDates"
                        style="max-width: 300px"
                        :locale="i18n.global.locale" />
                </div>
            </td>
            <td class="tableCell padding">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('DateDue') }}</span>
                    <HelpIcon :help-text="$t('WorkTaskSearch_DateDue_HelpText')" />
                </div>
            </td>
            <td>
                <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                    <VueDatePicker
                        v-model="dateDue"
                        range
                        format="dd/MM/yyyy"
                        :enable-time-picker="false"
                        :teleport="true"
                        text-input
                        :preset-dates="presetDueDates"
                        style="max-width: 300px"
                        :locale="i18n.global.locale" />
                </div>
            </td>
        </tr>
        <tr>
            <td v-if="!isOverdueReport" class="tableCell padding">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('DateConcluded') }}</span>
                    <HelpIcon :help-text="$t('WorkTaskSearch_DateConcluded_HelpText')" />
                </div>
            </td>
            <td v-if="!isOverdueReport">
                <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                    <VueDatePicker
                        v-model="dateConcluded"
                        range
                        format="dd/MM/yyyy"
                        :enable-time-picker="false"
                        text-input
                        :teleport="true"
                        :preset-dates="presetDates"
                        style="max-width: 300px"
                        :locale="i18n.global.locale" />
                </div>
            </td>
            <td class="tableCell padding">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('User') }}</span>
                    <HelpIcon :help-text="$t('WorkTaskSearch_User_HelpText')" />
                </div>
            </td>
            <td>
                <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                    <v-autocomplete
                        v-model="localSearchParameters.userIdList"
                        :items="users"
                        hide-details
                        multiple
                        clearable
                        chips
                        item-title="userFullName"
                        item-value="userId"
                        max-width="400px"></v-autocomplete>
                </div>
            </td>
        </tr>
        <tr>
            <td class="tableCell padding">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('Season') }}</span>
                    <HelpIcon :help-text="$t('WorkTaskSearch_Season_HelpText')" />
                </div>
            </td>
            <td>
                <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                    <v-select
                        v-model="localSearchParameters.seasonId"
                        hide-details
                        :items="seasonsWithNull"
                        item-title="title"
                        item-value="value"
                        max-width="400px"></v-select>
                </div>
            </td>
        </tr>
        <tr v-if="displayExportOptions">
            <td class="tableCell padding">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('ExportTo') }}</span>
                    <HelpIcon :help-text="$t('ObservationSearch_ExportResultsTo_HelpText')" />
                </div>
            </td>
            <td>
                <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                    <v-select
                        v-model="localSearchParameters.isExportCSV"
                        hide-details
                        :items="exportOptions"
                        item-title="title"
                        item-value="value"
                        max-width="400px"></v-select>
                </div>
            </td>
        </tr>
    </table>
</template>

<script setup lang="ts">
import {defineAsyncComponent, onMounted, ref, watch, computed} from 'vue';
import {SearchWorkTasksRequest} from '@/models/api/requests/search/SearchWorkTasksRequest';
import i18n from '@/i18n';
import {addDays, addMonths} from 'date-fns';
import {formatTime} from '@/helpers/TimeFormatHelper';
import {useFarmSites} from '@/composables/data-source/farm-sites';
const {getFarmSites, farmSites} = useFarmSites();
import {useWorkTaskTypes} from '@/composables/data-source/work-task-types';
const {getWorkTaskTypes, workTaskTypes} = useWorkTaskTypes();
import {useUsers} from '@/composables/data-source/users';
const {getUsers, users} = useUsers();
import {useFarmFields} from '@/composables/data-source/farm-fields';
const {getFarmFields, farmFields} = useFarmFields();
import {useSeasons} from '@/composables/data-source/seasons';
const {getSeasons, seasonsWithNull} = useSeasons();

const VueDatePicker = defineAsyncComponent(() => import('@vuepic/vue-datepicker'));
const props = withDefaults(
    defineProps<{
        searchParameters: SearchWorkTasksRequest;
        isOverdueReport?: boolean;
        displayExportOptions?: boolean;
    }>(),
    {
        isOverdueReport: false,
        displayExportOptions: false,
    },
);

const emit = defineEmits(['update:searchParameters']);
const isLoading = ref<boolean>(false);
const dateRaised = ref<Array<Date> | null>(null);
const dateDue = ref<Array<Date> | null>(null);
const dateConcluded = ref<Array<Date> | null>(null);

// Create a local copy of the prop
const localSearchParameters = ref({...props.searchParameters});

//The present date for the date picker
const today = new Date();
const presetDates = ref([
    {
        label: i18n.global.t('Last7Days'),
        value: [addDays(today, -6), today],
    },
    {
        label: i18n.global.t('Last14Days'),
        value: [addDays(today, -13), today],
    },
    {
        label: i18n.global.t('Last30Days'),
        value: [addDays(today, -30), today],
    },
    {
        label: i18n.global.t('Last3Months'),
        value: [addMonths(today, -3), today],
    },
    {
        label: i18n.global.t('Last6Months'),
        value: [addMonths(today, -6), today],
    },
    {
        label: i18n.global.t('Last9Months'),
        value: [addMonths(today, -9), today],
    },
    {
        label: i18n.global.t('Last12Months'),
        value: [addMonths(today, -12), today],
    },
]);
const presetDueDates = ref([
    {
        label: i18n.global.t('Last7Days'),
        value: [addDays(today, -6), today],
    },
    {
        label: i18n.global.t('Last14Days'),
        value: [addDays(today, -13), today],
    },
    {
        label: i18n.global.t('Last30Days'),
        value: [addDays(today, -29), today],
    },
    {
        label: i18n.global.t('Last3Months'),
        value: [addMonths(today, -3), today],
    },
    {
        label: i18n.global.t('Next7Days'),
        value: [addDays(today, 6), today],
    },
    {
        label: i18n.global.t('Next14Days'),
        value: [addDays(today, 13), today],
    },
    {
        label: i18n.global.t('Next30Days'),
        value: [addDays(today, 29), today],
    },
    {
        label: i18n.global.t('Next3Months'),
        value: [addMonths(today, 3), today],
    },
]);

// Options for Work Task Status
const workTaskStatusOptions = [
    {value: null, title: ''},
    {value: 'Completed', title: i18n.global.t('Completed')},
    {value: 'CompletedLast30Days', title: i18n.global.t('CompletedLast30Days')},
    {value: 'CompletedLast7Days', title: i18n.global.t('CompletedLast7Days')},
    {value: 'Overdue', title: i18n.global.t('Overdue')},
    {value: 'DueNext7Days', title: i18n.global.t('DueNext7Days')},
    {value: 'DueNext30Days', title: i18n.global.t('DueNext30Days')},
    {value: 'Incomplete', title: i18n.global.t('Incomplete')},
    {value: 'Cancelled', title: i18n.global.t('Cancelled')},
];

//Export results options
const exportOptions = computed(() => [
    {
        title: i18n.global.t('CSVFile'),
        value: true,
    },
    {
        title: i18n.global.t('Screen'),
        value: false,
    },
]);
/**
 * Reset the form to its default state.
 */
const resetLocalParameters = () => {
    dateRaised.value = [];
    dateDue.value = [];
    dateConcluded.value = [];
};

/**
 * Gets the From date (first date) from a date range array.
 */
const getFromDate = (dates: Array<Date> | null) => {
    // If there is a From date
    if (dates != null && dates.length >= 1 && dates[0] != null) {
        // Return From date
        return formatTime(dates[0], 'yyyy-MM-dd');
    }

    return null;
};

/**
 * Gets the To date (second date) from a date range array.
 */
const getToDate = (dates: Array<Date> | null) => {
    // If there is a To date
    if (dates != null && dates.length >= 2 && dates[1] != null) {
        // Return To date
        return formatTime(dates[1], 'yyyy-MM-dd');
    }

    return null;
};

// Watch for changes in the dateRaised object and update local parameters
watch(
    () => dateRaised.value,
    (newVal) => {
        localSearchParameters.value.dateRaisedFrom = getFromDate(newVal);
        localSearchParameters.value.dateRaisedTo = getToDate(newVal);
    },
);

// Watch for changes in the dateDue object and update local parameters
watch(
    () => dateDue.value,
    (newVal) => {
        localSearchParameters.value.dateDueFrom = getFromDate(newVal);
        localSearchParameters.value.dateDueTo = getToDate(newVal);
    },
);

// Watch for changes in the dateConcluded object and update local parameters
watch(
    () => dateConcluded.value,
    (newVal) => {
        localSearchParameters.value.dateConcludedFrom = getFromDate(newVal);
        localSearchParameters.value.dateConcludedTo = getToDate(newVal);
    },
);

// Watch for changes in the parent parameters object and update local copy
watch(
    () => props.searchParameters,
    (newVal) => {
        if (JSON.stringify(newVal) !== JSON.stringify(localSearchParameters.value)) {
            if (newVal.dateRaisedFrom === null || newVal.dateRaisedTo === null) dateRaised.value = [];
            else {
                dateRaised.value = [new Date(newVal.dateRaisedFrom), new Date(newVal.dateRaisedTo)];
            }
            if (newVal.dateDueTo === null || newVal.dateDueFrom === null) dateDue.value = [];
            else {
                dateDue.value = [new Date(newVal.dateDueFrom), new Date(newVal.dateDueTo)];
            }
            if (newVal.dateConcludedTo === null || newVal.dateConcludedFrom === null) dateConcluded.value = [];
            else {
                dateConcluded.value = [new Date(newVal.dateConcludedFrom), new Date(newVal.dateConcludedTo)];
            }
            localSearchParameters.value = {...newVal};
        }
    },
    {deep: true, immediate: true},
);

// Watch for changes in the local copy and emit the update event
watch(
    localSearchParameters,
    (newVal) => {
        if (JSON.stringify(newVal) !== JSON.stringify(props.searchParameters)) {
            emit('update:searchParameters', newVal);
        }
    },
    {deep: true},
);

// Watch for changes in the farm site list
watch(
    () => localSearchParameters.value.farmSiteIdList,
    async (newVal) => {
        await getFarmFields(newVal);

        const currentFarmFields = localSearchParameters.value.farmFieldIdList || [];
        const filteredFieldIds = farmFields.value.map((field) => field.farmFieldId);

        // Only keep the field IDs that are in the filtered list and were previously selected
        localSearchParameters.value.farmFieldIdList = currentFarmFields.filter((fieldId) =>
            filteredFieldIds.includes(fieldId),
        );
    },
);

// Fetch data for dropdown
onMounted(async () => {
    isLoading.value = true;

    // reset date range object to an empty array when the any of the date range parameters passed are nulls.
    if (props.searchParameters.dateRaisedFrom === null || props.searchParameters.dateRaisedTo === null)
        dateRaised.value = [];
    else {
        //Convert the passed parameter string to a two-date-object array to show value on the date picker
        dateRaised.value = [
            new Date(props.searchParameters.dateRaisedFrom),
            new Date(props.searchParameters.dateRaisedTo),
        ];
    }
    // reset date range object to an empty array when the any of the date range parameters passed are nulls.
    if (props.searchParameters.dateDueTo === null || props.searchParameters.dateDueFrom === null) dateDue.value = [];
    else {
        //Convert the passed parameter string to a two-date-object array to show value on the date picker
        dateDue.value = [new Date(props.searchParameters.dateDueFrom), new Date(props.searchParameters.dateDueTo)];
    }

    // reset date range object to an empty array when the any of the date range parameters passed are nulls.
    if (props.searchParameters.dateConcludedTo === null || props.searchParameters.dateConcludedFrom === null)
        dateConcluded.value = [];
    else {
        //Convert the passed parameter string to a two-date-object array to show value on the date picker
        dateConcluded.value = [
            new Date(props.searchParameters.dateConcludedFrom),
            new Date(props.searchParameters.dateConcludedTo),
        ];
    }
    // Get data
    await getFarmSites();
    await getWorkTaskTypes();
    await getUsers();
    await getFarmFields(localSearchParameters.value.farmSiteIdList);
    await getSeasons();

    isLoading.value = false;
});

// Expose reset function
defineExpose({resetLocalParameters});
</script>
