<template>
    <v-text-field v-model="localValue" v-bind="$attrs" :rules="computedRules" @blur="handleBlur" @focus="handleFocus">
        <template #append-inner>
            <slot name="append-inner" />
        </template>
    </v-text-field>
</template>
<script setup lang="ts">
import {ref, computed, watch} from 'vue';
import i18n from '@/i18n';

const props = defineProps<{
    modelValue: string | number | null | undefined;
    rules?: ((v: string | null) => boolean | string)[];
}>();

const emit = defineEmits<{
    'update:modelValue': [value: string | null];
    blur: [event: FocusEvent];
}>();

const localValue = ref('');
const isDirty = ref(false);
const isFocused = ref(false);

/**
 * Get locale number separators
 */
function getLocaleInfo(localeString: string) {
    const dummyNumber = new Intl.NumberFormat(localeString).format(1111.1);
    return {
        groupSeparator: dummyNumber.substring(1, 2),
        decimalSeparator: dummyNumber.substring(5, 6),
    };
}

/**
 * Validate number format for current locale
 */
function isStrictValidNumber(value: string, localeString: string): boolean {
    const {decimalSeparator} = getLocaleInfo(localeString);
    const escapedDecSep = decimalSeparator.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

    const validFormatRegex = new RegExp(`^-?\\d+(?:${escapedDecSep}\\d+)?$`);
    if (!validFormatRegex.test(value)) {
        return false;
    }

    const decCount = (value.match(new RegExp(escapedDecSep, 'g')) || []).length;
    if (decCount > 1) return false;

    if (value.includes(decimalSeparator + decimalSeparator)) return false;

    if (value.indexOf('-') > 0) return false;

    return true;
}

/**
 * Convert locale number to JS number
 */
function parseNumber(value: string, localeString: string): number | null {
    if (!isStrictValidNumber(value, localeString)) return null;

    const {decimalSeparator} = getLocaleInfo(localeString);
    const normalized = value.replace(decimalSeparator, '.');
    const result = parseFloat(normalized);
    return isNaN(result) ? null : result;
}

/**
 * Format number preserving decimal precision in current locale
 */
function formatNumberPreservePrecision(val: string | number, localeString: string): string {
    const {decimalSeparator} = getLocaleInfo(localeString);

    if (typeof val === 'number') {
        return val.toString().replace('.', decimalSeparator);
    }

    const parsed = parseNumber(val, localeString);
    if (parsed === null) return val;

    const parts = val.split(decimalSeparator);
    if (parts.length === 2) {
        return parsed.toFixed(parts[1].length).replace('.', decimalSeparator);
    }
    return parsed.toString().replace('.', decimalSeparator);
}

/**
 * Parse string as float only if it contains just digits, dots, and minus
 */
function fallbackParseFloat(str: string): number | null {
    if (!/^-?[0-9.]+$/.test(str)) return null;

    const res = parseFloat(str);
    return isNaN(res) ? null : res;
}

function handleBlur(event: FocusEvent) {
    isFocused.value = false;
    isDirty.value = true;

    if (!localValue.value) {
        if (props.modelValue !== null) {
            emit('update:modelValue', null);
        }
        return;
    }

    if (isStrictValidNumber(localValue.value, i18n.global.locale)) {
        const parsed = parseNumber(localValue.value, i18n.global.locale);
        if (parsed !== null) {
            const parsedStr = parsed.toString();
            if (props.modelValue !== parsedStr) {
                emit('update:modelValue', parsedStr);
            }
            return;
        }
    }

    if (props.modelValue !== localValue.value) {
        emit('update:modelValue', localValue.value);
    }

    emit('blur', event);
}

function handleFocus() {
    isFocused.value = true;
}

/**
 * Update display value when model changes
 */
function updateLocalValueFromModel(modelVal: string | number | null | undefined) {
    if (modelVal == null || modelVal == undefined || modelVal === '') {
        localValue.value = '';
        return;
    }

    if (isFocused.value) return;

    if (typeof modelVal === 'number') {
        localValue.value = formatNumberPreservePrecision(modelVal, i18n.global.locale);
        return;
    }

    const parsed = parseNumber(modelVal, i18n.global.locale);
    if (parsed !== null) {
        localValue.value = formatNumberPreservePrecision(modelVal, i18n.global.locale);
        return;
    }

    const fallbackNum = fallbackParseFloat(modelVal);
    if (fallbackNum !== null) {
        localValue.value = formatNumberPreservePrecision(fallbackNum, i18n.global.locale);
        return;
    }

    localValue.value = modelVal;
}

// Initialize
updateLocalValueFromModel(props.modelValue);

// Watch parent changes
watch(
    () => props.modelValue,
    (newVal) => {
        if (!isFocused.value) {
            updateLocalValueFromModel(newVal);
        }
    },
);

// Reformat on locale change if not focused
watch(
    () => i18n.global.locale,
    () => {
        if (!props.modelValue || isFocused.value) return;
        updateLocalValueFromModel(props.modelValue);
    },
);

// Parse and emit while typing, but don't reformat display
watch(localValue, (newVal) => {
    if (!isFocused.value) return;

    if (isStrictValidNumber(newVal, i18n.global.locale)) {
        const parsed = parseNumber(newVal, i18n.global.locale);
        if (parsed !== null && props.modelValue !== parsed.toString()) {
            emit('update:modelValue', parsed.toString());
        }
    }
});

const computedRules = computed(() => {
    if (!props.rules) return [];

    return [
        // Format validation
        (val: string) => {
            if (!val || !isDirty.value) return true;
            return isStrictValidNumber(val, i18n.global.locale) || i18n.global.t('Validation_InvalidFormat');
        },
        // User rules
        ...props.rules.map((rule) => {
            return (val: string) => {
                if (!val) return rule(val);

                if (!isStrictValidNumber(val, i18n.global.locale)) return rule(val);

                const parsed = parseNumber(val, i18n.global.locale);
                if (parsed === null) return rule(val);

                return rule(parsed.toString());
            };
        }),
    ];
});
</script>
