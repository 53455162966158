<template>
    <v-form :class="{'first-item': index == 0}">
        <table style="width: 100%; border-spacing: 1px; margin-bottom: -1px" class="tblForm swatchG9BG">
            <tr v-if="index == 0">
                <td class="swatchG7BG">{{ $t('NumberShort') }}</td>
                <td class="swatchG7BG">{{ $t('Variety') }}</td>
                <td class="swatchG7BG">{{ $t('SeedOrigin') }}</td>
                <td class="swatchG7BG">{{ $t('Information') }}</td>
                <td class="swatchG7BG">{{ $t('SeedRate') }}</td>
            </tr>
            <tr>
                <td style="width: 7%; text-align: center" class="swatchWHTBG">
                    {{ index + 1 }}
                </td>
                <td style="width: 28%" class="swatchWHTBG">
                    <div style="display: flex; align-items: center">
                        <CropIcon
                            :cropCode="workTaskCropVarietyPreference.cropCode"
                            :primary="true"
                            :varieties="[workTaskCropVarietyPreference.cropVarietyName]"
                            :isCollapsed="true" />

                        <!-- View Crop Variety button -->
                        <v-btn
                            @click="viewCropVarietyModalStore.open(workTaskCropVarietyPreference.cropVarietyId)"
                            class="v-btn--custom"
                            style="margin-left: auto">
                            <template v-slot:prepend>
                                <FontAwesomeIcon :icon="['fal', 'arrow-circle-right']" size="xl" />
                            </template>
                            {{ $t('View') }}
                        </v-btn>
                    </div>
                </td>
                <td style="width: 17%; text-align: center" class="swatchWHTBG">
                    <SeedOriginChip
                        v-if="workTaskCropVarietyPreference.seedOriginBlockId"
                        :farmSiteAbbreviation="workTaskCropVarietyPreference.seedOriginFarmSiteAbbreviation!"
                        :farmFieldCode="workTaskCropVarietyPreference.seedOriginFarmFieldCode!"
                        :farmBlockCode="workTaskCropVarietyPreference.seedOriginFarmBlockCode!" />
                </td>
                <td style="width: 29%" class="swatchWHTBG">
                    <div
                        v-for="(artifact, index) in workTaskCropVarietyPreference.artifacts"
                        :key="artifact.artifactId"
                        :class="{'mt-2': index > 0}">
                        <FileTypeButton
                            :url="artifact.artifactUrl"
                            :display-name="formatFileName(artifact.artifactDisplayName)"
                            :file-type-code="artifact.artifactFileTypeCode" />
                    </div>
                </td>
                <td style="width: 19%" class="swatchWHTBG swatchA1 bold">
                    {{ $n(workTaskCropVarietyPreference.seedRate, 'decimal') }}
                    {{ getSeedRateUnitName(workTaskCropVarietyPreference.seedRateUnitCode) }}
                </td>
            </tr>
        </table>
    </v-form>
</template>

<script setup lang="ts">
import {seedRateUnits} from '@/services/seed-rate-units-service';
import {WorkTaskCropVarietyPreferenceDto} from '@/models/data-transfer-objects/search/work-task-search/WorkTaskCropVarietyPreferenceDto';
import FileTypeButton from '@/components/FileTypeButton.vue';
import SeedOriginChip from '@/components/other-chips/SeedOriginChip.vue';
import {WorkTaskTemplateCropVarietyPreferenceDto} from '@/models/data-transfer-objects/search/work-task-template-search/WorkTaskTemplateCropVarietyPreferenceDto';
import {useViewCropVarietyModalStore} from '@/stores/modals/view-crop-variety-modal-store';
const viewCropVarietyModalStore = useViewCropVarietyModalStore();

defineProps<{
    workTaskCropVarietyPreference: WorkTaskCropVarietyPreferenceDto | WorkTaskTemplateCropVarietyPreferenceDto;
    index: number;
}>();

/**
 * Shortern the file name if needed to ensure the buttons are not too wide.
 */
const formatFileName = (fileName: string, maxLength: number = 20): string => {
    if (fileName.length <= maxLength) {
        return fileName;
    }
    return fileName.slice(0, maxLength) + '...';
};

/**
 * The name of the seed rate unit.
 */
const getSeedRateUnitName = (seedRateUnitCode: string) => {
    return seedRateUnits.find((sru) => sru.seedRateUnitCode == seedRateUnitCode)?.getSeedRateUnitName();
};
</script>
