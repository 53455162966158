<template>
    <LoadingSymbol v-if="homeDashboardStore.isLoading || homeDashboardStore.isMapLoading" class="swatchBLK" />
    <div
        :style="homeDashboardStore.isLoading || homeDashboardStore.isMapLoading ? 'display: none;' : ''"
        class="home-dashboard-map">
        <l-map
            ref="homeDashboardMap"
            :center="center"
            style="height: 100%; width: 100%"
            :options="mapOptions"
            @ready="initialMapSetup">
            <!-- Attribution (required by OpenStreetMaps license) -->
            <l-control-attribution position="bottomright" prefix="" />

            <!-- Background Map Tile Layer -->
            <l-tile-layer
                :url="backgroundImageryUrl"
                :opacity="homeDashboardStore.backgroundMapOpacity"
                :attribution="openStreetMapsAttribution"></l-tile-layer>

            <!-- Aerial Imagery Tile Layer -->
            <l-tile-layer :url="aerialImageryUrl" :opacity="homeDashboardStore.aerialImageryOpacity"></l-tile-layer>

            <!-- Farm Fields -->
            <template v-for="(farmField, index) in farmFieldsWithPolygon" :key="farmField">
                <l-polygon
                    v-for="(coords, subIndex) in convertCoordinates(farmField.farmFieldPolygon)"
                    :key="`${index}-${subIndex}`"
                    :lat-lngs="coords"
                    :fill-color="farmField.farmFieldColorHexCode"
                    :fill-opacity="getFarmFieldPolygonFillOpacity(farmField)"
                    :color="getFarmFieldPolygonBorderColor(farmField)"
                    :opacity="getFarmFieldPolygonBorderOpacity(farmField)"
                    :weight="getFarmFieldPolygonBorderWeight(farmField)"
                    @mouseover="homeDashboardStore.onFarmFieldMouseOver(farmField.farmFieldId)"
                    @mouseout="mouseOutFarmFieldPolygon"
                    @click="homeDashboardStore.onFarmFieldClicked(farmField, true)"
                    :pane="HomeDashboardMapPane.FarmFieldPolygon" />
            </template>

            <!-- Farm Field chips -->
            <div v-if="selectedFarmFieldBlocks.length == 0">
                <div v-for="(farmField, index) in farmFieldsWithPolygon" :key="`farm-field-chip-${index}`">
                    <l-marker
                        v-if="showFarmFieldChip(farmField)"
                        :lat-lng="calculateCentroid(farmField.farmFieldPolygon.coordinates)"
                        :pane="HomeDashboardMapPane.FarmFieldMarker"
                        @mouseout="homeDashboardStore.onObservationMouseOut">
                        <l-icon>
                            <div style="width: max-content; position: absolute; transform: translate(-50%, -50%)">
                                <HomeDashboardMapFarmFieldChip
                                    :farm-field="farmField"
                                    :show-farm-field-name="showFarmFieldName"
                                    :show-crop-varieties="showCropVarieties(farmField)"
                                    :show-crop-year="showCropYear(farmField)"
                                    :show-next-work-task="showNextWorkTask(farmField)" />
                            </div>
                        </l-icon>
                    </l-marker>
                </div>
            </div>

            <!-- Farm Blocks of selected farm field (when in single select mode) -->
            <template v-for="(farmBlock, index) in selectedFarmFieldBlocks" :key="`${index}-${farmBlock.farmBlockId}`">
                <l-polygon
                    v-for="(coords, subIndex) in convertCoordinates(farmBlock.farmBlockPolygon)"
                    :key="`${index}-${subIndex}-${farmBlock.farmBlockId}`"
                    :lat-lngs="coords"
                    :fill-color="homeDashboardStore.selectedFarmFieldsLevel1[0].farmFieldColorHexCode"
                    :fill-opacity="0.5"
                    :color="getFarmBlockPolygonBorderColor(farmBlock)"
                    :opacity="1"
                    :weight="getFarmBlockPolygonBorderWeight(farmBlock)"
                    :pane="HomeDashboardMapPane.FarmBlockPolygon"
                    @mouseover="homeDashboardStore.onFarmBlockMouseOver(farmBlock)"
                    @mouseout="homeDashboardStore.onFarmBlockMouseOut"
                    @click="homeDashboardStore.onFarmFieldClicked(homeDashboardStore.selectedFarmFieldsLevel1[0], true)"
                    :ref="(el) => savePolygonRef(farmBlock.farmBlockId, el)" />
            </template>

            <!-- Farm Block chips for blocks in selected farm field (when in single select mode) -->
            <l-marker
                v-for="(farmBlock, index) in selectedFarmFieldBlocks"
                :key="`marker-${index}`"
                :lat-lng="calculateCentroid(farmBlock.farmBlockPolygon.coordinates)"
                :pane="HomeDashboardMapPane.FarmBlockMarker">
                <l-icon>
                    <div style="width: max-content; position: absolute; transform: translate(-50%, -50%)">
                        <FarmBlockChip
                            :farm-block-code="farmBlock.farmBlockCode"
                            :cropCode="farmBlock.cropCode"
                            :crop-variety="farmBlock.cropVariety?.cropVarietyName" />
                    </div>
                </l-icon>
            </l-marker>

            <!-- AB Line of selected farm field -->
            <l-polyline
                v-if="
                    !homeDashboardStore.isMultiSelectFarmFieldsMode &&
                    homeDashboardStore.selectedFarmFieldsLevel1.length > 0 &&
                    homeDashboardStore.selectedFarmFieldsLevel1[0].abLine != null
                "
                :lat-lngs="
                    convertLineStringCoordinates(homeDashboardStore.selectedFarmFieldsLevel1[0].abLine.coordinates)
                "
                :color="getSwatchValue(SwatchCode.SwatchRED)"
                :weight="2"
                dash-array="5"
                :pane="HomeDashboardMapPane.AbLine">
            </l-polyline>

            <!-- Observations Markers -->
            <div v-if="homeDashboardStore.isContentVisibleOnMap(HomeDashboardMapSettingCode.Observations)">
                <div v-for="(observation, index) in observationsWithLocation" :key="index">
                    <l-marker
                        :lat-lng="[
                            observation.observationLocation.coordinates[1],
                            observation.observationLocation.coordinates[0],
                        ]"
                        :icon="createObservationIcon(observation)"
                        @mouseover="homeDashboardStore.onObservationMouseOver(observation.observationId)"
                        @mouseout="homeDashboardStore.onObservationMouseOut"
                        @click="homeDashboardStore.onObservationClicked(observation)"
                        :pane="HomeDashboardMapPane.ObservationMarker">
                    </l-marker>

                    <!-- Selected Observation Circle -->
                    <l-circle-marker
                        v-if="homeDashboardStore.isObservationMousedOver(observation)"
                        :lat-lng="[
                            observation.observationLocation.coordinates[1],
                            observation.observationLocation.coordinates[0],
                        ]"
                        :radius="50"
                        :color="getSwatchValue(SwatchCode.SwatchWHT)"
                        :fill-color="getSwatchValue(SwatchCode.SwatchWHT)"
                        :fill-opacity="0.5"
                        :weight="1"
                        :pane="HomeDashboardMapPane.ObservationCircleMarker">
                    </l-circle-marker>
                </div>

                <!-- Selected Observation Circle -->
                <l-circle-marker
                    v-if="selectedObservationCenterPoint"
                    :lat-lng="selectedObservationCenterPoint"
                    :radius="50"
                    :color="getSwatchValue(SwatchCode.SwatchA6)"
                    :fill-color="getSwatchValue(SwatchCode.SwatchA6)"
                    :fill-opacity="0.5"
                    :weight="1"
                    :pane="HomeDashboardMapPane.ObservationCircleMarker">
                </l-circle-marker>
            </div>
        </l-map>
    </div>
    <div v-show="!homeDashboardStore.isLoading && !homeDashboardStore.isMapLoading" class="top-right-content">
        <HomeDashboardMapSettings />

        <TaskMetricPanel
            class="task-metric-panel"
            v-show="
                !homeDashboardStore.isLoading &&
                !homeDashboardStore.isMapLoading &&
                homeDashboardStore.isContentVisibleOnMap(HomeDashboardMapSettingCode.Metrics)
            "
            v-if="userStore.hasRoleLevel(RoleCode.Tasks, 1)" />
    </div>
</template>

<script setup lang="ts">
import {FarmFieldSearchResultDto} from '@/models/data-transfer-objects/search/farm-field-search/FarmFieldSearchResultDto';
import {ObservationSearchResultDto} from '@/models/data-transfer-objects/search/observation-search/ObservationSearchResultDto';
import {computed, inject, nextTick, onMounted, ref, watch} from 'vue';
import {
    LMap,
    LTileLayer,
    LPolygon,
    LPolyline,
    LMarker,
    LIcon,
    LCircleMarker,
    LControlAttribution,
} from '@vue-leaflet/vue-leaflet';
import 'leaflet/dist/leaflet.css';
import type {Icon, LatLngTuple, Map as LeafletMap, PointExpression} from 'leaflet';
import '@/assets/scss/home-dashboard/home-dashboard-map.scss';
import L from 'leaflet';
import {getObservationTypeByCode} from '@/services/observation-types-service';
import {useSwatches} from '@/composables/data-source/swatches';
const {getSwatches, getSwatchValue} = useSwatches();
import * as turf from '@turf/turf';
import FarmBlockChip from '@/components/FarmBlockChip.vue';
import TaskMetricPanel from '@/components/TaskMetricPanel.vue';
import {RoleCode} from '@/enums/role-code';
import {useUserStore} from '@/stores/user-store';
import {useConfigStore} from '@/stores/config-store';
import {useHomeDashboardStore} from '@/stores/home-dashboard-store';
import {SwatchCode} from '@/enums/swatch-code';
import {FarmBlockDto} from '@/models/data-transfer-objects/search/farm-field-search/FarmBlockDto';
import {GeoJsonPolygon} from '@/models/generic/GeoJsonPolygon';
import {Position} from 'geojson';
import HomeDashboardMapSettings from '@/views/home-dashboard/HomeDashboardMapSettings.vue';
import ApiService from '@/services/api-service';
import {GetHomeDashboardMapSettingsResponse} from '@/models/api/responses/users/GetHomeDashboardMapSettingsResponse';
import HomeDashboardMapFarmFieldChip from '@/components/other-chips/HomeDashboardMapFarmFieldChip.vue';
import {HomeDashboardMapPane} from '@/enums/home-dashboard/home-dashboard-map-pane';
import {HomeDashboardMapSettingCode} from '@/enums/home-dashboard/home-dashboard-map-setting-code';
import {SearchFarmSitesResponse} from '@/models/api/responses/search/SearchFarmSitesResponse';

//Stores
const userStore = useUserStore();
const configStore = useConfigStore();
const homeDashboardStore = useHomeDashboardStore();

// Services
const apiService = inject('apiService') as ApiService;

// Map
const homeDashboardMap = ref();
const center = ref<PointExpression>([configStore.defaultMapLatitude, configStore.defaultMapLongitude]);
const aerialImageryUrl = process.env.VUE_APP_AERIAL_IMAGERY_TILE_LAYER_URL!;
const backgroundImageryUrl = process.env.VUE_APP_BACKGROUND_MAP_TILE_LAYER_URL!;
const mapOptions = ref({
    attributionControl: false,
    zoomDelta: 0.25,
    zoomSnap: 0.25,
    wheelPxPerZoomLevel: 240,
});
const openStreetMapsAttribution = '&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> Contributors';
let farmSiteDefaultCoordinates: number[] | null | undefined = null;

// Get border weight of the specified farm field.
const getFarmFieldPolygonBorderWeight = (farmField: FarmFieldSearchResultDto): number => {
    if (
        (!homeDashboardStore.isMultiSelectFarmFieldsMode && homeDashboardStore.isFarmFieldMousedOver(farmField)) ||
        (homeDashboardStore.isMultiSelectFarmFieldsMode && homeDashboardStore.isFarmFieldSelected(farmField))
    ) {
        // If in single select mode and the field is moused over
        // OR if in multi select mode and the field is selected
        return 3;
    } else if (homeDashboardStore.isFarmFieldSelected(farmField)) {
        // If in single select mode and the field is selected
        return 2;
    } else {
        return 1;
    }
};

// Get border opacity of the specified farm field.
const getFarmFieldPolygonBorderOpacity = (farmField: FarmFieldSearchResultDto): number => {
    if (homeDashboardStore.isFarmFieldSelected(farmField) || homeDashboardStore.isFarmFieldMousedOver(farmField)) {
        return 1;
    } else {
        return 0.5;
    }
};

// Get border color of the specified farm field.
const getFarmFieldPolygonBorderColor = (farmField: FarmFieldSearchResultDto): string => {
    if (
        homeDashboardStore.isFarmFieldMousedOver(farmField) ||
        (homeDashboardStore.isMultiSelectFarmFieldsMode && homeDashboardStore.isFarmFieldSelected(farmField))
    ) {
        return getSwatchValue(SwatchCode.SwatchWHT) || '#FFFFFF';
    } else {
        return farmField.farmFieldColorHexCode;
    }
};

// Get fill opacity of the specified farm field.
const getFarmFieldPolygonFillOpacity = (farmField: FarmFieldSearchResultDto): number => {
    if (
        // If another farm field is selected
        homeDashboardStore.isOtherFarmFieldSelected(farmField) ||
        // Or if this farm field is selected and it contains blocks with polygons (in this scenario a lower opacity
        // is used because the farm block polygons will make it stand out more)
        (!homeDashboardStore.isMultiSelectFarmFieldsMode &&
            homeDashboardStore.isFarmFieldSelected(farmField) &&
            farmField.farmBlocks.some((fb) => fb.farmBlockPolygon != null))
    ) {
        // Reduce opacity
        return 0.25;
    } else {
        // Default opacity
        return 0.5;
    }
};

// Get border weight of the specified farm block.
const getFarmBlockPolygonBorderWeight = (farmBlock: FarmBlockDto): number => {
    if (homeDashboardStore.isFarmBlockMousedOver(farmBlock)) {
        return 3;
    } else {
        return 1;
    }
};

// Get border color of the specified farm block.
const getFarmBlockPolygonBorderColor = (farmBlock: FarmBlockDto): string => {
    if (homeDashboardStore.isFarmBlockMousedOver(farmBlock)) {
        return getSwatchValue(SwatchCode.SwatchWHT) || '#FFFFFF';
    } else {
        return homeDashboardStore.selectedFarmFieldsLevel1[0].farmFieldColorHexCode;
    }
};

// Calculate centroid position. Used for determining the location of farm block chips.
const calculateCentroid = (coordinates: number[][][] | number[][][][]): [number, number] => {
    let polygon;

    if (isPolygon(coordinates)) {
        // Convert number[][][] to Position[][]
        const positions: Position[][] = coordinates.map((ring: number[][]) =>
            ring.map((coord: number[]) => [coord[0], coord[1]] as Position),
        );
        polygon = turf.polygon(positions); // Pass as Position[][]
    } else {
        // Convert number[][][][] to Position[][][]
        const positions: Position[][][] = coordinates.map((singlePolygon: number[][][]) =>
            singlePolygon.map((ring: number[][]) => ring.map((coord: number[]) => [coord[0], coord[1]] as Position)),
        );
        polygon = turf.multiPolygon(positions); // Pass as Position[][][]
    }

    const centroid = turf.centroid(polygon);
    return [centroid.geometry.coordinates[1], centroid.geometry.coordinates[0]]; // Leaflet uses lat, lng order
};

// Helper function to check if the input is a Polygon (number[][][])
const isPolygon = (coordinates: number[][][] | number[][][][]): coordinates is number[][][] => {
    return Array.isArray(coordinates[0][0]) && !Array.isArray(coordinates[0][0][0]);
};

// Farm fields with a polygon
const farmFieldsWithPolygon = computed<FarmFieldSearchResultDto[]>(() => {
    return homeDashboardStore.farmFields.filter((farmField) => farmField.farmFieldPolygon != null);
});

// Observations with a location
const observationsWithLocation = computed<ObservationSearchResultDto[]>(() => {
    return homeDashboardStore.observations.filter((observation) => observation.observationLocation != null);
});

// Farm blocks for the selected farm field
const selectedFarmFieldBlocks = computed<FarmBlockDto[]>(() => {
    if (!homeDashboardStore.isMultiSelectFarmFieldsMode && homeDashboardStore.selectedFarmFieldsLevel1.length > 0) {
        // If in single select mode and a single farm field has been selected, return its blocks
        return homeDashboardStore.selectedFarmFieldsLevel1[0].farmBlocks.filter(
            (farmBlock) => farmBlock.farmBlockPolygon != null,
        );
    } else {
        // Return empty array
        return [];
    }
});

// Center point of the selected observation
const selectedObservationCenterPoint = computed<LatLngTuple | null>(() => {
    if (
        homeDashboardStore.selectedObservationLevel1 &&
        homeDashboardStore.selectedObservationLevel1.observationLocation
    ) {
        return [
            homeDashboardStore.selectedObservationLevel1.observationLocation.coordinates[1],
            homeDashboardStore.selectedObservationLevel1.observationLocation.coordinates[0],
        ];
    } else {
        return null;
    }
});

// Convert coordinates from the API response into a set of coordinates suitable for the polygons
const convertCoordinates = (polygon: GeoJsonPolygon): LatLngTuple[][] => {
    if (polygon.type === 'Polygon') {
        // Convert coordinates for a single Polygon
        return [polygon.coordinates[0].map((coord: number[]) => [coord[1], coord[0]] as LatLngTuple)];
    } else if (polygon.type === 'MultiPolygon') {
        // Convert coordinates for a MultiPolygon
        // Eventually we should change singlePolygon's type from "any" to something else (possibly number[][])
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return polygon.coordinates.map((singlePolygon: any) =>
            singlePolygon[0].map((coord: number[]) => [coord[1], coord[0]] as LatLngTuple),
        );
    } else {
        throw new Error(`Unsupported GeoJSON type: ${polygon.type}`);
    }
};

// Convert LineString coordinates from the API response into a set of coordinates suitable for the polyline
const convertLineStringCoordinates = (coordinates: number[][]): LatLngTuple[] => {
    return coordinates.map((coord: number[]) => [coord[1], coord[0]]);
};

// Function to create observation icon
function createObservationIcon(observation: ObservationSearchResultDto): Icon {
    const observationType = getObservationTypeByCode(observation.observationTypeCode)!;
    const observationTypeIcon = observationType.icon.replace('<svg', '<svg class="custom-icon"');
    const observationTypeColor = observationType.color;

    return L.divIcon({
        className: 'svgMapMarkerContainer',
        // TODO: Use SVG instead of HTML. Ensure size and anchor are correct (taking into account the arrow as well).
        html: `
            <div class="svgGlyphLossOfComms svgLight" style="height: 80%; width: 80%; z-index: 4;" title="${observation.observationTitle}">
                ${observationTypeIcon}
            </div>
            <div class="svgMapMarker" style="background-color: ${observationTypeColor};" title="${observation.observationTitle}"></div>
            <div class="svgMapMarkerTip" style="background-color: ${observationTypeColor};" title="${observation.observationTitle}"></div>`,
        iconSize: [40, 40],
        iconAnchor: [20, 40.3],
    }) as unknown as Icon;
}

// Invalidate size of the map to account for situations where the map is resized after being loaded. This will
// ensure that there are no display errors.
const invalidateMapSize = () => {
    if (homeDashboardMap.value?.leafletObject) {
        const mapInstance: LeafletMap = homeDashboardMap.value.leafletObject;
        mapInstance.invalidateSize();
    }
};

// Invalidate size of the map to account for situations where the map is resized after being loaded. This will
// ensure that there are no display errors.
const refreshMap = async () => {
    // Redraw map and fit bounds to the data shown on the map
    await nextTick(async () => {
        invalidateMapSize();
        await fitBounds();
    });
};

// Focus map on the polygons that are displayed
const fitBounds = async () => {
    if (homeDashboardMap.value?.leafletObject) {
        let allLatLngs: LatLngTuple[] = [];

        if (farmFieldsWithPolygon.value.length > 0) {
            // Check to see if a specific farm field should be focused on
            let farmFieldIds: number[] = [];
            let focusOnEverything = false;

            if (homeDashboardStore.selectedWorkTaskLevel1) {
                // If a Level 1 work task is selected, focus on its farm field
                farmFieldIds.push(homeDashboardStore.selectedWorkTaskLevel1?.farmFieldId);
            } else if (
                homeDashboardStore.selectedObservationLevel1 &&
                homeDashboardStore.selectedObservationLevel1?.farmFieldId
            ) {
                // If a Level 1 observation is selected, focus on its farm field (if it has one)
                farmFieldIds.push(homeDashboardStore.selectedObservationLevel1?.farmFieldId);
            } else if (homeDashboardStore.selectedFarmFieldsLevel1.length > 0) {
                // If one or more Level 1 farm fields have been selected, focus on all of them
                farmFieldIds = homeDashboardStore.selectedFarmFieldsLevel1.map((field) => field.farmFieldId);
            } else if (!homeDashboardStore.selectedObservationLevel1) {
                // If nothing has been selected, focus on all farm fields
                focusOnEverything = true;
            }

            // Collect all latLngs from the farm field polygons
            farmFieldsWithPolygon.value
                .filter(
                    (field) =>
                        // Everything should be focused on
                        focusOnEverything ||
                        // Or this is one of the fields that should be focused on
                        farmFieldIds.includes(field.farmFieldId),
                )
                .forEach((farmField: FarmFieldSearchResultDto) => {
                    const polygon = farmField.farmFieldPolygon;
                    const coords = convertCoordinates(polygon);
                    // Eventually we should remove the assertion of coords to "any"
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    allLatLngs = allLatLngs.concat(coords as any);
                });

            // If observations should be shown on the map
            if (showObservations.value) {
                // Collect all latlngs from the observation markers
                observationsWithLocation.value
                    .filter(
                        (observation) =>
                            // Everything should be focused on
                            focusOnEverything ||
                            // Or the observation is associated with one of the fields that should be focused on
                            (observation.farmFieldId != null && farmFieldIds.includes(observation.farmFieldId)) ||
                            // Or the observation has been selected
                            observation.observationId == homeDashboardStore.selectedObservationLevel1?.observationId,
                    )
                    .forEach((observation: ObservationSearchResultDto) => {
                        const coords: LatLngTuple = [
                            observation.observationLocation.coordinates[1],
                            observation.observationLocation.coordinates[0],
                        ];
                        allLatLngs = allLatLngs.concat([coords]);
                    });
            }
        } else {
            // If observations should be shown on the map
            if (showObservations.value) {
                // Collect all latlngs from the observation markers
                observationsWithLocation.value
                    .filter(
                        (observation) =>
                            // Everything should be focused on
                            !homeDashboardStore.selectedObservationLevel1 ||
                            // Or the observation has been selected
                            observation.observationId == homeDashboardStore.selectedObservationLevel1?.observationId,
                    )
                    .forEach((observation: ObservationSearchResultDto) => {
                        const coords: LatLngTuple = [
                            observation.observationLocation.coordinates[1],
                            observation.observationLocation.coordinates[0],
                        ];
                        allLatLngs = allLatLngs.concat([coords]);
                    });
            }

            if (
                !(showObservations.value && observationsWithLocation.value && observationsWithLocation.value.length > 0)
            ) {
                // Get default farm site coordinates if they have not been retrieved yet
                if (!farmSiteDefaultCoordinates) {
                    farmSiteDefaultCoordinates = await getFarmSiteDefaultCoordinates();
                }

                if (farmSiteDefaultCoordinates) {
                    // Use default farm site coordinates if they exist
                    allLatLngs = [[farmSiteDefaultCoordinates[1], farmSiteDefaultCoordinates[0]]];
                } else {
                    // Use system default coordinates
                    allLatLngs = [[configStore.defaultMapLatitude, configStore.defaultMapLongitude]];
                }
            }
        }

        // Compute the bounds
        const bounds = L.latLngBounds(allLatLngs);

        // Fit the bounds on the map
        if (bounds.isValid()) {
            const mapInstance: LeafletMap = homeDashboardMap.value.leafletObject;
            mapInstance.fitBounds(bounds);
        }
    }
};

/**
 * Get the default coordinates for the selected site.
 */
const getFarmSiteDefaultCoordinates = async () => {
    const farmSiteId = userStore.user?.farmSiteId;

    if (farmSiteId) {
        // Get farm site details
        const searchResults = (await apiService.post('search/farm-sites-by-id', {
            farmSiteId: farmSiteId,
        })) as SearchFarmSitesResponse;

        if (searchResults.farmSites.length === 1) {
            const farmSite = searchResults.farmSites[0];
            return farmSite.farmSiteCentroid?.coordinates;
        }
    }

    return null;
};

// Store polygon references
const polygonRefs = ref<{[key: number]: L.Polygon[]}>({});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const savePolygonRef = (farmBlockId: number, el: any) => {
    if (el && el.leafletObject) {
        if (!polygonRefs.value[farmBlockId]) {
            polygonRefs.value[farmBlockId] = [];
        }
        polygonRefs.value[farmBlockId].push(el.leafletObject);
    }
};

/**
 * Flag to indicate if Field Names should be shown on the map.
 */
const showFarmFieldName = computed<boolean>(() => {
    return homeDashboardStore.isContentVisibleOnMap(HomeDashboardMapSettingCode.FieldNames);
});

/**
 * Flag to indicate if Crop Varieties should be shown on the map.
 */
const showCropVarieties = (farmField: FarmFieldSearchResultDto) => {
    return (
        homeDashboardStore.isContentVisibleOnMap(HomeDashboardMapSettingCode.CropVariety) &&
        farmField.cropVarieties.length > 0
    );
};

/**
 * Flag to indicate if Crop Year should be shown on the map.
 */
const showCropYear = (farmField: FarmFieldSearchResultDto) => {
    return homeDashboardStore.isContentVisibleOnMap(HomeDashboardMapSettingCode.CropYear) && farmField.cropYear != null;
};

/**
 * Flag to indicate if Next Task should be shown on the map.
 */
const showNextWorkTask = (farmField: FarmFieldSearchResultDto) => {
    return (
        homeDashboardStore.isContentVisibleOnMap(HomeDashboardMapSettingCode.NextTaskIcon) &&
        farmField.nextWorkTask != null
    );
};

/**
 * Flag to indicate if the Farm Field Chip should be shown on the map.
 */
const showFarmFieldChip = (farmField: FarmFieldSearchResultDto) => {
    return (
        showFarmFieldName.value ||
        showCropVarieties(farmField) ||
        showCropYear(farmField) ||
        showNextWorkTask(farmField)
    );
};

/**
 * Flag to indicate if Observations should be shown on the map.
 */
const showObservations = computed<boolean>(() => {
    return homeDashboardStore.isContentVisibleOnMap(HomeDashboardMapSettingCode.Observations);
});

// Initial setup for the map
const initialMapSetup = () => {
    nextTick(async () => {
        // Create custom panes
        const mapInstance = homeDashboardMap.value?.leafletObject;
        if (mapInstance) {
            // Panes are created in the order of priority they have when being displayed on
            // the map (panes created first will be lower priority and have a lower z-index)
            mapInstance.createPane(HomeDashboardMapPane.FarmFieldPolygon);
            mapInstance.createPane(HomeDashboardMapPane.FarmFieldMarker);
            mapInstance.createPane(HomeDashboardMapPane.FarmBlockPolygon);
            mapInstance.createPane(HomeDashboardMapPane.FarmBlockMarker);
            mapInstance.createPane(HomeDashboardMapPane.ObservationCircleMarker);
            mapInstance.createPane(HomeDashboardMapPane.ObservationMarker);
            mapInstance.createPane(HomeDashboardMapPane.AbLine);
        }

        // Get swatches
        await getSwatches();

        // Refresh map
        await refreshMap();
    });
};

// Mouse out event for the farm field polygons
const mouseOutFarmFieldPolygon = () => {
    homeDashboardStore.onFarmFieldMouseOut();

    // Also run the Observation mouse out event to help fix the issue where the mouse out event on the observation icon
    // sometimes doesn't get triggered, causing the white circle to stay visible.
    homeDashboardStore.onObservationMouseOut();
};

// Initialize map
onMounted(async () => {
    // Start loading
    homeDashboardStore.isMapLoading = true;

    // Add event listener for window resizing
    window.addEventListener('resize', invalidateMapSize);

    // Get map settings from database
    const savedSettings = (
        (await apiService.get('users/home-dashboard-map-settings')) as GetHomeDashboardMapSettingsResponse
    ).settings;

    if (savedSettings) {
        // For each saved setting
        for (const savedSetting of savedSettings) {
            // Find the associated store setting
            var settingInStore = homeDashboardStore.mapSettings.find(
                (ms) => ms.settingCode == savedSetting.settingCode,
            );

            if (settingInStore) {
                // Set saved values
                if (settingInStore.hasOpacity) {
                    settingInStore.opacity = savedSetting.opacity;
                } else {
                    settingInStore.isVisible = savedSetting.isVisible;
                }
            }
        }
    }

    // End loading
    homeDashboardStore.isMapLoading = false;
});

// Update map with new data whenever props change
watch(
    [
        () => homeDashboardStore.isLoading,
        () => homeDashboardStore.farmFields,
        () => homeDashboardStore.observations,
        () => homeDashboardStore.workTasks,
        // isMultiSelectFarmFieldsMode is included in the watch because it can affect whether the Level 2 tab is shown
        () => homeDashboardStore.isMultiSelectFarmFieldsMode,
        // selectedWorkTaskLevel2 is included in the watch because it can affect whether the Level 3 tab is shown
        () => homeDashboardStore.selectedWorkTaskLevel2,
        // Focus on the selected farm fields, or all farm fields if none are selected
        () => homeDashboardStore.selectedFarmFieldsLevel1,
        // When a task is selected, focus on it's field in the map
        () => homeDashboardStore.selectedWorkTaskLevel1,
        // When an observation is selected, focus on it's field in the map (if it is associated with a field)
        () => homeDashboardStore.selectedObservationLevel1,
        // When observations are shown/hidden, refresh the map bounds. This is done so that the map does not zoom out unnecessarily to
        // fit observations if observations are not actually being shown.
        () => showObservations,
    ],
    () => {
        refreshMap();
    },
    {deep: true},
);

// Reset default site cooridnates when the site changes
watch(
    () => userStore.user?.farmSiteId,
    () => {
        farmSiteDefaultCoordinates = null;
    },
);

/**
 * When a farm block is moused over, bring all of its polygons (it will usually just be 1 polygon though) to the front so
 * that its border is not partially hidden by any other blocks.
 */
watch(
    () => homeDashboardStore.mousedOverFarmBlock,
    () => {
        if (homeDashboardStore.mousedOverFarmBlock) {
            const polygons = polygonRefs.value[homeDashboardStore.mousedOverFarmBlock.farmBlockId];
            if (polygons) {
                polygons.forEach((polygon) => {
                    polygon.bringToFront();
                });
            }
        }
    },
    {deep: true},
);
</script>
<style lang="scss" scoped>
.home-dashboard-map {
    position: relative;
    height: 100%;
    width: 100%;
}

.top-right-content {
    position: fixed;
    right: 1rem;
    top: 6rem;
    z-index: 400;
}

.task-metric-panel {
    position: fixed;
    right: 1rem;
    top: 8.7rem;
    z-index: 400;
    max-height: calc(100vh - 6rem - 1rem);
    overflow-y: auto;
}
</style>
