import {ObservationStatusCode} from '@/enums/observaton-status-code';

export class SearchObservationsRequest {
    isQuickSearch: boolean = false;
    quickSearchText: string | null = null;
    observationId: number | null = null;
    observationIdList: number[] | null = null;
    farmSiteId: number | null = null;
    farmSiteIdList: number[] | null = null;
    farmFieldName: string | null = null;
    farmFieldIdList: number[] | null = null;
    observationName: string | null = null;
    observationTypeCodeList: string[] | null = null;
    observationStatusCodeList: ObservationStatusCode[] | null = null;
    dateRaisedFrom: string | null = null;
    dateRaisedTo: string | null = null;
    dateLastActivityFrom: string | null = null;
    dateLastActivityTo: string | null = null;
    dateConcludedFrom: string | null = null;
    dateConcludedTo: string | null = null;
    includeObservationActions = false;
    includeArtifacts = false;
    userIdList: number[] | null = null;
    sortOrder: string | null = null;
    isExportCSV: boolean = false;

    constructor(data?: Partial<SearchObservationsRequest>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
