<template>
    <div>
        <v-dialog v-model="isVisible">
            <v-card>
                <v-form ref="form" class="pa-4" style="overflow-y: scroll; height: 100%" @keyup.enter="search">
                    <observation-search-parameters v-model:searchParameters="localSearchParametersObservation" />
                </v-form>
                <v-card-actions class="swatchG9BG">
                    <v-btn @click="isVisible = false">
                        <template v-slot:prepend>
                            <FontAwesomeIcon :icon="['fal', 'arrow-rotate-left']" size="xl" />
                        </template>
                        {{ $t('Cancel') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="search" :disabled="isLoading">
                        <template v-slot:prepend>
                            <FontAwesomeIcon :icon="['fal', 'search']" size="xl" />
                        </template>
                        {{ $t('Search') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <LoadingSymbol v-if="isLoading" />

        <observation-search-results
            v-else
            @switch-to-search="openSearchModal"
            @export-observation-list="exportObservationList"
            @view-observation-list-on-home="viewObservationListOnHome"
            :is-filter-applied="isFilterApplied"
            :results-list="searchResultsObservation"
            :open-observation-default="defaultTab" />
        <v-snackbar v-model="snackbar.show" timeout="4000">
            {{ snackbar.text }}
        </v-snackbar>
    </div>
</template>

<script setup lang="ts">
import {computed, ref, inject, onMounted, watch} from 'vue';
import ApiService from '@/services/api-service.js';
import {SearchObservationsRequest} from '@/models/api/requests/search/SearchObservationsRequest';
import {ObservationSearchResultDto} from '@/models/data-transfer-objects/search/observation-search/ObservationSearchResultDto';
import {SearchObservationsResponse} from '@/models/api/responses/search/SearchObservationsResponse';
import ObservationSearchParameters from '@/views/search/ObservationSearchParameters.vue';
import ObservationSearchResults from '@/views/search/ObservationSearchResults.vue';
import {useFarmStore} from '@/stores/farm-store';
const farmStore = useFarmStore();
import {ObservationStatusCode} from '@/enums/observaton-status-code';
import i18n from '@/i18n';
import {formatTime} from '@/helpers/TimeFormatHelper';
import {useHomeDashboardStore} from '@/stores/home-dashboard-store';
const homeDashboardStore = useHomeDashboardStore();
import {useAdvancedSearchModalStore} from '@/stores/modals/advanced-search-modal-store';
const advancedSearchModalStore = useAdvancedSearchModalStore();
import {NavigatorPanelTab} from '@/enums/home-dashboard/navigator-panel-tab';
import router from '@/router';
import {useDeleteUserModalStore} from '@/stores/modals/delete-user-modal-store';
const deleteUserModalStore = useDeleteUserModalStore();
import {useRoute} from 'vue-router';
const route = useRoute();

// Services
const apiService = inject('apiService') as ApiService;

//Local variables
const isVisible = ref<boolean>(false);
const isLoading = ref<boolean>(false);
const searchParametersObservation = ref(new SearchObservationsRequest());
const localSearchParametersObservation = ref(new SearchObservationsRequest());
const searchResultsObservation = ref<ObservationSearchResultDto[]>([]);
const defaultTab = ref<boolean>(true);

const snackbar = ref({
    show: false,
    text: '',
});

const isFilterApplied = computed(
    () =>
        !searchParametersObservation.value.isQuickSearch &&
        (searchParametersObservation.value.observationName === null ||
            searchParametersObservation.value.observationName === '') &&
        (searchParametersObservation.value.userIdList === null ||
            searchParametersObservation.value.userIdList.length === 0) &&
        searchParametersObservation.value.observationStatusCodeList !== null &&
        ((route.params.isClosed != 'true' &&
            searchParametersObservation.value.observationStatusCodeList.length === 3 &&
            searchParametersObservation.value.observationStatusCodeList.includes(ObservationStatusCode.Open) &&
            searchParametersObservation.value.observationStatusCodeList.includes(ObservationStatusCode.Acknowledged) &&
            searchParametersObservation.value.observationStatusCodeList.includes(ObservationStatusCode.InProgress)) ||
            (route.params.isClosed == 'true' &&
                searchParametersObservation.value.observationStatusCodeList.length === 3 &&
                searchParametersObservation.value.observationStatusCodeList.includes(ObservationStatusCode.Resolved) &&
                searchParametersObservation.value.observationStatusCodeList.includes(ObservationStatusCode.Cancelled) &&
                searchParametersObservation.value.observationStatusCodeList.includes(
                    ObservationStatusCode.Archived,
                ))) &&
        (searchParametersObservation.value.farmFieldIdList === null ||
            searchParametersObservation.value.farmFieldIdList.length === 0) &&
        (searchParametersObservation.value.observationTypeCodeList === null ||
            searchParametersObservation.value.observationTypeCodeList.length === 0) &&
        searchParametersObservation.value.farmSiteIdList !== null &&
        searchParametersObservation.value.farmSiteIdList.length === 1 &&
        searchParametersObservation.value.farmSiteIdList[0] === farmStore.selectedSiteId &&
        (searchParametersObservation.value.dateRaisedFrom === null ||
            searchParametersObservation.value.dateRaisedFrom === '') &&
        (searchParametersObservation.value.dateRaisedTo === null ||
            searchParametersObservation.value.dateRaisedTo === '') &&
        (searchParametersObservation.value.dateLastActivityFrom === null ||
            searchParametersObservation.value.dateLastActivityFrom === '') &&
        (searchParametersObservation.value.dateLastActivityTo === null ||
            searchParametersObservation.value.dateLastActivityTo === '') &&
        (searchParametersObservation.value.dateConcludedFrom === null ||
            searchParametersObservation.value.dateConcludedFrom === '') &&
        (searchParametersObservation.value.dateConcludedTo === null ||
            searchParametersObservation.value.dateConcludedTo === ''),
);

/**
 * Open search modal
 */
async function openSearchModal() {
    isVisible.value = true;
}

/**
 * Save the modal.
 */
const search = async () => {
    isLoading.value = true;

    searchParametersObservation.value = Object.assign(
        new SearchObservationsRequest(),
        localSearchParametersObservation.value,
    );
    const response: SearchObservationsResponse = await apiService.post(
        'search/observations',
        searchParametersObservation.value,
    );
    searchResultsObservation.value = response.observations;
    if (
        searchParametersObservation.value.observationStatusCodeList &&
        searchParametersObservation.value.observationStatusCodeList.length > 0
    ) {
        if (
            searchParametersObservation.value.observationStatusCodeList.includes(ObservationStatusCode.Open) ||
            searchParametersObservation.value.observationStatusCodeList.includes(ObservationStatusCode.InProgress) ||
            searchParametersObservation.value.observationStatusCodeList.includes(ObservationStatusCode.Acknowledged)
        )
            defaultTab.value = true;
        else defaultTab.value = false;
    }

    isLoading.value = false;
    isVisible.value = false;
};

//Export Observation List
const exportObservationList = async () => {
    try {
        const fileName = i18n.global.t('Observations') + '_' + formatTime(new Date(), 'yyyyMMddHHmm') + '.csv';
        await apiService.downloadFilePost(
            'search/observations/export',
            searchParametersObservation.value,
            null,
            fileName,
        );
    } catch (error) {
        // Show fail feedback to user
        snackbar.value.show = true;
        snackbar.value.text = i18n.global.t('ExportCSVError');
        isLoading.value = false;
    }
};

const loadObservationList = async () => {
    const isFilterFromHome = !!homeDashboardStore.observationListFilter;
    if (homeDashboardStore.observationListFilter) {
        localSearchParametersObservation.value = {...homeDashboardStore.observationListFilter};
        defaultTab.value = homeDashboardStore.isObservationOpenMode;
        homeDashboardStore.observationListFilter = null;
    } else {
        localSearchParametersObservation.value = new SearchObservationsRequest();
        localSearchParametersObservation.value.observationStatusCodeList =
            route.params.isClosed == 'true'
                ? [ObservationStatusCode.Resolved, ObservationStatusCode.Archived, ObservationStatusCode.Cancelled]
                : [ObservationStatusCode.Open, ObservationStatusCode.Acknowledged, ObservationStatusCode.InProgress];
    }

    if (farmStore.selectedSiteId) localSearchParametersObservation.value.farmSiteIdList = [farmStore.selectedSiteId];
    await search();

    //reset search parameter after searching
    if (isFilterFromHome) {
        localSearchParametersObservation.value = new SearchObservationsRequest();
        localSearchParametersObservation.value.observationStatusCodeList =
            route.params.isClosed == 'true'
                ? [ObservationStatusCode.Resolved, ObservationStatusCode.Archived, ObservationStatusCode.Cancelled]
                : [ObservationStatusCode.Open, ObservationStatusCode.Acknowledged, ObservationStatusCode.InProgress];
        if (farmStore.selectedSiteId)
            localSearchParametersObservation.value.farmSiteIdList = [farmStore.selectedSiteId];
    } else {
        localSearchParametersObservation.value = Object.assign(
            new SearchObservationsRequest(),
            searchParametersObservation.value,
        );
    }
};

onMounted(async () => {
    await loadObservationList();
});

const viewObservationListOnHome = (isOpenObs: boolean) => {
    homeDashboardStore.resetObservationSearchParameters(true);
    homeDashboardStore.navigatorPanelActiveTab = NavigatorPanelTab.Observations;
    homeDashboardStore.observations = searchResultsObservation.value;
    homeDashboardStore.isObservationOpenMode = isOpenObs;
    homeDashboardStore.observationPanelVersion += 1;

    advancedSearchModalStore.close();

    if (!homeDashboardStore.isHomeDashboardOpen) {
        homeDashboardStore.observationPreventInitialLoading = true;
        router.push({name: 'HomeDashboard'});
    }
};

watch([() => deleteUserModalStore.deletedCounter], async () => {}, {deep: true});

watch(
    () => route.params.isClosed,
    async () => await loadObservationList(),
);
</script>
