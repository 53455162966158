<template>
    <div v-if="!isLoading" style="display: flex; align-items: center">
        <!-- The due date timing that was selected -->
        <div class="swatchA1">
            <span v-if="workTask.dueDateTiming == WorkTaskDueDateTiming.DueAfterTask"> {{ $t('Due') }}: </span>
            <span v-else>
                {{ workTaskDueDateTimings.find((ddt) => ddt.value == workTask?.dueDateTiming)?.title }}:
            </span>
        </div>

        <!-- Calendar icon -->
        <WorkTaskDateIcon
            v-if="workTask.dueDateTiming != WorkTaskDueDateTiming.DueAfterTask"
            class="ml-6"
            :work-task="workTask" />

        <!-- Date when the task is due -->
        <div class="ml-2">
            <div v-if="workTask.dueDateTiming == WorkTaskDueDateTiming.DueOn" class="swatchA1">
                <!-- Due On date -->
                {{ formatDate(workTask.dueDateOn) }}
            </div>
            <div v-else-if="workTask.dueDateTiming == WorkTaskDueDateTiming.DueBy" class="swatchA1">
                <!-- Due By date -->
                {{ formatDate(workTask.dueDateLatest) }}
            </div>
            <div v-else-if="workTask.dueDateTiming == WorkTaskDueDateTiming.DueBetween" class="swatchA1">
                <!-- Due Between dates -->
                {{ formatDate(workTask.dueDateRange![0]) + ' - ' + formatDate(workTask.dueDateRange![1]) }}
            </div>
            <div v-else-if="workTask.dueDateTiming == WorkTaskDueDateTiming.DueInMonth" class="swatchA1">
                <!-- Due in Month -->
                {{ formatDate(workTask.dueDate, 'MMMM yyyy') }}
            </div>
            <div
                v-else-if="workTask.dueDateTiming == WorkTaskDueDateTiming.DueAfterTask"
                style="display: flex; align-items: center">
                <!-- Due after another task -->
                <!-- Number of days due after the task -->
                <div class="swatchA1 bold">
                    <span v-if="workTask.dueAfterWorkTaskWithinDays == 1">
                        {{ $t('WithinDaysAfterTaskSingular') }}
                    </span>
                    <span v-else>
                        {{
                            $t('WithinDaysAfterTaskPlural', {
                                numDays: workTask.dueAfterWorkTaskWithinDays,
                            })
                        }}
                    </span>
                </div>

                <!-- The type of the predecessor task -->
                <WorkTaskTypeChip
                    v-if="predecessorWorkTask"
                    class="ml-6"
                    size="default"
                    :workTaskTypeCode="predecessorWorkTask.workTaskTypeCode" />

                <!-- The date that the predecessor task is due -->
                <div class="ml-6">
                    <div v-if="predecessorWorkTask?.isCompleted">
                        {{ $t('Completed') }}:

                        <FontAwesomeIcon class="swatchGRN ml-4" :icon="['fa', 'calendar-check']" size="xl" />

                        <span v-if="predecessorWorkTask.completedDate" class="ml-2">
                            {{ formatTime(predecessorWorkTask.completedDate, 'd MMMM yyyy') }}
                        </span>
                    </div>
                    <div v-else-if="predecessorWorkTask?.isCancelled">
                        {{ $t('Cancelled') }}:

                        <FontAwesomeIcon class="swatchG5 ml-4" :icon="['fa', 'calendar-minus']" size="xl" />

                        <span v-if="predecessorWorkTask.cancelledDate" class="ml-2">
                            {{ formatTime(predecessorWorkTask.cancelledDate, 'd MMMM yyyy') }}
                        </span>
                    </div>
                    <div v-else>
                        {{ $t('Due') }}:

                        <FontAwesomeIcon class="swatchG3 ml-4" :icon="['fa', 'calendar-day']" size="xl" />

                        <span v-if="predecessorWorkTask?.dueDate" class="ml-2">
                            {{ formatTime(predecessorWorkTask.dueDate, 'd MMMM yyyy') }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {WorkTaskSearchResultDto} from '@/models/data-transfer-objects/search/work-task-search/WorkTaskSearchResultDto';
import {formatTime} from '@/helpers/TimeFormatHelper';
import {WorkTaskDueDateTiming} from '@/enums/work-task-due-date-timing';
import {useWorkTaskDueDateTimings} from '@/composables/data-source/work-task-due-date-timings';
import {onMounted, ref} from 'vue';
import {GetPredecessorWorkTasksResponse} from '@/models/api/responses/work-tasks/GetPredecessorWorkTasksResponse';
const {setWorkTaskDueDateTimings, workTaskDueDateTimings} = useWorkTaskDueDateTimings();
import WorkTaskDateIcon from '@/components/work-tasks/WorkTaskDateIcon.vue';

defineProps<{
    workTask: WorkTaskSearchResultDto;
    predecessorWorkTask: GetPredecessorWorkTasksResponse | null;
}>();

const isLoading = ref(true);

const formatDate = (date: Date | null, dateFormat: string = 'dd MMMM yyyy'): string | null => {
    if (!date) return null;
    return formatTime(date, dateFormat);
};

onMounted(async () => {
    isLoading.value = true;

    // Load dropdown data
    await setWorkTaskDueDateTimings();

    // End loading
    isLoading.value = false;
});
</script>
