class AsyncHelper {
    /**
     * Waits for a condition to become true within a specified timeout.
     * @param conditionFn A function that returns a boolean indicating if the condition is met.
     * @param timeout The maximum time (in milliseconds) to wait for the condition to become true. Default is 5000ms.
     * @param interval The time (in milliseconds) between condition checks. Default is 50ms.
     * @returns A Promise that resolves when the condition is met or rejects if the timeout is reached.
     * @throws An Error if the timeout is exceeded.
     */
    public static async waitForCondition(
        conditionFn: () => boolean,
        timeout: number = 5000,
        interval: number = 50,
    ): Promise<void> {
        const startTime = Date.now();

        // eslint-disable-next-line no-constant-condition
        while (true) {
            if (conditionFn()) {
                return; // Condition met
            }
            if (Date.now() - startTime > timeout) {
                throw new Error('Timeout waiting for condition');
            }
            await new Promise((resolve) => setTimeout(resolve, interval));
        }
    }
}

export {AsyncHelper};
export default AsyncHelper;
