import {createI18n} from 'vue-i18n';
import messagesEnAu from './locales/messages.en-AU.json';
import messagesIdId from './locales/messages.id-ID.json';
import entityDataEnAu from './locales/entity-data.en-AU.json';
import entityDataIdId from './locales/entity-data.id-ID.json';

const messages = {
    'en-AU': {...messagesEnAu, ...entityDataEnAu},
    'id-ID': {...messagesIdId, ...entityDataIdId},
};

const i18n = createI18n({
    locale: 'en-AU',
    fallbackLocale: 'en-AU',
    messages,
    numberFormats: {
        'en-AU': {
            decimal: {
                style: 'decimal',
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
            },
            decimal1: {
                // for 10 < numbers < 100
                style: 'decimal',
                minimumFractionDigits: 0,
                maximumFractionDigits: 1,
            },
            decimal0: {
                // for numbers >= 100
                style: 'decimal',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            },
            decimal4: {
                // for file sizes
                style: 'decimal',
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
            },
            currency: {
                style: 'currency',
                currency: 'AUD',
            },
        },
        'id-ID': {
            decimal: {
                style: 'decimal',
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
            },
            decimal1: {
                style: 'decimal',
                minimumFractionDigits: 0,
                maximumFractionDigits: 1,
            },
            decimal0: {
                style: 'decimal',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            },
            decimal4: {
                // for file sizes
                style: 'decimal',
                minimumFractionDigits: 0,
                maximumFractionDigits: 4,
            },
            currency: {
                style: 'currency',
                currency: 'IDR',
            },
        },
    },
});

export default i18n;
