<template>
    <LoadingSymbol v-if="isLoading" />

    <v-form ref="form" v-model="valid" v-else>
        <table style="width: 100%" class="tblForm">
            <tr>
                <td colspan="4" class="subHeader">{{ $t('Particulars') }}</td>
            </tr>
            <tr>
                <td class="tableCell padding">
                    <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                        <span>{{ $t('DateRange') }}</span>
                        <HelpIcon :help-text="$t('ActivityReport_DateRange_HelpText')" />
                    </div>
                </td>
                <td>
                    <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                        <VueDatePicker
                            v-model="dateConcluded"
                            range
                            format="dd/MM/yyyy"
                            :enable-time-picker="false"
                            text-input
                            :preset-dates="presetDates"
                            style="max-width: 400px"
                            :locale="i18n.global.locale" />
                    </div>
                    <!-- DatePicker Validation -->
                    <v-validation :rules="[dateRequiredRule]">
                        <template v-slot="{errorMessages, isValid}">
                            <div v-if="isValid.value != null">
                                <span v-for="(error, index) in errorMessages.value" :key="index" class="error-message">
                                    {{ error }}
                                </span>
                            </div>
                        </template>
                    </v-validation>
                </td>
                <td class="tableCell padding">
                    <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                        <span>{{ $t('User') }}</span>
                        <HelpIcon :help-text="$t('ActivityReport_User_HelpText')" />
                    </div>
                </td>
                <td>
                    <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                        <v-autocomplete
                            v-model="localSearchParameters.userIdList"
                            :items="users"
                            hide-details
                            multiple
                            clearable
                            chips
                            item-title="userFullName"
                            item-value="userId"
                            max-width="400px"></v-autocomplete>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="tableCell padding" style="width: 10%; min-width: 10rem">
                    <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                        <span>{{ $t('FarmSite(s)') }}</span>
                        <HelpIcon :help-text="$t('ActivityReport_FarmSites_HelpText')" />
                    </div>
                </td>
                <td style="width: 40%">
                    <v-select
                        v-model="localSearchParameters.farmSiteIdList"
                        hide-details
                        multiple
                        clearable
                        chips
                        :items="farmSites"
                        item-title="farmSiteAbbreviationAndName"
                        item-value="farmSiteId"
                        max-width="400px"></v-select>
                </td>
                <td class="tableCell padding" style="width: 10%; min-width: 10rem">
                    <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                        <span>{{ $t('Field') }}</span>
                        <HelpIcon :help-text="$t('ActivityReport_Field_HelpText')" />
                    </div>
                </td>
                <td style="width: 40%">
                    <v-autocomplete
                        v-model="localSearchParameters.farmFieldId"
                        hide-details
                        clearable
                        :items="farmFields"
                        item-title="farmFieldCodeAndName"
                        item-value="farmFieldId"
                        max-width="400px"
                        :menu-props="{maxWidth: '400'}" />
                </td>
            </tr>
            <tr>
                <td class="tableCell padding">
                    <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                        <span>{{ $t('ExportTo') }}</span>
                        <HelpIcon :help-text="$t('ObservationSearch_ExportResultsTo_HelpText')" />
                    </div>
                </td>
                <td>
                    <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                        <v-select
                            v-model="localSearchParameters.isExportCSV"
                            hide-details
                            :items="exportOptions"
                            item-title="title"
                            item-value="value"
                            max-width="400px"></v-select>
                    </div>
                </td>
            </tr>
        </table>
    </v-form>
</template>

<script setup lang="ts">
import {computed, defineAsyncComponent, onMounted, ref, watch} from 'vue';
import i18n from '@/i18n';
import {addDays, addMonths} from 'date-fns';
import {formatTime} from '@/helpers/TimeFormatHelper';
import {useFarmSites} from '@/composables/data-source/farm-sites';
const {getFarmSites, farmSites} = useFarmSites();
import {useFarmFields} from '@/composables/data-source/farm-fields';
const {getFarmFields, farmFields} = useFarmFields();
import {useUsers} from '@/composables/data-source/users';
import {ActivityReportForm} from '@/models/reports/ActivityReportForm';
const {getUsers, users} = useUsers();

const VueDatePicker = defineAsyncComponent(() => import('@vuepic/vue-datepicker'));
const props = defineProps<{
    searchParameters: ActivityReportForm;
}>();

const emit = defineEmits(['update:searchParameters']);
const form = ref();
const valid = ref<boolean>(true);
const isLoading = ref<boolean>(false);
const dateConcluded = ref<Array<Date> | null>(null);

// Create a local copy of the prop
const localSearchParameters = ref({...props.searchParameters});

const dateRequiredRule = () => {
    if (
        localSearchParameters.value.dateConcludedFrom !== null &&
        localSearchParameters.value.dateConcludedTo !== null
    ) {
        return true;
    }

    return i18n.global.t('Validation_Required');
};

//The present date for the date picker
const today = new Date();
const presetDates = ref([
    {
        label: i18n.global.t('Yesterday'),
        value: [addDays(today, -1), today],
    },
    {
        label: i18n.global.t('Last7Days'),
        value: [addDays(today, -6), today],
    },
    {
        label: i18n.global.t('Last14Days'),
        value: [addDays(today, -13), today],
    },
    {
        label: i18n.global.t('Last30Days'),
        value: [addDays(today, -29), today],
    },
    {
        label: i18n.global.t('Last3Months'),
        value: [addMonths(today, -3), today],
    },
]);

// Export results options
const exportOptions = computed(() => [
    {
        title: i18n.global.t('CSVFile'),
        value: true,
    },
    {
        title: i18n.global.t('Screen'),
        value: false,
    },
]);

/**
 * Validate form.
 */
const validateParameters = async () => {
    await form.value.validate();

    return valid.value;
};

/**
 * Gets the From date (first date) from a date range array.
 */
const getFromDate = (dates: Array<Date> | null) => {
    // If there is a From date
    if (dates != null && dates.length >= 1 && dates[0] != null) {
        // Return From date
        return formatTime(dates[0], 'yyyy-MM-dd');
    }

    return null;
};

/**
 * Gets the To date (second date) from a date range array.
 */
const getToDate = (dates: Array<Date> | null) => {
    // If there is a To date
    if (dates != null && dates.length >= 2 && dates[1] != null) {
        // Return To date
        return formatTime(dates[1], 'yyyy-MM-dd');
    }

    return null;
};

// Watch for changes in the dateConcluded object and update local parameters
watch(
    () => dateConcluded.value,
    (newVal) => {
        localSearchParameters.value.dateConcludedFrom = getFromDate(newVal);
        localSearchParameters.value.dateConcludedTo = getToDate(newVal);
    },
);

// Watch for changes in the parent parameters object and update local copy
watch(
    () => props.searchParameters,
    (newVal) => {
        if (JSON.stringify(newVal) !== JSON.stringify(localSearchParameters.value)) {
            localSearchParameters.value = {...newVal};
        }
    },
    {deep: true, immediate: true},
);

// Watch for changes in the local copy and emit the update event
watch(
    localSearchParameters,
    (newVal) => {
        if (JSON.stringify(newVal) !== JSON.stringify(props.searchParameters)) {
            emit('update:searchParameters', newVal);
        }
    },
    {deep: true},
);

// Fetch data for dropdown
onMounted(async () => {
    isLoading.value = true;

    // Get data
    await getFarmSites();
    await getFarmFields();
    await getUsers();

    dateConcluded.value = [addDays(today, -1), today];

    isLoading.value = false;
});

// Expose reset function
defineExpose({validateParameters});
</script>
