<template>
    <v-dialog v-model="manageFarmFieldModalStore.isVisible" class="ModifyFarmFieldModal">
        <v-card style="overflow-y: hidden">
            <v-toolbar color="swatchA2">
                <v-toolbar-title>
                    <FontAwesomeIcon :icon="['fal', 'vector-polygon']" size="xl" class="mr-2" />
                    {{ modalTitle }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <CloseModalButton @click="close" />
            </v-toolbar>

            <LoadingSymbol v-if="isLoading" />

            <v-form v-else ref="form" class="pa-4 divUserManager" v-model="valid" style="overflow-y: scroll">
                <FarmFieldProfile v-if="LoadedFarmField.farmFieldId" :farmField="LoadedFarmField" />
                <v-row class="subHeader ma-0">
                    {{ $t('PaddockDetails') }}
                </v-row>
                <table style="width: 100%" class="tblForm">
                    <tr>
                        <td class="tableCell padding" style="width: 20%; min-width: 10rem">
                            <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                                <span>{{ $t('FieldName') }}</span>
                                <HelpIcon :help-text="$t('FarmFieldManage_FieldName_HelpText')" />
                            </div>
                        </td>
                        <td style="width: 30%">
                            <v-text-field v-model="farmField.farmFieldName" max-width="300px" :rules="fieldNameRules">
                                <template v-slot:append-inner>
                                    <FontAwesomeIcon
                                        :icon="['fas', 'spinner']"
                                        pulse
                                        size="xl"
                                        class="swatchA2"
                                        v-if="farmField.farmFieldName && isCheckingFieldName" />

                                    <CircleIcon
                                        class="marginLeft marginRightSmall swatchREDBG"
                                        color="swatchWHT"
                                        :title="$t('FarmFieldNameIsNotAvailable')"
                                        v-if="farmField.farmFieldName && !isCheckingFieldName && !isFieldNameAvailable">
                                        <FontAwesomeIcon :icon="['fas', 'xmark']" />
                                    </CircleIcon>

                                    <CircleIcon
                                        class="marginLeft marginRightSmall swatchGRNBG"
                                        color="swatchWHT"
                                        :title="$t('FarmFieldNameIsAvailable')"
                                        v-if="farmField.farmFieldName && !isCheckingFieldName && isFieldNameAvailable">
                                        <FontAwesomeIcon :icon="['fas', 'check']" />
                                    </CircleIcon>
                                </template>
                            </v-text-field>
                        </td>
                        <td class="tableCell padding" style="width: 20%; min-width: 10rem">
                            <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                                <span>{{ $t('FieldCode') }}</span>
                                <HelpIcon :help-text="$t('FarmFieldManage_FieldCode_HelpText')" />
                            </div>
                        </td>
                        <td style="width: 30%">
                            <v-text-field v-model="farmField.farmFieldCode" :rules="fieldCodeRules">
                                <template v-slot:append-inner>
                                    <FontAwesomeIcon
                                        :icon="['fas', 'spinner']"
                                        pulse
                                        size="xl"
                                        class="swatchA2"
                                        v-if="farmField.farmFieldCode && isCheckingFieldCode" />

                                    <CircleIcon
                                        class="marginLeft marginRightSmall swatchREDBG"
                                        color="swatchWHT"
                                        :title="$t('FieldCodeIsNotAvailable')"
                                        v-if="farmField.farmFieldCode && !isCheckingFieldCode && !isFieldCodeAvailable">
                                        <FontAwesomeIcon :icon="['fas', 'xmark']" />
                                    </CircleIcon>

                                    <CircleIcon
                                        class="marginLeft marginRightSmall swatchGRNBG"
                                        color="swatchWHT"
                                        :title="$t('FieldCodeIsAvailable')"
                                        v-if="farmField.farmFieldCode && !isCheckingFieldCode && isFieldCodeAvailable">
                                        <FontAwesomeIcon :icon="['fas', 'check']" />
                                    </CircleIcon>
                                </template>
                            </v-text-field>
                        </td>
                    </tr>
                    <tr>
                        <td class="tableCell padding" style="width: 20%; min-width: 10rem; vertical-align: top">
                            <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                                <span>{{ $t('Description') }}</span>
                                <HelpIcon :help-text="$t('FarmFieldManage_Description_HelpText')" />
                            </div>
                        </td>
                        <td colspan="3">
                            <v-textarea
                                v-model="farmField.farmFieldDescription"
                                variant="outlined"
                                rows="3"
                                auto-grow />
                        </td>
                    </tr>
                    <tr>
                        <td class="tableCell padding" style="width: 20%; min-width: 10rem; vertical-align: top">
                            <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                                <span>{{ $t('FieldGeometry') }}</span>
                            </div>
                        </td>
                        <td colspan="3">
                            <i>{{ $t('FarmFieldManage_FieldGeometry_Text') }}</i>
                        </td>
                    </tr>
                    <tr>
                        <td class="tableCell padding" style="width: 20.5%; min-width: 10rem">
                            <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                                <span>{{ $t('PrimaryCrop') }}</span>
                                <HelpIcon :help-text="$t('FarmFieldManage_PrimaryCrop_HelpText')" />
                            </div>
                        </td>
                        <td style="width: 30.5%">
                            <v-select
                                v-model="farmField.cropId"
                                :items="crops"
                                item-title="cropName"
                                item-value="cropId"
                                max-width="300px"
                                :rules="primaryCropRules" />
                        </td>
                        <td class="tableCell padding" style="width: 20.5%; min-width: 10rem">
                            <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                                <span>{{ $t('RowWidth') }}</span>
                                <HelpIcon :help-text="$t('FarmFieldManage_RowWidth_HelpText')" />
                            </div>
                        </td>
                        <td style="width: 40%">
                            <LocalizedNumberInput v-model="farmField.rowWidth" :rules="rowWidthRules">
                            </LocalizedNumberInput>
                        </td>
                    </tr>
                    <tr>
                        <td class="tableCell padding" style="width: 20%; min-width: 10rem">
                            <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                                <span>{{ $t('FieldColour') }}</span>
                                <HelpIcon :help-text="$t('FarmFieldManage_FieldColour_HelpText')" />
                            </div>
                        </td>
                        <td>
                            <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                                <v-text-field v-model="color" max-width="300px" :rules="colorRules">
                                    <template v-slot:prepend-inner>
                                        <div :style="colorBlockStyle" @click="showColorPicker = true"></div>
                                    </template>
                                    <template v-slot:append-inner>
                                        <div @click="showColorPicker = true">
                                            <FontAwesomeIcon :icon="['fal', 'palette']" />
                                        </div>
                                    </template>
                                </v-text-field>
                                <v-dialog v-model="showColorPicker" max-width="300">
                                    <v-color-picker
                                        v-model="color"
                                        :modes="['hex']"
                                        hide-mode-switch
                                        show-swatches
                                        :swatches="localSwatches" />
                                </v-dialog>
                            </div>
                        </td>
                        <td class="tableCell padding" style="width: 20%; min-width: 10rem">
                            <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                                <span>{{ $t('RowDirection') }}</span>
                                <HelpIcon :help-text="$t('FarmFieldManage_RowNumbering_HelpText')" />
                            </div>
                        </td>
                        <td>
                            <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                                <v-select
                                    v-model="farmField.farmFieldRowDirection"
                                    :rules="rowDirectionRules"
                                    :items="farmFieldRowDirectionOptions"
                                    item-title="title"
                                    item-value="value"
                                    max-width="400px" />
                            </div>
                        </td>
                    </tr>
                </table>
            </v-form>

            <v-card-actions class="justify-space-between swatchG9BG">
                <v-btn @click="close">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'arrow-rotate-left']" size="xl" />
                    </template>
                    {{ $t('Cancel') }}
                </v-btn>
                <v-btn @click="save" :disabled="isLoading">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'save']" size="xl" />
                    </template>
                    {{ $t('SaveField') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" timeout="4000">
        {{ snackbar.text }}
    </v-snackbar>
</template>

<script setup lang="ts">
import '@/assets/scss/farm/modify-farm-field-modal.scss';
import {useManageFarmFieldModalStore} from '@/stores/modals/manage-farm-field-modal-store';
import FarmFieldProfile from '@/components/FarmFieldProfile.vue';
import {CheckFarmFieldNameAvailableRequest} from '@/models/api/requests/farm/CheckFarmFieldNameAvailableRequest';
import {CheckFarmFieldCodeAvailableRequest} from '@/models/api/requests/farm/CheckFarmFieldCodeAvailableRequest';
import {ManageFarmFieldForm} from '@/models/farm/ManageFarmFieldForm';
import {computed, watch, inject, ref, onUnmounted, onMounted} from 'vue';
import {SearchFarmFieldsResponse} from '@/models/api/responses/search/SearchFarmFieldsResponse';
import ApiService from '@/services/api-service.js';
import CloseModalButton from '@/components/CloseModalButton.vue';
import {useCrops} from '@/composables/data-source/crops';
const {getCrops, crops} = useCrops();
import {useSwatches} from '@/composables/data-source/swatches';
const {getSwatches, swatches} = useSwatches();
import {SaveFarmFieldRequest} from '@/models/api/requests/farm/SaveFarmFieldRequest';
import {useFarmStore} from '@/stores/farm-store';
const farmStore = useFarmStore();
import {useFarmSites} from '@/composables/data-source/farm-sites';
const {getFarmSites} = useFarmSites();
import {debounce} from 'lodash';
import {FarmFieldRowDirection} from '@/enums/farm-field-row-direction';
import i18n from '@/i18n';
import {SwatchCode} from '@/enums/swatch-code';
import {SearchFarmFieldsRequest} from '@/models/api/requests/search/SearchFarmFieldsRequest';
import {GetNewFarmFieldDefaultsResponse} from '@/models/api/responses/farms/GetNewFarmFieldDefaultsResponse';
import {useUserStore} from '@/stores/user-store';
import {SelectListItem} from '@/models/generic/SelectListItem';

const isCheckingFieldName = ref(false);
const isFieldNameAvailable = ref(true);
const isCheckingFieldCode = ref(false);
const isFieldCodeAvailable = ref(true);

// Form
const form = ref();
const valid = ref<boolean>(true);
let isLoading = ref<boolean>(false);
const snackbar = ref({
    show: false,
    text: '',
});
const showColorPicker = ref(false);
const color = ref('#000000');
const localSwatches = ref<string[][]>([]);

const colorBlockStyle = computed(() => ({
    width: '24px',
    height: '24px',
    backgroundColor: color.value,
    cursor: 'pointer',
    borderRadius: '50%',
    marginRight: '8px',
    border: '1px solid black',
}));

const farmFieldRowDirectionOptions = ref<SelectListItem[]>([]);

// // Rules
const fieldNameRules = ref([
    (v: string | null) => !v || v.length <= 30 || i18n.global.t('Validation_InvalidFormat'),
    async (v: string | null) => {
        if (!v) return true;
        if (isCheckingFieldName.value == true) return false;
        return await checkFarmFieldNameAvailable(v);
    },
]);

const fieldCodeRules = ref([
    (v: string | null) => !!v || i18n.global.t('Validation_Required'),
    (v: string | null) => {
        if (typeof v === 'string') {
            return (!v.includes(' ') && v.length <= 8) || i18n.global.t('Validation_InvalidFormat');
        }
        return i18n.global.t('Validation_InvalidFormat');
    },
    async (v: string | null) => {
        if (!v) return true;
        if (isCheckingFieldCode.value) return false;
        return await checkFarmFieldCodeAvailable(v);
    },
]);

const rowDirectionRules = ref([(v: number | null) => !!v || i18n.global.t('Validation_Required')]);

const colorRules = ref([
    (v: string | null) => !!v || i18n.global.t('Validation_Required'),
    (v: string | null) =>
        (v && /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(v)) || i18n.global.t('Validation_InvalidFormat'),
]);

const primaryCropRules = ref([(v: number | null) => !!v || v === 0 || i18n.global.t('Validation_Required')]);
const rowWidthRules = ref([
    (v: string | null) => !!v || i18n.global.t('Validation_Required'),
    (v: string | null) => (v && /^-?\d+(\.\d+)?$/.test(v)) || i18n.global.t('Validation_InvalidFormat'),
    (v: string | null) =>
        (v && parseFloat(v) >= 0.3 && parseFloat(v) <= 6) ||
        i18n.global.t('ManageFarmSite_RowWidthValidation_InvalidRowWidthRange'),
]);

// Services
const apiService = inject('apiService') as ApiService;
const userStore = useUserStore();

// Farm Field modal
const manageFarmFieldModalStore = useManageFarmFieldModalStore();

const farmField = ref<ManageFarmFieldForm>(new ManageFarmFieldForm());
const LoadedFarmField = ref<ManageFarmFieldForm>(new ManageFarmFieldForm());
const localFarmFieldId = ref<number | null>(null);
const defaultPanel = ref<number | null>(0);

const currentSiteId = computed(() => farmStore.selectedSiteId);

/**
 * Load data for the modal.
 */
const loadData = async (farmFieldId: number | null) => {
    isLoading.value = true;

    // Reset form
    defaultPanel.value = 0;
    farmField.value = new ManageFarmFieldForm();
    farmField.value.farmFieldRowDirection = FarmFieldRowDirection.NorthToSouth;
    await getCrops();
    localFarmFieldId.value = farmFieldId;

    // Populate row direction options on load to ensure that the correct translations are used
    farmFieldRowDirectionOptions.value = [
        {title: i18n.global.t(FarmFieldRowDirection.NorthToSouth), value: FarmFieldRowDirection.NorthToSouth},
        {title: i18n.global.t(FarmFieldRowDirection.SouthToNorth), value: FarmFieldRowDirection.SouthToNorth},
        {title: i18n.global.t(FarmFieldRowDirection.EastToWest), value: FarmFieldRowDirection.EastToWest},
        {title: i18n.global.t(FarmFieldRowDirection.WestToEast), value: FarmFieldRowDirection.WestToEast},
    ];

    // If a farmFieldId ID was specified
    if (farmFieldId !== null) {
        // Get farm field details
        const farmFieldsSearchRequest: SearchFarmFieldsRequest = new SearchFarmFieldsRequest({
            farmFieldId: farmFieldId,
        });

        const searchResults: SearchFarmFieldsResponse = (await apiService.post(
            'search/farm-fields',
            farmFieldsSearchRequest,
        )) as SearchFarmFieldsResponse;
        if (searchResults.farmFields.length === 1) {
            farmField.value = Object.assign(new ManageFarmFieldForm(), searchResults.farmFields[0]);
            LoadedFarmField.value = Object.assign(new ManageFarmFieldForm(), searchResults.farmFields[0]);
            color.value = farmField.value.farmFieldColorHexCode
                ? farmField.value.farmFieldColorHexCode
                : localSwatches.value[0][0] ?? '#000000';
        }
    } else {
        if (currentSiteId.value) {
            const response = (await apiService.post('farmFields/get-new-farm-field-defaults', {
                currentFarmSiteId: currentSiteId.value,
            })) as GetNewFarmFieldDefaultsResponse;

            farmField.value.farmFieldCode = response.farmFieldCode;
            farmField.value.rowWidth = response.rowWidth?.toString() ?? null;
            farmField.value.cropId = response.primaryCropId;
            localFarmFieldId.value = null;
            LoadedFarmField.value = new ManageFarmFieldForm();
        }
    }

    isLoading.value = false;
};

/**
 * Checks if the specified farm site name is available. Debounce is used to prevent the API from being called too often.
 */
const debouncedCheckFarmFieldNameAvailable = debounce(
    async (inputFarmFieldName: string, resolve: (value: string | boolean) => void) => {
        const request: CheckFarmFieldNameAvailableRequest = {
            farmFieldName: inputFarmFieldName,
            farmFieldId: localFarmFieldId.value,
        };

        const isAvailable = await apiService.post('farmFields/check-farm-field-name-available', request);
        isCheckingFieldName.value = false;
        isFieldNameAvailable.value = isAvailable;

        resolve(isAvailable || i18n.global.t('Validation_FarmFieldNameAlreadyTaken'));
    },
    500,
);

/**
 * Checks if the specified farm site name is available.
 */
const checkFarmFieldNameAvailable = async (inputFarmFieldName: string): Promise<string | boolean> => {
    isCheckingFieldName.value = true;

    return new Promise((resolve) => {
        debouncedCheckFarmFieldNameAvailable(inputFarmFieldName, resolve);
    });
};

/**
 * Checks if the specified farm site abbreviation is available. Debounce is used to prevent the API from being called too often.
 */
const debouncedCheckFarmFieldCodeAvailable = debounce(
    async (inputFarmFieldCode: string, resolve: (value: string | boolean) => void) => {
        if (!currentSiteId.value) return resolve(i18n.global.t('Validation_FarmFieldNameAlreadyTaken'));
        const request: CheckFarmFieldCodeAvailableRequest = {
            farmFieldCode: inputFarmFieldCode,
            farmFieldId: localFarmFieldId.value,
            currentFarmSiteId:
                farmField.value && farmField.value.farmSiteId ? farmField.value.farmSiteId : currentSiteId.value,
        };

        const isAvailable = await apiService.post('farmFields/check-farm-field-code-available', request);
        isCheckingFieldCode.value = false;
        isFieldCodeAvailable.value = isAvailable;

        resolve(isAvailable || i18n.global.t('Validation_FarmFieldCodeAlreadyTaken'));
    },
    500,
);

/**
 * Checks if the specified farm site abbreviation is available.
 */
const checkFarmFieldCodeAvailable = async (inputFarmFieldCode: string): Promise<string | boolean> => {
    isCheckingFieldCode.value = true;

    return new Promise((resolve) => {
        debouncedCheckFarmFieldCodeAvailable(inputFarmFieldCode, resolve);
    });
};

/**
 * Close the modal.
 */
const close = () => {
    localFarmFieldId.value = null;
    manageFarmFieldModalStore.close();
};

onUnmounted(() => {
    localFarmFieldId.value = null;
    close();
});

const swatchTable = [
    [SwatchCode.SwatchA1, SwatchCode.SwatchA2, SwatchCode.SwatchA3, SwatchCode.SwatchA4],
    [SwatchCode.SwatchB1, SwatchCode.SwatchB2, SwatchCode.SwatchB3, SwatchCode.SwatchB4],
    [SwatchCode.SwatchB5, SwatchCode.SwatchB6, SwatchCode.SwatchB7, SwatchCode.SwatchORG],
    [SwatchCode.SwatchC1, SwatchCode.SwatchC2, SwatchCode.SwatchC3, SwatchCode.SwatchC4],
    [SwatchCode.SwatchC5, SwatchCode.SwatchC6, SwatchCode.SwatchC7, SwatchCode.SwatchRED],
];

onMounted(async () => {
    if (userStore.isLoggedIn) {
        await getSwatches();
        if (swatches.value && swatches.value.length > 0) {
            localSwatches.value = swatchTable.map((row) =>
                row.map((code) => {
                    const swatch = swatches.value.find(
                        (swatch) => swatch.swatchCode.toLowerCase() === code.toLowerCase(),
                    );
                    return swatch ? swatch.swatchValue : '#000000';
                }),
            );

            color.value = localSwatches.value[0][0] ?? '#000000';
        }
    }
});

/**
 * Save the modal.
 */
const save = async () => {
    // Perform final client side validation of form
    await form.value.validate();

    // If form is valid
    if (valid.value && currentSiteId.value) {
        let snackbarMessage = '';
        isLoading.value = true;

        try {
            // Build API request
            const saveFarmFieldRequest: SaveFarmFieldRequest = {
                currentFarmSiteId: currentSiteId.value,
                farmFieldId: farmField.value.farmFieldId,
                farmFieldName: farmField.value.farmFieldName,
                farmFieldCode: farmField.value.farmFieldCode,
                farmFieldDescription: farmField.value.farmFieldDescription,
                primaryCropId: farmField.value.cropId,
                rowWidth: farmField.value.rowWidth ? parseFloat(farmField.value.rowWidth) : null,
                farmFieldColorHexCode: color.value.substring(1),
                farmFieldRowDirection: farmField.value.farmFieldRowDirection,
            };

            // Call API to save user
            await apiService.post('farmfields/farm-field', saveFarmFieldRequest);

            // Set snackbar message
            if (!localFarmFieldId.value) {
                snackbarMessage = i18n.global.t('CreateFarmField_Success');
            } else {
                snackbarMessage = i18n.global.t('ModifyFarmField_Success');
            }

            // Show success feedback to user
            snackbar.value.show = true;
            snackbar.value.text = snackbarMessage;
            manageFarmFieldModalStore.savedCounter++;

            // update farm store
            await farmStore.fetchFarmSites(apiService);
            await getFarmSites();
            farmField.value = new ManageFarmFieldForm();

            // Close modal
            manageFarmFieldModalStore.close();
        } catch (ex: unknown) {
            // Show fail feedback to user
            snackbar.value.show = true;
            snackbar.value.text = i18n.global.t('ErrorGeneric');
            isLoading.value = false;
        }
    }
};

/**
 * The title of the modal.
 */
const modalTitle = computed(() => {
    let title =
        localFarmFieldId.value !== null
            ? i18n.global.t('ModifyFarmFieldSlashPaddock')
            : i18n.global.t('CreateFarmFieldSlashPaddock');

    return title;
});

// Watch for changes to manageFarmFieldModalStore.farmFieldId
watch(
    () => manageFarmFieldModalStore.isVisible,
    async (isVisible) => {
        if (isVisible) {
            await loadData(manageFarmFieldModalStore.farmFieldId);
        }
    },
);

import {useDeleteFarmFieldModalStore} from '@/stores/modals/delete-farm-field-modal-store';
const deleteFarmFieldModalStore = useDeleteFarmFieldModalStore();

watch(
    () => deleteFarmFieldModalStore.deletedCounter,
    async () => {
        if (manageFarmFieldModalStore.isVisible) await loadData(manageFarmFieldModalStore.farmFieldId);
    },
);
</script>
