<template>
    <v-dialog v-model="viewFarmFieldModalStore.isVisible" style="width: 90vw">
        <v-card style="overflow-y: hidden">
            <v-toolbar color="swatchA2">
                <v-toolbar-title>
                    <FontAwesomeIcon :icon="['fal', 'vector-polygon']" size="xl" class="mr-2" />
                    {{ $t('ViewFarmFieldSlashPaddock') }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <CloseModalButton @click="close" />
            </v-toolbar>
            <LoadingSymbol v-if="isLoading || localFarmFieldId == null" />
            <v-form v-else ref="form" class="pa-4" style="overflow-y: scroll">
                <FarmFieldProfile v-if="farmField" :farmField="farmField" :isShowButton="true" />
                <v-expansion-panels multiple v-model="openedExpansionPanels">
                    <v-expansion-panel>
                        <v-expansion-panel-title class="expansion-header">
                            <FontAwesomeIcon :icon="['fal', 'vector-square']" size="xl" class="mr-2" />
                            {{ $t('BlocksConfigured') + ' (' + localFarmBlocks.length + ')' }}</v-expansion-panel-title
                        >
                        <v-expansion-panel-text class="farm-block-expansion">
                            <v-data-table
                                class="farm-block-table pa-0 ma-0"
                                :headers="farmBlockheaders"
                                :items="localFarmBlocks"
                                :items-per-page="-1"
                                hide-default-footer>
                                <template v-slot:[`item.farmBlockCode`]="{item}">
                                    <div class="tableCell">
                                        <b class="swatchA1">{{ item.farmBlockCode }}</b>
                                    </div>
                                </template>
                                <template v-slot:[`item.cropVariety`]="{item}">
                                    <div class="tableCell" v-if="item.cropVariety">
                                        <CropIcon
                                            :cropCode="item.cropVariety.cropCode"
                                            :primary="true"
                                            :varieties="[item.cropVariety.cropVarietyName]" />
                                    </div>
                                </template>
                                <template v-slot:[`item.seedOrigin`]="{item}">
                                    <div class="tableCell" v-if="item.seedOriginFarmBlock">
                                        <SeedOriginChip
                                            :farmSiteAbbreviation="item.seedOriginFarmBlock.farmSiteAbbreviation"
                                            :farmFieldCode="item.seedOriginFarmBlock.farmFieldCode"
                                            :farmBlockCode="item.seedOriginFarmBlock.farmBlockCode" />
                                    </div>
                                </template>

                                <template v-slot:[`item.information`]="{item}">
                                    <div v-if="item.cropVarietyArtifact && item.cropVarietyArtifact.artifactId">
                                        <FileTypeButton
                                            :url="item.cropVarietyArtifact.artifactUrl"
                                            :display-name="item.cropVarietyArtifact.artifactDisplayName"
                                            :file-type-code="item.cropVarietyArtifact.artifactFileTypeCode" />
                                    </div>
                                    <v-col v-else align="center">-</v-col>
                                </template>
                                <template v-slot:[`item.farmBlockNumRows`]="{item}">
                                    <div class="tableCell">
                                        <span class="swatchA1"
                                            >{{ item.farmBlockStartRow + '-' + item.farmBlockEndRow + ' ' }}
                                        </span>
                                        (<span class="swatchA1">
                                            {{ item.farmBlockNumRows }}
                                        </span>
                                        <span class="swatchBLK">{{
                                            ' ' + (item.farmBlockNumRows > 1 ? $t('Rows') : $t('Row'))
                                        }}</span
                                        >)
                                    </div>
                                </template>

                                <template v-slot:[`item.farmBlockSize`]="{item}">
                                    <div class="tableCell">
                                        {{ $n(item.farmBlockSize, 'decimal') + ' ha' }}
                                    </div>
                                </template>

                                <template v-slot:[`item.action`]="{item}">
                                    <div style="text-align: center">
                                        <v-menu open-on-hover>
                                            <template v-slot:activator="{props}">
                                                <v-btn rounded="1" elevation="2" v-bind="props">
                                                    <template v-slot:append>
                                                        <FontAwesomeIcon :icon="['fas', 'caret-down']" size="xl" />
                                                    </template>
                                                    {{ $t('Actions') }}
                                                </v-btn>
                                            </template>

                                            <v-list>
                                                <v-list-item v-if="item.farmBlockId">
                                                    <v-list-item-title>
                                                        <v-btn @click="identifyBlock(farmField)">
                                                            {{ $t('IdentifyBlock') }}
                                                        </v-btn>
                                                    </v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </div>
                                </template>
                            </v-data-table>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-title
                            class="expansion-header farm-block-expansion d-flex justify-space-between align-center">
                            <div class="d-flex align-center">
                                <FontAwesomeIcon :icon="['fal', 'clipboard-list-check']" size="xl" class="mr-2" />
                                {{ $t('Tasks') + ' (' + sortedWorkTasks.length + ')' }}
                            </div>

                            <div class="d-flex ml-auto">
                                <v-btn
                                    rounded="1"
                                    elevation="1"
                                    color="accent"
                                    @click.stop="viewCurrentTaskList"
                                    :disabled="sortedWorkTasks.length === 0"
                                    class="task-button swatchA4BG swatchWHT mr-5">
                                    <template v-slot:prepend>
                                        <FontAwesomeIcon :icon="['fal', 'circle-arrow-right']" size="lg" />
                                    </template>
                                    {{ $t('ViewAll') }}
                                </v-btn>
                                <v-btn
                                    rounded="1"
                                    elevation="1"
                                    color="accent"
                                    @click.stop="
                                        manageWorkTaskModalStore.open(
                                            ManageWorkTaskModalMode.Create,
                                            null,
                                            localFarmFieldId,
                                        )
                                    "
                                    class="task-button swatchA4BG swatchWHT mr-2">
                                    <template v-slot:prepend>
                                        <FontAwesomeIcon :icon="['fas', 'plus']" size="lg" />
                                    </template>
                                    {{ $t('Add') }}
                                </v-btn>
                            </div>
                        </v-expansion-panel-title>
                        <LoadingSymbol v-if="isLoadingTasks" />
                        <v-expansion-panel-text v-else class="farm-block-expansion">
                            <v-slide-group class="pa-4 carousel-container" v-model="selected" center-active show-arrows>
                                <template #prev>
                                    <v-btn
                                        class="swatchG9BG chevron-button"
                                        icon="mdi-chevron-left"
                                        rounded="circle"
                                        elevation="1">
                                    </v-btn>
                                </template>
                                <div class="carousel-items-wrapper">
                                    <v-slide-group-item
                                        v-for="(workTask, index) in sortedWorkTasks"
                                        :key="workTask.workTaskId">
                                        <div class="carousel-item">
                                            <TaskCarousel :workTask="workTask" class="ma-2" @click="selected = index" />
                                        </div>
                                    </v-slide-group-item>
                                    <v-slide-group-item v-if="localFarmField && sortedWorkTasks.length === 0">
                                        <div class="carousel-item">
                                            <DummyTaskCarousel
                                                v-if="localFarmField.historicTaskCount === 0"
                                                :farmFieldId="localFarmField.farmFieldId"
                                                :numberOfHistoricTask="localFarmField.historicTaskCount"
                                                class="ma-2" />
                                            <DummyTaskCarousel
                                                v-if="localFarmField.historicTaskCount !== 0"
                                                :farmFieldId="localFarmField.farmFieldId"
                                                :numberOfHistoricTask="localFarmField.historicTaskCount"
                                                class="ma-2" />
                                        </div>
                                    </v-slide-group-item>
                                </div>
                                <template #next>
                                    <v-btn class="swatchG9BG chevron-button" icon="mdi-chevron-right" rounded="circle">
                                    </v-btn>
                                </template>
                            </v-slide-group>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-title
                            class="expansion-header farm-block-expansion d-flex justify-space-between align-center">
                            <div class="d-flex align-center">
                                <FontAwesomeIcon :icon="['fas', 'calendar-days']" size="xl" class="mr-2" />
                                {{ $t('Seasons') + ' (' + localFarmSeasons.length + ')' }}
                            </div>

                            <div class="d-flex ml-auto">
                                <v-btn
                                    rounded="1"
                                    elevation="1"
                                    @click.stop="
                                        localFarmField && manageFarmFieldSeasonModalStore.open(localFarmFieldId)
                                    "
                                    color="accent"
                                    class="task-button swatchA4BG swatchWHT mr-5">
                                    <template v-slot:prepend>
                                        <FontAwesomeIcon :icon="['fas', 'calendar-days']" size="lg" />
                                    </template>
                                    {{ $t('Manage') }}
                                </v-btn>
                            </div>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text class="farm-block-expansion">
                            <v-data-table
                                class="farm-block-table pa-0 ma-0"
                                :headers="farmSeasonheaders"
                                :items="localFarmSeasons"
                                :items-per-page="-1"
                                hide-default-footer>
                                <template v-slot:[`item.seasonName`]="{item}">
                                    <div class="tableCell">
                                        <b class="swatchA1">{{ item.seasonName }}</b>
                                    </div>
                                </template>
                                <template v-slot:[`item.farmFieldSeasonStartDate`]="{item}">
                                    <div class="tableCell" v-if="item.farmFieldSeasonStartDate">
                                        {{
                                            formatDate(item.farmFieldSeasonStartDate) +
                                            ' - ' +
                                            formatDate(item.farmFieldSeasonEndDate)
                                        }}
                                    </div>
                                </template>
                                <template v-slot:[`item.cropYear`]="{item}">
                                    <v-row align="center" justify="center" v-if="item.cropYear || item.cropYear === 0">
                                        <v-col cols="12" sm="6" md="4">
                                            <FarmFieldAgeIcon
                                                iconHeight="40"
                                                iconWidth="40"
                                                :fieldAge="item.cropYear.toString()" />
                                        </v-col>
                                    </v-row>
                                </template>

                                <template v-slot:[`item.yield`]="{item}">
                                    <v-col align="center" class="bold swatchA1">
                                        {{
                                            item.yieldTotalWeight !== null
                                                ? $n(item.yieldTotalWeight, 'decimal') + ' t'
                                                : '-'
                                        }}</v-col
                                    >
                                </template>

                                <template v-slot:[`item.yieldUnit`]="{item}">
                                    <v-col align="center" class="bold swatchA1">
                                        {{
                                            item.yieldTotalWeight !== null && farmField?.farmFieldSize
                                                ? $n(item.yieldTotalWeight / farmField.farmFieldSize, 'decimal') +
                                                  ' t/ha'
                                                : '-'
                                        }}</v-col
                                    >
                                </template>

                                <template v-slot:[`item.CCS`]="{item}">
                                    <v-col align="center" class="bold swatchA1">
                                        {{
                                            item.yieldAverageCcs !== null ? $n(item.yieldAverageCcs, 'decimal') : '-'
                                        }}</v-col
                                    >
                                </template>

                                <template v-slot:[`item.report`]="{item}">
                                    <div
                                        style="text-align: center"
                                        v-if="item.relatedHarvestTaskId && userStore.hasRoleLevel(RoleCode.Tasks, 1)">
                                        <v-btn
                                            rounded="1"
                                            elevation="2"
                                            @click.stop="viewWorkTaskModalStore.open(item.relatedHarvestTaskId)">
                                            <FontAwesomeIcon :icon="['fas', 'file-spreadsheet']" size="xl" />
                                        </v-btn>
                                    </div>
                                </template>
                            </v-data-table>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-title
                            class="expansion-header farm-block-expansion d-flex justify-space-between align-center">
                            <div class="d-flex align-center">
                                <FontAwesomeIcon :icon="['fal', 'binoculars']" size="xl" class="mr-2" />
                                {{ $t('Observations') + ' (' + localObservations.length + ')' }}
                            </div>

                            <div class="d-flex ml-auto" v-if="userStore.hasRoleLevel(RoleCode.Observations, 1)">
                                <v-btn
                                    color="accent"
                                    rounded="1"
                                    elevation="1"
                                    @click.stop="manageObservationModalStore.open()"
                                    class="task-button swatchWHT mr-2">
                                    <template v-slot:prepend>
                                        <FontAwesomeIcon :icon="['fas', 'plus']" size="lg" />
                                    </template>
                                    {{ $t('Add') }}
                                </v-btn>
                            </div>
                        </v-expansion-panel-title>
                        <LoadingSymbol v-if="isLoadingObservations" />
                        <v-expansion-panel-text v-else class="farm-block-expansion">
                            <v-slide-group
                                class="pa-4 carousel-container"
                                center-active
                                show-arrows
                                v-model="selectedObs">
                                <template #prev>
                                    <v-btn
                                        class="swatchG9BG chevron-button"
                                        icon="mdi-chevron-left"
                                        rounded="circle"
                                        elevation="1">
                                    </v-btn>
                                </template>
                                <div class="carousel-items-wrapper">
                                    <v-slide-group-item
                                        v-for="(observation, index) in localObservations"
                                        :key="observation.observationId">
                                        <div class="carousel-item">
                                            <ObservationCarousel
                                                :observation="observation"
                                                @click="selectedObs = index"
                                                class="ma-2" />
                                        </div>
                                    </v-slide-group-item>
                                </div>
                                <template #next>
                                    <v-btn class="swatchG9BG chevron-button" icon="mdi-chevron-right" rounded="circle">
                                    </v-btn>
                                </template>
                            </v-slide-group>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-form>

            <v-card-actions class="justify-space-between swatchG9BG" v-if="!isLoading && localFarmFieldId != null">
                <v-btn @click="close">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'arrow-rotate-left']" size="xl" />
                    </template>
                    {{ $t('Back') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" timeout="4000">
        {{ snackbar.text }}
    </v-snackbar>
</template>

<script setup lang="ts">
import '@/assets/scss/farm/delete-farm-site-modal.scss';
import '@/assets/scss/user/user-manager.scss';

import FarmFieldProfile from '@/components/FarmFieldProfile.vue';
import TaskCarousel from '@/components/TaskCarousel.vue';
import ObservationCarousel from '@/components/ObservationCarousel.vue';
import DummyTaskCarousel from '@/components/DummyTaskCarousel.vue';
import SeedOriginChip from '@/components/other-chips/SeedOriginChip.vue';
import {useManageFarmFieldSeasonModalStore} from '@/stores/modals/manage-farm-field-season-modal-store';
import {useViewFarmFieldModalStore} from '@/stores/modals/view-farm-field-modal-store';
import {watch, inject, ref, onUnmounted} from 'vue';
import ApiService from '@/services/api-service.js';
import CloseModalButton from '@/components/CloseModalButton.vue';
import {SearchFarmFieldsResponse} from '@/models/api/responses/search/SearchFarmFieldsResponse';
import {FarmFieldSearchResultDto} from '@/models/data-transfer-objects/search/farm-field-search/FarmFieldSearchResultDto';
import {ManageFarmFieldForm} from '@/models/farm/ManageFarmFieldForm';
import {FarmBlockDto} from '@/models/data-transfer-objects/search/farm-field-search/FarmBlockDto';
import {FarmFieldSeasonDto} from '@/models/data-transfer-objects/search/farm-field-search/FarmFieldSeasonDto';
import i18n from '@/i18n';
import {useFarmSites} from '@/composables/data-source/farm-sites';
const {getFarmSites, farmSites} = useFarmSites();
import {WorkTaskSearchResultDto} from '@/models/data-transfer-objects/search/work-task-search/WorkTaskSearchResultDto';
import {useFarmStore} from '@/stores/farm-store';
const farmStore = useFarmStore();
import {useUserStore} from '@/stores/user-store';
const userStore = useUserStore();
import {useAdvancedSearchModalStore} from '@/stores/modals/advanced-search-modal-store';
const advancedSearchModalStore = useAdvancedSearchModalStore();
import {useHomeDashboardStore} from '@/stores/home-dashboard-store';
const homeDashboardStore = useHomeDashboardStore();
import router from '@/router';
import {ManageWorkTaskModalMode} from '@/enums/modal-modes/manage-work-task-modal-mode';
import {useManageWorkTaskModalStore} from '@/stores/modals/manage-work-task-modal-store';
import {formatTime} from '@/helpers/TimeFormatHelper';
import FarmFieldAgeIcon from '@/components/FarmFieldAgeIcon.vue';
import {ObservationSearchResultDto} from '@/models/data-transfer-objects/search/observation-search/ObservationSearchResultDto';
import {useManageObservationModalStore} from '@/stores/modals/manage-observation-modal-store';
import {RoleCode} from '@/enums/role-code';
import {SearchObservationsResponse} from '@/models/api/responses/search/SearchObservationsResponse';
import {SearchObservationsRequest} from '@/models/api/requests/search/SearchObservationsRequest';
import {SearchWorkTasksRequest} from '@/models/api/requests/search/SearchWorkTasksRequest';
import {SearchWorkTasksResponse} from '@/models/api/responses/search/SearchWorkTasksResponse';
import {useViewWorkTaskModalStore} from '@/stores/modals/view-work-task-modal-store';
import {useClearTasksModalStore} from '@/stores/modals/clear-tasks-modal-store';
const viewWorkTaskModalStore = useViewWorkTaskModalStore();
import FileTypeButton from '@/components/FileTypeButton.vue';
import {useViewObservationModalStore} from '@/stores/modals/view-observation-modal-store';
const viewObservationModalStore = useViewObservationModalStore();
import {useRescheduleWorkTaskModalStore} from '@/stores/modals/reschedule-work-task-modal-store';
const rescheduleWorkTaskModalStore = useRescheduleWorkTaskModalStore();
import {useDeleteOrCancelWorkTaskModalStore} from '@/stores/modals/delete-or-cancel-work-task-modal-store';
const deleteOrCancelWorkTaskModalStore = useDeleteOrCancelWorkTaskModalStore();

// Form
const isLoading = ref<boolean>(false);
const isLoadingObservations = ref<boolean>(false);
const isLoadingTasks = ref<boolean>(false);

const snackbar = ref({
    show: false,
    text: '',
});
const viewFarmFieldModalStore = useViewFarmFieldModalStore();
const manageWorkTaskModalStore = useManageWorkTaskModalStore();
const manageFarmFieldSeasonModalStore = useManageFarmFieldSeasonModalStore();
const manageObservationModalStore = useManageObservationModalStore();
const clearTasksModalStore = useClearTasksModalStore();

const isConfirmed = ref<boolean>(false);
const localFarmFieldId = ref<number | null>(null);
const farmField = ref<ManageFarmFieldForm | null>(null);
const localFarmField = ref<FarmFieldSearchResultDto | null>(null);
const localObservations = ref<ObservationSearchResultDto[]>([]);
const localFarmBlocks = ref<FarmBlockDto[]>([]);
const localFarmSeasons = ref<FarmFieldSeasonDto[]>([]);

const openedExpansionPanels = ref<number[]>([0, 1, 2, 3]);
const selected = ref<number>(0);
const selectedObs = ref<number>(0);

// Services
const apiService = inject('apiService') as ApiService;

const associatedSiteAbbreviation = ref<string>('');
const associatedSiteName = ref<string>('');

const farmBlockheaders = ref([
    {title: i18n.global.t('BlockCode'), key: 'farmBlockCode', sortable: true},
    {title: i18n.global.t('Variety'), key: 'cropVariety', sortable: false},
    {title: i18n.global.t('SeedOrigin'), key: 'seedOrigin', sortable: true},
    {title: i18n.global.t('Information'), key: 'information', sortable: false},
    {title: i18n.global.t('Rows'), key: 'farmBlockNumRows', sortable: true},
    {title: i18n.global.t('Area'), key: 'farmBlockSize', sortable: true},
    {title: '', key: 'action', sortable: false},
]);

const farmSeasonheaders = ref([
    {title: i18n.global.t('Season'), key: 'seasonName', sortable: false},
    {title: i18n.global.t('Dates'), key: 'farmFieldSeasonStartDate', sortable: true},
    {title: i18n.global.t('CropYear'), key: 'cropYear', sortable: false},
    {title: i18n.global.t('Yield'), key: 'yield', sortable: false},
    {title: i18n.global.t('Yield') + ' t/ha', key: 'yieldUnit', sortable: false},
    {title: 'CCS', key: 'CCS', sortable: false},
    {title: i18n.global.t('Report'), key: 'report', sortable: false},
]);

const sortedWorkTasks = ref<WorkTaskSearchResultDto[]>([]);
// view field on map
async function identifyBlock(farmField: ManageFarmFieldForm | null) {
    if (farmField && farmField.farmSiteId && farmField.farmFieldId) {
        if (farmStore.selectedSiteId !== farmField.farmSiteId) {
            farmStore.selectedSiteId = farmField.farmSiteId;
            if (userStore.isLoggedIn && userStore.user) userStore.user.farmSiteId = farmField.farmSiteId;
            setTimeout(() => {
                homeDashboardStore.farmFieldIdToFocusOn = farmField.farmFieldId;
            }, 1000);
        } else homeDashboardStore.farmFieldIdToFocusOn = farmField.farmFieldId;

        advancedSearchModalStore.close();
        viewFarmFieldModalStore.close();

        if (!homeDashboardStore.isHomeDashboardOpen) {
            router.push({name: 'HomeDashboard'});
        }
    }
}

//formate date
const formatDate = (date: Date | null): string => {
    if (!date) return '';
    return formatTime(date, 'd MMM, yyyy');
};

/**
 * Load data for the modal.
 */
const loadData = async (farmFieldId: number) => {
    isLoading.value = true;
    isLoadingObservations.value = true;
    isLoadingTasks.value = true;

    // Reset form
    resetForm();

    // Set data
    localFarmFieldId.value = farmFieldId;

    farmSeasonheaders.value = [
        {title: i18n.global.t('Season'), key: 'seasonName', sortable: false},
        {title: i18n.global.t('Dates'), key: 'farmFieldSeasonStartDate', sortable: true},
        {title: i18n.global.t('CropYear'), key: 'cropYear', sortable: false},
        {title: i18n.global.t('Yield'), key: 'yield', sortable: false},
        {title: i18n.global.t('Yield') + ' t/ha', key: 'yieldUnit', sortable: false},
        {title: 'CCS', key: 'CCS', sortable: false},
        {title: i18n.global.t('Report'), key: 'report', sortable: false},
    ];

    farmBlockheaders.value = [
        {title: i18n.global.t('BlockCode'), key: 'farmBlockCode', sortable: true},
        {title: i18n.global.t('Variety'), key: 'cropVariety', sortable: false},
        {title: i18n.global.t('SeedOrigin'), key: 'seedOrigin', sortable: true},
        {title: i18n.global.t('Information'), key: 'information', sortable: false},
        {title: i18n.global.t('Rows'), key: 'farmBlockNumRows', sortable: true},
        {title: i18n.global.t('Area'), key: 'farmBlockSize', sortable: true},
        {title: '', key: 'action', sortable: false},
    ];

    // Get farm field details
    const searchResults = (await apiService.post('search/farm-fields', {
        farmFieldId: farmFieldId,
        includeCropVarieties: true,
        includeFarmBlocks: true,
        includeFarmSeasons: true,
    })) as SearchFarmFieldsResponse;

    if (searchResults.farmFields.length === 1) {
        localFarmField.value = searchResults.farmFields[0];
        farmField.value = Object.assign(new ManageFarmFieldForm(), localFarmField.value);
        localFarmBlocks.value = localFarmField.value.farmBlocks;
        localFarmSeasons.value = localFarmField.value.farmFieldSeasons;

        if (!farmSites.value || farmSites.value.length === 0) {
            await getFarmSites();
        }
        const siteItem = farmSites.value.find((e) => e.farmSiteId === farmField.value?.farmSiteId);
        if (siteItem) {
            const farmSiteAbbreviationAndNameTokens = siteItem.farmSiteAbbreviationAndName.split('-');
            associatedSiteAbbreviation.value = farmSiteAbbreviationAndNameTokens[0].trim();
            if (farmSiteAbbreviationAndNameTokens.length > 1)
                associatedSiteName.value = farmSiteAbbreviationAndNameTokens[1].trim();
        }
    }

    isLoading.value = false;

    const obsResponse: SearchObservationsResponse = await apiService.post('search/observations', {
        farmFieldIdList: [farmFieldId],
        sortOrder: 'Carousel',
    } as SearchObservationsRequest);
    localObservations.value = obsResponse.observations;
    const centerObsIndex = localObservations.value.findIndex((e) => !e.isClosed);
    if (centerObsIndex !== -1) selectedObs.value = centerObsIndex;
    isLoadingObservations.value = false;

    const taskResponse: SearchWorkTasksResponse = await apiService.post('search/work-tasks', {
        farmFieldId: farmFieldId,
        sortOrder: 'Carousel',
        isCurrentSeasonOnly: true,
    } as SearchWorkTasksRequest);

    sortedWorkTasks.value = taskResponse.workTasks;

    const centerIndex = sortedWorkTasks.value.findIndex(
        (t) => !t.isCompleted && !t.isCancelled && !t.isPaused && t.completedDate === null,
    );

    if (centerIndex !== -1) selected.value = centerIndex;
    isLoadingTasks.value = false;
};

/**
 * Close the modal.
 */
const close = () => {
    viewFarmFieldModalStore.close();
};

onUnmounted(() => {
    viewFarmFieldModalStore.close();
});

/**
 * Reset the form to its default state.
 */
const resetForm = () => {
    farmField.value = null;
    isConfirmed.value = false;
    openedExpansionPanels.value = [0, 1, 2, 3];
    selected.value = 0;
    farmField.value = new ManageFarmFieldForm();
    localFarmBlocks.value = [];
    localFarmSeasons.value = [];
};

import {useDeleteFarmFieldModalStore} from '@/stores/modals/delete-farm-field-modal-store';
const deleteFarmFieldModalStore = useDeleteFarmFieldModalStore();
import {useDeleteUserModalStore} from '@/stores/modals/delete-user-modal-store';
const deleteUserModalStore = useDeleteUserModalStore();

// Watch for changes to viewUserModalStore.userId
watch(
    [
        () => viewFarmFieldModalStore.isVisible,
        () => clearTasksModalStore.deletedCounter,
        () => viewObservationModalStore.savedCounter,
        () => manageObservationModalStore.savedCounter,
        () => rescheduleWorkTaskModalStore.savedCounter,
        () => deleteOrCancelWorkTaskModalStore.savedCounter,
        () => manageWorkTaskModalStore.savedCounter,
        () => deleteFarmFieldModalStore.deletedCounter,
        () => deleteUserModalStore.deletedCounter,
    ],
    async ([isVisible]) => {
        if (isVisible && viewFarmFieldModalStore.farmFieldId !== null) {
            await loadData(viewFarmFieldModalStore.farmFieldId);
        }
    },
);

const viewCurrentTaskList = () => {
    if (localFarmFieldId.value) {
        const taskSearchRequest = new SearchWorkTasksRequest();
        taskSearchRequest.farmFieldId = localFarmFieldId.value;
        taskSearchRequest.isCurrentSeasonOnly = true;
        homeDashboardStore.workTaskListFilter = taskSearchRequest;
        userStore.closeAllPopups();
        router.push({name: 'WorkTaskList'});
    }
};
</script>

<style lang="scss">
@import '@/assets/scss/swatches.scss';

.farm-block-table .v-table__wrapper {
    overflow-y: hidden;
}

.farm-block-table .v-table__wrapper thead {
    background-color: $swatchG9;
}

.farm-block-expansion .v-expansion-panel-text__wrapper {
    padding: 0%;
}
.farm-block-expansion .v-expansion-panel-title__icon {
    margin-left: 0;
}
.farm-block-expansion .task-button {
    border: 1px solid $swatchWHT;
}

.farm-block-table .v-data-table__td,
.farm-block-table .v-table__wrapper thead {
    border: 1px solid $swatchG8; /* Adjust the color as needed */
}

.carousel-container {
    position: relative;
    width: 100%;
}

.carousel-items-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0 48px; // Account for navigation buttons
}

.carousel-item {
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    margin: 0 8px; // Fixed spacing between items
}

// Override v-slide-group default behaviors
.v-slide-group__wrapper {
    contain: none !important;
}

.v-slide-group__content {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
}

.chevron-button .v-btn__content {
    font-size: 20px;
}

// Force content to be centered when there are few items
.v-slide-group__content--no-data {
    justify-content: center !important;
}
</style>
