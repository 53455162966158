<template>
    <v-menu open-on-hover>
        <template v-slot:activator="{props}">
            <v-btn v-bind="props" class="operation-button" :elevation="elevation" rounded="x-large">
                {{ menuText }}
                <FontAwesomeIcon :icon="['fa', 'caret-down']" size="xl" class="ml-2" />
            </v-btn>
        </template>

        <v-list>
            <v-list-item v-if="!viewWorkTaskModalStore.isVisible && userStore.hasRoleLevel(RoleCode.Tasks, 1)">
                <v-list-item-title>
                    <v-btn @click="viewWorkTaskModalStore.open(workTask.workTaskId)">
                        {{ $t('ViewTaskDetails') }}
                    </v-btn>
                </v-list-item-title>
            </v-list-item>

            <v-list-item
                v-if="
                    !manageWorkTaskModalStore.isVisible &&
                    !workTask.isCompleted &&
                    !workTask.isCancelled &&
                    !workTask.isStarted &&
                    !workTask.isPaused &&
                    userStore.hasRoleLevel(RoleCode.Tasks, 2)
                ">
                <v-list-item-title>
                    <v-btn @click="manageWorkTaskModalStore.open(ManageWorkTaskModalMode.Modify, workTask.workTaskId)">
                        {{ $t('ModifyTask') }}
                    </v-btn>
                </v-list-item-title>
            </v-list-item>
            <v-list-item
                v-if="
                    !deleteOrCancelWorkTaskModalStore.isVisible &&
                    !workTask.isStarted &&
                    !workTask.isCompleted &&
                    !workTask.isCancelled &&
                    userStore.hasRoleLevel(RoleCode.Tasks, 3)
                ">
                <v-list-item-title>
                    <v-btn @click="deleteOrCancelWorkTaskModalStore.open(workTask.workTaskId)">
                        {{ $t('DeleteSlashCancelTask') }}
                    </v-btn>
                </v-list-item-title>
            </v-list-item>
            <v-list-item
                v-if="
                    !actionWorkTaskModalStore.isVisible &&
                    !workTask.isCompleted &&
                    !workTask.isCancelled &&
                    userStore.hasRoleLevel(RoleCode.Tasks, 2)
                ">
                <v-list-item-title>
                    <v-btn @click="actionWorkTaskModalStore.open(workTask.workTaskId)">
                        {{ $t('ActionTask') }}
                    </v-btn>
                </v-list-item-title>
            </v-list-item>
            <v-list-item
                v-if="
                    !actionWorkTaskModalStore.isVisible &&
                    (workTask.isCompleted || workTask.isCancelled) &&
                    userStore.hasRoleLevel(RoleCode.Tasks, 2)
                ">
                <v-list-item-title>
                    <v-btn @click="actionWorkTaskModalStore.open(workTask.workTaskId)">
                        {{ $t('ModifyCompletedTask') }}
                    </v-btn>
                </v-list-item-title>
            </v-list-item>
            <v-list-item
                v-if="
                    !rescheduleWorkTaskModalStore.isVisible &&
                    userStore.hasRoleLevel(RoleCode.Tasks, 2) &&
                    !workTask.isCompleted &&
                    !workTask.isCancelled &&
                    !workTask.isStarted &&
                    !workTask.isPaused
                ">
                <v-list-item-title>
                    <v-btn @click="rescheduleWorkTaskModalStore.open(workTask.workTaskId)">
                        {{ $t('RescheduleTask') }}
                    </v-btn>
                </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="!manageWorkTaskModalStore.isVisible && userStore.hasRoleLevel(RoleCode.Tasks, 2)">
                <v-list-item-title>
                    <v-btn
                        @click="manageWorkTaskModalStore.open(ManageWorkTaskModalMode.Duplicate, workTask.workTaskId)">
                        {{ $t('DuplicateTask') }}
                    </v-btn>
                </v-list-item-title>
            </v-list-item>
            <v-list-item
                v-if="!manageWorkTaskModalStore.isVisible && userStore.hasRoleLevel(RoleCode.PlansAndTemplates, 2)">
                <v-list-item-title>
                    <v-btn
                        @click="
                            manageWorkTaskModalStore.open(ManageWorkTaskModalMode.CreateTemplate, workTask.workTaskId)
                        ">
                        {{ $t('CreateTemplateFromTask') }}
                    </v-btn>
                </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="userStore.hasRoleLevel(RoleCode.Tasks, 1)">
                <v-list-item-title>
                    <v-btn @click="viewWorkTaskModalStore.open(workTask.workTaskId, true)">
                        {{ $t('ExportSlashPrintTask') }}
                    </v-btn>
                </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="userStore.hasRoleLevel(RoleCode.Fields, 1)">
                <v-list-item-title>
                    <v-btn @click.prevent.stop="viewOnMap(workTask.farmFieldId, workTask.farmSiteId)">
                        {{ $t('SelectField') }}
                    </v-btn>
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script setup lang="ts">
import {WorkTaskSearchResultDto} from '@/models/data-transfer-objects/search/work-task-search/WorkTaskSearchResultDto';
import {defineProps} from 'vue';
import {ManageWorkTaskModalMode} from '@/enums/modal-modes/manage-work-task-modal-mode';
import {useManageWorkTaskModalStore} from '@/stores/modals/manage-work-task-modal-store';
import {useDeleteOrCancelWorkTaskModalStore} from '@/stores/modals/delete-or-cancel-work-task-modal-store';
import {useActionWorkTaskModalStore} from '@/stores/modals/action-work-task-modal-store';
import i18n from '@/i18n';
import {RoleCode} from '@/enums/role-code';
import {useUserStore} from '@/stores/user-store';
const manageWorkTaskModalStore = useManageWorkTaskModalStore();
const deleteOrCancelWorkTaskModalStore = useDeleteOrCancelWorkTaskModalStore();
const actionWorkTaskModalStore = useActionWorkTaskModalStore();
import {useViewWorkTaskModalStore} from '@/stores/modals/view-work-task-modal-store';
const viewWorkTaskModalStore = useViewWorkTaskModalStore();
import {useRescheduleWorkTaskModalStore} from '@/stores/modals/reschedule-work-task-modal-store';
const rescheduleWorkTaskModalStore = useRescheduleWorkTaskModalStore();
const userStore = useUserStore();
import {useFarmStore} from '@/stores/farm-store';
const farmStore = useFarmStore();
import router from '@/router';
import {useHomeDashboardStore} from '@/stores/home-dashboard-store';
const homeDashboardStore = useHomeDashboardStore();
import {useAdvancedSearchModalStore} from '@/stores/modals/advanced-search-modal-store';
const advancedSearchModalStore = useAdvancedSearchModalStore();

withDefaults(
    defineProps<{
        workTask: WorkTaskSearchResultDto;
        menuText?: string;
        elevation?: number;
    }>(),
    {
        menuText: i18n.global.t('Operations'),
        elevation: 0,
    },
);

// view field on map
async function viewOnMap(farmFieldId: number, farmSiteId: number | null) {
    if (farmSiteId !== null && farmFieldId !== null) {
        if (farmStore.selectedSiteId !== farmSiteId) {
            farmStore.selectedSiteId = farmSiteId;
            if (userStore.isLoggedIn && userStore.user) userStore.user.farmSiteId = farmSiteId;
            setTimeout(() => {
                homeDashboardStore.farmFieldIdToFocusOn = farmFieldId;
            }, 1000);
        } else homeDashboardStore.farmFieldIdToFocusOn = farmFieldId;

        advancedSearchModalStore.close();
        rescheduleWorkTaskModalStore.close();
        viewWorkTaskModalStore.close();
        manageWorkTaskModalStore.close();
        actionWorkTaskModalStore.close();
        deleteOrCancelWorkTaskModalStore.close();
        if (!homeDashboardStore.isHomeDashboardOpen) {
            router.push({name: 'HomeDashboard'});
        }
    }
}
</script>
