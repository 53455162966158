import {defineStore} from 'pinia';

export const usePurgeDeviceModalStore = defineStore('purgeDeviceModal', {
    state: () => ({
        isVisible: false,
        mobileDeviceIds: null as number[] | null,
        purgedCounter: 0,
    }),
    actions: {
        open(mobileDeviceIds: number[]) {
            // Close modal first (if it's already open) so that the watch is triggered when it's reopened
            this.close();
            this.mobileDeviceIds = mobileDeviceIds;
            this.isVisible = true;
        },
        close() {
            this.isVisible = false;
            this.mobileDeviceIds = null;
        },
    },
});
