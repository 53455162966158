import {format} from 'date-fns';
import {enAU, id} from 'date-fns/locale';
import i18n from '@/i18n'; // Your i18n instance
import type {Locale} from 'date-fns';

const dateFnsLocaleMap: Record<string, Locale> = {
    'en-AU': enAU,
    'id-ID': id,
};

export function formatTime(date: Date | number | string | null, pattern = 'dd MMM yyyy HH:mm'): string {
    if (!date) return '';

    const dateObj = typeof date === 'string' || typeof date === 'number' ? new Date(date) : date;

    const currentLocale = i18n.global.locale;

    const dateFnsLocale = dateFnsLocaleMap[currentLocale] || enAU;

    return format(dateObj, pattern, {locale: dateFnsLocale});
}
