<template>
    <div>
        <v-card outlined style="overflow: visible">
            <v-toolbar flat color="swatchG2">
                <v-toolbar-title>
                    <FontAwesomeIcon :icon="byPrefixAndName.fal['cog']" size="xl" class="mr-2" />
                    {{ $t('ManageSystemConfiguration') }}
                </v-toolbar-title>
            </v-toolbar>

            <div class="tblList pa-4">
                <div class="tblListRow">
                    <div class="tblListCell head" style="width: 20%">{{ $t('Config') }}</div>
                    <div class="tblListCell head" style="width: 35%">{{ $t('ConfigValue') }}</div>
                    <div class="tblListCell head" style="width: 35%">{{ $t('ConfigDescription') }}</div>
                    <div class="tblListCell head" style="width: 10%">{{ $t('Action') }}</div>
                </div>

                <div
                    class="tblListRow hover separator"
                    v-for="(configSetting, index) in configSettingList"
                    :key="index"
                    @click="openPopup(configSetting, true)">
                    <div class="tblListCell">{{ configSetting.configSettingName }}</div>
                    <div class="tblListCell">
                        <div v-if="configSetting.configSettingDataType == 'String'">
                            {{
                                configSetting.configSettingCustomValue != null
                                    ? configSetting.configSettingCustomValue.configSettingValueString
                                    : configSetting.configSettingDefaultValueString
                            }}
                        </div>
                        <div v-else-if="configSetting.configSettingDataType == 'Number'">
                            {{
                                configSetting.configSettingCustomValue != null
                                    ? configSetting.configSettingCustomValue.configSettingValueNumeric
                                    : configSetting.configSettingDefaultValueNumeric
                            }}
                        </div>
                        <div v-else-if="configSetting.configSettingDataType == 'Boolean'">
                            {{
                                configSetting.configSettingCustomValue != null
                                    ? configSetting.configSettingCustomValue.configSettingValueBoolean
                                    : configSetting.configSettingDefaultValueBoolean
                            }}
                        </div>
                    </div>
                    <div class="tblListCell">
                        <TranslatableTexts :text="configSetting.configSettingDescription" />
                    </div>
                    <div class="tblListCell" style="text-align: center">
                        <div>
                            <v-menu open-on-hover>
                                <template v-slot:activator="{props}">
                                    <v-btn color="primary" v-bind="props"> {{ $t('Operations') }} </v-btn>
                                </template>

                                <v-list>
                                    <v-list-item>
                                        <v-list-item-title>
                                            <v-btn @click="openPopup(configSetting, true)">
                                                {{ $t('ViewThisSystemConfig') }}
                                            </v-btn>
                                        </v-list-item-title>
                                        <v-list-item-title v-if="hasModifyConfigAccess">
                                            <v-btn @click="openPopup(configSetting, false)">
                                                {{ $t('ModifyThisSystemConfig') }}
                                            </v-btn>
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                    </div>
                </div>
            </div>
            <v-card-actions class="swatchG9BG">
                <BackButton />
            </v-card-actions>
            <v-dialog v-model="isPopupOpen" max-width="900">
                <v-card>
                    <v-toolbar flat color="swatchG2">
                        <v-toolbar-title>
                            <FontAwesomeIcon :icon="byPrefixAndName.fal['cog']" size="xl" class="mr-2" />
                            {{ popupTitle }}
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <CloseModalButton @click="isPopupOpen = false" />
                    </v-toolbar>

                    <LoadingSymbol v-if="panelLoading" />

                    <table style="width: 100%" class="tblForm pa-4">
                        <tr>
                            <td class="tableCell padding" style="width: 25%">
                                <div
                                    class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                                    <span>{{ $t('Config') }}</span>
                                    <HelpIcon :help-text="$t('SystemConfig_Config_HelpText')" />
                                </div>
                            </td>
                            <td style="width: 75%">
                                {{ selectedConfig?.configSettingName }}
                            </td>
                        </tr>

                        <tr>
                            <td class="tableCell padding" style="width: 25%">
                                <div
                                    class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                                    <span>{{ $t('ConfigValue') }}</span>
                                    <HelpIcon :help-text="$t('SystemConfig_ConfigValue_HelpText')" />
                                </div>
                            </td>
                            <td style="width: 75%">
                                <v-text-field
                                    v-if="selectedConfig?.configSettingDataType == 'String'"
                                    v-model="valueString"
                                    rows="1"
                                    auto-grow
                                    :rules="[(v: any) => !!v || $t('AValueIsRequired')]"
                                    :disabled="isViewMode" />
                                <v-text-field
                                    v-else-if="selectedConfig?.configSettingDataType == 'Number'"
                                    v-model="valueNumber"
                                    type="number"
                                    :rules="[(v: any) => !!v || $t('AValueIsRequired')]"
                                    oninput="if(this.value < 0) this.value = 0;"
                                    :disabled="isViewMode" />
                                <v-select
                                    v-else-if="selectedConfig?.configSettingDataType == 'Boolean'"
                                    :items="[
                                        {text: $t('True'), value: true},
                                        {text: $t('False'), value: false},
                                    ]"
                                    v-model="valueBoolean"
                                    hide-details
                                    item-title="text"
                                    item-value="value"
                                    style="width: 150px"
                                    :rules="[(v: null | undefined) => (v !== null && v !== undefined) || $t('ASelectionIsRequired')]"
                                    :disabled="isViewMode" />
                            </td>
                        </tr>

                        <tr>
                            <td class="tableCell padding" style="width: 25%">
                                <div
                                    class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                                    <span>{{ $t('ConfigDescription') }}</span>
                                    <HelpIcon :help-text="$t('SystemConfig_ConfigDescription_HelpText')" />
                                </div>
                            </td>
                            <td style="width: 75%">
                                <TranslatableTexts :text="selectedConfig?.configSettingDescription" />
                            </td>
                        </tr>
                    </table>

                    <v-card-actions class="justify-space-between swatchG9BG">
                        <v-btn @click="isPopupOpen = false">
                            <template v-slot:prepend>
                                <FontAwesomeIcon :icon="byPrefixAndName.fal['arrow-rotate-left']" size="xl" />
                            </template>
                            {{ $t('Cancel') }}
                        </v-btn>
                        <v-btn
                            v-if="!isViewMode"
                            :disabled="
                                (selectedConfig?.configSettingDataType == 'String' && valueString == '') ||
                                (selectedConfig?.configSettingDataType == 'Number' && valueNumber?.toString() == '') ||
                                (selectedConfig?.configSettingDataType == 'Boolean' && valueBoolean == null)
                            "
                            @click="
                                saveChanges();
                                isPopupOpen = false;
                            ">
                            <template v-slot:prepend>
                                <FontAwesomeIcon :icon="byPrefixAndName.fal['save']" size="xl" />
                            </template>
                            {{ $t('SaveChanges') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
        <v-snackbar v-model="snackbar.show" timeout="4000">
            {{ snackbar.text }}
        </v-snackbar>
    </div>
</template>

<script lang="ts">
import ApiService from '@/services/api-service';
import {defineComponent, inject, ref, computed, onMounted} from 'vue';
import {useUserStore} from '@/stores/user-store';
import {ConfigSettingDto} from '@/models/system/ConfigSettingDto';
import {byPrefixAndName} from '@awesome.me/kit-b64aff0d40/icons';
import CloseModalButton from '@/components/CloseModalButton.vue';
import {RoleCode} from '@/enums/role-code';
import i18n from '@/i18n';
import {useConfigStore} from '@/stores/config-store';

export default defineComponent({
    setup() {
        const userStore = useUserStore();
        const configStore = useConfigStore();
        const apiService = inject('apiService') as ApiService;
        const configSettingList = ref<ConfigSettingDto[]>([]);
        const selectedConfig = ref<ConfigSettingDto | null>(null);
        const isViewMode = ref<boolean>(true);
        const panelLoading = ref<boolean>(false);
        const hasModifyConfigAccess = userStore.hasRoleLevel(RoleCode.System, 3);
        const isPopupOpen = ref(false);

        const popupTitle = computed(() => {
            if (isViewMode.value) {
                return i18n.global.t('ViewSystemConfigurationDetails');
            } else {
                return i18n.global.t('ModifySystemConfigurationDetails');
            }
        });

        const snackbar = ref({
            show: false,
            text: '',
        });

        const valueString = computed({
            get() {
                return selectedConfig.value?.configSettingCustomValue
                    ? selectedConfig.value.configSettingCustomValue.configSettingValueString
                    : selectedConfig.value?.configSettingDefaultValueString ?? null;
            },
            set(newValue: string | null) {
                if (selectedConfig.value && selectedConfig.value.configSettingCustomValue) {
                    selectedConfig.value.configSettingCustomValue.configSettingValueString = newValue;
                } else if (selectedConfig.value) {
                    selectedConfig.value.configSettingDefaultValueString = newValue;
                }
            },
        });

        const valueNumber = computed({
            get() {
                return selectedConfig.value?.configSettingCustomValue
                    ? selectedConfig.value.configSettingCustomValue.configSettingValueNumeric
                    : selectedConfig.value?.configSettingDefaultValueNumeric ?? null;
            },
            set(newValue: number | null) {
                if (selectedConfig.value && selectedConfig.value.configSettingCustomValue) {
                    selectedConfig.value.configSettingCustomValue.configSettingValueNumeric = newValue;
                } else if (selectedConfig.value) {
                    selectedConfig.value.configSettingDefaultValueNumeric = newValue;
                }
            },
        });

        const valueBoolean = computed({
            get() {
                return selectedConfig.value?.configSettingCustomValue
                    ? selectedConfig.value.configSettingCustomValue.configSettingValueBoolean
                    : selectedConfig.value?.configSettingDefaultValueBoolean ?? null;
            },
            set(newValue: boolean | null) {
                if (selectedConfig.value && selectedConfig.value.configSettingCustomValue) {
                    selectedConfig.value.configSettingCustomValue.configSettingValueBoolean = newValue;
                } else if (selectedConfig.value) {
                    selectedConfig.value.configSettingDefaultValueBoolean = newValue;
                }
            },
        });

        /**
         * Initialize the page.
         */
        async function init() {
            try {
                const response = await apiService.get('config/config-settings');
                configSettingList.value = response;
            } catch (error) {
                snackbar.value.show = true;
                snackbar.value.text = i18n.global.t('ErrorGeneric');
            }
        }

        /**
         * Opens the view / modify config popup.
         */
        function openPopup(configSetting: ConfigSettingDto, viewMode: boolean) {
            selectedConfig.value = JSON.parse(JSON.stringify(configSetting));
            isViewMode.value = viewMode;
            isPopupOpen.value = true;
        }

        /**
         * Attempts to save changes made to a config.
         */
        async function saveChanges() {
            panelLoading.value = true;

            const params = {
                configSettingName: selectedConfig.value?.configSettingName,
                configSettingValueString: valueString.value,
                configSettingValueNumeric: valueNumber.value,
                configSettingValueBoolean: valueBoolean.value,
            };

            try {
                await apiService.post('config/config-settings', params);

                // Show success feedback to user
                snackbar.value.show = true;
                snackbar.value.text = i18n.global.t('ModifyConfig_Success');

                const updatedConfig = selectedConfig.value;

                if (updatedConfig) {
                    const index = configSettingList.value.findIndex(
                        (cs) => cs.configSettingName === selectedConfig.value?.configSettingName,
                    );

                    if (index !== -1) {
                        configSettingList.value[index] = updatedConfig;
                    }

                    await configStore.fetchConfigSettings(apiService);
                }
            } catch (error) {
                // Show fail feedback to user
                snackbar.value.show = true;
                snackbar.value.text = i18n.global.t('ErrorGeneric');
            }

            panelLoading.value = false;
        }

        onMounted(async () => {
            await init();
        });

        return {
            byPrefixAndName,
            userStore,
            apiService,
            init,
            configSettingList,
            selectedConfig,
            isViewMode,
            popupTitle,
            panelLoading,
            valueString,
            valueNumber,
            valueBoolean,
            CloseModalButton,
            hasModifyConfigAccess,
            isPopupOpen,
            snackbar,
            openPopup,
            saveChanges,
        };
    },
});
</script>
