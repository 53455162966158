<template>
    <div style="display: flex; align-items: center">
        <div v-if="sequenceNumber" class="mr-2">
            <CircleIcon class="sequence-number-icon" :style="{height: '40px', width: '40px', fontSize: '14pt'}">
                <div>{{ sequenceNumber }}</div>
            </CircleIcon>
        </div>

        <WorkTaskTemplateChip
            :work-task-type-code="workTaskTemplate.workTaskTypeCode"
            :work-task-template-name="workTaskTemplate.workTaskTemplateName" />

        <div class="ml-2">
            <IdIcon>{{ workTaskTemplate.workTaskTemplateId }}</IdIcon>
        </div>

        <div class="ml-10">
            <WorkTaskTypeChip :workTaskTypeCode="workTaskTemplate.workTaskTypeCode" />
        </div>

        <div v-if="numberOfDaysDueAfterPreviousTask != null" style="margin-left: auto">
            <WorkTaskPlanItemDaysDueAfter
                :number-of-days-due-after-previous-task="numberOfDaysDueAfterPreviousTask"
                icon-size="lg" />
        </div>

        <div v-if="showOperationsMenu" style="margin-left: auto">
            <WorkTaskTemplateOperationsMenu
                :work-task-template="workTaskTemplate"
                :show-delete-task-template="showDeleteTaskLink" />
        </div>
    </div>

    <div
        v-if="showCreatedAndModifiedDetails"
        :class="{'mt-4': workTaskTemplate.createdUserId || workTaskTemplate.modifiedUserId}">
        <!-- Created -->
        <div v-if="workTaskTemplate.createdUserId" class="flex-layout fill flex-layout-space-05">
            <div class="formHead" style="width: 12rem">{{ $t('Created') }}</div>
            <div style="flex-grow: 1">
                <UserChip
                    :user="
                        UserHelper.userChipType(
                            workTaskTemplate.createdUserId,
                            UserHelper.userFullName(
                                workTaskTemplate.createdUserGivenName,
                                workTaskTemplate.createdUserSurname,
                            ),
                        )
                    " />

                <span class="swatchA1 ml-4">{{ formatDate(workTaskTemplate.createdDate) }}</span>
            </div>

            <div style="display: flex; align-items: center; gap: 0.5rem; margin-left: auto">
                <WorkTaskTemplateNumberOfTasksChip :number-of-work-tasks="workTaskTemplate.numberOfWorkTasks" />
                <WorkTaskTemplateNumberOfTaskPlansChip
                    :number-of-work-task-plans="workTaskTemplate.numberOfWorkTaskPlans" />
            </div>
        </div>

        <!-- Last Modified -->
        <div v-if="workTaskTemplate.modifiedUserId" class="flex-layout fill flex-layout-space-05">
            <div class="formHead" style="width: 12rem">{{ $t('LastModified') }}</div>
            <div>
                <UserChip
                    :user="
                        UserHelper.userChipType(
                            workTaskTemplate.modifiedUserId,
                            UserHelper.userFullName(
                                workTaskTemplate.modifiedUserGivenName,
                                workTaskTemplate.modifiedUserSurname,
                            ),
                        )
                    " />

                <span class="swatchA1 ml-4">{{ formatDate(workTaskTemplate.modifiedDate) }}</span>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {defineProps} from 'vue';
import {WorkTaskTemplateSearchResultDto} from '@/models/data-transfer-objects/search/work-task-template-search/WorkTaskTemplateSearchResultDto';
import {formatTime} from '@/helpers/TimeFormatHelper';
import UserChip from '@/components/UserChip.vue';
import WorkTaskTemplateNumberOfTasksChip from '@/components/other-chips/WorkTaskTemplateNumberOfTasksChip.vue';
import WorkTaskTemplateNumberOfTaskPlansChip from '@/components/other-chips/WorkTaskTemplateNumberOfTaskPlansChip.vue';
import WorkTaskPlanItemDaysDueAfter from '@/components/other-chips/WorkTaskPlanItemDaysDueAfter.vue';
import WorkTaskTemplateChip from '@/components/WorkTaskTemplateChip.vue';
import WorkTaskTemplateOperationsMenu from '@/components/operations-menus/WorkTaskTemplateOperationsMenu.vue';
import UserHelper from '@/helpers/UserHelper';

withDefaults(
    defineProps<{
        workTaskTemplate: WorkTaskTemplateSearchResultDto;
        showOperationsMenu?: boolean;
        showCreatedAndModifiedDetails?: boolean;
        showDeleteTaskLink?: boolean;
        sequenceNumber?: number;
        numberOfDaysDueAfterPreviousTask?: number | null;
    }>(),
    {
        showOperationsMenu: true,
        showCreatedAndModifiedDetails: true,
        showDeleteTaskLink: true,
    },
);

const formatDate = (date: Date | null): string | null => {
    if (!date) return null;
    return formatTime(date, 'dd MMMM yyyy');
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/swatches.scss';

.sequence-number-icon {
    color: $swatchWHT;
    font-size: 12pt;
    font-style: normal;
    font-weight: bold;
    background-color: $swatchG1;
}
</style>
