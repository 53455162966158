<template>
    <LoadingSymbol v-if="isLoading" />

    <table v-else style="width: 100%" class="tblForm">
        <tr>
            <td colspan="4" class="subHeader">{{ $t('Particulars') }}</td>
        </tr>
        <tr>
            <td class="tableCell padding" style="width: 15%; min-width: 10rem">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('MixtureName') }}</span>
                    <HelpIcon :help-text="$t('MixtureSearch_MixtureName_HelpText')" />
                </div>
            </td>
            <td style="width: 35%">
                <v-text-field v-model="localSearchParameters.mixtureName" hide-details clearable style="width: 400px">
                </v-text-field>
            </td>
            <td class="tableCell padding" style="width: 15%; min-width: 10rem">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span> {{ $t('ProductUsed') }}</span>
                    <HelpIcon :help-text="$t('MixtureSearch_ProductName_HelpText')" />
                </div>
            </td>
            <td style="width: 35%">
                <v-select
                    v-model="localSearchParameters.productIdList"
                    hide-details
                    multiple
                    clearable
                    chips
                    :items="products"
                    item-title="productName"
                    item-value="productId"
                    max-width="400px" />
            </td>
        </tr>
        <tr>
            <td class="tableCell padding">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('ProductType') }}</span>
                    <HelpIcon :help-text="$t('MixtureSearch_ProductType_HelpText')" />
                </div>
            </td>
            <td>
                <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                    <v-select
                        v-model="localSearchParameters.productTypeCodeList"
                        hide-details
                        multiple
                        clearable
                        chips
                        :items="productTypes"
                        item-title="productTypeName"
                        item-value="productTypeCode"
                        max-width="400px"></v-select>
                </div>
            </td>
            <td class="tableCell padding">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('ProductSubtype') }}</span>
                    <HelpIcon :help-text="$t('MixtureSearch_MixtureType_HelpText')" />
                </div>
            </td>
            <td>
                <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                    <v-select
                        v-model="localSearchParameters.productSubtypeCodeList"
                        hide-details
                        multiple
                        clearable
                        chips
                        :items="filteredProductSubtypes"
                        item-title="productSubtypeName"
                        item-value="productSubtypeCode"
                        max-width="400px"></v-select>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="4" class="subHeader">{{ $t('AttributesAndAssociations') }}</td>
        </tr>
        <tr>
            <td class="tableCell padding" style="width: 15%; min-width: 10rem">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('FarmSite(s)Applied') }}</span>
                    <HelpIcon :help-text="$t('MixtureSearch_FarmSites_HelpText')" />
                </div>
            </td>
            <td style="width: 35%">
                <v-select
                    v-model="localSearchParameters.farmSiteIdList"
                    hide-details
                    multiple
                    clearable
                    chips
                    :items="farmSites"
                    item-title="farmSiteAbbreviationAndName"
                    item-value="farmSiteId"
                    max-width="400px"></v-select>
            </td>
            <td class="tableCell padding" style="width: 15%; min-width: 10rem">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('FieldApplied') }}</span>
                    <HelpIcon :help-text="$t('MixtureSearch_Field_HelpText')" />
                </div>
            </td>
            <td>
                <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                    <v-autocomplete
                        v-model="localSearchParameters.farmFieldIdList"
                        hide-details
                        multiple
                        clearable
                        chips
                        :items="farmFields"
                        item-title="farmFieldCodeAndName"
                        item-value="farmFieldId"
                        max-width="400px" />
                </div>
            </td>
        </tr>
        <tr>
            <td class="tableCell padding" style="width: 15%; min-width: 10rem">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('DateRangeApplied') }}</span>
                    <HelpIcon :help-text="$t('MixtureSearch_DateRange_HelpText')" />
                </div>
            </td>
            <td>
                <VueDatePicker
                    style="width: 25rem"
                    v-model="dateRange"
                    placeholder="DD/MM/YYYY - DD/MM/YYYY"
                    :range="{partialRange: false}"
                    format="dd/MM/yyyy"
                    text-input
                    :teleport="true"
                    :enable-time-picker="false"
                    :preset-dates="presetDates"
                    :locale="i18n.global.locale" />
            </td>
        </tr>
    </table>
</template>

<script setup lang="ts">
import {endOfToday, subDays, subMonths, subYears} from 'date-fns';
import {onMounted, ref, watch, defineAsyncComponent, computed} from 'vue';
import {SearchMixtureRequest} from '@/models/api/requests/search/SearchMixtureRequest';
import i18n from '@/i18n';
import {useFarmSites} from '@/composables/data-source/farm-sites';
const {getFarmSites, farmSites} = useFarmSites();
import {useFarmFields} from '@/composables/data-source/farm-fields';
const {getFarmFields, farmFields} = useFarmFields();
import {useProductTypes} from '@/composables/data-source/product-types';
const {getProductTypes, productTypes} = useProductTypes();
import {useProductSubtypes} from '@/composables/data-source/product-subtypes';
const {getProductSubtypes, productSubtypes} = useProductSubtypes();
import {useProducts} from '@/composables/data-source/products';
const {getProducts, products} = useProducts();

import {formatTime} from '@/helpers/TimeFormatHelper';

const props = defineProps<{searchParameters: SearchMixtureRequest}>();
const emit = defineEmits(['update:searchParameters']);
const isLoading = ref<boolean>(false);

const dateRange = ref<Array<Date>>([]);

// Dynamically import VueDatePicker
const VueDatePicker = defineAsyncComponent(() => import('@vuepic/vue-datepicker'));

//The present date for the date picker
const presetDates = ref([
    {
        label: i18n.global.t('Last30Days'),
        value: [subDays(new Date(), 30), endOfToday()],
    },
    {
        label: i18n.global.t('Last3Months'),
        value: [subMonths(new Date(), 3), endOfToday()],
    },
    {
        label: i18n.global.t('LastYear'),
        value: [subYears(new Date(), 1), endOfToday()],
    },
]);

// Create a local copy of the prop
const localSearchParameters = ref({...props.searchParameters});
const filteredProductSubtypes = computed(() =>
    localSearchParameters.value.productTypeCodeList && localSearchParameters.value.productTypeCodeList.length > 0
        ? productSubtypes.value.filter((e) =>
              localSearchParameters.value.productTypeCodeList?.includes(e.productTypeCode),
          )
        : productSubtypes.value,
);

// Watch for changes in the parent parameters object and update local copy
watch(
    () => props.searchParameters,
    (newVal) => {
        if (JSON.stringify(newVal) !== JSON.stringify(localSearchParameters.value)) {
            localSearchParameters.value = {...newVal};
            if (newVal.dateRangeTo === null || newVal.dateRangeFrom === null) dateRange.value = [];
            else {
                dateRange.value = [new Date(newVal.dateRangeFrom), new Date(newVal.dateRangeTo)];
            }
        }
    },
    {deep: true, immediate: true},
);

// Watch for changes in the local copy and emit the update event
watch(
    localSearchParameters.value,
    (newVal) => {
        if (JSON.stringify(newVal) !== JSON.stringify(props.searchParameters)) {
            emit('update:searchParameters', newVal);
        }
    },
    {deep: true},
);

watch(
    () => localSearchParameters.value.productTypeCodeList,
    () => {
        localSearchParameters.value.productSubtypeCodeList = [];
    },
    {deep: true},
);

/**
 * Gets the From date (first date) from a date range array.
 */
const getFromDate = (dates: Array<Date> | null) => {
    // If there is a From date
    if (dates != null && dates.length >= 1 && dates[0] != null) {
        // Return From date
        return formatTime(dates[0], 'yyyy-MM-dd');
    }

    return null;
};

/**
 * Gets the To date (second date) from a date range array.
 */
const getToDate = (dates: Array<Date> | null) => {
    // If there is a To date
    if (dates != null && dates.length >= 2 && dates[1] != null) {
        // Return To date
        return formatTime(dates[1], 'yyyy-MM-dd');
    }

    return null;
};

// Watch for changes in the dateRaised object and update local parameters
watch(
    () => dateRange.value,
    (newVal) => {
        localSearchParameters.value.dateRangeFrom = getFromDate(newVal);
        localSearchParameters.value.dateRangeTo = getToDate(newVal);
    },
    {deep: true},
);

// Fetch data for dropdown
onMounted(async () => {
    isLoading.value = true;

    // Get data
    await getFarmSites();

    // Farm Fields
    await getFarmFields();

    //product data
    await getProductTypes();
    await getProductSubtypes();
    await getProducts();

    // reset date range object to an empty array when the any of the date range parameters passed are nulls.
    if (props.searchParameters.dateRangeTo === null || props.searchParameters.dateRangeFrom === null)
        dateRange.value = [];
    else {
        //Convert the passed parameter string to a two-date-object array to show value on the date picker
        dateRange.value = [
            new Date(props.searchParameters.dateRangeFrom),
            new Date(props.searchParameters.dateRangeTo),
        ];
    }

    isLoading.value = false;
});
</script>
