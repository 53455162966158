<template>
    <v-dialog v-model="clearTasksModalStore.isVisible" style="width: 70vw">
        <v-card style="overflow-y: hidden">
            <v-toolbar color="swatchA2">
                <v-toolbar-title>
                    <FontAwesomeIcon :icon="['fal', 'trash']" size="xl" class="mr-2" />
                    {{ $t('ClearTasks') }}
                </v-toolbar-title>
                <v-spacer />
                <CloseModalButton @click="close" />
            </v-toolbar>
            <LoadingSymbol v-if="isLoading" />

            <v-form v-else ref="form" class="pa-4" v-model="valid" style="overflow-y: scroll">
                <WorkTaskDetailsHeader v-if="farmField" :farm-field="farmField!" :show-view-field-button="false" />
                <table style="width: 100%" class="tblForm pa-4 pb-0">
                    <tr>
                        <td class="tableCell padding" style="width: 15%; min-width: 5rem">
                            <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                                <span>{{ $t('ClearTasksAfter') }}</span>
                                <HelpIcon :help-text="$t('ClearTasks_ClearTasksAfter_HelpText')" />
                            </div>
                        </td>
                        <td>
                            <VueDatePicker
                                v-model="clearDate"
                                format="dd/MM/yyyy"
                                :enable-time-picker="false"
                                text-input
                                style="width: 200px"
                                :teleport="true"
                                :rules="[requiredRule]"
                                :locale="i18n.global.locale" />
                            <!-- DatePicker Validation -->
                            <v-validation :rules="[dateRequiredRule]">
                                <template v-slot="{errorMessages, isValid}">
                                    <div v-if="isValid.value != null">
                                        <span
                                            v-for="(error, index) in errorMessages.value"
                                            :key="index"
                                            class="error-message">
                                            {{ error }}
                                        </span>
                                    </div>
                                </template>
                            </v-validation>
                        </td>
                    </tr>
                    <tr>
                        <td class="tableCell padding">
                            <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                                <span>{{ $t('Explanation') }}</span>
                                <HelpIcon :help-text="$t('ClearTasks_Explanation_HelpText')" />
                            </div>
                        </td>
                        <td>
                            <v-textarea
                                v-model="clearExplanation"
                                class="explanation-text"
                                rows="1"
                                max-rows="5"
                                auto-grow
                                clearable
                                :rules="[requiredRule]">
                            </v-textarea>
                        </td>
                    </tr>
                </table>

                <div class="subHeader">
                    {{ $t('ImpactOfChange') }}
                </div>
                <div class="bold swatchA1 pa-2">
                    <span v-if="workTasks && workTasks.length > 0">
                        {{ $t('TheFollowingTasksWillBeDeleted_Colon') }}
                    </span>
                    <span v-else>
                        {{ $t('NoTasksFound') }}
                    </span>
                </div>

                <table v-if="workTasks && workTasks.length > 0" class="tblForm pa-4">
                    <tr v-for="workTask in workTasks" :key="workTask.workTaskId" class="swatchA1">
                        <td>
                            <WorkTaskTypeChip
                                :workTaskTypeCode="workTask.workTaskTypeCode"
                                size="default"
                                icon-size="lg"
                                style="width: 100%" />
                        </td>
                        <td v-if="workTask.workTaskName" class="bold">
                            {{ `"${workTask.workTaskName}"` }}
                        </td>
                        <td>
                            {{ `(${$t('ID')}: ${workTask.workTaskId})` }}
                        </td>
                        <td>
                            <WorkTaskDateIcon class="pr-2" :work-task="workTask" />
                            <WorkTaskDate :work-task="workTask" date-format="d MMM yyyy" />
                        </td>
                    </tr>
                </table>
                <!-- Confirm Delete -->
                <v-card v-if="workTasks && workTasks.length > 0" class="marginTop">
                    <v-card-text class="complete-confirmation pa-0">
                        <v-row class="confirm-header pa-3 ma-0">
                            <FontAwesomeIcon :icon="['fal', 'check']" size="xl" class="mr-5" />
                            <span> {{ $t('ConfirmDeletion') }} </span>
                        </v-row>

                        <div class="pa-2">
                            <v-row class="ma-1">
                                <v-col cols="1" align-self="center">
                                    <v-checkbox v-model="isConfirmed" class="confirm-checkbox" hide-details />
                                </v-col>
                                <v-col cols="11" align-self="center" style="font-size: medium">
                                    {{ $t('ClearTasks_Confirmation') }}
                                </v-col>
                            </v-row>
                        </div>
                    </v-card-text>
                </v-card>
            </v-form>

            <v-card-actions class="justify-space-between swatchG9BG" v-if="!isLoading">
                <v-btn @click="close">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'arrow-rotate-left']" size="xl" />
                    </template>
                    {{ $t('Cancel') }}
                </v-btn>
                <v-btn
                    @click="confirmDelete"
                    :disabled="!isConfirmed || !(workTasks && workTasks.length > 0)"
                    v-if="userHasAccess">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'check']" size="xl" />
                    </template>
                    {{ $t('Save') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" timeout="4000">
        {{ snackbar.text }}
    </v-snackbar>
</template>

<script setup lang="ts">
import '@/assets/scss/farm/delete-farm-site-modal.scss';
import '@/assets/scss/user/user-manager.scss';

import {useUserStore} from '@/stores/user-store';
import {RoleCode} from '@/enums/role-code';
import {useClearTasksModalStore} from '@/stores/modals/clear-tasks-modal-store';
import {watch, inject, ref, onUnmounted, defineAsyncComponent, computed} from 'vue';
import ApiService from '@/services/api-service.js';
import CloseModalButton from '@/components/CloseModalButton.vue';
import {FarmFieldSearchResultDto} from '@/models/data-transfer-objects/search/farm-field-search/FarmFieldSearchResultDto';
import {SearchFarmFieldsResponse} from '@/models/api/responses/search/SearchFarmFieldsResponse';
import {SearchFarmFieldsRequest} from '@/models/api/requests/search/SearchFarmFieldsRequest';
import WorkTaskDate from '@/components/work-tasks/WorkTaskDate.vue';
import WorkTaskDateIcon from '@/components/work-tasks/WorkTaskDateIcon.vue';
import {ClearWorkTasksRequest} from '@/models/api/requests/work-tasks/ClearWorkTasksRequest';
import i18n from '@/i18n';
import Validation from '@/helpers/ValidationHelper';
import WorkTaskDetailsHeader from '@/components/WorkTaskDetailsHeader.vue';
import {SearchWorkTasksRequest} from '@/models/api/requests/search/SearchWorkTasksRequest';
import {SearchWorkTasksResponse} from '@/models/api/responses/search/SearchWorkTasksResponse';
import {WorkTaskSearchResultDto} from '@/models/data-transfer-objects/search/work-task-search/WorkTaskSearchResultDto';
import {formatTime} from '@/helpers/TimeFormatHelper';
const userStore = useUserStore();
const VueDatePicker = defineAsyncComponent(() => import('@vuepic/vue-datepicker'));

// Form
const form = ref();
const valid = ref<boolean>(true);
let isLoading = ref<boolean>(false);
const snackbar = ref({
    show: false,
    text: '',
});
const clearTasksModalStore = useClearTasksModalStore();
const isConfirmed = ref<boolean>(false);
const farmField = ref<FarmFieldSearchResultDto | null>(null);
const workTasks = ref<WorkTaskSearchResultDto[] | null>(null);

// Services
const apiService = inject('apiService') as ApiService;

const userHasAccess = computed(() => userStore.hasRoleLevel(RoleCode.Tasks, 3));

const clearDate = ref<Date>(new Date());
const clearExplanation = ref<string | null>(null);

// Rules
const requiredRule = Validation.createRule_Required();
const dateRequiredRule = () => {
    if (clearDate.value !== null) {
        return true;
    }

    return i18n.global.t('Validation_Required');
};

/**
 * Load farm field data.
 */
const loadData = async () => {
    isLoading.value = true;

    // Reset form
    resetForm();

    // If a farm field ID was specified
    if (clearTasksModalStore.farmFieldId !== null) {
        // Build search request using the farm field ID
        const searchParameters = new SearchFarmFieldsRequest({
            farmFieldId: clearTasksModalStore.farmFieldId,
        });

        // Get farm field details
        const searchResults = (await apiService.post(
            'search/farm-fields',
            searchParameters,
        )) as SearchFarmFieldsResponse;

        if (searchResults.farmFields.length == 1) {
            // Store search result
            farmField.value = searchResults.farmFields[0];
        }
    }

    isLoading.value = false;
};

/**
 * Load work task data.
 */
const loadWorkTasks = async () => {
    isLoading.value = true;

    // If a farm field ID was specified
    if (clearTasksModalStore.farmFieldId !== null) {
        // Build search request using the farm field ID
        const searchParameters = new SearchWorkTasksRequest({
            farmFieldId: clearTasksModalStore.farmFieldId,
            dateDueFrom: formatTime(clearDate.value, 'yyyy-MM-dd'),
            workTaskStatus: 'Incomplete',
        });

        // Get work tasks
        const searchResults = (await apiService.post('search/work-tasks', searchParameters)) as SearchWorkTasksResponse;

        workTasks.value = searchResults.workTasks;
    }

    isLoading.value = false;
};

/**
 * Close the modal.
 */
const close = () => {
    clearTasksModalStore.close();
};

/**
 * Save the modal.
 */
const confirmDelete = async () => {
    if (farmField.value == null) return;

    if (!userHasAccess.value) return;

    // Perform final client side validation of form
    await form.value.validate();

    // If form is valid
    if (valid.value) {
        isLoading.value = true;

        try {
            // Build API request
            const clearWorkTasksRequest: ClearWorkTasksRequest = {
                workTaskIds: workTasks.value ? workTasks.value.map((wt) => wt.workTaskId) : [],
                deletedExplanation: clearExplanation.value!,
            };

            // Call API to delete tasks
            const response = await apiService.post('work-tasks/clear-work-tasks', clearWorkTasksRequest);

            if (response) {
                // Show success feedback to user
                snackbar.value.show = true;
                snackbar.value.text = i18n.global.t('ClearTasks_Success');
                clearTasksModalStore.deletedCounter++;

                // Close modal
                clearTasksModalStore.close();
            } else {
                // Show fail feedback to user
                snackbar.value.show = true;
                snackbar.value.text = i18n.global.t('ErrorGeneric');
                isLoading.value = false;
            }
        } catch (ex: unknown) {
            // Show fail feedback to user
            snackbar.value.show = true;
            snackbar.value.text = i18n.global.t('ErrorGeneric');
            isLoading.value = false;
        }
    }
};

onUnmounted(() => {
    clearTasksModalStore.close();
});

/**
 * Reset the form to its default state.
 */
const resetForm = () => {
    clearDate.value = new Date();
    clearExplanation.value = null;
    farmField.value = null;
    isConfirmed.value = false;
};

/**
 * Watch for changes.
 */
watch(
    () => clearTasksModalStore.isVisible,
    async (isVisible) => {
        if (isVisible) {
            await loadData();
        }
    },
);

watch(
    () => clearDate.value,
    async (val) => {
        if (val) {
            clearExplanation.value = null;
            isConfirmed.value = false;
            await loadWorkTasks();
        }
    },
);

import {useDeleteOrCancelWorkTaskModalStore} from '@/stores/modals/delete-or-cancel-work-task-modal-store';
const deleteOrCancelWorkTaskModalStore = useDeleteOrCancelWorkTaskModalStore();
watch(
    () => deleteOrCancelWorkTaskModalStore.savedCounter,
    async () => {
        if (clearTasksModalStore.isVisible) await loadData();
    },
);
</script>

<style lang="scss" scoped>
@import '@/assets/scss/swatches.scss';

.explanation-text {
    font-weight: bold;
    color: $swatchA1;
}

.complete-confirmation {
    background-color: rgba($swatchGRN, 0.2) !important;

    .confirm-header {
        background-color: rgba($swatchA5, 0.5);
        font-size: large;
    }

    .confirm-checkbox {
        font-size: 18pt;
    }
}
</style>
