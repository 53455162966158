<template>
    <LoadingSymbol v-if="isLoading" />
    <v-list v-else-if="homeDashboardStore.farmFields.length > 0 && hasFarmFieldAccess">
        <template v-for="(farmField, index) in homeDashboardStore.farmFields" :key="farmField.farmFieldId">
            <v-list-item
                :id="'navigatorTabFarmField_' + farmField.farmFieldId"
                class="navigator-panel-row"
                style="padding: 0"
                :class="{
                    selected: homeDashboardStore.isFarmFieldSelected(farmField),
                    'is-moused-over': homeDashboardStore.isFarmFieldMousedOver(farmField),
                }"
                @mouseenter="homeDashboardStore.onFarmFieldMouseOver(farmField.farmFieldId)"
                @mouseleave="homeDashboardStore.onFarmFieldMouseOut"
                @click="() => handleRowClick(farmField)"
                @dblclick="() => handleRowDoubleClick(farmField)">
                <div class="farm-field-row">
                    <div
                        style="width: 80px; display: flex; align-items: center; justify-content: center"
                        class="pr-1 pl-1">
                        <SvgLoader
                            v-if="farmField.farmFieldPolygonImageUrl"
                            :url="farmField.farmFieldPolygonImageUrl"
                            :color="farmField.farmFieldColorHexCode" />

                        <SvgLoader v-else url="Field_UnknownShape.svg" :color="farmField.farmFieldColorHexCode" />
                    </div>
                    <div style="width: 200px">
                        <div class="field-name">
                            {{ farmField.farmFieldCode }}
                            <span v-if="farmField.farmFieldCode && farmField.farmFieldName"> - </span>
                            {{ farmField.farmFieldName }}
                        </div>
                        <div class="crop-age-row">
                            <CropIcon
                                v-if="farmField.cropCode"
                                :cropCode="farmField.cropCode"
                                :varieties="getVarieties(farmField).map((cv) => cv.cropVarietyName)"
                                :isCollapsed="true"
                                :collapseCharacterLimit="24" />
                            <span class="field-age-icon">
                                <FarmFieldAgeIcon
                                    v-if="farmField.cropYear != null"
                                    iconHeight="30"
                                    iconWidth="30"
                                    :fieldAge="farmField.cropYear.toString()" />
                            </span>
                        </div>
                    </div>
                    <div v-if="farmField.nextWorkTask" class="next-task-column">
                        <span v-if="farmField.nextWorkTask?.isOverdue" class="task-alert-icon"></span>
                        <WorkTaskTypeIcon
                            :workTaskTypeCode="getNextTask(farmField.nextWorkTask?.workTaskTypeCode)"
                            iconHeight="35"
                            iconWidth="35" />
                    </div>
                    <div v-if="farmField.farmFieldSize" class="field-size">
                        {{ formatFieldSize(farmField.farmFieldSize) }} ha
                    </div>
                </div>
            </v-list-item>
            <v-divider class="field-divider" v-if="index < homeDashboardStore.farmFields.length - 1"></v-divider>
        </template>
    </v-list>
    <div v-else class="pa-4">{{ hasFarmFieldAccess ? $t('NoDataToDisplay') : $t('YouDoNotHaveFarmFieldAccess') }}</div>
</template>

<script setup lang="ts">
import {ref, watch} from 'vue';
import FarmFieldAgeIcon from '@/components/FarmFieldAgeIcon.vue';
import SvgLoader from '@/components/SvgLoader.vue';
import CropIcon from '@/components/CropIcon.vue';
import {WorkTaskTypeCode} from '@/enums/work-task-type-code';
import {useHomeDashboardStore} from '@/stores/home-dashboard-store';
import {FarmFieldSearchResultDto} from '@/models/data-transfer-objects/search/farm-field-search/FarmFieldSearchResultDto';
import {useViewFarmFieldModalStore} from '@/stores/modals/view-farm-field-modal-store';
import {RoleCode} from '@/enums/role-code';
import {useUserStore} from '@/stores/user-store';
import {CropVarietyDto} from '@/models/data-transfer-objects/search/farm-field-search/CropVarietyDto';
import i18n from '@/i18n';

// Stores
const homeDashboardStore = useHomeDashboardStore();
const viewFarmFieldModalStore = useViewFarmFieldModalStore();
const userStore = useUserStore();

const hasFarmFieldAccess = userStore.hasRoleLevel(RoleCode.Fields, 1);

// Loading
const isLoading = ref(false);

// Format decimal points for field size
const formatFieldSize = (size: number) => {
    return size < 10
        ? i18n.global.n(size, 'decimal')
        : size < 100
        ? i18n.global.n(size, 'decimal1')
        : i18n.global.n(size, 'decimal0');
};

// Get task code from string
const getNextTask = (nextTask: string | undefined) => {
    return nextTask as WorkTaskTypeCode;
};

// Get varieties from blocks
const getVarieties = (field: FarmFieldSearchResultDto): CropVarietyDto[] => {
    const varieties = field.farmBlocks.map((block) => block.cropVariety).filter((variety) => variety !== null);

    return varieties;
};

// Scroll to the specified farm field. This is used to:
//  1. Bring the farm field fully into view if it's only partially visible when selecting it on the navigator panel
//  2. Bring the farm field fully into view when selecting it on the map
const scrollToFarmField = (farmField: FarmFieldSearchResultDto) => {
    const element = document.getElementById('navigatorTabFarmField_' + farmField.farmFieldId);
    if (element) {
        // Get the top position
        const header = document.querySelector('.divMasterHeaderRow');
        const panel = document.querySelector('.navigator-panel-tabs');
        const operations = document.querySelector('.navigator-panel-operations');
        const headerHeight = header ? header.clientHeight : 0;
        const panelHeight = panel ? panel.clientHeight : 0;
        const operationsHeight = operations ? operations.clientHeight : 0;
        const topPosition = headerHeight + panelHeight + operationsHeight;

        // Check if the top/bottom of the farm field item is in view
        const rect = element.getBoundingClientRect();
        const isTopInView = rect.top >= topPosition;
        const isBottomInView = rect.bottom <= (window.innerHeight || document.documentElement.clientHeight);

        // Scroll the farm field item into view if necessary
        if (!isTopInView) {
            element.scrollIntoView({behavior: 'smooth', block: 'start'});
        } else if (!isBottomInView) {
            element.scrollIntoView({behavior: 'smooth', block: 'end'});
        }
    }
};

// Timeout for row clicks
const clickTimeout = ref<number | null>(null);

// Handle single click row
const handleRowClick = (farmField: FarmFieldSearchResultDto) => {
    // Clear the timeout if it exists to avoid triggering the single-click event
    if (clickTimeout.value) {
        clearTimeout(clickTimeout.value);
        clickTimeout.value = null;
    }

    // Set a timeout to wait for a possible double-click
    clickTimeout.value = window.setTimeout(() => {
        homeDashboardStore.onFarmFieldClicked(farmField, false);
        clickTimeout.value = null;
    }, 200);
};

// Handle double click row
const handleRowDoubleClick = (farmField: FarmFieldSearchResultDto) => {
    // Clear the timeout to prevent the single-click event from firing
    if (clickTimeout.value) {
        clearTimeout(clickTimeout.value);
        clickTimeout.value = null;
    }

    viewFarmFieldModalStore.open(farmField.farmFieldId);
};

// If in single select mode, scroll the selected farm field into view
watch(
    () => homeDashboardStore.selectedFarmFieldsLevel1,
    () => {
        if (
            !homeDashboardStore.isMultiSelectFarmFieldsMode &&
            homeDashboardStore.selectedFarmFieldsLevel1.length == 1
        ) {
            scrollToFarmField(homeDashboardStore.selectedFarmFieldsLevel1[0]);
        }
    },
    {deep: true},
);
</script>

<style lang="scss" scoped>
@import '@/assets/scss/swatches.scss';

.farm-field-row {
    display: flex;
    height: 80px;
}

.field-divider {
    color: $swatchG1;
}

.field-name {
    height: 45px;
    align-content: center;
    padding-left: 10px;
    color: $swatchA1;
    font-size: large;
    font-weight: bolder;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.crop-age-row {
    height: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .field-age-icon {
        padding-left: 4px;
        display: flex;
        align-items: center;
        height: 100%;
    }
}

.next-task-column {
    width: 35px;
    align-content: center;
    position: relative;

    .task-alert-icon {
        position: absolute;
        top: 23px;
        right: 0;
        border-radius: 50%;
        background-color: $swatchRED;
        height: 13px;
        width: 13px;
        border: solid $swatchWHT 1px;
        z-index: 1;
    }
}

.field-size {
    width: 65px;
    align-content: center;
    text-align: right;
    color: $swatchA1;
    font-family: 'Open Sans Condensed', sans-serif;
    font-size: large;
    margin-left: auto;
}
</style>
