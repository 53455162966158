<template>
    <div class="observation-details-header">
        <div class="pt-2" style="width: 11%">
            <ObservationTypeChips
                icon-height="45"
                icon-width="40"
                icon-size="28"
                :observationType="observation.observationTypeCode" />
        </div>
        <div class="pl-2" style="width: 90%; display: flex; flex-direction: column">
            <div style="display: flex">
                <div class="observation-type-name swatchWHT bold pa-1">
                    {{ observation.observationTypeName }}
                    <IdIcon v-if="observation.observationId">{{ observation.observationId }}</IdIcon>
                </div>
                <div style="margin-left: auto">
                    <ObservationStatusChip
                        :observation-status-code="observation.observationStatusCode"
                        width="80px"
                        height="24px" />
                </div>
            </div>
            <div style="display: flex">
                <div class="observation-recorded-date pa-1">
                    {{ formatDate(observation.recordedDate) }}
                </div>
                <div style="width: 170px; height: 28px; text-align: right">
                    <UserChip
                        v-if="observation.recordedUserId"
                        :user="
                            UserHelper.userChipType(
                                observation.recordedUserId,
                                UserHelper.userFullName(
                                    observation.recordedUserGivenName,
                                    observation.recordedUserSurname,
                                ),
                            )
                        " />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {computed} from 'vue';
import {ObservationSearchResultDto} from '@/models/data-transfer-objects/search/observation-search/ObservationSearchResultDto';
import {formatTime} from '@/helpers/TimeFormatHelper';
import UserChip from '@/components/UserChip.vue';
import ObservationStatusChip from '@/components/ObservationStatusChip.vue';
import ObservationTypeChips from '@/components/ObservationTypeChips.vue';
import UserHelper from '@/helpers/UserHelper';

// Props
const props = withDefaults(
    defineProps<{
        observation: ObservationSearchResultDto;
    }>(),
    {},
);

// Local state for tasks
const observation = computed(() => {
    return props.observation;
});

// Format date to necessary structure
const formatDate = (date: Date | null): string | null => {
    if (!date) return null;

    return formatTime(date, 'dd MMM, yyyy h:mm a');
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/swatches.scss';

.observation-details-header {
    height: 80px;
    width: 100%;
    padding: 5px 10px 5px 10px;
    background-color: rgba($swatchA1, 0.92);
    display: flex;

    .observation-type-name {
        width: 220px;
        height: 40px;
        font-size: larger;

        :deep(.id-icon) {
            margin-left: 0px;
            background-color: $swatchWHT;
        }
    }

    .observation-recorded-date {
        font-size: 11pt;
        width: 170px;
        height: 28px;
    }
}
</style>
