<template>
    <div class="subHeader mt-2 mb-4">
        {{ $t('ImpactOfChange') }}
    </div>

    <LoadingSymbol v-if="isLoading" />
    <div v-else class="pl-6">
        <div v-if="tasksThatWillHaveDueDateChanged.length > 0" class="mb-4">
            <RescheduleWorkTaskChanges
                :tasks-that-will-be-changed="tasksThatWillHaveDueDateChanged"
                :description="$t('RescheduleTaskImpact_EstimatedDueDateChanged')" />
        </div>
        <div v-if="tasksThatWillHaveDueDaysAfterChanged.length > 0" class="mb-4">
            <RescheduleWorkTaskChanges
                :tasks-that-will-be-changed="tasksThatWillHaveDueDaysAfterChanged"
                :description="$t('RescheduleTaskImpact_DueAfterDaysChanged')" />
        </div>
        <div v-if="tasksThatWillHaveDependencyRemoved.length > 0" class="mb-4">
            <RescheduleWorkTaskChanges
                :tasks-that-will-be-changed="tasksThatWillHaveDependencyRemoved"
                :description="$t('RescheduleTaskImpact_DependencyRemoved')" />
        </div>
        <div v-if="newPreviousTask.length > 0 && newNextTask.length > 0" class="mb-4">
            <RescheduleWorkTaskChanges
                :tasks-that-will-be-changed="newPreviousAndNextTasks"
                :description="$t('RescheduleTaskImpact_TaskSequenceChanged_Between')" />
        </div>
        <div v-if="newPreviousTask.length > 0 && newNextTask.length == 0" class="mb-4">
            <RescheduleWorkTaskChanges
                :tasks-that-will-be-changed="newPreviousTask"
                :description="$t('RescheduleTaskImpact_TaskSequenceChanged_Next')" />
        </div>
        <div v-if="newPreviousTask.length == 0 && newNextTask.length > 0" class="mb-4">
            <RescheduleWorkTaskChanges
                :tasks-that-will-be-changed="newNextTask"
                :description="$t('RescheduleTaskImpact_TaskSequenceChanged_Previous')" />
        </div>
    </div>
</template>

<script setup lang="ts">
import {computed, defineProps} from 'vue';
import {WorkTaskChangeDetailsDto} from '@/models/data-transfer-objects/work-tasks/WorkTaskChangeDetailsDto';
import RescheduleWorkTaskChanges from '@/components/work-tasks/RescheduleWorkTaskChanges.vue';
import LoadingSymbol from '@/components/LoadingSymbol.vue';

const props = defineProps<{
    isLoading: boolean;
    tasksThatWillBeChanged: WorkTaskChangeDetailsDto[];
}>();

/**
 * Returns a list of tasks that will have their due date changed as a result of the rescheduling.
 */
const tasksThatWillHaveDueDateChanged = computed(() => {
    return props.tasksThatWillBeChanged.filter((t) => t.isDueDateChanged);
});

/**
 * Returns a list of tasks that will have their "due days after" changed as a result of the rescheduling.
 */
const tasksThatWillHaveDueDaysAfterChanged = computed(() => {
    return props.tasksThatWillBeChanged.filter((t) => t.isDueAfterDaysChanged);
});

/**
 * Returns a list of tasks that will have their dependency removed as a result of the rescheduling.
 */
const tasksThatWillHaveDependencyRemoved = computed(() => {
    return props.tasksThatWillBeChanged.filter((t) => t.isDependencyRemoved);
});

/**
 * Returns the new previous task as a result of the rescheduling.
 */
const newPreviousTask = computed(() => {
    return props.tasksThatWillBeChanged.filter((t) => t.isNewPreviousTask);
});

/**
 * Returns the new next task as a result of the rescheduling.
 */
const newNextTask = computed(() => {
    return props.tasksThatWillBeChanged.filter((t) => t.isNewNextTask);
});

/**
 * Returns the new previous and next tasks as a result of the rescheduling.
 */
const newPreviousAndNextTasks = computed(() => {
    return props.tasksThatWillBeChanged.filter((t) => t.isNewPreviousTask || t.isNewNextTask);
});
</script>
