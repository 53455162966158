import {ComponentPublicInstance} from 'vue';
import PasswordValidator from './PasswordValidator';
import i18n from '@/i18n';
import FileService from '@/services/file-service';
import {ArtifactFileTypeCode} from '@/enums/artifact-file-type-code';

/**
 * Represents the result of a validation attempt. Can be true, false, or a string containing an error message.
 */
type ValidationResult = (v: unknown) => boolean | string;
type ValidateComponent = ComponentPublicInstance<{validate: () => boolean}>;

/**
 * Call used to list various validation rules
 */
class Validation {
    /**
     * Creates a required field validation rule
     * @param errorMessage Error message returned if the field is null.
     * @returns true value, or a validation error message.
     */
    public static createRule_Required(errorMessage?: string): ValidationResult {
        return (v: unknown) =>
            (v !== null && v !== undefined && v !== '') || errorMessage || i18n.global.t('Validation_Required');
    }

    /**
     * Creates a numeric validation rule
     * @param errorMessage Error message returned if the field is invalid.
     * @returns true value, or a validation error message.
     */
    public static createRule_Numeric(
        errorMessage = null as string | null,
        minValue = null as number | null,
        maxValue = null as number | null,
    ): ValidationResult {
        return (v: unknown) => {
            if ((v ?? '') !== '') {
                //Perform validation
                if (isNaN((v as string).toString().replaceAll(',', '') as never)) {
                    //Not a number
                    return i18n.global.t('Validation_Number');
                } else if (minValue != null && (v as number) < minValue) {
                    //Less than min value
                    return errorMessage || i18n.global.t('Validation_OutOfRange');
                } else if (maxValue != null && (v as number) > maxValue) {
                    //Less than max value
                    return errorMessage || i18n.global.t('Validation_OutOfRange');
                }
            }

            return true;
        };
    }

    /**
     * Creates a whole number validation rule
     * @param errorMessage Error message returned if the field is invalid.
     * @returns true value, or a validation error message.
     */
    public static createRule_WholeNumber(errorMessage = null as string | null): ValidationResult {
        return (v: unknown) => {
            if ((v ?? '') !== '') {
                // Ensure the value is a valid number
                const num = Number(v);
                if (isNaN(num) || !Number.isInteger(num)) {
                    return errorMessage || i18n.global.t('Validation_MustBeWholeNumber');
                }
            }
            return true;
        };
    }

    /**
     * Creates a validation rule that validates the extension and size of an uploaded file.
     * @param allowedFileTypes Optional array of file types to includes e.g. Image, Video.
     * @returns true value, or a validation error message.
     */
    public static createRule_UploadedFile(allowedFileTypes?: ArtifactFileTypeCode[]): ValidationResult {
        return (v: unknown) => {
            if ((v ?? '') !== '') {
                if (v instanceof Array && v.every((item) => item instanceof File)) {
                    for (const file of v) {
                        const fileValidationResult = FileService.validateFile(file, allowedFileTypes);

                        if (!fileValidationResult.isValid) {
                            return fileValidationResult.message!;
                        }
                    }
                }
            }

            return true;
        };
    }

    /**
     * Creates a validation rule that validates the dimensions of an uploaded image.
     * @param dimension The size and width to check for.
     * @returns true value, or a validation error message.
     */
    public static createRule_Image(height: number, width: number) {
        return async (v: unknown) => {
            if ((v ?? '') !== '') {
                if (v instanceof Array && v.every((item) => item instanceof File)) {
                    for (const file of v) {
                        const dimensionValidationResult = await FileService.validateImageDimensions(
                            file,
                            height,
                            width,
                        );
                        if (!dimensionValidationResult.isValid) {
                            return dimensionValidationResult.message;
                        }
                    }
                }
            }

            return true;
        };
    }

    /**
     * Creates a limit validation rule
     * @param errorMessage Error message returned if the field is invalid.
     * @returns true value, or a validation error message.
     */
    public static createRule_Limit(errorMessage = '' as string, min: number, max: number): ValidationResult {
        return (v: unknown) => {
            let result = true as boolean | string;
            const value = (v ?? '') as string;
            if (value !== '') {
                //Test limit
                result = value.length >= min && value.length <= max;

                //Set error message
                if (!result && errorMessage) result = errorMessage;
            }
            return result;
        };
    }

    /**
     * Creates a compare validation rule
     * @param errorMessage Error message returned if the field is invalid.
     * @returns true value, or a validation error message.
     */
    public static createRule_CompareExact(compareValue: unknown, errorMessage = '' as string): ValidationResult {
        return (v: unknown) => {
            let result = false as boolean | string;
            if ((v ?? '') !== '') {
                //Compare value
                result = v === compareValue;
            }

            //Set error message
            if (!result && errorMessage) result = errorMessage;

            return result;
        };
    }

    /**
     * Creates a compare not equal to validation rule
     * @param errorMessage Error message returned if the field is invalid.
     * @returns true value, or a validation error message.
     */
    public static createRule_CompareNotEqual(compareValue: unknown, errorMessage = '' as string): ValidationResult {
        return (v: unknown) => {
            let result = false as boolean | string;
            if ((v ?? '') !== '') {
                //Compare value
                result = v !== compareValue;
            }

            //Set error message
            if (!result && errorMessage) result = errorMessage;

            return result;
        };
    }

    /**
     * Creates a password validation rule.
     * @param errorMessage Error message to be returned if the password is invalid.
     */
    public static createRule_Password(errorMessage = '' as string): ValidationResult {
        return (v: unknown) => {
            if (typeof v === 'string' && new PasswordValidator().isValid(v)) {
                return true;
            } else if (errorMessage !== '') {
                return errorMessage;
            } else {
                return false;
            }
        };
    }
}

export {Validation, ValidationResult, ValidateComponent};
export default Validation;
