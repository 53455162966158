import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_LoadingSymbol = _resolveComponent("LoadingSymbol")!
  const _component_task_type_metric = _resolveComponent("task-type-metric")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    class: "task-metric-panel pa-3",
    width: "200px"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, { class: "pa-3" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_select, {
            modelValue: _ctx.selectedPeriod,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedPeriod) = $event)),
            items: _ctx.periodOptions,
            "item-title": "periodOptionName",
            "item-value": "periodOptionValue",
            label: _ctx.$t('Period'),
            "max-width": "133",
            class: "mb-3 swatchWHT",
            "hide-details": ""
          }, null, 8, ["modelValue", "items", "label"]),
          _createVNode(_component_v_btn, {
            icon: "",
            onClick: _ctx.close,
            size: "small",
            class: "transparent-btn"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, {
                size: "x-large",
                style: {"opacity":"1"}
              }, {
                default: _withCtx(() => [
                  _createTextVNode("mdi-close")
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_select, {
        modelValue: _ctx.selectedMetric,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedMetric) = $event)),
        items: _ctx.metricOptions,
        "item-title": "metricOptionName",
        "item-value": "metricOptionValue",
        label: _ctx.$t('Metric'),
        "hide-details": "",
        class: "mb-3 swatchWHT"
      }, null, 8, ["modelValue", "items", "label"]),
      (_ctx.isLoading && _ctx.selectedSiteId)
        ? (_openBlock(), _createBlock(_component_LoadingSymbol, { key: 0 }))
        : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.taskTypeData, (taskType, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "mt-3"
            }, [
              (taskType.workTaskTypeCode && taskType.totalArea !== null)
                ? (_openBlock(), _createBlock(_component_task_type_metric, {
                    key: 0,
                    "work-task-type-code": taskType.workTaskTypeCode,
                    "total-area": taskType.totalArea,
                    "historic-data": taskType.timeSeriesData,
                    "is-hide": false
                  }, null, 8, ["work-task-type-code", "total-area", "historic-data"]))
                : _createCommentVNode("", true)
            ]))
          }), 128))
    ]),
    _: 1
  }))
}