import {defineStore} from 'pinia';
export const useManageFarmFieldSeasonModalStore = defineStore('manageFarmFieldSeasonModal', {
    state: () => ({
        isVisible: false,
        farmFieldId: null as number | null,
        savedCounter: 0,
    }),
    actions: {
        open(farmFieldId: number | null) {
            this.close();
            if (farmFieldId) {
                this.farmFieldId = farmFieldId;
                this.isVisible = true;
            }
        },
        close() {
            this.isVisible = false;
            this.farmFieldId = null;
        },
    },
});
