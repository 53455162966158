<template>
    <div>
        <v-card flat style="overflow: visible">
            <v-toolbar flat color="swatchG2">
                <v-tabs v-model="tab">
                    <v-tab value="SearchParameters">
                        <FontAwesomeIcon :icon="['fal', 'filter']" size="xl" class="mr-2" />
                        {{ $t('Filters') }}
                    </v-tab>
                    <v-tab value="SearchResults" v-show="hasSearchBeenRun">
                        <FontAwesomeIcon :icon="['fal', 'th']" size="xl" class="mr-2" />
                        {{ resultsTabTitle }}
                    </v-tab>
                </v-tabs>
            </v-toolbar>

            <LoadingSymbol v-if="isLoading" />

            <v-form v-else class="pa-4" @keyup.enter="search">
                <v-tabs-window v-model="tab" style="overflow: visible">
                    <v-tabs-window-item value="SearchParameters">
                        <WorkTaskSearchParameters
                            v-model:searchParameters="searchParameters"
                            :is-overdue-report="true"
                            :display-export-options="true" />
                    </v-tabs-window-item>
                    <v-tabs-window-item value="SearchResults">
                        <WorkTaskSearchResults
                            :results-list="searchResults"
                            @switch-to-search="selectSearchParametersTab"
                            @export-task-list="exportTaskList"
                            :is-filter-applied="isFilterApplied"
                            :show-farm-site="true" />
                    </v-tabs-window-item>
                </v-tabs-window>
            </v-form>
            <v-card-actions class="justify-space-between swatchG9BG">
                <BackButton v-if="!isSearchParametersTabSelected" @click="selectSearchParametersTab" />
                <v-spacer></v-spacer>
                <v-btn v-if="isSearchParametersTabSelected" @click="search">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'check-square']" size="xl" />
                    </template>
                    {{ $t('ApplyFilters') }}
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-snackbar v-model="snackbar.show" timeout="4000">
            {{ snackbar.text }}
        </v-snackbar>
    </div>
</template>

<script setup lang="ts">
import {computed, inject, onMounted, ref, watch} from 'vue';
import ApiService from '@/services/api-service.js';
import WorkTaskSearchParameters from '@/views/search/WorkTaskSearchParameters.vue';
import i18n from '@/i18n';
import {SearchWorkTasksRequest} from '@/models/api/requests/search/SearchWorkTasksRequest';
import WorkTaskSearchResults from '@/views/search/WorkTaskSearchResults.vue';
import {WorkTaskSearchResultDto} from '@/models/data-transfer-objects/search/work-task-search/WorkTaskSearchResultDto';
import {SearchWorkTasksResponse} from '@/models/api/responses/search/SearchWorkTasksResponse';
import {useUserStore} from '@/stores/user-store';
import {formatTime} from '@/helpers/TimeFormatHelper';

const userStore = useUserStore();
const isLoading = ref<boolean>(true);
const hasSearchBeenRun = ref<boolean>(false);
const siteId = userStore.user?.farmSiteId;
const searchParameters = ref(
    new SearchWorkTasksRequest({
        isQuickSearch: false,
        farmSiteIdList: siteId ? [siteId] : [],
        workTaskStatus: 'Overdue',
        isExportCSV: false,
    }),
);
const searchResults = ref<WorkTaskSearchResultDto[]>([]);
const snackbar = ref({
    show: false,
    text: '',
});
let apiService = inject('apiService') as ApiService;
let tab = ref<string>('SearchParameters');

/**
 * Call API to retrieve and display data.
 */
async function search() {
    isLoading.value = true;
    // Get search results

    if (!searchParameters.value.isExportCSV) {
        const response: SearchWorkTasksResponse = await apiService.post('search/work-tasks', searchParameters.value);
        searchResults.value = response.workTasks;
        // Record the search has been run, so that the results tab can be shown
        hasSearchBeenRun.value = true;
        // Switch to results tab
        tab.value = 'SearchResults';
    } else {
        await exportTaskList();
        snackbar.value.show = true;
        snackbar.value.text = i18n.global.t('ExportCSVSuccess');
    }

    isLoading.value = false;
}

/**
 * Export Task List to CSV file
 */
const exportTaskList = async () => {
    try {
        const fileName = i18n.global.t('Tasks') + '_' + formatTime(new Date(), 'yyyyMMddHHmm') + '.csv';
        await apiService.downloadFilePost('search/work-tasks/export', searchParameters.value, null, fileName);
    } catch (error) {
        // Show fail feedback to user
        snackbar.value.show = true;
        snackbar.value.text = i18n.global.t('ExportCSVError');
        isLoading.value = false;
    }
};

/**
 * Select the search parameters tab.
 */
async function selectSearchParametersTab() {
    // Switch to search paramters tab
    tab.value = 'SearchParameters';
}

/**
 * Title of the results tab.
 */
const resultsTabTitle = computed(() => {
    return i18n.global.t('SearchResults_WithNumberOfResults', {
        numberOfResults: searchResults.value.length.toLocaleString(),
    });
});

/**
 * Flag to indicate if the Search Parameters tab is selected.
 */
const isSearchParametersTabSelected = computed(() => {
    return tab.value == 'SearchParameters';
});

const isFilterApplied = computed((): boolean => {
    return !!(
        (searchParameters.value.isQuickSearch &&
            (searchParameters.value.quickSearchText === null || searchParameters.value.quickSearchText === '')) ||
        (!searchParameters.value.isQuickSearch &&
            (searchParameters.value.farmFieldName === null || searchParameters.value.farmFieldName === '') &&
            (searchParameters.value.workTaskDetails === null || searchParameters.value.workTaskDetails === '') &&
            (searchParameters.value.workTaskStatus === null || searchParameters.value.workTaskStatus === '') &&
            (searchParameters.value.workTaskTypeCodeList === null ||
                searchParameters.value.workTaskTypeCodeList.length === 0) &&
            (searchParameters.value.userIdList === null || searchParameters.value.userIdList.length === 0) &&
            searchParameters.value.farmSiteIdList !== null &&
            searchParameters.value.farmSiteIdList.length === 1 &&
            searchParameters.value.farmSiteIdList[0] === siteId &&
            (searchParameters.value.dateRaisedFrom === null || searchParameters.value.dateRaisedFrom === '') &&
            (searchParameters.value.dateRaisedTo === null || searchParameters.value.dateRaisedTo === '') &&
            (searchParameters.value.dateDueFrom === null || searchParameters.value.dateDueFrom === '') &&
            (searchParameters.value.dateDueTo === null || searchParameters.value.dateDueTo === '') &&
            (searchParameters.value.dateConcludedFrom === null || searchParameters.value.dateConcludedFrom === '') &&
            (searchParameters.value.dateConcludedTo === null || searchParameters.value.dateConcludedTo === ''))
    );
});

onMounted(async () => {
    await search();
});

import {useDeleteOrCancelWorkTaskModalStore} from '@/stores/modals/delete-or-cancel-work-task-modal-store';
const deleteOrCancelWorkTaskModalStore = useDeleteOrCancelWorkTaskModalStore();
import {useDeleteUserModalStore} from '@/stores/modals/delete-user-modal-store';
const deleteUserModalStore = useDeleteUserModalStore();
watch(
    [() => deleteOrCancelWorkTaskModalStore.savedCounter, () => deleteUserModalStore.deletedCounter],
    async () => {
        await search();
    },
    {deep: true},
);
</script>
