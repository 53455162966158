<template>
    <LoadingSymbol v-if="isLoading" />

    <table v-else style="width: 100%" class="tblForm">
        <tr>
            <td colspan="4" class="subHeader">{{ $t('Particulars') }}</td>
        </tr>
        <tr>
            <td class="tableCell padding" style="width: 10%; min-width: 10rem">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('FarmSite(s)') }}</span>
                    <HelpIcon :help-text="$t('ObservationSearch_FarmSites_HelpText')" />
                </div>
            </td>
            <td style="width: 40%">
                <v-select
                    v-model="localSearchParameters.farmSiteIdList"
                    hide-details
                    multiple
                    clearable
                    chips
                    :items="farmSites"
                    item-title="farmSiteAbbreviationAndName"
                    item-value="farmSiteId"
                    max-width="400px"></v-select>
            </td>
            <td class="tableCell padding" style="width: 10%; min-width: 10rem">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('ObservationName') }}</span>
                    <HelpIcon :help-text="$t('ObservationSearch_ObservationName_HelpText')" />
                </div>
            </td>
            <td style="width: 40%">
                <v-text-field
                    v-model="localSearchParameters.observationName"
                    hide-details
                    clearable
                    style="width: 400px">
                </v-text-field>
            </td>
        </tr>
        <tr>
            <td class="tableCell padding">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('ObservationType') }}</span>
                    <HelpIcon :help-text="$t('ObservationSearch_ObservationType_HelpText')" />
                </div>
            </td>
            <td>
                <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                    <v-select
                        v-model="localSearchParameters.observationTypeCodeList"
                        hide-details
                        multiple
                        clearable
                        chips
                        :items="observationTypes"
                        item-title="observationTypeName"
                        item-value="observationTypeCode"
                        max-width="400px"></v-select>
                </div>
            </td>
            <td class="tableCell padding">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('ObservationStatus') }}</span>
                    <HelpIcon :help-text="$t('ObservationSearch_ObservationStatus_HelpText')" />
                </div>
            </td>
            <td>
                <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                    <v-select
                        v-model="localSearchParameters.observationStatusCodeList"
                        hide-details
                        multiple
                        clearable
                        chips
                        :items="filteredStatusList"
                        item-title="observationStatusName"
                        item-value="observationStatusCode"
                        max-width="400px"></v-select>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="4" class="subHeader">{{ $t('AttributesAndAssociations') }}</td>
        </tr>
        <tr>
            <td class="tableCell padding">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('DateRaised') }}</span>
                    <HelpIcon :help-text="$t('ObservationSearch_DateRaised_HelpText')" />
                </div>
            </td>
            <td>
                <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                    <VueDatePicker
                        v-model="dateRaised"
                        range
                        format="dd/MM/yyyy"
                        :enable-time-picker="false"
                        text-input
                        :teleport="true"
                        :preset-dates="presetDates"
                        style="max-width: 300px"
                        :locale="i18n.global.locale" />
                </div>
            </td>
            <td class="tableCell padding">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('DateLastActivity') }}</span>
                    <HelpIcon :help-text="$t('ObservationSearch_DateLastActivity_HelpText')" />
                </div>
            </td>
            <td>
                <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                    <VueDatePicker
                        v-model="dateLastActivity"
                        :teleport="true"
                        range
                        format="dd/MM/yyyy"
                        :enable-time-picker="false"
                        text-input
                        :preset-dates="presetDates"
                        style="max-width: 300px"
                        :locale="i18n.global.locale" />
                </div>
            </td>
        </tr>
        <tr>
            <td class="tableCell padding">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('DateConcluded') }}</span>
                    <HelpIcon :help-text="$t('ObservationSearch_DateConcluded_HelpText')" />
                </div>
            </td>
            <td>
                <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                    <VueDatePicker
                        v-model="dateConcluded"
                        :teleport="true"
                        range
                        format="dd/MM/yyyy"
                        :enable-time-picker="false"
                        text-input
                        :preset-dates="presetDates"
                        style="max-width: 300px"
                        :locale="i18n.global.locale" />
                </div>
            </td>
            <td class="tableCell padding">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('User') }}</span>
                    <HelpIcon :help-text="$t('ObservationSearch_User_HelpText')" />
                </div>
            </td>
            <td>
                <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                    <v-autocomplete
                        v-model="localSearchParameters.userIdList"
                        :items="users"
                        hide-details
                        multiple
                        clearable
                        chips
                        item-title="userFullName"
                        item-value="userId"
                        max-width="400px"></v-autocomplete>
                </div>
            </td>
        </tr>
        <tr>
            <td class="tableCell padding">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('Field') }}</span>
                    <HelpIcon :help-text="$t('ObservationSearch_Field_HelpText')" />
                </div>
            </td>
            <td>
                <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                    <v-autocomplete
                        v-model="localSearchParameters.farmFieldIdList"
                        :items="farmFields"
                        hide-details
                        multiple
                        clearable
                        chips
                        item-title="farmFieldCodeAndName"
                        item-value="farmFieldId"
                        max-width="400px"></v-autocomplete>
                </div>
            </td>
        </tr>
        <tr v-if="displayExportOptions">
            <td class="tableCell padding">
                <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                    <span>{{ $t('ExportTo') }}</span>
                    <HelpIcon :help-text="$t('ObservationSearch_ExportResultsTo_HelpText')" />
                </div>
            </td>
            <td>
                <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                    <v-select
                        v-model="localSearchParameters.isExportCSV"
                        hide-details
                        :items="exportOptions"
                        item-title="title"
                        item-value="value"
                        max-width="400px"></v-select>
                </div>
            </td>
        </tr>
    </table>
</template>

<script setup lang="ts">
import {defineAsyncComponent, onMounted, ref, watch, computed} from 'vue';
import {SearchObservationsRequest} from '@/models/api/requests/search/SearchObservationsRequest';
import {addDays, addMonths} from 'date-fns';
import {formatTime} from '@/helpers/TimeFormatHelper';
import {useFarmSites} from '@/composables/data-source/farm-sites';
const {getFarmSites, farmSites} = useFarmSites();
import {useFarmFields} from '@/composables/data-source/farm-fields';
const {getFarmFields, farmFields} = useFarmFields();
import {useObservationTypes} from '@/composables/data-source/observation-types';
const {getObservationTypes, observationTypes, acknowledgedObservationTypeCodes} = useObservationTypes();
import {useObservationStatuses} from '@/composables/data-source/observation-statuses';
import i18n from '@/i18n';
const {getObservationStatuses, observationStatuses} = useObservationStatuses();
import {useUsers} from '@/composables/data-source/users';
const {getUsers, users} = useUsers();
import {ObservationStatusCode} from '@/enums/observaton-status-code';

const VueDatePicker = defineAsyncComponent(() => import('@vuepic/vue-datepicker'));

const props = withDefaults(
    defineProps<{
        searchParameters: SearchObservationsRequest;
        displayExportOptions?: boolean;
    }>(),
    {
        searchParameters: undefined,
        displayExportOptions: false,
    },
);

const emit = defineEmits(['update:searchParameters']);
const isLoading = ref<boolean>(false);
const dateRaised = ref<Array<Date> | null>(null);
const dateLastActivity = ref<Array<Date> | null>(null);
const dateConcluded = ref<Array<Date> | null>(null);
const filteredStatusList = ref(observationStatuses.value);
const localSearchParameters = ref({...props.searchParameters});

// Export options computed property
const exportOptions = computed(() => [
    {title: i18n.global.t('CSVFile'), value: true},
    {title: i18n.global.t('Screen'), value: false},
]);

// Preset dates
const today = new Date();
const presetDates = ref([
    {label: i18n.global.t('Last7Days'), value: [addDays(today, -6), today]},
    {label: i18n.global.t('Last14Days'), value: [addDays(today, -13), today]},
    {label: i18n.global.t('Last30Days'), value: [addDays(today, -29), today]},
    {label: i18n.global.t('Last3Months'), value: [addMonths(today, -3), today]},
    {label: i18n.global.t('Last6Months'), value: [addMonths(today, -6), today]},
    {label: i18n.global.t('Last9Months'), value: [addMonths(today, -9), today]},
    {label: i18n.global.t('Last12Months'), value: [addMonths(today, -12), today]},
]);

// Helper functions
const getFromDate = (dates: Array<Date> | null) => {
    return dates?.[0] ? formatTime(dates[0], 'yyyy-MM-dd') : null;
};

const getToDate = (dates: Array<Date> | null) => {
    return dates?.[1] ? formatTime(dates[1], 'yyyy-MM-dd') : null;
};

const resetLocalParameters = () => {
    dateRaised.value = null;
    dateLastActivity.value = null;
    dateConcluded.value = null;
};

// Watch handlers with modifications to prevent recursion
watch(
    () => localSearchParameters.value.observationTypeCodeList,
    (newVal) => {
        if (!newVal || newVal.length === 0) {
            filteredStatusList.value = observationStatuses.value;
            return;
        }

        const acknowledgeStatuses = [
            ObservationStatusCode.Acknowledged,
            ObservationStatusCode.Cancelled,
            ObservationStatusCode.Archived,
            ObservationStatusCode.Open,
        ];
        const resolvedStatuses = [
            ObservationStatusCode.Resolved,
            ObservationStatusCode.InProgress,
            ObservationStatusCode.Cancelled,
            ObservationStatusCode.Open,
        ];

        const isAllAcknowledged = newVal.every((e) => acknowledgedObservationTypeCodes.value.includes(e));
        const isAllResolved = newVal.every((e) => !acknowledgedObservationTypeCodes.value.includes(e));

        if (isAllAcknowledged) {
            filteredStatusList.value = observationStatuses.value.filter((e) =>
                acknowledgeStatuses.includes(e.observationStatusCode),
            );
            if (
                localSearchParameters.value.observationStatusCodeList !== null &&
                localSearchParameters.value.observationStatusCodeList.length > 0 &&
                !(
                    localSearchParameters.value.observationStatusCodeList.includes(
                        ObservationStatusCode.Acknowledged,
                    ) ||
                    localSearchParameters.value.observationStatusCodeList.includes(ObservationStatusCode.Cancelled) ||
                    localSearchParameters.value.observationStatusCodeList.includes(ObservationStatusCode.Archived) ||
                    localSearchParameters.value.observationStatusCodeList.includes(ObservationStatusCode.Open)
                )
            ) {
                localSearchParameters.value.observationStatusCodeList = null;
            }
        } else if (isAllResolved) {
            filteredStatusList.value = observationStatuses.value.filter((e) =>
                resolvedStatuses.includes(e.observationStatusCode),
            );
            if (
                localSearchParameters.value.observationStatusCodeList !== null &&
                localSearchParameters.value.observationStatusCodeList.length > 0 &&
                !(
                    localSearchParameters.value.observationStatusCodeList.includes(ObservationStatusCode.Resolved) ||
                    localSearchParameters.value.observationStatusCodeList.includes(ObservationStatusCode.InProgress) ||
                    localSearchParameters.value.observationStatusCodeList.includes(ObservationStatusCode.Cancelled) ||
                    localSearchParameters.value.observationStatusCodeList.includes(ObservationStatusCode.Open)
                )
            ) {
                localSearchParameters.value.observationStatusCodeList = null;
            }
        } else {
            filteredStatusList.value = observationStatuses.value;
        }
    },
    {deep: true},
);

// Date watchers
watch(dateRaised, (newVal) => {
    localSearchParameters.value = {
        ...localSearchParameters.value,
        dateRaisedFrom: getFromDate(newVal),
        dateRaisedTo: getToDate(newVal),
    };
});

watch(dateLastActivity, (newVal) => {
    localSearchParameters.value = {
        ...localSearchParameters.value,
        dateLastActivityFrom: getFromDate(newVal),
        dateLastActivityTo: getToDate(newVal),
    };
});

watch(dateConcluded, (newVal) => {
    localSearchParameters.value = {
        ...localSearchParameters.value,
        dateConcludedFrom: getFromDate(newVal),
        dateConcludedTo: getToDate(newVal),
    };
});

// Watch for props changes
watch(
    () => props.searchParameters,
    (newVal) => {
        if (JSON.stringify(newVal) !== JSON.stringify(localSearchParameters.value)) {
            const updateDates = () => {
                dateRaised.value =
                    newVal.dateRaisedFrom && newVal.dateRaisedTo
                        ? [new Date(newVal.dateRaisedFrom), new Date(newVal.dateRaisedTo)]
                        : null;

                dateLastActivity.value =
                    newVal.dateLastActivityFrom && newVal.dateLastActivityTo
                        ? [new Date(newVal.dateLastActivityFrom), new Date(newVal.dateLastActivityTo)]
                        : null;

                dateConcluded.value =
                    newVal.dateConcludedFrom && newVal.dateConcludedTo
                        ? [new Date(newVal.dateConcludedFrom), new Date(newVal.dateConcludedTo)]
                        : null;
            };

            updateDates();
            localSearchParameters.value = {...newVal};
        }
    },
    {deep: true, immediate: true},
);

// Watch for local changes
watch(
    localSearchParameters,
    (newVal) => {
        if (JSON.stringify(newVal) !== JSON.stringify(props.searchParameters)) {
            emit('update:searchParameters', {...newVal});
        }
    },
    {deep: true},
);

// Component initialization
onMounted(async () => {
    isLoading.value = true;

    // Initialize date values
    if (props.searchParameters.dateRaisedFrom && props.searchParameters.dateRaisedTo) {
        dateRaised.value = [
            new Date(props.searchParameters.dateRaisedFrom),
            new Date(props.searchParameters.dateRaisedTo),
        ];
    }

    if (props.searchParameters.dateLastActivityFrom && props.searchParameters.dateLastActivityTo) {
        dateLastActivity.value = [
            new Date(props.searchParameters.dateLastActivityFrom),
            new Date(props.searchParameters.dateLastActivityTo),
        ];
    }

    if (props.searchParameters.dateConcludedFrom && props.searchParameters.dateConcludedTo) {
        dateConcluded.value = [
            new Date(props.searchParameters.dateConcludedFrom),
            new Date(props.searchParameters.dateConcludedTo),
        ];
    }

    // Fetch required data
    await Promise.all([
        getFarmSites(),
        getFarmFields(localSearchParameters.value.farmSiteIdList ?? null),
        getObservationTypes(),
        getObservationStatuses(),
        getUsers(),
    ]);

    filteredStatusList.value = observationStatuses.value;
    isLoading.value = false;
});

watch(
    () => localSearchParameters.value.farmSiteIdList,
    async (val) => {
        if (val && val.length > 0) await getFarmFields(val);
        else await getFarmFields();
    },
);
defineExpose({resetLocalParameters});
</script>
