<template>
    <v-dialog max-width="1000px" v-model="homeDashboardStore.farmFieldFiltersVisible">
        <v-card>
            <v-toolbar color="swatchG2">
                <v-tab class="ma-0 swatchA3BG" height="100%" readonly>
                    <FontAwesomeIcon :icon="['fal', 'filter']" size="xl" class="mr-2" />
                    {{ $t('ApplyFilter') }}
                </v-tab>
                <v-spacer></v-spacer>
                <CloseModalButton @click="closeModal()" />
            </v-toolbar>
            <LoadingSymbol v-if="isLoading" />
            <table v-else style="width: 100%" class="tblForm pa-4">
                <tr>
                    <td class="tableCell padding" style="width: 10%; min-width: 10rem">
                        <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                            <span>{{ $t('CropType') }}</span>
                            <HelpIcon :help-text="$t('FarmFieldSearch_CropType_HelpText')" />
                        </div>
                    </td>
                    <td :colspan="3">
                        <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                            <v-select
                                v-model="searchParameters.cropTypeIdList"
                                hide-details
                                multiple
                                clearable
                                chips
                                closable-chips
                                :items="cropTypes"
                                item-title="cropTypeName"
                                item-value="cropTypeId"
                                class="custom-chips"></v-select>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="tableCell padding">
                        <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                            <span>{{ $t('Crops') }}</span>
                            <HelpIcon :help-text="$t('FarmFieldSearch_Crop_HelpText')" />
                        </div>
                    </td>
                    <td colspan="3">
                        <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                            <v-select
                                v-model="searchParameters.cropIdList"
                                hide-details
                                multiple
                                clearable
                                chips
                                closable-chips
                                :items="filteredCrops"
                                item-title="cropName"
                                item-value="cropId"
                                class="custom-chips"></v-select>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="tableCell padding">
                        <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                            <span>{{ $t('CropYear') }}</span>
                            <HelpIcon :help-text="$t('FarmFieldSearch_CropYear_HelpText')" />
                        </div>
                    </td>
                    <td colspan="3">
                        <div class="flex-layout align-items-center justify-content-flex-start flex-wrap-nowrap">
                            <v-select
                                v-model="searchParameters.cropYear"
                                hide-details
                                :items="cropYearsWithNull"
                                item-title="title"
                                item-value="value"
                                :max-width="'200'"></v-select>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="tableCell padding">
                        <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                            <span>{{ $t('NextTaskType') }}</span>
                            <HelpIcon :help-text="$t('FarmFieldSearch_NextTaskType_HelpText')" />
                        </div>
                    </td>
                    <td colspan="1" style="width: 40%">
                        <div>
                            <v-select
                                v-model="searchParameters.nextWorkTaskTypeCode"
                                hide-details
                                clearable
                                :items="workTaskTypes"
                                item-title="workTaskTypeName"
                                item-value="workTaskTypeCode">
                            </v-select>
                        </div>
                    </td>
                    <td style="width: 10%; min-width: 10rem">
                        <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                            <span>{{ $t('LastTaskType') }}</span>
                            <HelpIcon :help-text="$t('FarmFieldSearch_LastTaskType_HelpText')" />
                        </div>
                    </td>
                    <td style="width: 40%">
                        <div>
                            <v-select
                                v-model="searchParameters.lastWorkTaskTypeCode"
                                hide-details
                                clearable
                                :items="workTaskTypes"
                                item-title="workTaskTypeName"
                                item-value="workTaskTypeCode">
                            </v-select>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="tableCell padding">
                        <div class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                            <span>{{ $t('SortOrder') }}</span>
                            <HelpIcon :help-text="$t('FarmFieldSearch_SortOrder_HelpText')" />
                        </div>
                    </td>
                    <td colspan="4">
                        <v-select
                            v-model="searchParameters.sortOrder"
                            hide-details
                            :items="sortOrderOptions"
                            item-title="title"
                            item-value="value"
                            max-width="400"></v-select>
                    </td>
                </tr>
            </table>

            <v-card-actions class="swatchG9BG">
                <v-btn @click="closeModal()">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'arrow-rotate-left']" size="xl" />
                    </template>
                    {{ $t('Back') }}
                </v-btn>
                <v-spacer />
                <v-btn @click="resetParameters()">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'filter-circle-xmark']" size="xl" />
                    </template>
                    {{ $t('ResetFilters') }}
                </v-btn>
                <v-btn @click="search(false)">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fac', 'filter-check']" size="xl" />
                    </template>
                    {{ $t('ApplyFilters') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
import {computed, inject, onMounted, ref, watch} from 'vue';
import ApiService from '@/services/api-service';
import i18n from '@/i18n';
import {useCropYears} from '@/composables/data-source/crop-years';
const {cropYearsWithNull} = useCropYears();
import {useCrops} from '@/composables/data-source/crops';
const {getCrops, crops} = useCrops();
import {useCropTypes} from '@/composables/data-source/crop-types';
const {getCropTypes, cropTypes} = useCropTypes();
import {useWorkTaskTypes} from '@/composables/data-source/work-task-types';
const {getWorkTaskTypes, workTaskTypes} = useWorkTaskTypes();
import {useHomeDashboardStore} from '@/stores/home-dashboard-store';
import {useUserStore} from '@/stores/user-store';
import {GetCropTypesRequest} from '@/models/api/requests/data-source/GetCropTypesRequest';
import {SearchFarmFieldsResponse} from '@/models/api/responses/search/SearchFarmFieldsResponse';
import {SearchFarmFieldsRequest} from '@/models/api/requests/search/SearchFarmFieldsRequest';

// Services and stores
const apiService = inject('apiService') as ApiService;
const homeDashboardStore = useHomeDashboardStore();
const userStore = useUserStore();

const isLoading = ref<boolean>(false);
const farmSiteId = computed(() => userStore.user?.farmSiteId ?? null);

// Options for Sort Order
const sortOrderOptions = [
    {value: 'FarmCodeName', title: i18n.global.t('FarmCodeAndName')},
    {value: 'FieldName', title: i18n.global.t('FieldName')},
    {value: 'NextTaskSequence', title: i18n.global.t('NextTaskSequence')},
    {value: 'NextTaskDueDate', title: i18n.global.t('NextTaskDueDate')},
];

const searchParameters = ref<SearchFarmFieldsRequest>(new SearchFarmFieldsRequest());
const defaultSearchParameters = ref<SearchFarmFieldsRequest>({
    ...new SearchFarmFieldsRequest(),
    includeCropVarieties: true,
    includeFarmBlocks: true,
    includeFarmSeasons: true,
    sortOrder: 'FarmCodeName',
});

// Crops based on selected crop types
const filteredCrops = computed(() => {
    const selectedCropTypeIds = searchParameters.value.cropTypeIdList;

    if (!selectedCropTypeIds || selectedCropTypeIds.length === 0) {
        return [];
    }

    return crops.value.filter((crop) => selectedCropTypeIds.includes(crop.cropTypeId));
});

const closeModal = () => {
    homeDashboardStore.farmFieldFiltersVisible = false;
};

// Search for fields
const search = async (isReset: boolean) => {
    isLoading.value = true;

    const response: SearchFarmFieldsResponse = await apiService.post('search/farm-fields', searchParameters.value);

    homeDashboardStore.farmFields = response.farmFields;
    homeDashboardStore.farmFieldFilterApplied = isFilterApplied();

    if (!isReset) {
        homeDashboardStore.deselectWorkTaskLevel2();
        homeDashboardStore.deselectFarmFieldsLevel1();

        Object.assign(homeDashboardStore.searchParametersFarmField, searchParameters.value);

        closeModal();
    }

    isLoading.value = false;
};

// Determines if filters have changed from default
const isFilterApplied = () => {
    const sortedObj1 = sortArraysInObject(searchParameters.value);
    const sortedObj2 = sortArraysInObject(defaultSearchParameters.value);

    return JSON.stringify(sortedObj1) !== JSON.stringify(sortedObj2);
};

// Makes a copy to compare arrays regardless of order
function sortArraysInObject(obj: SearchFarmFieldsRequest): SearchFarmFieldsRequest {
    const copy = new SearchFarmFieldsRequest({...obj});

    if (copy.cropTypeIdList) copy.cropTypeIdList = [...copy.cropTypeIdList].sort((a, b) => a - b);
    if (copy.cropIdList) copy.cropIdList = [...copy.cropIdList].sort((a, b) => a - b);

    return copy;
}

// Reset search parameters to default
const resetParameters = async () => {
    homeDashboardStore.searchParametersFarmField = {...defaultSearchParameters.value};
    searchParameters.value = {...defaultSearchParameters.value};
    search(true);
};

// Watch for changes in the local copy and emit the update event
watch(
    () => searchParameters.value.cropTypeIdList,
    (newCropTypeIdList) => {
        if (newCropTypeIdList?.length) {
            const currentCropIds = searchParameters.value.cropIdList || [];
            const filteredCropIds = filteredCrops.value.map((crop) => crop.cropId);

            searchParameters.value.cropIdList = currentCropIds.filter((cropId) => filteredCropIds.includes(cropId));
        } else {
            searchParameters.value.cropIdList = [];
        }
    },
);

// Get dropdown data
const initializeDropdowns = async () => {
    const searchRequest: GetCropTypesRequest = {
        farmSiteId: farmSiteId.value,
    };

    await getCropTypes(searchRequest);
    await getCrops();
    await getWorkTaskTypes();
};

// Initialize default and regular search parameters
const initializeSearchParameters = () => {
    Object.assign(searchParameters.value, homeDashboardStore.searchParametersFarmField);

    searchParameters.value.farmSiteId = farmSiteId.value;
    defaultSearchParameters.value.farmSiteId = farmSiteId.value;

    if (!searchParameters.value.cropTypeIdList) {
        searchParameters.value.cropTypeIdList = cropTypes.value.map((cropType) => cropType.cropTypeId);
    }
    if (!searchParameters.value.cropIdList) {
        searchParameters.value.cropIdList = crops.value.map((crop) => crop.cropId);
    }

    defaultSearchParameters.value.cropTypeIdList = cropTypes.value.map((cropType) => cropType.cropTypeId);
    defaultSearchParameters.value.cropIdList = crops.value.map((crop) => crop.cropId);
};

// Initialize data and parameters
onMounted(async () => {
    isLoading.value = true;

    await initializeDropdowns();
    initializeSearchParameters();

    isLoading.value = false;
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/swatches.scss';

.custom-chips :deep(.v-chip) {
    background-color: $swatchB2;
    color: $swatchWHT;
}
</style>
