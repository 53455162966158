<template>
    <div>
        <v-dialog v-model="isVisible">
            <v-card>
                <v-form ref="form" class="pa-4" style="overflow-y: scroll; height: 100%" @keyup.enter="search">
                    <product-search-parameters v-model:searchParameters="localSearchParametersProduct" />
                </v-form>
                <v-card-actions class="swatchG9BG">
                    <v-btn @click="isVisible = false">
                        <template v-slot:prepend>
                            <FontAwesomeIcon :icon="['fal', 'arrow-rotate-left']" size="xl" />
                        </template>
                        {{ $t('Cancel') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="search" :disabled="isLoading">
                        <template v-slot:prepend>
                            <FontAwesomeIcon :icon="['fal', 'search']" size="xl" />
                        </template>
                        {{ $t('Search') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <LoadingSymbol v-if="isLoading" />

        <product-search-results
            v-else
            @switch-to-search="openSearchModal"
            :is-filter-applied="isFilterApplied"
            :results-list="searchResultsProduct" />
    </div>
</template>

<script setup lang="ts">
import {computed, ref, inject, onMounted, watch} from 'vue';
import ApiService from '@/services/api-service.js';
import {SearchProductRequest} from '@/models/api/requests/search/SearchProductRequest';
import {ProductDto} from '@/models/data-transfer-objects/search/product-search/ProductSearchResultDto';
import {SearchProductsResponse} from '@/models/api/responses/search/SearchProductsResponse';
import ProductSearchParameters from '@/views/search/ProductSearchParameters.vue';
import ProductSearchResults from '@/views/search/ProductSearchResults.vue';
import {useUserStore} from '@/stores/user-store';
const userStore = useUserStore();

// Services
const apiService = inject('apiService') as ApiService;

//Local variables
const isVisible = ref<boolean>(false);
const isLoading = ref<boolean>(false);
const searchParametersProduct = ref(new SearchProductRequest());
const localSearchParametersProduct = ref(new SearchProductRequest());
const searchResultsProduct = ref<ProductDto[]>([]);

const isFilterApplied = computed(
    () =>
        (searchParametersProduct.value.isQuickSearch &&
            (searchParametersProduct.value.quickSearchText === null ||
                searchParametersProduct.value.quickSearchText === '')) ||
        (!searchParametersProduct.value.isQuickSearch &&
            (searchParametersProduct.value.productName === null || searchParametersProduct.value.productName === '') &&
            (searchParametersProduct.value.productBrandName === null ||
                searchParametersProduct.value.productBrandName === '') &&
            (searchParametersProduct.value.farmFieldIdList === null ||
                searchParametersProduct.value.farmFieldIdList.length === 0) &&
            (searchParametersProduct.value.productTypeCodeList === null ||
                searchParametersProduct.value.productTypeCodeList.length === 0) &&
            !!searchParametersProduct.value.farmSiteIdList &&
            searchParametersProduct.value.farmSiteIdList.length === 1 &&
            searchParametersProduct.value.farmSiteIdList[0] == userStore.user?.farmSiteId &&
            (searchParametersProduct.value.dateRangeFrom === null ||
                searchParametersProduct.value.dateRangeFrom === '') &&
            (searchParametersProduct.value.dateRangeTo === null || searchParametersProduct.value.dateRangeTo === '')),
);

/**
 * Open search modal
 */
async function openSearchModal() {
    isVisible.value = true;
}

/**
 * Save the modal.
 */
const search = async () => {
    isLoading.value = true;

    searchParametersProduct.value = Object.assign(new SearchProductRequest(), localSearchParametersProduct.value);
    const response: SearchProductsResponse = await apiService.post('search/products', searchParametersProduct.value);
    searchResultsProduct.value = response.products;

    isLoading.value = false;
    isVisible.value = false;
};

onMounted(async () => {
    localSearchParametersProduct.value.farmSiteIdList = userStore.user?.farmSiteId ? [userStore.user.farmSiteId] : null;
    await search();
    localSearchParametersProduct.value = Object.assign(new SearchProductRequest(), searchParametersProduct.value);
});

import {useDeleteOrCancelWorkTaskModalStore} from '@/stores/modals/delete-or-cancel-work-task-modal-store';
const deleteOrCancelWorkTaskModalStore = useDeleteOrCancelWorkTaskModalStore();

watch(
    () => deleteOrCancelWorkTaskModalStore.savedCounter,
    async () => {
        await search();
        localSearchParametersProduct.value = Object.assign(new SearchProductRequest(), searchParametersProduct.value);
    },
);
</script>
