<template>
    <v-dialog v-model="manageFarmFieldSeasonModalStore.isVisible" class="ModifyFarmFieldModal">
        <v-card style="overflow-y: hidden">
            <v-toolbar color="swatchA2">
                <v-toolbar-title>
                    <FontAwesomeIcon :icon="['fas', 'calendar-days']" size="xl" class="mr-2" />
                    {{ $t('FarmFieldSeasonManage_ModalTitle', {farmFieldCode: loadedFarmField.farmFieldCode}) }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <CloseModalButton @click="close" />
            </v-toolbar>

            <LoadingSymbol v-if="isLoading" />

            <v-form v-else ref="form" class="pa-4 divUserManager" v-model="valid" style="overflow-y: scroll">
                <FarmFieldProfile v-if="loadedFarmField" :farmField="loadedFarmField" />
                <div v-if="isNoActiveSeason" class="pb-3">
                    <v-row class="subHeader ma-0">
                        {{ $t('CommenceNewSeason') }}
                    </v-row>
                    <FarmFieldSeasonForm
                        :farmFieldSeason="currentSeason"
                        :isCurrentSeason="true"
                        :seasonOptions="activeSeasonOptions"
                        :pastDateRanges="pastFarmFieldDateRanges" />
                </div>
                <div v-else class="pb-3">
                    <v-row class="subHeader ma-0">
                        {{ $t('CurrentSeason') }}
                    </v-row>
                    <FarmFieldSeasonForm
                        :farmFieldSeason="currentSeason"
                        :isCurrentSeason="true"
                        :seasonOptions="activeSeasonOptions"
                        :pastDateRanges="pastFarmFieldDateRanges" />
                </div>

                <v-expansion-panels multiple>
                    <v-expansion-panel>
                        <v-expansion-panel-title class="expansion-header">
                            {{ $t('PastSeasons') }}</v-expansion-panel-title
                        >
                        <v-expansion-panel-text class="farm-block-expansion" :key="pastSeasons.length">
                            <FarmFieldSeasonForm
                                v-for="(pastSeason, index) in pastSeasons"
                                :key="pastSeasons[index].farmFieldSeasonId ?? 0 + pastSeasons.length"
                                :farmFieldSeason="pastSeasons[index]"
                                :isCurrentSeason="false"
                                :seasonOptions="seasons"
                                :pastDateRanges="pastFarmFieldDateRanges"
                                :isFirstPastSeasonAndNoActiveSeason="isNoActiveSeason && index === 0" />
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                    <v-expansion-panel class="mt-3">
                        <v-expansion-panel-title class="expansion-header">
                            {{ $t('SeasonSpread') }}</v-expansion-panel-title
                        >
                        <v-expansion-panel-text class="farm-block-expansion">
                            <FarmFieldSeasonChart
                                :currentSeason="currentSeason"
                                :pastSeasons="pastSeasons"
                                :allDefaultSeasons="seasons" />
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-form>

            <v-card-actions class="justify-space-between swatchG9BG">
                <v-btn @click="close">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'arrow-rotate-left']" size="xl" />
                    </template>
                    {{ $t('Cancel') }}
                </v-btn>
                <v-btn @click="save" :disabled="isLoading">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'save']" size="xl" />
                    </template>
                    {{ $t('SaveChanges') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" timeout="4000">
        {{ snackbar.text }}
    </v-snackbar>
</template>

<script setup lang="ts">
import '@/assets/scss/farm/modify-farm-field-modal.scss';
import FarmFieldSeasonChart from '@/views/farms/FarmFieldSeasonChart.vue';
import {useManageFarmFieldSeasonModalStore} from '@/stores/modals/manage-farm-field-season-modal-store';
import FarmFieldProfile from '@/components/FarmFieldProfile.vue';
import FarmFieldSeasonForm from '@/views/farms/FarmFieldSeasonForm.vue';
import {ManageFarmFieldForm} from '@/models/farm/ManageFarmFieldForm';
import {ManageFarmFieldSeasonForm} from '@/models/farm/ManageFarmFieldSeasonForm';
import {computed, watch, inject, ref, onUnmounted} from 'vue';
import ApiService from '@/services/api-service.js';
import CloseModalButton from '@/components/CloseModalButton.vue';
import {SaveFarmFieldSeasonRequest} from '@/models/api/requests/farm/SaveFarmFieldSeasonRequest';
import {SaveFarmFieldSeasonListRequest} from '@/models/api/requests/farm/SaveFarmFieldSeasonListRequest';
import i18n from '@/i18n';
import {useSeasons} from '@/composables/data-source/seasons';
const {getSeasons, seasons} = useSeasons();
import {formatTime} from '@/helpers/TimeFormatHelper';
import {SearchFarmFieldsResponse} from '@/models/api/responses/search/SearchFarmFieldsResponse';

// Form
const form = ref();
const valid = ref<boolean>(true);
const isLoading = ref<boolean>(false);
const snackbar = ref({
    show: false,
    text: '',
});

// Services
const apiService = inject('apiService') as ApiService;

// Farm Field modal
const manageFarmFieldSeasonModalStore = useManageFarmFieldSeasonModalStore();

const farmField = ref<ManageFarmFieldForm>(new ManageFarmFieldForm());
const loadedFarmField = ref<ManageFarmFieldForm>(new ManageFarmFieldForm());
const localFarmFieldId = ref<number | null>(null);
const farmFieldSeasons = ref<ManageFarmFieldSeasonForm[]>([]);
const defaultFarmFieldSeason = ref<ManageFarmFieldSeasonForm>(new ManageFarmFieldSeasonForm());
const currentSeason = computed(() => farmFieldSeasons.value.find((e) => e.isActive) ?? defaultFarmFieldSeason.value);
const isNoActiveSeason = computed(() => farmFieldSeasons.value.every((e) => !e.isActive));

const pastSeasons = computed(() => farmFieldSeasons.value.filter((e) => !e.isActive));

const pastFarmFieldDateRanges = computed(() =>
    pastSeasons.value
        .filter((e) => e.farmFieldSeasonStartDate && e.farmFieldSeasonEndDate)
        .map((e) => ({
            start: new Date(e.farmFieldSeasonStartDate ? e.farmFieldSeasonStartDate : new Date()),
            end: new Date(e.farmFieldSeasonEndDate ? e.farmFieldSeasonEndDate : new Date()),
        })),
);

const pastSeasonIds = computed(() => new Set(pastSeasons.value.map((pastSeason) => pastSeason.seasonId)));

const activeSeasonOptions = computed(() => seasons.value.filter((season) => !pastSeasonIds.value.has(season.seasonId)));

/**
 * Load data for the modal.
 */
const loadData = async (passedFarmFieldId: number | null) => {
    if (passedFarmFieldId) {
        isLoading.value = true;
        const searchResults = (await apiService.post('search/farm-fields', {
            farmFieldId: passedFarmFieldId,
            includeCropVarieties: true,
            includeFarmBlocks: true,
            includeFarmSeasons: true,
        })) as SearchFarmFieldsResponse;

        if (searchResults.farmFields.length === 1) {
            const passedFarmField = searchResults.farmFields[0];
            farmField.value = Object.assign(new ManageFarmFieldForm(), passedFarmField);
            loadedFarmField.value = Object.assign(new ManageFarmFieldForm(), passedFarmField);
            localFarmFieldId.value = loadedFarmField.value.farmFieldId;
            if (passedFarmField.farmFieldSeasons && passedFarmField.farmFieldSeasons.length > 0) {
                const previousCropYear = passedFarmField.farmFieldSeasons[0].cropYear ?? 0;
                farmFieldSeasons.value = passedFarmField.farmFieldSeasons.map((e) =>
                    Object.assign(new ManageFarmFieldSeasonForm(), e),
                );
                farmFieldSeasons.value.forEach((e) => (e.temporaryIsActive = e.isActive));
                defaultFarmFieldSeason.value = new ManageFarmFieldSeasonForm();
                defaultFarmFieldSeason.value.farmFieldId = loadedFarmField.value.farmFieldId;
                defaultFarmFieldSeason.value.cropYear = (previousCropYear + 1) % 7;
            }
        }
    }

    await getSeasons();
    isLoading.value = false;
};

/**
 * Close the modal.
 */
const close = () => {
    farmField.value = new ManageFarmFieldForm();
    loadedFarmField.value = new ManageFarmFieldForm();
    farmFieldSeasons.value = [];
    defaultFarmFieldSeason.value = new ManageFarmFieldSeasonForm();
    manageFarmFieldSeasonModalStore.close();
};

onUnmounted(() => {
    localFarmFieldId.value = null;
    close();
});

/**
 * Save the modal.
 */
const save = async () => {
    // Perform final client side validation of form
    await form.value.validate();

    // If form is valid
    if (
        valid.value &&
        ((farmFieldSeasons.value && farmFieldSeasons.value.length > 0) ||
            (currentSeason.value &&
                currentSeason.value.farmFieldSeasonId === null &&
                currentSeason.value.seasonId !== null))
    ) {
        let snackbarMessage = '';
        isLoading.value = true;

        try {
            // Build API request
            const saveFarmFieldSeasonsRequest: SaveFarmFieldSeasonListRequest = {
                newFieldSeason:
                    currentSeason.value &&
                    currentSeason.value.farmFieldSeasonId === null &&
                    currentSeason.value.seasonId !== null
                        ? ({
                              farmFieldSeasonStartDate: currentSeason.value.farmFieldSeasonStartDate
                                  ? formatTime(currentSeason.value.farmFieldSeasonStartDate, 'yyyy-MM-dd')
                                  : '',
                              farmFieldSeasonEndDate: currentSeason.value.farmFieldSeasonEndDate
                                  ? formatTime(currentSeason.value.farmFieldSeasonEndDate, 'yyyy-MM-dd')
                                  : null,
                              cropYear: currentSeason.value.cropYear,
                              farmFieldId: localFarmFieldId.value,
                              seasonId: currentSeason.value.seasonId,
                              yieldTotalWeight: currentSeason.value.yieldTotalWeight,
                              yieldAverageCcs: currentSeason.value.yieldAverageCcs,
                          } as SaveFarmFieldSeasonRequest)
                        : null,
                fieldSeasons: farmFieldSeasons.value.map(
                    (e) =>
                        ({
                            farmFieldSeasonId: e.farmFieldSeasonId,
                            farmFieldSeasonStartDate: e.farmFieldSeasonStartDate
                                ? formatTime(e.farmFieldSeasonStartDate, 'yyyy-MM-dd')
                                : '',
                            farmFieldSeasonEndDate: e.farmFieldSeasonEndDate
                                ? formatTime(e.farmFieldSeasonEndDate, 'yyyy-MM-dd')
                                : null,
                            cropYear: e.cropYear,
                            farmFieldId: e.farmFieldId,
                            seasonId: e.seasonId,
                            yieldTotalWeight: e.yieldTotalWeight,
                            yieldAverageCcs: e.yieldAverageCcs,
                        } as SaveFarmFieldSeasonRequest),
                ),
            };

            // todos
            const response = await apiService.post('farmfields/save-farm-field-seasons', saveFarmFieldSeasonsRequest);

            // Set snackbar message
            if (response) {
                snackbarMessage = i18n.global.t('SaveFarmFieldSeason_Success');
                // Show success feedback to user
                snackbar.value.show = true;
                snackbar.value.text = snackbarMessage;
                manageFarmFieldSeasonModalStore.savedCounter++;

                // Close modal
                close();
            } else {
                // Show fail feedback to user
                snackbar.value.show = true;
                snackbar.value.text = i18n.global.t('ErrorGeneric');
                isLoading.value = false;
            }
        } catch (ex: unknown) {
            // Show fail feedback to user
            snackbar.value.show = true;
            snackbar.value.text = i18n.global.t('ErrorGeneric');
            isLoading.value = false;
        }
    }
};

// Watch for changes to manageFarmFieldSeasonModalStore.farmFieldId
watch(
    () => manageFarmFieldSeasonModalStore.isVisible,
    async (isVisible) => {
        if (isVisible) {
            await loadData(manageFarmFieldSeasonModalStore.farmFieldId);
        }
    },
);

import {useDeleteFarmFieldModalStore} from '@/stores/modals/delete-farm-field-modal-store';
const deleteFarmFieldModalStore = useDeleteFarmFieldModalStore();

watch(
    () => deleteFarmFieldModalStore.deletedCounter,
    () => {
        if (manageFarmFieldSeasonModalStore.isVisible) manageFarmFieldSeasonModalStore.close();
    },
);
</script>

<style lang="css" scoped>
:deep(.v-expansion-panel--active > .v-expansion-panel-title:not(.v-expansion-panel-title--static)) {
    min-height: 48px;
}
:deep(.v-expansion-panel--active:not(:first-child)),
:deep(.v-expansion-panel--active + .v-expansion-panel) {
    margin-top: 0px;
}

:deep(.v-expansion-panel-text__wrapper) {
    padding: 0 !important;
}
</style>
