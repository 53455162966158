<template>
    <v-dialog
        v-model="applyWorkTaskPlanModalStore.isVisible"
        class="pnlUserManager"
        style="min-width: 70vw; max-width: 70vw">
        <v-card>
            <v-toolbar color="secondary">
                <v-toolbar-title>
                    <FontAwesomeIcon :icon="['fal', 'list-timeline']" size="xl" class="mr-2" />
                    {{ $t('ApplyTaskPlan') }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <CloseModalButton @click="close" />
            </v-toolbar>

            <LoadingSymbol v-if="isLoading" />

            <v-form ref="form" v-else class="pa-4 divUserManager" v-model="valid" style="overflow-y: scroll">
                <!-- Instructions panel -->
                <v-card class="pnlInstructions">
                    <v-toolbar color="swatchA6">
                        <v-toolbar-title>
                            <FontAwesomeIcon :icon="['fas', 'question-circle']" size="xl" class="mr-2" />
                            {{ $t('Instructions') }}
                        </v-toolbar-title>
                    </v-toolbar>

                    <v-card-text class="instructionContentBG">
                        {{ $t('ApplyTaskPlanInstructions') }}
                    </v-card-text>
                </v-card>

                <!-- Field -->
                <div class="flex-layout fill flex-layout-space-05 align-items-center">
                    <div class="formHead">{{ $t('Field') }}</div>
                    <div class="formIcon">
                        <HelpIcon :help-text="$t('ApplyWorkTaskPlan_Field_HelpText')" />
                    </div>
                    <div class="formModifyGroup">
                        <v-autocomplete
                            v-model="localApplyWorkTaskPlanModel.farmFieldId"
                            :rules="[requiredRule]"
                            required
                            :placeholder="$t('SelectField')"
                            :items="farmFields"
                            item-title="farmFieldCodeAndName"
                            item-value="farmFieldId"
                            width="400px"
                            menu-icon=""
                            class="swatchA1 font-weight-bold">
                            <template v-slot:append-inner>
                                <CircleIcon
                                    class="marginLeft marginRightSmall swatchGRNBG"
                                    color="swatchWHT"
                                    v-if="localApplyWorkTaskPlanModel.farmFieldId != null">
                                    <FontAwesomeIcon :icon="['fas', 'check']" />
                                </CircleIcon>
                            </template>
                        </v-autocomplete>
                    </div>
                </div>

                <!-- Plan Name -->
                <div class="flex-layout fill flex-layout-space-05 align-items-center">
                    <div class="formHead">{{ $t('PlanName') }}</div>
                    <div class="formIcon">
                        <HelpIcon :help-text="$t('ApplyWorkTaskPlan_PlanName_HelpText')" />
                    </div>
                    <div class="formModifyGroup">
                        <v-autocomplete
                            v-model="localApplyWorkTaskPlanModel.workTaskPlanId"
                            :rules="[requiredRule]"
                            required
                            :placeholder="$t('Select')"
                            :items="workTaskPlans"
                            item-title="workTaskPlanName"
                            item-value="workTaskPlanId"
                            width="400px"
                            menu-icon=""
                            class="swatchA1 font-weight-bold">
                            <template v-slot:append-inner>
                                <CircleIcon
                                    class="marginLeft marginRightSmall swatchGRNBG"
                                    color="swatchWHT"
                                    v-if="localApplyWorkTaskPlanModel.workTaskPlanId != null">
                                    <FontAwesomeIcon :icon="['fas', 'check']" />
                                </CircleIcon>
                            </template>
                        </v-autocomplete>
                    </div>
                </div>

                <!-- First Task Timing -->
                <div class="flex-layout fill flex-layout-space-05 align-items-center">
                    <div class="formHead">{{ $t('FirstTaskTiming') }}</div>
                    <div class="formIcon">
                        <HelpIcon :help-text="$t('ApplyWorkTaskPlan_FirstTaskTiming_HelpText')" />
                    </div>
                    <div class="formModifyGroup">
                        <WorkTaskDueDateTimingControl
                            ref="dueDateTimingControl"
                            v-model:work-task="localApplyWorkTaskPlanModel"
                            :work-task-type-code="firstWorkTaskPlanItem?.workTaskTypeCode" />
                    </div>
                </div>

                <!-- Apply From Step -->
                <div class="flex-layout fill flex-layout-space-05 align-items-center">
                    <div class="formHead">{{ $t('ApplyFromStep') }}</div>
                    <div class="formIcon">
                        <HelpIcon :help-text="$t('ApplyWorkTaskPlan_ApplyFromStep_HelpText')" />
                    </div>
                    <div class="formModifyGroup">
                        <v-select
                            v-model="localApplyWorkTaskPlanModel.applyFromSequenceNumber"
                            :rules="[requiredRule]"
                            required
                            :placeholder="$t('Select')"
                            :items="workTaskPlanService.workTaskPlanItemsSorted.value"
                            item-title="workTaskTemplateNameWithSequenceNumber"
                            item-value="sequenceNumber"
                            width="400px"
                            class="swatchA1 font-weight-bold">
                        </v-select>
                    </div>
                </div>

                <!-- Work Task Plan Summary -->
                <v-expansion-panels class="marginTop" v-model="expansionPanelsPlanSummary">
                    <v-expansion-panel>
                        <v-expansion-panel-title class="expansion-header">
                            {{ $t('PlanSummary') }}
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <WorkTaskPlanSummary :work-task-plan-service="workTaskPlanService" />
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-form>

            <v-card-actions class="justify-space-between swatchG9BG">
                <!-- Cancel -->
                <v-btn @click="close">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'arrow-rotate-left']" size="xl" />
                    </template>
                    {{ $t('Cancel') }}
                </v-btn>

                <!-- Apply -->
                <v-btn @click="applyTaskPlan" :disabled="isLoading">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'save']" size="xl" />
                    </template>
                    {{ $t('Apply') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" timeout="4000">
        {{ snackbar.text }}
    </v-snackbar>
</template>

<script setup lang="ts">
import '@/assets/scss/user/user-manager.scss';
import {watch, ref, inject, computed} from 'vue';
import CloseModalButton from '@/components/CloseModalButton.vue';
import {useApplyWorkTaskPlanModalStore} from '@/stores/modals/apply-work-task-plan-modal-store';
import {ApplyWorkTaskPlanForm} from '@/models/work-tasks/ApplyWorkTaskPlanForm';
import ApiService from '@/services/api-service';
import WorkTaskPlanService from '@/services/work-task-plan-service';
import WorkTaskPlanSummary from '@/components/WorkTaskPlanSummary.vue';
import WorkTaskDueDateTimingControl from '@/components/work-tasks/WorkTaskDueDateTimingControl.vue';
import Validation from '@/helpers/ValidationHelper';
import {useWorkTaskPlans} from '@/composables/data-source/work-task-plans';
const {getWorkTaskPlans, workTaskPlans} = useWorkTaskPlans();
import {useFarmFields} from '@/composables/data-source/farm-fields';
import {ApplyWorkTaskPlanRequest} from '@/models/api/requests/work-tasks/ApplyWorkTaskPlanRequest';
import i18n from '@/i18n';
import {formatTime} from '@/helpers/TimeFormatHelper';
const {getFarmFields, farmFields} = useFarmFields();
import {useUserStore} from '@/stores/user-store';
const userStore = useUserStore();

// Form
const form = ref();
const dueDateTimingControl = ref();
const valid = ref<boolean>(true);
let isLoading = ref<boolean>(false);
const expansionPanelsPlanSummary = ref([0]);
const snackbar = ref({
    show: false,
    text: '',
});

// Rules
const requiredRule = Validation.createRule_Required();

// Services
const apiService = inject('apiService') as ApiService;
const workTaskPlanService = new WorkTaskPlanService(apiService);

// Modal
const applyWorkTaskPlanModalStore = useApplyWorkTaskPlanModalStore();
const localApplyWorkTaskPlanModel = ref<ApplyWorkTaskPlanForm>(new ApplyWorkTaskPlanForm());

/**
 * Load data for the modal.
 */
const loadData = async () => {
    isLoading.value = true;

    // Reset form
    resetForm();

    // Load data
    await getWorkTaskPlans();
    await getFarmFields(userStore.user?.farmSiteId ? [userStore.user.farmSiteId] : []);

    // If a work task ID was specified
    if (applyWorkTaskPlanModalStore.workTaskPlanId !== null) {
        // Select plan
        localApplyWorkTaskPlanModel.value.workTaskPlanId = applyWorkTaskPlanModalStore.workTaskPlanId;

        // Start from first step by default
        localApplyWorkTaskPlanModel.value.applyFromSequenceNumber = 1;
    }

    // End loading
    isLoading.value = false;
};

/**
 * Call API to apply the task plan.
 */
const applyTaskPlan = async () => {
    // Perform final client side validation of form
    await form.value.validate();
    const isDueDateValid = await dueDateTimingControl.value.validate();

    // If form is valid
    if (valid.value && isDueDateValid) {
        isLoading.value = true;

        try {
            // Build API request
            const applyWorkTaskPlanRequest: ApplyWorkTaskPlanRequest = {
                parameters: {
                    workTaskPlanId: localApplyWorkTaskPlanModel.value.workTaskPlanId!,
                    farmFieldId: localApplyWorkTaskPlanModel.value.farmFieldId!,
                    dueDateTiming: localApplyWorkTaskPlanModel.value.dueDateTiming,
                    dueDateOn: localApplyWorkTaskPlanModel.value.dueDateOn
                        ? formatDateForApi(localApplyWorkTaskPlanModel.value.dueDateOn)
                        : null,
                    dueDateLatest: localApplyWorkTaskPlanModel.value.dueDateLatest
                        ? formatDateForApi(localApplyWorkTaskPlanModel.value.dueDateLatest)
                        : null,
                    dueDateMonth: localApplyWorkTaskPlanModel.value.dueDateMonth
                        ? formatDateForApi(
                              new Date(
                                  localApplyWorkTaskPlanModel.value.dueDateMonth.year,
                                  localApplyWorkTaskPlanModel.value.dueDateMonth.month,
                                  1,
                              ),
                          )
                        : null,
                    dueDateRange:
                        localApplyWorkTaskPlanModel.value.dueDateRange &&
                        localApplyWorkTaskPlanModel.value.dueDateRange.length == 2
                            ? [
                                  formatDateForApi(localApplyWorkTaskPlanModel.value.dueDateRange[0]),
                                  formatDateForApi(localApplyWorkTaskPlanModel.value.dueDateRange[1]),
                              ]
                            : null,
                    dueAfterWorkTaskId: localApplyWorkTaskPlanModel.value.dueAfterWorkTaskId,
                    dueAfterWorkTaskWithinDays: localApplyWorkTaskPlanModel.value.dueAfterWorkTaskWithinDays,
                    applyFromSequenceNumber: localApplyWorkTaskPlanModel.value.applyFromSequenceNumber!,
                },
            };

            // Call API to save work task
            await apiService.post('work-task-plans/apply', applyWorkTaskPlanRequest);

            // Show success feedback to user
            snackbar.value.show = true;
            snackbar.value.text = i18n.global.t('ApplyWorkTaskPlan_Success');
            applyWorkTaskPlanModalStore.savedCounter++;

            // Close modal
            applyWorkTaskPlanModalStore.close();
        } catch (ex: unknown) {
            // Show fail feedback to user
            snackbar.value.show = true;
            snackbar.value.text = i18n.global.t('ErrorGeneric');
            isLoading.value = false;
        }
    } else {
        // Show validation error feedback to user
        snackbar.value.show = true;
        snackbar.value.text = i18n.global.t('ErrorValidation');
    }
};

/**
 * Format date for the API.
 */
const formatDateForApi = (date: Date) => {
    return formatTime(date, 'yyyy-MM-dd');
};

/**
 * Close the modal.
 */
const close = () => {
    applyWorkTaskPlanModalStore.close();
};

/**
 * Reset the form to its default state.
 */
const resetForm = () => {
    localApplyWorkTaskPlanModel.value = new ApplyWorkTaskPlanForm();
    expansionPanelsPlanSummary.value = [0];
};

/**
 * The first item in the plan.
 */
const firstWorkTaskPlanItem = computed(() => {
    if (workTaskPlanService.workTaskPlanItemsSorted.value.length > 0) {
        return workTaskPlanService.workTaskPlanItemsSorted.value[0];
    } else {
        return null;
    }
});

// Watch for changes to modal state
watch(
    () => applyWorkTaskPlanModalStore.isVisible,
    async (isVisible) => {
        if (isVisible) {
            await loadData();
        }
    },
);

import {useDeleteWorkTaskTemplateModalStore} from '@/stores/modals/delete-work-task-template-modal-store';
const deleteWorkTaskTemplateModalStore = useDeleteWorkTaskTemplateModalStore();
watch(
    () => deleteWorkTaskTemplateModalStore.deletedCounter,
    async () => {
        if (applyWorkTaskPlanModalStore.isVisible) {
            await loadData();
        }
    },
);

import {useDeleteWorkTaskPlanModalStore} from '@/stores/modals/delete-work-task-plan-modal-store';
const deleteWorkTaskPlanModalStore = useDeleteWorkTaskPlanModalStore();
watch(
    () => deleteWorkTaskPlanModalStore.deletedCounter,
    () => {
        if (applyWorkTaskPlanModalStore.isVisible) {
            close();
        }
    },
);

// Watch for changes to modal state
watch(
    () => localApplyWorkTaskPlanModel.value.workTaskPlanId,
    async () => {
        // If a work task ID was specified
        if (localApplyWorkTaskPlanModel.value.workTaskPlanId !== null) {
            // Load plan details
            await workTaskPlanService.loadWorkTaskPlan(localApplyWorkTaskPlanModel.value.workTaskPlanId);
        }
    },
);
</script>
