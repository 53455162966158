<template>
    <div ref="chartContainer"></div>
</template>

<script setup lang="ts">
import {ref, onMounted, watch} from 'vue';
import Highcharts, {Point} from 'highcharts';
import xrange from 'highcharts/modules/xrange';
import {ManageFarmFieldSeasonForm} from '@/models/farm/ManageFarmFieldSeasonForm';
import {GetSeasonsResponse} from '@/models/api/responses/data-source/GetSeasonsResponse';
import {configureHighchartsLocale} from '@/i18n/highchartsLocale';
import i18n from '@/i18n';

interface XRangePoint extends Point {
    dataLabels?: {
        format?: string;
    };
}

xrange(Highcharts); // Initialize the XRange module

// Props definition

const props = defineProps<{
    pastSeasons: ManageFarmFieldSeasonForm[];
    currentSeason: ManageFarmFieldSeasonForm;
    allDefaultSeasons: GetSeasonsResponse[];
}>();

// Reference for the chart container
const chartContainer = ref<HTMLDivElement | null>(null);

const selectedSeason =
    props.currentSeason.farmFieldId === null
        ? null
        : props.allDefaultSeasons.find((e) => e.seasonId === props.currentSeason.seasonId);

// Calculate the completion progress of the current season
const currentSeasonStartDate = new Date(props.currentSeason.farmFieldSeasonStartDate as Date | string).getTime();
const currentSeasonStartDateDefault = new Date(props.currentSeason.farmFieldSeasonStartDate as Date | string);
const currentSeasonStartDatePlusOneYearDefault = new Date(
    currentSeasonStartDateDefault.setFullYear(currentSeasonStartDateDefault.getFullYear() + 1),
);
const currentSeasonEndDate = selectedSeason
    ? new Date(selectedSeason.seasonEndDate).getTime()
    : currentSeasonStartDatePlusOneYearDefault.getTime();
const currentTime = new Date().getTime();
const completedDays = (currentTime - currentSeasonStartDate) / (1000 * 60 * 60 * 24);
const totalDays = (currentSeasonEndDate - currentSeasonStartDate) / (1000 * 60 * 60 * 24);
const completionPercentage = totalDays > 0 ? (completedDays / totalDays) * 100 : 0;

// Define the gradient for the current season's progress
const gradientColor = {
    linearGradient: {x1: 0, x2: 1, y1: 0, y2: 0},
    stops: [
        [0, '#00804E'],
        [completionPercentage / 100, '#00804E'],
        [completionPercentage / 100, '#50C861'],
        [1, '#50C861'],
    ],
};

const currentSeasonData = selectedSeason
    ? [
          {
              x: currentSeasonStartDate,
              x2: currentSeasonEndDate,
              y: 0,
              color: gradientColor,
              dataLabels: {
                  enabled: true,
                  inside: true,
                  align: 'center',
                  format: i18n.global.n(completionPercentage, 'decimal1') + '%',
              },
          },
      ]
    : null;

const pastSeasonData = props.pastSeasons.map((season, index) => ({
    x: new Date(season.farmFieldSeasonStartDate as Date | string).getTime(),
    x2: new Date(season.farmFieldSeasonEndDate as Date | string).getTime(),
    y: index + (currentSeasonData ? 1 : 0),
    color: '#003B46',
}));

const allSeriesData = currentSeasonData ? [...currentSeasonData, ...pastSeasonData] : pastSeasonData;
const categories = currentSeasonData
    ? [props.currentSeason.seasonName, ...props.pastSeasons.map((season) => season.seasonName)]
    : [...props.pastSeasons.map((season) => season.seasonName)];

// Function to render the chart
const renderChart = () => {
    if (!chartContainer.value) return;

    Highcharts.chart(chartContainer.value, {
        chart: {
            type: 'xrange',
        },
        legend: {
            enabled: false,
        },
        credits: {enabled: false},
        navigation: {
            buttonOptions: {
                enabled: false,
            },
        },
        title: {
            text: null,
        },
        accessibility: {
            point: {
                descriptionFormat: '{add index 1}. {yCategory}, {x:%A %e %B %Y} to {x2:%A %e %B %Y}.',
            },
        },
        xAxis: {
            type: 'datetime',
            labels: {
                format: '{value:%b %e, %Y}',
            },
        },
        yAxis: {
            title: {
                text: '',
            },
            categories: categories,
            reversed: true,
        },
        series: [
            {
                type: 'xrange',
                borderColor: 'gray',
                pointWidth: 20,
                data: allSeriesData,
                dataLabels: {
                    enabled: true,
                    inside: true,
                    formatter: function (this: XRangePoint): string {
                        if (this.dataLabels?.format) {
                            const percentage = parseFloat(this.dataLabels.format.replace('%', ''));
                            return i18n.global.n(percentage, 'decimal1') + '%';
                        }
                        return '';
                    },
                },
            },
        ],
    });
};

// Render the chart when the component is mounted
onMounted(() => {
    renderChart();
    configureHighchartsLocale();
});

// Re-render the chart when the pastSeasons prop changes
watch(() => props.pastSeasons, renderChart);
</script>

<style scoped>
#container {
    height: 200px;
    font-family: 'Open Sans', sans-serif;
    border: 1px solid black;
}

.highcharts-figure,
.highcharts-data-table table {
    min-width: 320px;
    max-width: 935px;
    margin: 1em auto;
}

.highcharts-data-table table {
    font-family: 'Open Sans', sans-serif;
    border-collapse: collapse;
    border: 1px solid #ebebeb;
    margin: 10px auto;
    text-align: center;
    width: 100%;
    max-width: 500px;
}
</style>
