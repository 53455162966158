<template>
    <v-data-table
        class="crop-variety-table pa-0 ma-0"
        style="overflow-x: auto; width: 99.9%"
        :headers="headers"
        :items="filteredList"
        :items-per-page="configStore.defaultListItemNumberPerPage"
        @click:row="viewCrop">
        <template v-slot:[`item.cropCode`]="{item}">
            <div class="tableCell">
                <CropIcon :cropCode="item.cropCode" :primary="item.isPrimary" :varieties="[item.cropVarietyName]" />
            </div>
        </template>
        <template v-slot:[`item.cropVarietyName`]="{item}">
            <div class="tableCell">
                <span class="swatchA1 slightlyLarger"> {{ item.cropVarietyName }} </span>
            </div>
        </template>
        <template v-slot:[`item.cropName`]="{item}">
            <div class="tableCell">
                <b class="swatchA1"> {{ item.cropName }} </b>
            </div>
        </template>
        <template v-slot:[`item.areaPlantedTotal`]="{item}">
            <div class="tableCell">
                <b class="swatchA1"> {{ formattedFieldSize(item.areaPlantedTotal) + ' Ha' }} </b>
            </div>
        </template>
        <template v-slot:[`item.fieldPlantedTotal`]="{item}">
            <v-row class="tableCell">
                <v-col cols="12" align="center"
                    ><b class="swatchA1"> {{ $n(item.fieldPlantedTotal, 'decimal') }} </b></v-col
                >
            </v-row>
        </template>

        <template v-slot:[`item.lastPlantedDate`]="{item}">
            <div class="tableCell">
                <span class="swatchA1"> {{ formatDate(item.lastPlantedDate) }} </span>
            </div>
        </template>

        <template v-slot:[`item.distribution`]="{item}">
            <v-col align="center" v-if="getCropDistribution(item.cropId)">
                <CropDistributionChart :cropSizeData="getCropDistribution(item.cropId)" />
            </v-col>
        </template>

        <template v-slot:[`item.sitesPlanted`]="{item}">
            <div class="tableCell">
                <span class="pr-1" v-for="site in item.sitesPlanted" :key="site.farmSiteId"
                    ><FarmSiteChip :farm-site-abbreviation="site.farmSiteAbbreviationAndName"
                /></span>
            </div>
        </template>
        <template v-slot:[`item.artifacts`]="{item}">
            <div style="max-width: 220px">
                <span v-for="(artifact, index) in item.artifacts" :key="artifact.artifactId">
                    <FileTypeButton
                        v-if="index <= (item.links.length > 0 ? 0 : 1)"
                        :key="artifact.artifactId"
                        :url="artifact.artifactUrl"
                        :display-name="artifact.artifactDisplayName"
                        :file-type-code="artifact.artifactFileTypeCode" />
                </span>

                <span class="mr-1" v-for="(link, index) in item.links" :key="link.linkId">
                    <FileTypeButton
                        v-if="index <= (item.artifacts.length > 0 ? 0 : 1)"
                        :url="link.linkUrl"
                        :display-name="link.linkName"
                        :file-type-code="ArtifactFileTypeCode.Other" />
                </span>

                <span
                    v-if="
                        (item.artifacts && item.artifacts.length > (item.links.length > 0 ? 1 : 2)) ||
                        (item.links && item.links.length > (item.artifacts.length > 0 ? 1 : 2))
                    ">
                    <v-menu>
                        <template v-slot:activator="{props}">
                            <v-btn class="v-btn--custom" size="small" v-bind="props"
                                ><font-awesome-icon :icon="['fas', 'ellipsis']"
                            /></v-btn>
                        </template>

                        <v-list>
                            <v-list-item
                                v-for="artifact in item.artifacts.slice(item.links.length > 0 ? 1 : 2)"
                                :key="artifact.artifactId">
                                <v-list-item-title>
                                    <FileTypeButton
                                        :key="artifact.artifactId"
                                        :url="artifact.artifactUrl"
                                        :display-name="artifact.artifactDisplayName"
                                        :file-type-code="artifact.artifactFileTypeCode" />
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                v-for="link in item.links.slice(item.artifacts.length > 0 ? 1 : 2)"
                                :key="link.linkId">
                                <v-list-item-title>
                                    <FileTypeButton
                                        :url="link.linkUrl"
                                        :display-name="link.linkName"
                                        :file-type-code="ArtifactFileTypeCode.Other" />
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </span>
            </div>
        </template>

        <template v-slot:[`item.action`]="{item}">
            <div style="text-align: center">
                <v-menu>
                    <template v-slot:activator="{props}">
                        <v-btn class="v-btn--custom" v-bind="props"> {{ $t('Actions') }} </v-btn>
                    </template>

                    <v-list>
                        <v-list-item>
                            <v-list-item-title v-if="userStore.hasRoleLevel(RoleCode.System, 1)">
                                <v-btn @click="viewCropVarietyModalStore.open(item.cropVarietyId)">{{
                                    $t('ViewVariety')
                                }}</v-btn>
                            </v-list-item-title>
                            <v-list-item-title v-if="userStore.hasRoleLevel(RoleCode.Fields, 1)">
                                <v-btn @click="viewFieldsWithThisVariety(item)">
                                    {{ $t('ViewFieldsWithThisVariety') }}
                                </v-btn>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </template>
    </v-data-table>
</template>
<script setup lang="ts">
import {ref, onMounted, inject} from 'vue';
import ApiService from '@/services/api-service';
import {CropVarietyDto} from '@/models/data-transfer-objects/search/crop-variety-search/CropVarietySearchDto';
import i18n from '@/i18n';
import {formatTime} from '@/helpers/TimeFormatHelper';
import FarmSiteChip from '@/components/other-chips/FarmSiteChip.vue';
import {useViewCropVarietyModalStore} from '@/stores/modals/view-crop-variety-modal-store';
import {ArtifactFileTypeCode} from '@/enums/artifact-file-type-code';
const viewCropVarietyModalStore = useViewCropVarietyModalStore();
import FileTypeButton from '@/components/FileTypeButton.vue';
import {useConfigStore} from '@/stores/config-store';
const configStore = useConfigStore();
import CropDistributionChart from '@/components/CropDistributionChart.vue';
import {GetCropDistributionResponse} from '@/models/api/responses/crops/GetCropDistributionResponse';
import {useAdvancedSearchModalStore} from '@/stores/modals/advanced-search-modal-store';
const advancedSearchModalStore = useAdvancedSearchModalStore();
import router from '@/router';
import {useUserStore} from '@/stores/user-store';
const userStore = useUserStore();
import {RoleCode} from '@/enums/role-code';

const props = withDefaults(
    defineProps<{
        filteredList: CropVarietyDto[];
        isPrimary?: boolean;
    }>(),
    {
        filteredList: () => [] as CropVarietyDto[],
        isPrimary: false,
    },
);

// Format date to necessary structure
const formatDate = (date: Date | null): string | null => {
    if (!date) return 'N/A';

    return formatTime(new Date(date), 'dd MMM, yyyy');
};

const cropSizeData = ref<GetCropDistributionResponse[]>([]);

const viewFieldsWithThisVariety = (item: CropVarietyDto) => {
    advancedSearchModalStore.close();
    router.push({path: `/farm-field-list/${item.cropVarietyId}`});
};

const viewCrop = (event: MouseEvent, row: {item: CropVarietyDto}) => {
    if (userStore.hasRoleLevel(RoleCode.System, 1)) viewCropVarietyModalStore.open(row.item.cropVarietyId);
};

const formattedFieldSize = (size: number | null) => {
    size = size ?? 0;
    if (size === 0) {
        return '0';
    }
    return size < 10
        ? i18n.global.n(size, 'decimal')
        : size < 100
        ? i18n.global.n(size, 'decimal1')
        : i18n.global.n(size, 'decimal0');
};

const headers = ref([
    {title: i18n.global.t('Chip'), key: 'cropCode', sortable: false},
    {title: i18n.global.t('Variety'), key: 'cropVarietyName', sortable: true},
    {title: i18n.global.t('Crop'), key: 'cropName', sortable: true},
    {title: i18n.global.t('AreaPlanted'), key: 'areaPlantedTotal', sortable: true},
    {title: i18n.global.t('FieldsPlanted'), key: 'fieldPlantedTotal', sortable: true},
    {title: i18n.global.t('LastPlanted'), key: 'lastPlantedDate', sortable: true},
    {title: i18n.global.t('FarmSite(s)'), key: 'sitesPlanted', sortable: false},
    {title: i18n.global.t('DataSheetsAndLinks'), key: 'artifacts', sortable: false},
    {title: i18n.global.t('Action'), key: 'action', sortable: false},
]);

if (props.isPrimary)
    headers.value.splice(6, 0, {title: i18n.global.t('Distribution'), key: 'distribution', sortable: false});

const getCropDistribution = (cropId: number): GetCropDistributionResponse | null => {
    const cropDistribution = cropSizeData.value.find((e) => e.cropId === cropId);
    if (cropDistribution) return cropDistribution;
    return null;
};

onMounted(async () => {
    const apiService = inject('apiService') as ApiService;

    const cropSizeResponse = await apiService.get('crops/crop-distribution');
    if (cropSizeResponse && cropSizeResponse.cropSizes && cropSizeResponse.cropSizes.length > 0) {
        cropSizeData.value = cropSizeResponse.cropSizes;
    }
});
</script>
<style lang="scss">
@import '@/assets/scss/swatches.scss';
.crop-variety-table .v-table__wrapper thead {
    background-color: $swatchG7;
}
.v-data-table__td,
.v-table__wrapper thead {
    border: 1px groove $swatchGA;
}
.crop-search-links {
    font-family: 'Open Sans Condensed', sans-serif;
}
</style>
