<template>
    <div>
        <LoadingSymbol v-if="combinedLoading" />
        <div v-else-if="farmField">
            <v-list v-if="farmField.farmBlocks.length > 0" density="compact">
                <v-list-item
                    v-for="farmBlock in farmField?.farmBlocks"
                    :key="farmBlock.farmBlockId"
                    height="32"
                    class="pa-0">
                    <div class="farm-block-row">
                        <div
                            style="width: 100px"
                            @mouseover="homeDashboardStore.onFarmBlockMouseOver(farmBlock)"
                            @mouseout="homeDashboardStore.onFarmBlockMouseOut">
                            <CropIcon
                                :cropCode="farmField.cropCode"
                                iconHeight="32"
                                iconWidth="32"
                                iconSize="18"
                                :varieties="[farmBlock.cropVariety?.cropVarietyName]" />
                        </div>
                        <div style="width: 80px">
                            {{ farmBlock.farmBlockNumRows }}
                            {{ farmBlock.farmBlockNumRows == 1 ? $t('Row') : $t('Rows') }}
                        </div>
                        <div style="width: 80px">{{ formatFieldSize(farmBlock.farmBlockSize) }}ha</div>
                        <div class="farm-block-name">
                            {{ farmBlock.farmBlockCode }}
                        </div>
                    </div>
                </v-list-item>
            </v-list>
            <div v-if="farmField?.plantedDate" class="planted-year-row">
                <span style="padding-left: 10px; width: 110px">{{ $t('Planted') }}</span>
                <span>{{ formatDate(farmField?.plantedDate) }}</span>
                <span style="font-weight: bold; padding-left: 5px">{{
                    new Date(farmField?.plantedDate).getFullYear()
                }}</span>
            </div>
        </div>
        <div class="swatchG9BG">
            <div class="task-tabs-row" :style="{width: farmField == null ? '383px' : '323px'}">
                <v-tabs
                    height="42"
                    v-model="homeDashboardStore.isTaskFutureMode"
                    bg-color="swatchG9"
                    selected-class="active-tab">
                    <v-tab class="task-tab" :style="{width: farmField == null ? '170px' : '160px'}" :value="false">
                        <div>
                            <FontAwesomeIcon :icon="['fal', 'clipboard-check']" size="xl" />
                            {{ getTaskTabRecentText }}
                        </div>
                    </v-tab>
                    <v-tab class="task-tab" :style="{width: farmField == null ? '170px' : '160px'}" :value="true">
                        <div>
                            <FontAwesomeIcon :icon="['fal', 'clipboard-list']" size="xl" />
                            {{ getTaskTabFutureText }}
                            <span v-if="overdueTasks.length > 0" class="overdue-alert-icon"></span>
                        </div>
                    </v-tab>
                </v-tabs>
            </div>
        </div>
        <LoadingSymbol v-if="combinedLoading" />
        <div v-else-if="(futureWorkTasks.length > 0 && isFutureMode) || (recentWorkTasks.length > 0 && !isFutureMode)">
            <v-list class="list-container">
                <!--Tab One-->
                <!--Future Tasks-->
                <v-expansion-panels
                    v-if="isFutureMode && props.farmField == null"
                    :multiple="true"
                    v-model="openedFutureExpansionPanels">
                    <!--Underway Now Tasks-->
                    <v-expansion-panel
                        class="swatchBGBG"
                        @drop.prevent="onDrop"
                        @dragover.prevent="onDragOver(NavigatorFutureTaskList.UnderwayNow)">
                        <v-expansion-panel-title :class="getHeaderClass(NavigatorFutureTaskList.UnderwayNow)">
                            <div class="expansion-panel-title">
                                <FontAwesomeIcon class="swatchA1" :icon="['fa', 'bars-progress']" size="xl" />
                                {{ $t('UnderwayNow').toUpperCase() }} ({{ underwayNowTasks.length }}):
                            </div>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <NavigatorTabWorkTaskItemList
                                :workTasks="underwayNowTasks"
                                :isTabOne="true"
                                :isCompressedMode="props.isCompressedMode"
                                :modelValue="props.modelValue"
                                :is-drag-disabled="false"
                                :list-name="NavigatorFutureTaskList.UnderwayNow"
                                @work-task-item-selected="selectItem" />
                        </v-expansion-panel-text>
                    </v-expansion-panel>

                    <!--Overdue Tasks-->
                    <v-expansion-panel
                        class="swatchBGBG"
                        @drop.prevent="onDrop"
                        @dragover.prevent="onDragOver(NavigatorFutureTaskList.Overdue)">
                        <v-expansion-panel-title :class="getHeaderClass(NavigatorFutureTaskList.Overdue)">
                            <div class="expansion-panel-title">
                                <FontAwesomeIcon
                                    class="swatchRED"
                                    :icon="['fa', 'calendar-circle-exclamation']"
                                    size="xl" />
                                {{ $t('Overdue').toUpperCase() }} ({{ overdueTasks.length }}):
                            </div>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <NavigatorTabWorkTaskItemList
                                :workTasks="overdueTasks"
                                :isTabOne="true"
                                :isCompressedMode="props.isCompressedMode"
                                :modelValue="props.modelValue"
                                :is-drag-disabled="false"
                                :list-name="NavigatorFutureTaskList.Overdue"
                                @work-task-item-selected="selectItem" />
                        </v-expansion-panel-text>
                    </v-expansion-panel>

                    <!--Today Tasks-->
                    <v-expansion-panel
                        class="swatchBGBG"
                        @drop.prevent="onDrop"
                        @dragover.prevent="onDragOver(NavigatorFutureTaskList.Today)">
                        <v-expansion-panel-title :class="getHeaderClass(NavigatorFutureTaskList.Today)">
                            <div class="expansion-panel-title">
                                <FontAwesomeIcon class="swatchORG" :icon="['fa', 'calendar-day']" size="xl" />
                                {{ $t('Today').toUpperCase() }} ({{ todayTasks.length }}):
                            </div>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <NavigatorTabWorkTaskItemList
                                :workTasks="todayTasks"
                                :isTabOne="true"
                                :isCompressedMode="props.isCompressedMode"
                                :modelValue="props.modelValue"
                                :is-drag-disabled="false"
                                :list-name="NavigatorFutureTaskList.Today"
                                @work-task-item-selected="selectItem" />
                        </v-expansion-panel-text>
                    </v-expansion-panel>

                    <!--Next 7 Days Tasks-->
                    <v-expansion-panel
                        class="swatchBGBG"
                        @drop.prevent="onDrop"
                        @dragover.prevent="onDragOver(NavigatorFutureTaskList.Next7Days)">
                        <v-expansion-panel-title :class="getHeaderClass(NavigatorFutureTaskList.Next7Days)">
                            <div class="expansion-panel-title">
                                <FontAwesomeIcon class="swatchERR" :icon="['fa', 'calendar-week']" size="xl" />
                                {{ $t('Next7Days').toUpperCase() }} ({{ next7DaysTasks.length }}):
                            </div>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <NavigatorTabWorkTaskItemList
                                :workTasks="next7DaysTasks"
                                :isTabOne="true"
                                :isCompressedMode="props.isCompressedMode"
                                :modelValue="props.modelValue"
                                :is-drag-disabled="false"
                                :list-name="NavigatorFutureTaskList.Next7Days"
                                @work-task-item-selected="selectItem" />
                        </v-expansion-panel-text>
                    </v-expansion-panel>

                    <!--Next 30 Days Tasks-->
                    <v-expansion-panel
                        class="swatchBGBG"
                        @drop.prevent="onDrop"
                        @dragover.prevent="onDragOver(NavigatorFutureTaskList.Next30Days)">
                        <v-expansion-panel-title :class="getHeaderClass(NavigatorFutureTaskList.Next30Days)">
                            <div class="expansion-panel-title">
                                <FontAwesomeIcon class="swatchG2" :icon="['fa', 'calendar-range']" size="xl" />
                                {{ $t('Next30Days').toUpperCase() }} ({{ next30DaysTasks.length }}):
                            </div>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <NavigatorTabWorkTaskItemList
                                :workTasks="next30DaysTasks"
                                :isTabOne="true"
                                :isCompressedMode="props.isCompressedMode"
                                :modelValue="props.modelValue"
                                :is-drag-disabled="false"
                                :list-name="NavigatorFutureTaskList.Next30Days"
                                @work-task-item-selected="selectItem" />
                        </v-expansion-panel-text>
                    </v-expansion-panel>

                    <!--Next 60 Days Tasks-->
                    <v-expansion-panel
                        class="swatchBGBG"
                        @drop.prevent="onDrop"
                        @dragover.prevent="onDragOver(NavigatorFutureTaskList.Next60Days)">
                        <v-expansion-panel-title :class="getHeaderClass(NavigatorFutureTaskList.Next60Days)">
                            <div class="expansion-panel-title">
                                <FontAwesomeIcon class="swatchG3" :icon="['fal', 'calendar-range']" size="xl" />
                                {{ $t('Next60Days').toUpperCase() }} ({{ next60DaysTasks.length }}):
                            </div>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <NavigatorTabWorkTaskItemList
                                :workTasks="next60DaysTasks"
                                :isTabOne="true"
                                :isCompressedMode="props.isCompressedMode"
                                :modelValue="props.modelValue"
                                :is-drag-disabled="false"
                                :list-name="NavigatorFutureTaskList.Next60Days"
                                @work-task-item-selected="selectItem" />
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>

                <!--Recent Tasks-->
                <v-expansion-panels
                    v-if="!isFutureMode && props.farmField == null"
                    :multiple="true"
                    v-model="openedRecentExpansionPanels">
                    <!--Just Completed Tasks-->
                    <v-expansion-panel class="swatchBGBG">
                        <v-expansion-panel-title>
                            <div class="expansion-panel-title">
                                <FontAwesomeIcon class="swatchGRN" :icon="['fa', 'calendar-star']" size="xl" />
                                {{ $t('JustCompleted').toUpperCase() }} ({{ justCompletedTasks.length }}):
                            </div>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <NavigatorTabWorkTaskItemList
                                :workTasks="justCompletedTasks"
                                :isTabOne="true"
                                :isCompressedMode="props.isCompressedMode"
                                :modelValue="props.modelValue"
                                @work-task-item-selected="selectItem" />
                        </v-expansion-panel-text>
                    </v-expansion-panel>

                    <!--Completed Yesterday-->
                    <v-expansion-panel class="swatchBGBG">
                        <v-expansion-panel-title>
                            <div class="expansion-panel-title">
                                <FontAwesomeIcon class="swatchGRN" :icon="['fa', 'calendar-day']" size="xl" />
                                {{ $t('CompletedYesterday').toUpperCase() }} ({{ completedYesterdayTasks.length }}):
                            </div>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <NavigatorTabWorkTaskItemList
                                :workTasks="completedYesterdayTasks"
                                :isTabOne="true"
                                :isCompressedMode="props.isCompressedMode"
                                :modelValue="props.modelValue"
                                @work-task-item-selected="selectItem" />
                        </v-expansion-panel-text>
                    </v-expansion-panel>

                    <!--Completed Last Week-->
                    <v-expansion-panel class="swatchBGBG">
                        <v-expansion-panel-title>
                            <div class="expansion-panel-title">
                                <FontAwesomeIcon class="swatchORG" :icon="['fa', 'calendar-week']" size="xl" />
                                {{ $t('CompletedLastWeek').toUpperCase() }} ({{ completedLastWeekTasks.length }}):
                            </div>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <NavigatorTabWorkTaskItemList
                                :workTasks="completedLastWeekTasks"
                                :isTabOne="true"
                                :isCompressedMode="props.isCompressedMode"
                                :modelValue="props.modelValue"
                                @work-task-item-selected="selectItem" />
                        </v-expansion-panel-text>
                    </v-expansion-panel>

                    <!--Completed Last 30 Days-->
                    <v-expansion-panel class="swatchBGBG">
                        <v-expansion-panel-title>
                            <div class="expansion-panel-title">
                                <FontAwesomeIcon class="swatchG2" :icon="['fa', 'calendar-range']" size="xl" />
                                {{ $t('CompletedLast30Days').toUpperCase() }} ({{ completedLast30DaysTasks.length }}):
                            </div>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <NavigatorTabWorkTaskItemList
                                :workTasks="completedLast30DaysTasks"
                                :isTabOne="true"
                                :isCompressedMode="props.isCompressedMode"
                                :modelValue="props.modelValue"
                                @work-task-item-selected="selectItem" />
                        </v-expansion-panel-text>
                    </v-expansion-panel>

                    <!--Completed Last 60 Days-->
                    <v-expansion-panel class="swatchBGBG">
                        <v-expansion-panel-title>
                            <div class="expansion-panel-title">
                                <FontAwesomeIcon class="swatchG3" :icon="['fal', 'calendar-range']" size="xl" />
                                {{ $t('CompletedLast60Days').toUpperCase() }} ({{ completedLast60DaysTasks.length }}):
                            </div>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <NavigatorTabWorkTaskItemList
                                :workTasks="completedLast60DaysTasks"
                                :isTabOne="true"
                                :isCompressedMode="props.isCompressedMode"
                                :modelValue="props.modelValue"
                                @work-task-item-selected="selectItem" />
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>

                <!--Tab Two-->
                <!--Future Tasks-->
                <NavigatorTabWorkTaskItemList
                    v-if="isFutureMode && props.farmField"
                    @drop.prevent="onDrop"
                    :workTasks="futureWorkTasks"
                    :isTabOne="false"
                    :isCompressedMode="props.isCompressedMode"
                    :modelValue="props.modelValue"
                    :is-drag-disabled="false"
                    @work-task-item-selected="selectItem" />

                <!--Recent Tasks-->
                <NavigatorTabWorkTaskItemList
                    v-if="!isFutureMode && props.farmField"
                    :workTasks="recentWorkTasks"
                    :isTabOne="false"
                    :isCompressedMode="props.isCompressedMode"
                    :modelValue="props.modelValue"
                    @work-task-item-selected="selectItem" />
            </v-list>
        </div>
        <div v-else class="pa-1">
            <div class="no-tasks-warning pa-4">
                <div style="font-size: large; font-weight: bold">{{ $t('NoTasks') }}</div>
                {{ noWorkDataText }}
            </div>
        </div>
        <v-snackbar v-model="snackbar.show" timeout="4000">
            {{ snackbar.text }}
        </v-snackbar>
    </div>
</template>

<script setup lang="ts">
import {ref, onMounted, inject, watch, computed, nextTick} from 'vue';
import {SearchWorkTasksRequest} from '@/models/api/requests/search/SearchWorkTasksRequest';
import {SearchWorkTasksResponse} from '@/models/api/responses/search/SearchWorkTasksResponse';
import ApiService from '@/services/api-service';
import {FarmFieldSearchResultDto} from '@/models/data-transfer-objects/search/farm-field-search/FarmFieldSearchResultDto';
import {WorkTaskSearchResultDto} from '@/models/data-transfer-objects/search/work-task-search/WorkTaskSearchResultDto';
import {useUserStore} from '@/stores/user-store';
import i18n from '@/i18n';
import NavigatorTabWorkTaskItemList from '@/views/home-dashboard/NavigatorTabWorkTaskItemList.vue';
import {useHomeDashboardStore} from '@/stores/home-dashboard-store';
import {RoleCode} from '@/enums/role-code';
import {NavigatorFutureTaskList} from '@/enums/home-dashboard/navigator-future-task-list';
import {addDays} from 'date-fns';
import {formatTime} from '@/helpers/TimeFormatHelper';
import {RescheduleWorkTaskRequest} from '@/models/api/requests/work-tasks/RescheduleWorkTaskRequest';
import {WorkTaskDueDateTiming} from '@/enums/work-task-due-date-timing';
import {RescheduleWorkTaskResponse} from '@/models/api/responses/work-tasks/RescheduleWorkTaskResponse';
import {useRescheduleWorkTaskModalStore} from '@/stores/modals/reschedule-work-task-modal-store';
const rescheduleWorkTaskModalStore = useRescheduleWorkTaskModalStore();
const snackbar = ref({
    show: false,
    text: '',
});

// Props
const props = withDefaults(
    defineProps<{
        modelValue?: WorkTaskSearchResultDto | null;
        isTab1: boolean;
        farmField?: FarmFieldSearchResultDto | null;
        isCompressedMode?: boolean;
    }>(),
    {
        modelValue: null,
        isTab1: true,
        farmField: null,
        isCompressedMode: false,
    },
);

const isFutureMode = computed(() => homeDashboardStore.isTaskFutureMode);

// Stores
const homeDashboardStore = useHomeDashboardStore();

// Local state for tasks
const workTasks = ref<WorkTaskSearchResultDto[]>([]);

const openedFutureExpansionPanels = ref<number[]>([0, 1, 2, 3, 4, 5, 6]);
const openedRecentExpansionPanels = ref<number[]>([0, 1, 2, 3, 4]);

// Function to fetch tasks from API
const apiService = inject('apiService') as ApiService;
const isLoading = ref(false);
const combinedLoading = computed(() => isLoading.value || homeDashboardStore.isLoading);
const userStore = useUserStore();
const hasWorkTaskAccess = userStore.hasRoleLevel(RoleCode.Tasks, 1);
const fetchWorkTasks = async () => {
    isLoading.value = true;

    // Build search request using the user ID
    const searchRequest: SearchWorkTasksRequest = new SearchWorkTasksRequest({
        isQuickSearch: false,
        farmSiteId: userStore.user?.farmSiteId,
        farmFieldId: props.farmField?.farmFieldId,
        // If showing tasks for a specific field, include all completed tasks (instead of only including those completed in the last X days)
        treatAllHistoricWorkTasksAsRecent: props.farmField?.farmFieldId ? true : false,
        includeWorkTaskMixtures: true,
        includeWorkTaskProducts: true,
    });

    // Get and display details
    const results: SearchWorkTasksResponse = await apiService.post('search/work-tasks', searchRequest);
    workTasks.value = results.workTasks;

    isLoading.value = false;
};

// Select item
const selectItem = async (item: WorkTaskSearchResultDto) => {
    if (isItemSelected(item)) {
        // If selecting the same field, deselect it
        if (props.isTab1) {
            homeDashboardStore.selectedWorkTaskLevel1 = null;
        } else {
            homeDashboardStore.selectedWorkTaskLevel2 = null;
        }
    } else {
        // Select the field
        if (props.isTab1) {
            homeDashboardStore.selectedWorkTaskLevel1 = item;
        } else {
            homeDashboardStore.selectedWorkTaskLevel2 = item;
        }
    }
};

// Check if item is selected
const isItemSelected = (item: WorkTaskSearchResultDto) => {
    return props.modelValue?.workTaskId == item.workTaskId;
};

// Format decimal points for field size
const formatFieldSize = (size: string) => {
    const sizeNumber = Number(size);
    return sizeNumber < 10
        ? i18n.global.n(sizeNumber, 'decimal')
        : sizeNumber < 100
        ? i18n.global.n(sizeNumber, 'decimal1')
        : i18n.global.n(sizeNumber, 'decimal0');
};

// Local user culture
const localCulturalCode = localStorage.getItem('languagePreference') ?? 'en-AU';

// Format date
const formatDate = (inputDate: Date): string => {
    const date = new Date(inputDate);

    const options: Intl.DateTimeFormatOptions = {day: 'numeric', month: 'long'};

    // Format the date to the desired format
    return date.toLocaleDateString(localCulturalCode, options);
};

// Future work tasks
const futureWorkTasks = computed(() => {
    if (homeDashboardStore.workTaskFutureFilterApplied && props.isTab1) {
        return homeDashboardStore.futureWorkTasksFiltered.filter((wt) => wt.isIncludedInFutureTasks);
    } else {
        return workTasks.value.filter((wt) => wt.isIncludedInFutureTasks);
    }
});

// Recent work tasks
const recentWorkTasks = computed(() => {
    if (homeDashboardStore.workTaskRecentFilterApplied && props.isTab1) {
        return homeDashboardStore.recentWorkTasksFiltered.filter((wt) => wt.isIncludedInRecentTasks);
    } else {
        return workTasks.value.filter((wt) => wt.isIncludedInRecentTasks);
    }
});

// Get no work data text
const noWorkDataText = computed(() => {
    if (hasWorkTaskAccess) {
        if (isFutureMode.value) {
            return i18n.global.t('NavigatorTaskList_NoFutureDataText');
        } else {
            return i18n.global.t('NavigatorTaskList_NoRecentDataText');
        }
    } else {
        return i18n.global.t('YouDoNotHaveWorkTaskAccess');
    }
});

// Get task tab recent text
const getTaskTabRecentText = computed(() => {
    const text = i18n.global.t('RecentTasks');

    return text.length > 15 ? i18n.global.t('Recent') : text;
});

// Get task tab future text
const getTaskTabFutureText = computed(() => {
    const text = i18n.global.t('FutureTasks');

    return text.length > 15 ? i18n.global.t('Future') : text;
});

const millisecondsPerDay = 24 * 60 * 60 * 1000;

// Utility function to check if two dates are on the same day
function isSameDay(date1: Date, date2: Date) {
    return date1.toDateString() === date2.toDateString();
}

// Utility function to get the start of a day (midnight)
function getStartOfDay(date: Date) {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);
    return d;
}

// FUTURE TASKS
// Utility function to filter tasks by due date
function filterTasksByDueDate(startDate: Date, endDate: Date) {
    return futureWorkTasks.value.filter((wt) => {
        const taskDueDate = getStartOfDay(new Date(wt.dueDate));
        return taskDueDate > startDate && taskDueDate <= endDate && !wt.isStarted;
    });
}

// All tasks that have been marked as currently underway
const underwayNowTasks = computed(() => {
    return futureWorkTasks.value.filter((wt) => wt.isStarted);
});

// All tasks that are overdue and have not been started
const overdueTasks = computed(() => {
    return futureWorkTasks.value.filter((wt) => wt.isIncludedInFutureTasks && wt.isOverdue && !wt.isStarted);
});

// All tasks that have a due date for today
const todayTasks = computed(() => {
    const now = new Date();
    return futureWorkTasks.value.filter((wt) => {
        const taskDueDate = new Date(wt.dueDate);
        return isSameDay(taskDueDate, now) && taskDueDate < now && !wt.isStarted;
    });
});

// All tasks that have a due date for the next 7 days (excluding today)
const next7DaysTasks = computed(() => {
    const today = getStartOfDay(new Date());
    const next7Days = new Date(today.getTime() + 7 * millisecondsPerDay);

    return filterTasksByDueDate(today, next7Days);
});

// All tasks that have a due date for the next 30 days (excluding the last 7 days)
const next30DaysTasks = computed(() => {
    const today = getStartOfDay(new Date());
    const startDate = new Date(today.getTime() + 7 * millisecondsPerDay);
    const endDate = new Date(today.getTime() + 30 * millisecondsPerDay);

    return filterTasksByDueDate(startDate, endDate);
});

// All tasks that have a due date for the next 60 days (excluding the last 30 days)
const next60DaysTasks = computed(() => {
    const today = getStartOfDay(new Date());
    const startDate = new Date(today.getTime() + 30 * millisecondsPerDay);
    const endDate = new Date(today.getTime() + 60 * millisecondsPerDay);

    return filterTasksByDueDate(startDate, endDate);
});

// RECENT TASKS
// Utility function to filter completed tasks
function filterCompletedTasks(startDate: Date, endDate: Date) {
    return recentWorkTasks.value.filter((wt) => {
        const workDate = wt.isCompleted ? wt.completedDate : wt.cancelledDate;
        const taskCompletedDate = getStartOfDay(new Date(workDate!));
        return taskCompletedDate >= startDate && taskCompletedDate < endDate;
    });
}

// All tasks completed today
const justCompletedTasks = computed(() => {
    const today = getStartOfDay(new Date());
    const tomorrow = new Date(today.getTime() + 1 * millisecondsPerDay);

    return filterCompletedTasks(today, tomorrow);
});

// All tasks completed yesterday
const completedYesterdayTasks = computed(() => {
    const today = getStartOfDay(new Date());
    const yesterday = new Date(today.getTime() - 1 * millisecondsPerDay);

    return filterCompletedTasks(yesterday, today);
});

// All tasks completed last week
const completedLastWeekTasks = computed(() => {
    const today = getStartOfDay(new Date());

    const sevenDaysAgo = new Date(today.getTime() - 7 * millisecondsPerDay);
    const yesterday = new Date(today.getTime() - 1 * millisecondsPerDay);

    return filterCompletedTasks(sevenDaysAgo, yesterday);
});

// All tasks completed in the last 30 days (excluding the first week)
const completedLast30DaysTasks = computed(() => {
    const today = getStartOfDay(new Date());

    const thirtyDaysAgo = new Date(today.getTime() - 30 * millisecondsPerDay);
    const sevenDaysAgo = new Date(today.getTime() - 7 * millisecondsPerDay);

    return filterCompletedTasks(thirtyDaysAgo, sevenDaysAgo);
});

// All tasks completed in the last 60 days (excluding the first 30 days)
const completedLast60DaysTasks = computed(() => {
    const today = getStartOfDay(new Date());

    const sixtyDaysAgo = new Date(today.getTime() - 60 * millisecondsPerDay);
    const thirtyDaysAgo = new Date(today.getTime() - 30 * millisecondsPerDay);

    return filterCompletedTasks(sixtyDaysAgo, thirtyDaysAgo);
});

// Drag related constants
const targetListId = ref<NavigatorFutureTaskList | null>(null);

/**
 * Called when a task drags over a valid target.
 */
const onDragOver = (target: NavigatorFutureTaskList) => {
    targetListId.value = target;
};

/**
 * Called when a task finishes dragging.
 */
const onDrop = () => {
    if (!props.isTab1) {
        if (
            homeDashboardStore.taskDragTarget &&
            homeDashboardStore.draggedWorkTaskId &&
            homeDashboardStore.taskDragTarget.workTaskId != homeDashboardStore.draggedWorkTaskId
        ) {
            const taskSource = workTasks.value.filter((f) => f.workTaskId == homeDashboardStore.draggedWorkTaskId);

            if (taskSource) {
                validateTaskDrag(
                    getDragStatus(taskSource[0], false),
                    getDragStatus(homeDashboardStore.taskDragTarget, true),
                    homeDashboardStore.draggedWorkTaskId,
                );
            }
        }
    } else {
        // If task is being moved
        if (homeDashboardStore.draggedWorkTaskId && targetListId.value) {
            // If source and destination differ
            if (homeDashboardStore.draggedSourceList && homeDashboardStore.draggedSourceList != targetListId.value) {
                validateTaskDrag(
                    homeDashboardStore.draggedSourceList,
                    targetListId.value,
                    homeDashboardStore.draggedWorkTaskId,
                );
            }

            targetListId.value = null;
        }
    }

    homeDashboardStore.draggedWorkTaskId = null;
    homeDashboardStore.draggedSourceList = null;
    homeDashboardStore.taskDragTarget = null;
};

/**
 * Get the due status of a task.
 */
const getDragStatus = (task: WorkTaskSearchResultDto, isTarget: boolean) => {
    if (task.isOverdue && !task.isStarted && !isTarget) {
        return NavigatorFutureTaskList.Overdue;
    } else if (task.isStarted) {
        return NavigatorFutureTaskList.UnderwayNow;
    }

    const now = new Date();
    const todayStart = getStartOfDay(now);
    const next7Days = new Date(todayStart.getTime() + 7 * millisecondsPerDay);
    const next30Days = new Date(todayStart.getTime() + 30 * millisecondsPerDay);

    const taskDueDate = getStartOfDay(new Date(task.dueDate));

    if (isTarget) {
        taskDueDate.setDate(taskDueDate.getDate() + 1);
        if (task.isOverdue && !task.isStarted && !isSameDay(taskDueDate, now)) {
            return NavigatorFutureTaskList.Overdue;
        }
    }

    if (isSameDay(taskDueDate, now)) {
        return NavigatorFutureTaskList.Today;
    }
    if (taskDueDate > todayStart && taskDueDate <= next7Days) {
        return NavigatorFutureTaskList.Next7Days;
    }
    if (taskDueDate > next7Days && taskDueDate <= next30Days) {
        return NavigatorFutureTaskList.Next30Days;
    }

    return NavigatorFutureTaskList.Next60Days;
};

/**
 * Validates the task drag action.
 */
const validateTaskDrag = async (
    sourceList: NavigatorFutureTaskList,
    targetList: NavigatorFutureTaskList,
    workTaskId: number,
) => {
    // Check if relocation is erroneous
    const hasErrors = checkForDragErrors(sourceList, targetList);

    // If there's no errors, check for warnings
    if (!hasErrors) {
        // Get date to reschedule by
        let dueDate;
        const today = new Date();

        if (props.isTab1) {
            switch (targetList) {
                case NavigatorFutureTaskList.Next7Days:
                    dueDate = addDays(today, 7);
                    break;
                case NavigatorFutureTaskList.Next30Days:
                    dueDate = addDays(today, 30);
                    break;
                case NavigatorFutureTaskList.Next60Days:
                    dueDate = addDays(today, 60);
                    break;
                default:
                    dueDate = today;
            }
        } else if (homeDashboardStore.taskDragTarget?.dueDate) {
            dueDate = addDays(homeDashboardStore.taskDragTarget.dueDate, 1);
        }

        if (dueDate) {
            const formattedDate = formatDateForApi(dueDate);

            const hasWarnings = await checkForDragWarnings(sourceList, workTaskId, formattedDate);

            // If there's warnings, open reschedule modal, otherwise update date
            if (hasWarnings) {
                rescheduleWorkTaskModalStore.open(workTaskId, WorkTaskDueDateTiming.DueBy, dueDate);
            } else {
                //Update due date if no warnings
                saveTaskReschedule(workTaskId, formattedDate);
            }
        }
    }
};

/**
 * Checks if the task relocation has errors.
 */
const checkForDragErrors = (sourceList: NavigatorFutureTaskList, targetList: NavigatorFutureTaskList) => {
    // Underway tasks can't be moved
    if (sourceList == NavigatorFutureTaskList.UnderwayNow) {
        showSnackbar('UnderwayTasksCannotBeChanged');
        return true;
    }

    // Tasks can't be set to Underway
    if (targetList == NavigatorFutureTaskList.UnderwayNow) {
        showSnackbar('TasksCannotBeSetToUnderway');
        return true;
    }

    // Tasks can't be set to Overdue
    if (targetList == NavigatorFutureTaskList.Overdue) {
        showSnackbar('TasksCannotBeSetToOverdue');
        return true;
    }

    // Overdue tasks can't be set to Today
    if (sourceList == NavigatorFutureTaskList.Overdue && targetList == NavigatorFutureTaskList.Today) {
        showSnackbar('OverdueTasksCannotBeSetToToday');
        return true;
    }

    return false;
};

/**
 * Checks if the task relocation has warnings.
 */
const checkForDragWarnings = async (sourceList: NavigatorFutureTaskList, workTaskId: number, dueDate: string) => {
    // Overdue tasks being rescheduled require confirmation
    if (sourceList == NavigatorFutureTaskList.Overdue) {
        return true;
    }

    // Build API request
    const request = buildRescheduleWorkTaskApiRequest(workTaskId, dueDate, false);

    // Call API to get impact of changes
    const response = (await apiService.post('work-tasks/reschedule-work-task', request)) as RescheduleWorkTaskResponse;

    if (response.workTasksThatWillBeChanged.length > 1) {
        return true;
    }

    return false;
};

/**
 * Saves the relocation change
 */
const saveTaskReschedule = async (workTaskId: number, dueDate: string) => {
    try {
        // Build API request
        const rescheduleWorkTaskRequest = buildRescheduleWorkTaskApiRequest(workTaskId, dueDate, true);

        // Call API to reschedule work task
        await apiService.post('work-tasks/reschedule-work-task', rescheduleWorkTaskRequest);

        // Set snackbar message
        showSnackbar('RescheduleWorkTask_Success');

        setTimeout(() => {
            homeDashboardStore.workTaskIdToFocus = workTaskId;
            rescheduleWorkTaskModalStore.savedCounter++;
        }, 700);
    } catch (ex: unknown) {
        // Show fail feedback to user
        showSnackbar('ErrorGeneric');
    }
};

/**
 * Builds the request object for the Action Task APIs.
 */
const buildRescheduleWorkTaskApiRequest = (workTaskId: number, dueDate: string, commitChanges: boolean) => {
    // Build API request
    const actionWorkTaskRequest: RescheduleWorkTaskRequest = {
        parameters: {
            workTaskId: workTaskId,
            dueDateTiming: WorkTaskDueDateTiming.DueBy,
            dueDateOn: null,
            dueDateLatest: dueDate,
            dueDateMonth: null,
            dueDateRange: null,
            dueAfterWorkTaskId: null,
            dueAfterWorkTaskWithinDays: null,
            rescheduleTaskReason: null,
            rescheduleTaskCascadeChangesOptionCode: null,
            commitChanges: commitChanges,
            getBeforeAndAfterTasks: !commitChanges,
        },
    };

    return actionWorkTaskRequest;
};

/**
 * Format date for the API.
 */
const formatDateForApi = (date: Date) => {
    return formatTime(date, 'yyyy-MM-dd');
};

/**
 * Display snackbar with message
 */
const showSnackbar = (message: string) => {
    snackbar.value.show = true;
    snackbar.value.text = i18n.global.t(message);
};

/**
 * Get class for the header.
 */
const getHeaderClass = (listId: NavigatorFutureTaskList) => {
    if (
        homeDashboardStore.isTaskDragMode &&
        targetListId.value == listId &&
        homeDashboardStore.draggedSourceList != listId
    ) {
        return 'drag-target';
    }
    return '';
};

const scrollToTask = (workTask: WorkTaskSearchResultDto) => {
    // Ensure the correct tab is selected
    if (workTask.isIncludedInFutureTasks) {
        homeDashboardStore.isTaskFutureMode = true;
    } else if (!workTask.isIncludedInRecentTasks) {
        homeDashboardStore.isTaskFutureMode = false;
    }

    // nextTick is used here to handle situations where the tab needs to be changed
    nextTick(() => {
        const element = document.getElementById('navigatorTabTask_' + workTask.workTaskId);
        if (element) {
            // Get the top position
            const header = document.querySelector('.divMasterHeaderRow');
            const panel = document.querySelector('.navigator-panel-tabs');
            const operations = document.querySelector('.navigator-panel-operations');
            const headerHeight = header ? header.clientHeight : 0;
            const panelHeight = panel ? panel.clientHeight : 0;
            const operationsHeight = operations ? operations.clientHeight : 0;
            const topPosition = headerHeight + panelHeight + operationsHeight;

            // Check if the top/bottom of the task item is in view
            const rect = element.getBoundingClientRect();
            const isTopInView = rect.top >= topPosition;
            const isBottomInView = rect.bottom <= (window.innerHeight || document.documentElement.clientHeight);

            // Scroll the task item into view if necessary
            if (!isTopInView) {
                element.scrollIntoView({behavior: 'smooth', block: 'start'});
            } else if (!isBottomInView) {
                element.scrollIntoView({behavior: 'smooth', block: 'end'});
            }
        }
    });
};

// Scroll the selected task into view
watch(
    () => homeDashboardStore.selectedWorkTaskLevel1,
    () => {
        if (homeDashboardStore.selectedWorkTaskLevel1) {
            scrollToTask(homeDashboardStore.selectedWorkTaskLevel1);
        }
    },
);

// Watch the farmField prop to refetch tasks when it changes
watch(
    () => props.farmField,
    (newVal, oldVal) => {
        if (newVal !== oldVal) {
            fetchWorkTasks();
        }
    },
    {deep: true},
);

// Watch for changes to the active tab
watch(isFutureMode, () => {
    // Deselect any active work tasks
    if (props.isTab1) {
        homeDashboardStore.selectedWorkTaskLevel1 = null;
    } else {
        homeDashboardStore.selectedWorkTaskLevel2 = null;
    }
});

// Fetch tasks on component mount
onMounted(() => {
    if (props.isTab1) {
        workTasks.value = homeDashboardStore.workTasks;
    } else {
        fetchWorkTasks();
    }
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/swatches.scss';

.farm-block-row {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .farm-block-name {
        color: $swatchA1;
        font-family: Condensed, sans-serif;
        width: 60px;
    }
}

.farm-block-row > * {
    align-content: center;
}

.planted-year-row {
    height: 28px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: $swatchWHT;
}

.task-tabs-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .task-tab {
        color: $swatchBLK;
        padding-bottom: 5px;

        &.active-tab {
            border-bottom: 5px solid $swatchB6;
            padding-bottom: 0px;
        }

        &:hover {
            background-color: rgba($swatchB7, 0.5);
        }

        .overdue-alert-icon {
            position: absolute;
            top: 5px;
            right: 10px;
            border-radius: 50%;
            background-color: $swatchRED;
            height: 13px;
            width: 13px;
            border: solid $swatchWHT 1px;
            z-index: 1;
        }
    }
}

.list-container {
    padding: 5px;
    background-color: $swatchBG;

    .expansion-panel-title {
        width: 100%;
        text-align: center;
        font-size: medium;
    }

    .drag-target {
        border-bottom: solid $swatchB6 3px;
    }
}

.list-item-container:last-child {
    margin-bottom: 0;
}

.no-tasks-warning {
    background-color: $swatchG6;
    border-radius: 5px;
    text-align: center;
}

:deep(.v-expansion-panel--active > .v-expansion-panel-title:not(.v-expansion-panel-title--static)) {
    min-height: 48px;
}

:deep(.v-expansion-panel--active:not(:first-child)),
:deep(.v-expansion-panel--active + .v-expansion-panel) {
    margin-top: 0px;
}

:deep(.v-expansion-panel-text__wrapper) {
    padding: 0 !important;
}
</style>
