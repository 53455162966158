<template>
    <v-form :class="{'first-item': index == 0}">
        <table style="width: 100%; border-spacing: 1px; margin-bottom: -1px" class="tblForm swatchG9BG">
            <tr v-if="index == 0">
                <td class="swatchG7BG">{{ $t('BlockCode') }}</td>
                <td class="swatchG7BG">{{ $t('Variety') }}</td>
                <td class="swatchG7BG">{{ $t('SeedOrigin') }}</td>
                <td class="swatchG7BG">{{ $t('SeedRate') }}</td>
                <td class="swatchG7BG">{{ $t('Information') }}</td>
                <td class="swatchG7BG">{{ $t('Rows') }}</td>
                <td class="swatchG7BG">{{ $t('Area') }}</td>
            </tr>
            <tr>
                <td style="width: 10%; text-align: center" class="swatchWHTBG swatchA1 bold">
                    {{ workTaskFarmBlock.farmBlockCode }}
                </td>
                <td style="width: 18%" class="swatchWHTBG">
                    <div style="display: flex; align-items: center">
                        <CropIcon
                            :cropCode="workTaskFarmBlock.cropCode"
                            :primary="true"
                            :varieties="[workTaskFarmBlock.cropVarietyName]"
                            :isCollapsed="true" />

                        <!-- View Crop Variety button -->
                        <v-btn
                            @click="viewCropVarietyModalStore.open(workTaskFarmBlock.cropVarietyId)"
                            class="v-btn--custom"
                            style="margin-left: auto">
                            <template v-slot:prepend>
                                <FontAwesomeIcon :icon="['fal', 'arrow-circle-right']" size="xl" />
                            </template>
                            {{ $t('View') }}
                        </v-btn>
                    </div>
                </td>
                <td style="width: 16%; text-align: center" class="swatchWHTBG">
                    <SeedOriginChip
                        v-if="workTaskFarmBlock.seedOriginBlockId"
                        :farmSiteAbbreviation="workTaskFarmBlock.seedOriginFarmSiteAbbreviation!"
                        :farmFieldCode="workTaskFarmBlock.seedOriginFarmFieldCode!"
                        :farmBlockCode="workTaskFarmBlock.seedOriginFarmBlockCode!" />
                </td>
                <td style="width: 13%" class="swatchWHTBG swatchA1 bold">
                    {{ $n(workTaskFarmBlock.seedRate, 'decimal') }}
                    {{ getSeedRateUnitName(workTaskFarmBlock.seedRateUnitCode) }}
                </td>
                <td style="width: 20%" class="swatchWHTBG">
                    <div
                        v-for="(artifact, index) in workTaskFarmBlock.artifacts"
                        :key="artifact.artifactId"
                        :class="{'mt-2': index > 0}">
                        <FileTypeButton
                            :url="artifact.artifactUrl"
                            :display-name="formatFileName(artifact.artifactDisplayName)"
                            :file-type-code="artifact.artifactFileTypeCode" />
                    </div>
                </td>
                <td style="width: 13%" class="swatchWHTBG">
                    <span class="swatchA1">
                        {{ workTaskFarmBlock.startRow + '-' + workTaskFarmBlock.endRow + ' ' }}
                    </span>
                    (
                    <span class="swatchA1">
                        {{ workTaskFarmBlock.farmBlockNumRows }}
                    </span>
                    <span class="swatchBLK">
                        {{ ' ' + (workTaskFarmBlock.farmBlockNumRows > 1 ? $t('Rows') : $t('Row')) }}
                    </span>
                    )
                </td>
                <td style="width: 10%" class="swatchWHTBG swatchA1">
                    {{ $n(workTaskFarmBlock.farmBlockSize, 'decimal') }} ha
                </td>
            </tr>
        </table>
    </v-form>
</template>

<script setup lang="ts">
import {WorkTaskFarmBlockDto} from '@/models/data-transfer-objects/search/work-task-search/WorkTaskFarmBlockDto';
import FileTypeButton from '@/components/FileTypeButton.vue';
import SeedOriginChip from '@/components/other-chips/SeedOriginChip.vue';
import {seedRateUnits} from '@/services/seed-rate-units-service';
import {useViewCropVarietyModalStore} from '@/stores/modals/view-crop-variety-modal-store';
const viewCropVarietyModalStore = useViewCropVarietyModalStore();

defineProps<{
    workTaskFarmBlock: WorkTaskFarmBlockDto;
    index: number;
}>();

/**
 * Shortern the file name if needed to ensure the buttons are not too wide.
 */
const formatFileName = (fileName: string, maxLength: number = 20): string => {
    if (fileName.length <= maxLength) {
        return fileName;
    }
    return fileName.slice(0, maxLength) + '...';
};

/**
 * The name of the seed rate unit.
 */
const getSeedRateUnitName = (seedRateUnitCode: string) => {
    return seedRateUnits.find((sru) => sru.seedRateUnitCode == seedRateUnitCode)?.getSeedRateUnitName();
};
</script>
