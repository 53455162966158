<template>
    {{ workTaskDate }}
</template>

<script setup lang="ts">
import {computed, defineProps} from 'vue';
import {WorkTaskSearchResultDto} from '@/models/data-transfer-objects/search/work-task-search/WorkTaskSearchResultDto';
import {formatTime} from '@/helpers/TimeFormatHelper';

const props = withDefaults(
    defineProps<{
        workTask: WorkTaskSearchResultDto;
        dateFormat?: string;
    }>(),
    {
        dateFormat: 'd MMMM yyyy',
    },
);

/**
 * Figure out which date to display depending on the status.
 */
const workTaskDate = computed(() => {
    let date: Date | null = null;

    if (props.workTask.isCompleted) {
        date = props.workTask.completedDate;
    } else if (props.workTask.isCancelled) {
        date = props.workTask.cancelledDate;
    } else {
        date = props.workTask.dueDate;
    }

    if (date == null) return null;
    else return formatTime(date, props.dateFormat);
});
</script>
