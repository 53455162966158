<template>
    <div class="loginForm">
        <!-- Login Form -->
        <v-card outlined class="transparent-card">
            <v-toolbar color="primary">
                <v-toolbar-title>
                    <FontAwesomeIcon :icon="['fal', 'user']" size="xl" class="mr-2" />
                    {{ $t('Login') }}
                </v-toolbar-title>
            </v-toolbar>

            <v-form class="pa-4 transparent-card" v-model="valid" @keyup.enter="login">
                <v-text-field
                    :label="usernameLabel"
                    variant="outlined"
                    v-model="username"
                    required
                    :hide-details="false"
                    :rules="usernameRules">
                    <template v-slot:prepend-inner>
                        <FontAwesomeIcon :icon="['fal', 'user']" size="xl" class="mr-1" style="width: 24px" />
                    </template>
                </v-text-field>

                <v-text-field
                    :label="$t('Password')"
                    variant="outlined"
                    type="Password"
                    v-model="password"
                    required
                    :hide-details="false"
                    :rules="passwordRules">
                    <template v-slot:prepend-inner>
                        <FontAwesomeIcon :icon="['fal', 'key']" size="xl" class="mr-1" style="width: 24px" />
                    </template>
                </v-text-field>

                <v-row justify="space-between" align="center" class="mb-1">
                    <v-col cols="auto">
                        <v-checkbox
                            :label="$t('RememberMe_Question')"
                            class="remember-me"
                            v-model="rememberMe"></v-checkbox>
                    </v-col>

                    <v-col cols="auto">
                        <router-link to="/forgotten-password">{{ $t('ForgotPassword_Question') }}</router-link>
                    </v-col>
                </v-row>

                <v-alert v-if="errorMessage" type="error" :value="true">
                    {{ errorMessage }}
                </v-alert>

                <div v-if="isLoading" style="text-align: center">
                    <v-progress-circular :size="20" class="mr-2"></v-progress-circular>
                    {{ $t('Loading_Ellipsis') }}
                </div>
                <v-btn v-else block color="primary" @click="login" :disabled="!valid">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'circle-arrow-right']" size="xl" />
                    </template>
                    {{ $t('LogIn_WithSpace') }}
                </v-btn>
            </v-form>
        </v-card>

        <!-- Login Message -->
        <div
            class="loginMessage"
            v-if="loginPageMessage != null && loginPageMessage != ''"
            v-html="loginPageMessage"
            style="width: 100%"></div>
    </div>
</template>

<script lang="ts">
import {defineComponent, inject, ref, onMounted, onUnmounted, computed} from 'vue';
import {useConfigStore} from '@/stores/config-store';
import {useUserStore} from '@/stores/user-store';
import {useFarmStore} from '@/stores/farm-store';
import ApiService from '@/services/api-service';
import router from '@/router';
import axios, {HttpStatusCode} from 'axios';
import {GetLastLoginDetailsResponse} from '@/models/api/responses/authentication/GetLastLoginDetailsResponse';
import i18n from '@/i18n';
import {useLocaleCodeStore} from '@/stores/locale-code-store';
import {LocaleCultureCode} from '@//models/system/LocaleCultureCode';

export default defineComponent({
    setup() {
        // Stores
        const configStore = useConfigStore();
        const userStore = useUserStore();
        const localeCodestore = useLocaleCodeStore();
        const farmStore = useFarmStore();

        // Loading
        const isLoading = ref<boolean>(false);
        const isLocaleCodeLoading = computed(() => !(localeCodestore.locales && localeCodestore.locales.length > 0));

        // Services
        const apiService = inject('apiService') as ApiService;

        // Form fields
        const username = ref<string>('');
        const password = ref<string>('');
        const rememberMe = ref<boolean>(false);
        const valid = ref<boolean>(true);
        const errorMessage = ref('');

        // Rules
        const usernameRules = ref<Array<(v: string) => true | string>>([
            (v) => !!v || i18n.global.t('Validation_Required'),
        ]);
        const passwordRules = ref<Array<(v: string) => true | string>>([
            (v) => !!v || i18n.global.t('Validation_Required'),
        ]);

        /**
         * Get the last login details of the user and set them automatically if needed.
         */
        const getLastLoginDetails = async () => {
            // Call API to get lockout details
            const response = (await apiService.get('authentication/last-login-details')) as GetLastLoginDetailsResponse;

            // If user details should be remembered
            if (response && response.rememberMe) {
                // Set username and remember me flag
                if (response.loginUsername != null) {
                    username.value = response.loginUsername;
                }
                if (response.rememberMe != null) {
                    rememberMe.value = response.rememberMe;
                }
            }
        };

        /**
         * Call API to attempt to log the user in with the specified credentials.
         */
        async function login() {
            if (valid.value) {
                try {
                    isLoading.value = true;

                    // Call API to log in
                    await apiService.login(username.value, password.value, rememberMe.value);

                    if (userStore.isLoggedIn) {
                        //Call API to get farm sites
                        await farmStore.fetchFarmSites(apiService);

                        //Call API to get locale codes if they have not been loaded yet
                        if (isLocaleCodeLoading.value) {
                            await localeCodestore.fetchLocaleCodeSettings(apiService);
                        }

                        const userLocale = localeCodestore.locales.filter(
                            (e) => e.localeCode === userStore.user?.localeCode,
                        );
                        const localStoredLocale = localStorage.getItem(
                            'languagePreference',
                        ) as LocaleCultureCode | null;
                        if (userLocale.length > 0) {
                            i18n.global.locale = userLocale[0].localeCultureCode as LocaleCultureCode;
                        } else i18n.global.locale = localStoredLocale ? localStoredLocale : 'en-AU';
                        localStorage.setItem('languagePreference', i18n.global.locale);

                        router.push({name: 'HomeDashboard'});
                    }
                } catch (error) {
                    if (axios.isAxiosError(error) && error.response) {
                        // Set the error message
                        if (error.response.status == HttpStatusCode.BadRequest) {
                            errorMessage.value = i18n.global.t('UsernameOrPasswordIsIncorrect');
                        } else {
                            errorMessage.value = i18n.global.t('LoginFailed');
                        }
                    } else {
                        // Generic error message if the error structure is not as expected
                        errorMessage.value = i18n.global.t('LoginFailedPleaseTryAgainLater');
                    }
                } finally {
                    if (!userStore.isLoggedIn) {
                        isLoading.value = false;
                    }
                }
            }
        }

        const usernameLabel = computed<string>(() => {
            if (configStore.enableLoginViaUsername) return i18n.global.t('EmailAddressOrUsername');
            else return i18n.global.t('EmailAddress');
        });

        const loginPageMessage = computed<string>(() => {
            return i18n.global.t('LoginPageMessage');
        });

        // Function to resolve the dynamic image path
        const resolveDynamicImagePath = (imageName: string): string => {
            const context = require.context('@/assets/images/login', false, /\.(png|jpe?g|svg)$/);
            return context(`./${imageName}`);
        };

        // Function to dynamically set the login background
        function setLoginBackground(imageName: string) {
            const loginBodyElement = document.querySelector('.login-body');

            if (loginBodyElement instanceof HTMLElement) {
                const resolvedImagePath = resolveDynamicImagePath(imageName);

                if (resolvedImagePath) {
                    loginBodyElement.style.backgroundImage = `url('${resolvedImagePath}')`;
                }
            }
        }

        onMounted(() => {
            document.body.classList.add('login-body');
            setLoginBackground(configStore.loginBackgroundImageFilename);
            getLastLoginDetails();
        });

        onUnmounted(() => {
            const loginBodyElement = document.querySelector('.login-body');
            if (loginBodyElement instanceof HTMLElement) {
                loginBodyElement.style.backgroundImage = '';
            }
            document.body.classList.remove('login-body');
        });

        return {
            // Stores
            userStore,
            configStore,

            // Services
            apiService,
            router,

            // Form fields
            username,
            password,
            rememberMe,
            valid,
            errorMessage,

            // Computed
            usernameLabel,
            loginPageMessage,

            // Rules
            usernameRules,
            passwordRules,

            // Functions
            login,

            // Other
            isLoading,
        };
    },
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/user/login.scss';

.transparent-card {
    background-color: rgba(44, 48, 59, 0.7);
    color: $swatchWHT;
}
</style>

<style>
.remember-me label {
    opacity: 1;
}

.remember-me {
    margin-top: -1rem; /* Remove margin */
    margin-bottom: -2.3rem; /* Remove margin */
}
</style>
