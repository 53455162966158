import {createApp} from 'vue';
import {createPinia} from 'pinia';
import App from './App.vue';
import router from './router';
import './assets/scss/main.scss';
import './assets/scss/swatch-colors.scss';
import './assets/scss/sys-uc.scss';
import './assets/scss/operations-menu.scss';
import './assets/scss/layout.scss';
import './assets/scss/buttons.scss';
import './assets/scss/vuetify-custom-classes.scss';
import './assets/scss/vue-date-picker-override.scss';
import 'vuetify/styles';
import '@mdi/font/css/materialdesignicons.css';
import ApiService from '@/services/api-service';
import vClickAway from './directives/v-click-away';
import vRipple from './directives/v-ripple';
import {FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText} from '@fortawesome/vue-fontawesome';
import BackButton from './components/BackButton.vue';
import CircleIcon from './components/CircleIcon.vue';
import CloseModalButton from './components/CloseModalButton.vue';
import HelpIcon from './components/HelpIcon.vue';
import IdIcon from './components/IdIcon.vue';
import LoadingSymbol from './components/LoadingSymbol.vue';
import WorkTaskTypeChip from './components/WorkTaskTypeChip.vue';
import WorkTaskTypeIcon from './components/WorkTaskTypeIcon.vue';
import CropIcon from './components/CropIcon.vue';
import TranslatableTexts from './components/TranslatableTexts.vue';
import {library} from '@fortawesome/fontawesome-svg-core';
import {fab, fal, fas, fak, fad, far} from '@awesome.me/kit-b64aff0d40/icons';
import '@vuepic/vue-datepicker/dist/main.css';
import i18n from './i18n';
import './assets/customIcons.js';
import HighchartsVue from 'highcharts-vue';
import LocalizedNumberInput from '@/components/LocalizedNumberInput.vue';
import {configureHighchartsLocale} from '@/i18n/highchartsLocale';

async function initApp() {
    const vuetify = await loadVuetify();
    const pinia = createPinia();
    const app = createApp(App);

    library.add(fab, fal, fas, fak, fad, far);

    // Create ApiService instance
    const apiService = new ApiService(pinia);

    // Use Pinia for state management
    app.use(pinia);

    // Provide ApiService to the whole application
    app.provide('apiService', apiService);

    // Register directives
    app.directive('click-away', vClickAway);
    app.directive('ripple', vRipple);

    // Add components
    app.component('BackButton', BackButton);
    app.component('CircleIcon', CircleIcon);
    app.component('CloseModalButton', CloseModalButton);
    app.component('FontAwesomeIcon', FontAwesomeIcon);
    app.component('FontAwesomeLayers', FontAwesomeLayers);
    app.component('FontAwesomeLayersText', FontAwesomeLayersText);
    app.component('HelpIcon', HelpIcon);
    app.component('IdIcon', IdIcon);
    app.component('LoadingSymbol', LoadingSymbol);
    app.component('WorkTaskTypeChip', WorkTaskTypeChip);
    app.component('WorkTaskTypeIcon', WorkTaskTypeIcon);
    app.component('CropIcon', CropIcon);
    app.component('TranslatableTexts', TranslatableTexts);
    app.component('LocalizedNumberInput', LocalizedNumberInput);

    app.use(router);
    app.use(vuetify);
    app.use(i18n);
    app.use(HighchartsVue);

    configureHighchartsLocale();

    app.mount('#app');
}

// Initialize the app
initApp();

/**
 * Initialize Vuetify.
 */
async function loadVuetify() {
    const {createVuetify} = await import('vuetify');
    const components = await import('vuetify/components');
    const directives = await import('vuetify/directives');

    const vuetify = createVuetify({
        components,
        directives,
        defaults: {
            VAutocomplete: {
                density: 'compact',
                hideDetails: 'auto',
                variant: 'outlined',
            },
            VBtn: {
                elevation: 0,
                rounded: '0',
            },
            VCard: {
                flat: true,
            },
            VExpansionPanels: {
                flat: true,
            },
            VMenu: {
                openDelay: 50,
            },
            VProgressCircular: {
                indeterminate: true,
            },
            VTabs: {
                bgColor: 'swatchG2',
                hideSlider: true,
                selectedClass: 'swatchA2BG',
            },
            VSelect: {
                density: 'compact',
                hideDetails: 'auto',
                variant: 'outlined',
            },
            VTextField: {
                density: 'compact',
                hideDetails: 'auto',
                variant: 'outlined',
            },
            VToolbar: {
                density: 'compact',
                flat: true,
            },
        },
        theme: {
            themes: {
                light: {
                    colors: {
                        primary: '#003B46',
                        secondary: '#00804E',
                        accent: '#00AF93',
                        error: '#DE3716',
                        info: '#1D82B9',
                        success: '#60A91D',
                        warning: '#FF8B06',

                        // Swatch Group A
                        swatchA1: '#003B46',
                        'on-swatchA1': '#FFFFFF',
                        swatchA2: '#005D47',
                        'on-swatchA2': '#FFFFFF',
                        swatchA3: '#00804E',
                        'on-swatchA3': '#FFFFFF',
                        swatchA4: '#7FC81C',
                        'on-swatchA4': '#FFFFFF',
                        swatchA5: '#B8E531',
                        'on-swatchA5': '#333333',
                        swatchA6: '#DDF587',
                        'on-swatchA6': '#333333',
                        swatchA7: '#EBFAB6',
                        'on-swatchA7': '#333333',

                        // Swatch Group B
                        swatchB1: '#00AF93',
                        'on-swatchB1': '#FFFFFF',
                        swatchB2: '#50C861',
                        'on-swatchB2': '#FFFFFF',
                        swatchB3: '#C5DF45',
                        'on-swatchB3': '#333333',
                        swatchB4: '#E9EA34',
                        'on-swatchB4': '#333333',
                        swatchB5: '#9D6848',
                        'on-swatchB5': '#FFFFFF',
                        swatchB6: '#3BA3DB',
                        'on-swatchB6': '#FFFFFF',
                        swatchB7: '#9BC9E1',
                        'on-swatchB7': '#FFFFFF',

                        // Swatch Group C
                        swatchC1: '#6BD23C',
                        swatchC2: '#3CD28C',
                        swatchC3: '#3C9AD2',
                        swatchC4: '#5D3CD2',
                        swatchC5: '#D23CC9',
                        swatchC6: '#D23C6D',
                        swatchC7: '#D2BB3C',

                        // Swatch Group GRAY
                        swatchG1: '#333333',
                        swatchG2: '#555555',
                        swatchG3: '#888888',
                        swatchG4: '#AAAAAA',
                        swatchG5: '#BFBFBF',
                        swatchG6: '#CCCCCC',
                        swatchG7: '#DDDDDD',
                        swatchG8: '#E5E5E5',
                        swatchG9: '#EEEEEE',
                        swatchGA: '#F5F5F5',
                        swatchGB: '#FDFDFD',

                        // Swatch Group ALERT
                        swatchERR: '#E4CD36',
                        swatchGRN: '#60A91D',
                        'on-swatchGRN': '#FFFFFF',
                        swatchORG: '#FF8B06',
                        swatchRED: '#DE3716',

                        // Swatch Group BASIC
                        swatchBG: '#F7F7F3',
                        swatchBLK: '#111111',
                        swatchPBG: '#FFFFFF',
                        swatchWHT: '#FFFFFF',
                    },
                },
            },
        },
    });
    return vuetify;
}
