<template>
    <div v-if="localFarmField" class="farm-details-header">
        <div style="width: 53.125%">
            <div class="farm-field-name">
                {{ localFarmField.farmFieldName }}
            </div>
            <div style="height: 28px; font-size: 15pt">
                <FontAwesomeIcon :icon="['fal', 'vector-polygon']" />
                <span class="farm-field-size">{{ formattedFieldSize }}ha</span>
            </div>
        </div>
        <div class="farm-field-icons">
            <CropIcon
                v-if="localFarmField.cropCode"
                :cropCode="localFarmField.cropCode"
                iconHeight="45"
                iconWidth="45"
                iconSize="25" />

            <FarmFieldAgeIcon
                v-if="localFarmField.cropYear"
                iconHeight="45"
                iconWidth="45"
                :fieldAge="localFarmField.cropYear.toString()" />

            <WorkTaskTypeIcon
                v-if="localFarmField.nextWorkTask"
                :workTaskTypeCode="nextTaskCode"
                iconHeight="45"
                iconWidth="45" />
        </div>
    </div>
</template>

<script setup lang="ts">
import {computed, inject, onMounted, ref, watch} from 'vue';
import {FarmFieldSearchResultDto} from '@/models/data-transfer-objects/search/farm-field-search/FarmFieldSearchResultDto';
import {WorkTaskTypeCode} from '@/enums/work-task-type-code';
import FarmFieldAgeIcon from '@/components/FarmFieldAgeIcon.vue';
import {SearchFarmFieldsRequest} from '@/models/api/requests/search/SearchFarmFieldsRequest';
import {SearchFarmFieldsResponse} from '@/models/api/responses/search/SearchFarmFieldsResponse';
import CropIcon from '@/components/CropIcon.vue';
import ApiService from '@/services/api-service';
import i18n from '@/i18n';

// Props
const props = defineProps<{farmField?: FarmFieldSearchResultDto; farmFieldId?: number}>();

// Local state for work task
const localFarmField = ref<FarmFieldSearchResultDto | undefined>(undefined);

// Function to fetch work task from API
const apiService = inject('apiService') as ApiService;

// Computed property for next task code
const nextTaskCode = computed(() => {
    return localFarmField.value?.nextWorkTask?.workTaskTypeCode as WorkTaskTypeCode;
});

// Format decimal points for field size
const formattedFieldSize = computed<string>(() => {
    const size = localFarmField.value?.farmFieldSize ?? 0;
    if (size === 0) {
        return '0';
    }
    return size < 10
        ? i18n.global.n(size, 'decimal')
        : size < 100
        ? i18n.global.n(size, 'decimal1')
        : i18n.global.n(size, 'decimal0');
});

// Obtain farm field details
const fetchFarmFieldDetails = async () => {
    // Reset object
    localFarmField.value = undefined;

    // Build search request using the user ID
    const searchRequest: SearchFarmFieldsRequest = new SearchFarmFieldsRequest({
        isQuickSearch: false,
        farmFieldId: props.farmFieldId,
    });

    // Get and display details
    const results: SearchFarmFieldsResponse = await apiService.post('search/farm-fields', searchRequest);
    if (results.farmFields.length == 1) {
        localFarmField.value = results.farmFields[0];
    }
};

// Watch for changes in farmFieldId
watch(
    () => props.farmFieldId,
    (newVal) => {
        if (newVal !== null) {
            fetchFarmFieldDetails();
        }
    },
);

// Watch for changes in farmField
watch(
    () => props.farmField,
    (newVal) => {
        if (newVal !== null) {
            localFarmField.value = props.farmField;
        }
    },
);

// Fetch items on component mount
onMounted(() => {
    if (props.farmField) {
        localFarmField.value = props.farmField;
    } else {
        fetchFarmFieldDetails();
    }
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/swatches.scss';

.farm-details-header {
    height: 80px;
    width: 100%;
    padding: 5px 10px 5px 10px;
    background-color: rgba($swatchA1, 0.92);
    display: inline-flex;

    .farm-field-name {
        height: 40px;
        font-size: larger;
        font-weight: bold;
        align-content: center;
        line-height: 1;
        word-wrap: break-word;
        overflow: hidden;
    }

    .farm-field-size {
        font-size: large;
        padding-left: 10px;
    }

    .farm-field-icons {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        flex-grow: 1;
    }
}
</style>
